import api from '../utils/api';


export const GetQuarterlyBonus = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/financials/getquarterlybonus',
        method: 'POST',
        data: data,
        ...options,
    });
};

export const getBonusLevels = (config = {}) => {
    const { options } = config;
    return api({
        url: `/api/financials/getquarterlybonuslist`,
        method: "GET",
        ...options,
    });
}

export const getCurrentMonthName = (config = {}) => {
    const { options } = config;
    return api({
        url: `/api/financials/getcurrentmonthname`,
        method: "GET",
        ...options,
    });
}

export const GetYTDBonus = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/financials/getytdbonus',
        method: 'POST',
        data: data,
        ...options,
    });
};