import AdminLayout from '../layouts/AdminLayout.jsx';
import Admin from '../pages/admin/Admin.js';
import Employee from '../pages/admin/Employee/employee';
import  SkillInterestDetail from '../pages/admin/SkillsInterests/skillInterestDetails';
import { AddEmployee } from '../pages/admin/Employee/addEditEmployee';
import Events from '../pages/admin/Events/events';
import { AddEditEvent } from '../pages/admin/Events/addEditEvent';
import News from '../pages/admin/News/news';
import HrBenefits from '../pages/admin/HrBenefits/hrBenefits';
import TextAlert from '../pages/admin/TextAlert/textAlert';
import Projects from '../pages/admin/projects/projects.js';
import { AddEditNews } from '../pages/admin/News/addEditNews';
import Financials from '../pages/admin/Financials/financials.js'
import DocumentLibrary from '../pages/admin/DocumentLibrary/documentlibrary.js';
import DocumentLibraryAddEdit from '../pages/admin/DocumentLibrary/documentLibraryAddEdit.js';
import Departments from '../pages/admin/Departments/departments.js'
import Skills from '../pages/admin/SkillsInterests/Skills';
import Interests from '../pages/admin/SkillsInterests/Interests';
import GGOBResources from '../pages/admin/GGOBResources/ggobResources';


export const adminRoutes = [
	// Admin Pages	
	{
		path: '/siteadmin',
		element: Admin,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/skillinterest/:openTab?',
		element: SkillInterestDetail,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/employee',
		element: Employee,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/employee/create',
		element: AddEmployee,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/employee/edit/:employeeId',
		element: AddEmployee,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/events',
		element: Events,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/event/create',
		element: AddEditEvent,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/event/edit/:eventBaseId',
		element: AddEditEvent,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/event/edit/:eventBaseId/:eventLogId',
		element: AddEditEvent,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/news',
		element: News,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/hrbenefits',
		element: HrBenefits,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/hrbenefits/:openTab?',
		element: HrBenefits,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/textalerts/:openTab?',
		element: TextAlert,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/textalerts/:openTab?/:composeid?',
		element: TextAlert,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/projects',
		element: Projects,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/news/create',
		element: AddEditNews,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/news/edit/:newsBaseId',
		element: AddEditNews,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/financials',
		element: Financials,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: 'siteadmin/document-library',
		element: DocumentLibrary,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: 'siteadmin/document-library/create',
		element: DocumentLibraryAddEdit,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: 'siteadmin/document-library/edit/:baseId',
		element: DocumentLibraryAddEdit,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/departments',
		element: Departments,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/skills',
		element: Skills,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/interests',
		element: Interests,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/siteadmin/ggob-resources',
		element: GGOBResources,
		layout: AdminLayout,
		adminPage: true
	},
]