import api from '../utils/api';


export const getDocument = (uid, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/doccenter/${uid}`,
        method: "GET",
        ...options,
    });
}

export const getVimeoToken = (config = {}) => {
    const { options } = config;
    return api({
        url: `/api/doccenter/getVimeoToken`,
        method: "GET",
        ...options,
    });
}