import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MediaUploader from '../../../components/multipleMedia/MediaUploader.js';
export function ProjectsAddEditModal({ show, onClose, handleAddEditProject, modelProject }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorMediaMessage, setErrorMediaMessage] = useState(null);
    const [formDataJobPosting, setFormDataJobPosting] = useState(null);
    const [mediaItems, setMediaItems] = useState([]);
    const buttonRef = useRef(null);
    const modalRef = useRef(null);
    useEffect(() => {
        setFormDataJobPosting(modelProject);
        if (modelProject?.projectMedias.length > 0) {
            var getMediaItems = [];
            modelProject.projectMedias.forEach((item) => {
                getMediaItems.push(item.mediaItem);
            });
            setMediaItems(getMediaItems);
        }

    }, [modelProject, show]);

    const validationOnVideoUpload = () => {
        if (!formDataJobPosting.title) {
            setErrorMessage('Project title is required.');
            return false;
        } else {
            setErrorMessage(null);
            return true;
        }
    };

    const handleSave = () => {
        if (!formDataJobPosting.title) {
            setErrorMessage('Project title is required.');
        } else {
            setErrorMessage(null);
        }

        if (mediaItems.length === 0) {
            setErrorMediaMessage('Image/video is required.');
            return;
        } else {
            setErrorMediaMessage(null);
        }

        if (errorMessage !== null || errorMediaMessage != null) {
            return;
        }

        var projectUpdateMedia = [];

        mediaItems.forEach((item) => {
            var eventMediaObj = {
                mediaId: item.id,
                mediaItem: item
            };
            projectUpdateMedia.push(eventMediaObj);
        });

        const newObj = { ...formDataJobPosting };
        newObj.projectMedias = projectUpdateMedia;

        if (newObj.title) {
            handleAddEditProject(newObj);
            onClose();
        }
    };

    const handleOnClose = () => {
        onClose();
        setErrorMessage(null);
        setErrorMediaMessage(null);
    };

    const handleMediaUpdates = (mediaItems) => {

        setMediaItems(mediaItems);
    };


    const handleOverlayClick = (e) => {
        if (modalRef.current.contains(e.target)) {
            return;
        }
        handleOnClose();
    };

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay installed-add-modal" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role" ref={modalRef}>
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Add/Edit Project</h2>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-radio-label inline">
                                <input className="admin-inline-checkbox" type="radio" id="active" name="activeproject" value={true} checked={formDataJobPosting?.active === true} onChange={() => setFormDataJobPosting({ ...formDataJobPosting, active: true })} />
                                Active
                            </label>
                            <label className="admin-radio-label inline">
                                <input className="admin-inline-checkbox" type="radio" id="inactive" name="activeproject" value={false} checked={formDataJobPosting?.active === false} onChange={() => setFormDataJobPosting({ ...formDataJobPosting, active: false })} />
                                Inactive
                            </label>
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="email-domain">Project Title*</label>
                            <input
                                type="text"
                                id="email-domain"
                                label="Email Url"
                                name="domain"
                                value={formDataJobPosting?.title || ''}
                                onChange={(event) => {
                                    setFormDataJobPosting({ ...formDataJobPosting, title: event.target.value });
                                    setErrorMessage(null);
                                }}
                            />

                            {errorMessage && <p className="error-messagess">{errorMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell video-thumbnail-cell">
                            <label className="admin-default-label" htmlFor="email-domain">Image/Video*</label>
                            <MediaUploader
                                isPlaceHolder={false}
                                requireAltText={false}
                                imageOnly={false}
                                videoOnly={false}
                                forceCrop={false}
                                allowGallery={true}
                                mediaItems={mediaItems}
                                saveMediaItems={handleMediaUpdates}
                                allowSorting={false}
                                sortInTable="ProjectMedia"
                                idealSizeText="1920px X 1080px"
                                width={1920}
                                higth={1080}
                                validationOnVideoUpload={validationOnVideoUpload}
                            />
                            {errorMediaMessage && <p className="error-messagess">{errorMediaMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" ref={buttonRef} onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

ProjectsAddEditModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddEditProject: PropTypes.object,
    modelProject: PropTypes.object,
};
