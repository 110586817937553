import React from 'react';
import { ModuleEditor } from "./ModuleEditor";
import { PageEditorProvider } from './ModuleEditorContext';
import PropTypes from 'prop-types';

const ModuleWrapper = ({setValue, onChange, isEdit}) => {
  return (
    <PageEditorProvider setValue={setValue} onChange= {onChange} isEdit={isEdit}>
      <ModuleEditor />
    </PageEditorProvider>
  );
};

ModuleWrapper.propTypes = {
    setValue: PropTypes.shape({
        modules: PropTypes.arrayOf(PropTypes.object),
    }),
    onChange: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
};
export default ModuleWrapper;
