import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { formatUSD } from "../../helpers/formatterHelper";
import YtdActualBoxes from './YtdActualBoxes';
import YtdBudgetedBoxes from './YtdBudgetedBoxes';

function findNearestIndex(array, number) {
    let minDiff = Infinity;
    let nearestIndex = null;

    array.forEach((element, index) => {
        const diff = Math.abs(element - number);

        if (diff < minDiff) {
            minDiff = diff;
            nearestIndex = index;
        }
    });

    return nearestIndex;
}

export default function YTDBonusPage({ ytdList, getCurrentMonthNameId }) {
    const graphLines = useRef(null);
    const [scaleAmount, setScaleAmount] = useState([]);
    const [graphLinesHeight, setGraphLinesHeight] = useState(0);
    const [quarterlyBonusMonths, setQuarterlyBonusMonths] = useState([]);
    const [scaleAmountwithNegative, setScaleAmountwithNegative] = useState([]);
    const [negativeScaleAmount, setNegativeScaleAmount] = useState([]);
    const [quarterLastMonthsList, setQuarterLastMonthsList] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [ytdScale, setYtdScale] = useState([]);  

    useEffect(() => {
        let scaleAmountList = [];
        const nagativeScaleAmountList = [];
        const scaleAmountListWithnagative = [];
        const bonuslevelsQ1 = ytdList.find(item => item.name === "Q1");
        const bonuslevelsQ4 = ytdList.find(item => item.name === "Q4");
        const bonuslevelsBottomQ1 = bonuslevelsQ1 && bonuslevelsQ1.bonuslevels[0];
        const bonuslevelsBottomQ4 = bonuslevelsQ4 && bonuslevelsQ4.bonuslevels[bonuslevelsQ4.bonuslevels.length - 1];

        let totalLength = 0;
        ytdList.forEach((item) => {
            if (item && Array.isArray(item.bonuslevels)) {
                totalLength += item.bonuslevels.length;
            }
        });

        const quarterlyBonusMonthslist = ytdList
            .map(item => item.quarterlyBonusMonths) 
            .flat();

        const quarterLastMonths = ytdList
            .map(item => item.quarterlyBonusMonths[0].monthName)
            .flat();

        setQuarterLastMonthsList(quarterLastMonths);

        if (bonuslevelsBottomQ1 && bonuslevelsBottomQ4) {
            const calScaleAmount = Math.floor(Math.abs(bonuslevelsBottomQ4.budgetAmount - bonuslevelsBottomQ1.budgetAmount) / (totalLength - 1));


            const topScale1 = Math.round(bonuslevelsQ4?.quarterTotal + (bonuslevelsQ4?.quarterTotal * 5 / 100));
            const bonusMonths = bonuslevelsQ4?.quarterlyBonusMonths[0];
            const topScale2 = bonusMonths && bonusMonths.consolidatedBonus?.actualProjectedSum && Math.round(bonusMonths.consolidatedBonus?.actualProjectedSum + (bonusMonths.consolidatedBonus?.actualProjectedSum * 5 / 100));

            let topScaleValue;
            if (topScale1 >= topScale2) {
                topScaleValue = topScale1;            
            } else {
                topScaleValue = topScale2;
            }

            const nagativeValues = quarterlyBonusMonthslist.filter(item => item.id <= getCurrentMonthNameId && (item.consolidatedBonus.actualProjectedSum < 0 || item.consolidatedBonus.actualAmount < 0));

            let i;
            for (i = calScaleAmount; i <= topScaleValue; i += calScaleAmount) {
                scaleAmountList.unshift(Math.round(i));
                scaleAmountListWithnagative.unshift(Math.round(i));	
            }

            if (nagativeValues && nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < calScaleAmount)) {
                scaleAmountListWithnagative.push(0);
                let value = -calScaleAmount;
                let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < calScaleAmount);
                let endValue = nagativeValue.consolidatedBonus.actualProjectedSum - calScaleAmount;
                while (value >= endValue) {
                    nagativeScaleAmountList.push(value);
                    scaleAmountListWithnagative.push(Math.round(value));
                    value -= calScaleAmount;
                }
            }


            const nagativeBudgetedValues = quarterlyBonusMonthslist.filter(item => item.consolidatedBonus.budgetSum < 0 || item.consolidatedBonus.budgetAmount < 0);

            if (nagativeBudgetedValues && nagativeBudgetedValues.find(item => item.consolidatedBonus.budgetSum < calScaleAmount)) {
                scaleAmountListWithnagative.push(0);
                let value = -calScaleAmount;
                let nagativeValue = nagativeBudgetedValues.find(item => item.consolidatedBonus.budgetSum < calScaleAmount);
                let endValue = nagativeValue?.consolidatedBonus.budgetSum - calScaleAmount;
                while (value >= endValue) {
                    nagativeScaleAmountList.push(value);
                    scaleAmountListWithnagative.push(Math.round(value));
                    value -= calScaleAmount;
                }
            }


            if (topScale1 == topScaleValue) {
                const getIndex = findNearestIndex(scaleAmountList, bonuslevelsQ4?.quarterTotal);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));	
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));	
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));	
                }
            }

            if (topScale2 == topScaleValue && bonusMonths) {
                const getIndex = findNearestIndex(scaleAmountList, bonusMonths.consolidatedBonus?.actualProjectedSum);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));	
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));	
                }
            }
        }

        
        const calYtdScale = scaleAmountList.length > 0 && Math.floor(scaleAmountList.length / 4);
        if (scaleAmountList.length > 0) {
            const dividedScaleAmountList = [];
            for (let i = scaleAmountList.length; i >= 0; i -= calYtdScale) {         
                if (scaleAmountList[i]) {
                    dividedScaleAmountList.push(scaleAmountList[i]);
                }
            }         
            setYtdScale(dividedScaleAmountList);
        }        


        setScaleAmount(scaleAmountList);
        setQuarterlyBonusMonths(quarterlyBonusMonthslist);
        setScaleAmountwithNegative(scaleAmountListWithnagative);
        setNegativeScaleAmount(nagativeScaleAmountList);

    }, [ytdList]);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const rect = graphLines.current?.getBoundingClientRect();
        setGraphLinesHeight(rect.height);
    }, [graphLines, scaleAmount, windowWidth]);

    const GetCustomYTDList = (quraterName) => {
        let filterYTDList;
        if (quraterName === "Q1") {
            filterYTDList = ytdList.filter(item => item.name === quraterName);
        }
        else if (quraterName === "Q2") {
            filterYTDList = ytdList.filter(item => item.name === "Q1" || item.name === "Q2");
        }
        else if (quraterName === "Q3") {
            filterYTDList = ytdList.filter(item => item.name !== "Q4");
        }
        else if (quraterName === "Q4") {
            filterYTDList = ytdList;
        }
        return filterYTDList;
    }

    const GetQuarterGoal = (quraterName) => {
        const filterYTDList = GetCustomYTDList(quraterName);
        let total = 0;
        filterYTDList.forEach(bonusmonth => {
            bonusmonth.quarterlyBonusMonths.forEach(month => {
                total += month.consolidatedBonus.budgetAmount;
            });
        });

        return total;
    }

    const GetActualProjected = (quraterName) => {
        const filterYTDList = GetCustomYTDList(quraterName);
        let total = 0;

        filterYTDList.forEach(bonusmonth => {
            bonusmonth.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId).forEach(month => {
                const actualAmount = month.consolidatedBonus.actualAmount;
                const projectedAmount = month.consolidatedBonus.projectedAmount;

                if (actualAmount !== 0 && projectedAmount !== 0) {
                    // Both actual and projected have values
                    total += actualAmount;
                } else {
                    // At least one of actual or projected is zero
                    total += actualAmount + projectedAmount;
                }
            });
        });

        return total;
    }

    const GetBonusPercent = (quraterName) => {
        const filterYTDList = GetCustomYTDList(quraterName);

        let total = GetActualProjected(quraterName);
        
        let currentQuarter = filterYTDList.find(item => item.name === quraterName);

        if (currentQuarter && currentQuarter.bonuslevels) {
            let matchBudgetIndex = currentQuarter.bonuslevels.findIndex(x => x.budgetAmount >= total);
            return matchBudgetIndex > 0 ? matchBudgetIndex : 0;
        } else {
            return 0;
        }

    }

    const calQGoalHeight = (quarterTotal) => {
        const index = findNearestIndex(scaleAmount, quarterTotal);
        return index * graphLinesHeight;
    }

    const getTop = () => {     
        const isTopValue = ytdScale.includes(scaleAmount[0]) ? true : false;
        return (
            <div className="bonus-levels-graph-lines" ref={graphLines}>
                <p className="bonus-levels-numbers">{isTopValue && scaleAmount.length > 0 && formatUSD(scaleAmount[0])}</p>
            </div>
        );
    }


    const getQuarterNameById = (quarterlyBonusId) => {
        const quarter = ytdList.find(x => x.id === quarterlyBonusId);
        return quarter && quarter.name;
    }    

    const reverseArray = () => {       
        return quarterlyBonusMonths && [...quarterlyBonusMonths].reverse();
    };


    return (
        <div className="bonus-levels-graph-outer-wrap bonus-levels-desktop-wrap YTD-wrapper">            
            {getTop()}
            {scaleAmount && scaleAmount.length > 0 && scaleAmount.slice(1).map((item, index) => { 
                const isInterval = ytdScale.includes(item) ? true : false;
                return (
                    <div className="bonus-levels-graph-lines" key={index}>
                        <p className="bonus-levels-numbers">{isInterval && formatUSD(item)}</p>
                    </div>
                )
            })}
            <div className="bonus-levels-graph-baseline"></div>

            {negativeScaleAmount && negativeScaleAmount.map((item, index) => {            
                const isInterval = ytdScale.includes(Math.abs(item)) ? true : false;
                return (
                    <div className="bonus-levels-graph-lines" key={index}>
                        <p className="bonus-levels-numbers">{isInterval && formatUSD(item)}</p>
                    </div>
                )}
            )}

            {ytdList && ytdList.length > 0 &&
                ytdList.map((item, index) => (
                    <div className={`q1-goal-wrap ytd-goal-${index + 1}`} key={item.id} style={{ top: `${calQGoalHeight(GetQuarterGoal(item.name))}px` }} >
                        <p className="q1-goal-text">
                            <span className="d-sm-none">{item.name === "Q4" ? 'Annual' : item.name} Budgeted
                                <span className="ytd-goal-value"> {formatUSD(GetQuarterGoal(item.name))}</span>
                            </span>
                        </p>
                    </div>
                ))}            


            <YtdBudgetedBoxes
                quarterlyBonusMonths={reverseArray()}
                getCurrentMonthNameId={getCurrentMonthNameId}
                scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
                graphLinesHeight={graphLinesHeight}
                isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
            />

            <YtdActualBoxes
                quarterlyBonusMonths={reverseArray()}
                getCurrentMonthNameId={getCurrentMonthNameId}
                scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
                graphLinesHeight={graphLinesHeight}
                getBonusPercent={GetBonusPercent}
                getQuarterNameById={getQuarterNameById}
                getActualProjected={GetActualProjected}
                quarterLastMonthsList={quarterLastMonthsList}
                isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
                windowWidth={windowWidth}
            />            
        </div>
    );
}

YTDBonusPage.propTypes = {
    ytdList: PropTypes.array,
    getCurrentMonthNameId: PropTypes.number
};