import api from '../utils/api';

export const getAllDocuments = (obj) => {
    return api({
        url: '/api/documents/get-all',
        method: 'POST',
        data: obj
    });
}

export const addDocument = (dataObj) => {
    return api({
        url: '/api/documents/add-document',
        method: 'POST',
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: dataObj
    });
}

export const getDetails = (baseid) => {
    return api.get('/api/documents/get-details/' + baseid);
}

export const deleteDocument = (baseid) => {
    return api.delete('/api/documents/delete/' + baseid);
}

export const deleteDocumentFile = (baseid) => {
    return api.post('/api/documents/deleteFile/' + baseid);
}