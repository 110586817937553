import api from '../utils/api';

export const getEmployeeList = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/employee/getAllEmployee`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const deleteEmployee = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/employee/deleteEmployee/${data}`,
        method: "DELETE",
        data: data,
        ...options,
    });
}

export const submitEmployee = (employee) => {
    let url = '/api/employee/addUpdateEmployee';
    return api({
        url: url,
        method: 'POST',
        data: employee
    })
}

export const getCompanies = (config = {}) => {
    const { options } = config;
    return api({
        url: `api/employee/getCompanies`,
        method: "GET",
        ...options,
    });
}

export const getEmployeeById = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/employee/getEmployeeById/${data.id}`,
        method: "GET",
        ...options,
    });
}

export const getLoggedInEmployeeById = (config = {}) => {
  const { data, options } = config;
  return api({
    url: `api/employee/getEmployeeById/${data.id}`,
    method: "GET",
    ...options,
  });
}

export const getCompanyListByCompanyid = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/employee/getDepartmentByCompanyId/${data}`,
        method: "GET",
        data: data,
        ...options,
    });
}

export const getEmployeeByIdEdit = (employeeId) => {
  return api({
    url: '/api/employee/getEmployeeById/' + employeeId,
    method: 'GET'
  })
}

export const saveEmployeeInfo = (config = {}) => {
  const { data, options } = config;
  return api({
    url: `api/employee/addUpdateEmployee`,
    method: "POST",
    data: data,
    ...options,
  });
}


export const getEmployeeWorkAnniversaries = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/employee/getEmployeeAnniversaries`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const getShirtSizes = (config = {}) => {
    const { options } = config;
    return api({
        url: `api/employee/getshirtsizeslist`,
        method: "GET",
        ...options,
    });
}

export const getSearchEmployee = (config = {}) => {
    const { data, options } = config;
    let value = data == null ? null : data.data;
    return api({
        url: `api/employee/getSearchEmployees/${value}`,
        method: "GET",
        ...options,
    });
}