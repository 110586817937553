import React from "react";

import PropTypes from 'prop-types';
import { formatUSD, getMonthshortName, findNearestIndex } from "../../helpers/formatterHelper";


export default function YtdBudgetedBoxes({ quarterlyBonusMonths, getCurrentMonthNameId, scaleAmount, lastQuarterActualAmount, isNegativeScale, graphLinesHeight }) {


	const BoxPositivePosition = (amount, i, isPeojected) => {

		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.budgetAmount !== 0);
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.budgetSum));
		const top = itemsIndex[i];

		let bottom;
		if (itemsIndex[i - 1] != undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (lastQuarterActualAmount != 0 && isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}

		let indexCount;
		let css = {};
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: (scaleAmount.length - bottom) * graphLinesHeight, position: (i === 1 && isNegativeScale ? "relative" : 'absolute') }
		}		

		css = { ...css, height: indexCount * graphLinesHeight }	

		console.log(isPeojected);

		return css;
	}

	const BoxNegativePosition = (amount, i) => {
		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.budgetAmount !== 0);
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.budgetSum));

		let top = itemsIndex[i];

		let bottom;
		if (itemsIndex[i - 1] !== undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}

		if (i === 0 && isNegativeScale === false) {
			const bottomScale = scaleAmount.length - top;
			bottom = bottom - bottomScale;
			top = top + bottomScale;
		}

		let css = {};
		let indexCount;
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}

		css = { ...css, height: indexCount * graphLinesHeight, position: 'absolute' }


		return css;
	}

	const getActualDivHeight = () => {
		const index = isNegativeScale ? 0 : null;

		if (index !== null) {
			return { bottom: 0 };
		}
		return null;
	}


    return (
		<div className="budgeted-boxes-outer-wrap bonus-budget-negative-wrapper-ytd" style={getActualDivHeight()}>
			{quarterlyBonusMonths && quarterlyBonusMonths.map((month, index) => {
                if (month?.consolidatedBonus?.budgetAmount > 0) {
                    const isCurrentMonth = getCurrentMonthNameId === month.id ? true : false;
					const boxCss = BoxPositivePosition(month.consolidatedBonus?.budgetSum, index, isCurrentMonth);

                    return (
						<div className="budgeted-boxes budgeted-boxes-height" key={month.id} style={boxCss}>
							<div className="budgeted-text-wrap">
								{getCurrentMonthNameId && getCurrentMonthNameId > 0 && getCurrentMonthNameId === month.id && (
									<p className="budgeted-text">{getMonthshortName(month.monthName)}</p>
								)}
								{getCurrentMonthNameId && getCurrentMonthNameId > 0 && getCurrentMonthNameId === month.id && (
									<p className="box-highlight-number">{month.consolidatedBonus?.budgetSum && formatUSD(month.consolidatedBonus?.budgetSum)}</p>
								)}
							</div>
                        </div>
                    );
				} else {

					const negativeBoxCss = BoxNegativePosition(month?.consolidatedBonus?.budgetSum, index);

                    return (
						<div className="budgeted-boxes budgeted-boxes-height loss-budget-box" key={month.id} style={negativeBoxCss}>
							<div className="budgeted-text-wrap">
								{getCurrentMonthNameId && getCurrentMonthNameId > 0 && getCurrentMonthNameId === month.id && (
									<p className="budgeted-text">{getMonthshortName(month.monthName)}</p>
								)}
								{getCurrentMonthNameId && getCurrentMonthNameId > 0 && getCurrentMonthNameId === month.id && (
									<p className="box-highlight-number">{month.consolidatedBonus?.budgetSum && formatUSD(month.consolidatedBonus?.budgetSum)}</p>
								)}
							</div>
						</div>
                    );
                }
                
            })}

        </div>
    );
}

YtdBudgetedBoxes.propTypes = {
	quarterlyBonusMonths: PropTypes.array,
    getCurrentMonthNameId: PropTypes.number,

    month: PropTypes.object,
	itemindex: PropTypes.number,
	scaleAmount: PropTypes.array,
	graphLinesHeight: PropTypes.number,
	scaleValue: PropTypes.number,
	lastQuarterActualAmount: PropTypes.number,
	isNegativeScale: PropTypes.bool,
	quarterLastMonthsList: PropTypes.array,
	getBonusPercent: PropTypes.func,
	getQuarterNameById: PropTypes.func,
	getActualProjected: PropTypes.func,
	windowWidth: PropTypes.number
};