
export const employeeItem = {
    id: 0,
    primaryCompanyId: 0,
    firstName: '',
    lastName: '',
    nickname: '',
    profileImageId: null,
    startDate: new Date(),
    birthDate: '',
    hideBirthDate: false,
    mobile: '',
    hideMobile: true,
    workPhone: '',
    workEmail: '',
    workPhoneExt: '',
    alternateContactFirstName:'',
    alternatePhone: '',
    biography: '',
    cWPPartnerUserName: '',
    toolCWPPartnerVisible: false,
    toolPaycomVisible: false,
    toolComputoollVisible: false,
    toolInnergyERPVisible: false,
    toolDashboardVisible: false,
    toolQWPISalesVisible: false,
    toolQWPIShopVisible: false,
    userDepartments: [],
    userCompanies: [],
    userSkills: [],
    userInterests: [],
    profileImage: null,
    alternateContactLastName: '',
    shirtSizeId: null,
    primaryPositionId: null,
    isGGOBResources: true,
}