import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../../store/employee/actions';
import { SkillDeleteConfrimationModal } from './SkillDeleteConfirmation';
import { SkillAddEditModal } from './skillAddEditModal';
import {  getSkillAndCompanyDataV2, updateSkillOrder } from '../../../store/skill/actions';
import { saveSkill, editSkill, getCompanyBySkillId, getEmployeesBySkillId, deleteSkill } from '../../../api/skill';
import { Company } from './SkillContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const PositionsSkills = () => {
    const dispatch = useDispatch();
    const { companySkillListV2 } = useSelector(state => state.skill);

    const moveItem = (item, targetDepartmentId, targetCompanyId) => {    
        // Check if the item is being moved to another company
        if (item?.sourceCompanyId !== targetCompanyId) {
            return Company;
        }

        // Check if the item is being moved within the same department
        if (item.sourceDepartmentId === targetDepartmentId) {
            return Company;
        }
        const skillId = item.id;
        dispatch(updateSkillOrder({
            skillId,
            companyId: targetCompanyId,
            toDepartmentId: targetDepartmentId,
        }))    
       
    };

 

    const defaultSkillObj = { id: 0, name: '', Deleted: false, skillCompanies: [], skillEmployees: 0 }

    const [skillModel, setSkillModel] = useState(defaultSkillObj);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const LoadCompanyData = () => {
        dispatch(getSkillAndCompanyDataV2());

    }

    useEffect(() => {
        document.title = 'Departments | CWP Employee Portal';
        dispatch(getCompanies());
        dispatch(getSkillAndCompanyDataV2());
    }, []);

    const handleAddSkill = () => {
        setSkillModel(defaultSkillObj);
        setShowAddModal(true);
    }

    const handleEditDepartment = (model) => {
        getCompanyBySkillId(model.id)
            .then((response) => {
                if (response.data.success) {
                    setSkillModel({ ...skillModel, id: model.id, name: model.name, skillCompanies: response.data.data });
                    setShowAddModal(true);
                }
            });
    }

    const handleAddEditSkill = (skillData) => {
        setSkillModel(skillData);
        if (skillData != undefined && skillData != null && skillData.id > 0) {
            editSkill({ options: { data: skillData } })
                .then((response) => {
                    if (response.data.success) LoadCompanyData();
                    else alert(response.data.message);
                });
        }
        else {
            //add email
            saveSkill({ options: { data: skillData } })
                .then((response) => {
                    if (response.data.success) LoadCompanyData();
                    else alert(response.data.message);
                });
        }
    }

    const handleDeleteSkill = (skillData) => {
        deleteSkill(skillData.id)
            .then((response) => {
                if (response.data.success) LoadCompanyData();
            });
    }

    const handleDeleteConfirmation = (id, itemName) => {
        if (id > 0) {
            getEmployeesBySkillId(id)
                .then((response) => {
                    if (response.data.success) {
                        setSkillModel((prevSkillModel) => ({
                            ...prevSkillModel,
                            id: id,
                            name: itemName,
                            skillEmployees: response.data.data.length,
                        }));
                        setShowDeleteModal(true);
                    }
                });
        }
    }

    return (

        <div className="admin-page-content">

            <SkillDeleteConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteSkill={handleDeleteSkill}
                modelSkill={skillModel}
            />

            <div className="admin-page-title-wrap">
                <h1 className="admin-page-heading">Positions & Qualifications</h1>
                <button className="admin-button" onClick={() => handleAddSkill()}>
                    <span className="admin-button-text">Add Positions & Qualifications</span>
                    <span className="material-symbols-rounded" aria-hidden="true">add</span>
                </button>
            </div>
            <div className="admin-section">

                <SkillAddEditModal
                    show={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    handleAddEditSkill={handleAddEditSkill}
                    modelSkill={skillModel}
                />
                <div className="admin-tab-content admin-position-skill-wrap">
                  
                    <DndProvider backend={HTML5Backend}>
                        <div>
                            {companySkillListV2.map((company) => (
                                <Company
                                    key={company.id}
                                    company={company}
                                    moveItem={moveItem}
                                    onEdit={handleEditDepartment}
                                    onDelete={handleDeleteConfirmation}
                                />
                            ))}
                        </div>
                    </DndProvider>                    

                </div>
            </div>

        </div>
    );
};
PositionsSkills.propTypes = {
};

export default PositionsSkills;