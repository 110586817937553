import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export function InterestsAddEditModal({ show, onClose, handleAddEditInterest, modelInterest }) {
    const [formDataInterest, setFormDataInterest] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    useEffect(() => {
        setFormDataInterest(modelInterest)
    }, [modelInterest])

    const handleSave = () => {
        if (formDataInterest && !formDataInterest.name) {
            setErrorMessage('Interest filed is required.');
            return;
        }
        if (formDataInterest.name) {
            handleAddEditInterest(formDataInterest);
            setFormDataInterest({})
            onClose();
        }
    };

    const handleOnClose = () => {
        onClose();
        setErrorMessage(null);
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleOnClose();
        }
    };

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Add/Edit Interest</h2>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="email-domain">Interest*</label>
                            <input
                                type="text"
                                id="email-domain"
                                label="Email Url"
                                name="domain"
                                value={formDataInterest.name || ''}
                                onChange={(event) => {
                                    setFormDataInterest({ ...formDataInterest, name: event.target.value });
                                }}
                            />
                            {errorMessage && <p className="validation-error-message">{errorMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

InterestsAddEditModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddEditInterest: PropTypes.func.isRequired,
    modelInterest: PropTypes.object
};