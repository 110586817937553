import React from 'react';
import PropTypes from 'prop-types';
import downArrow from '../../assets/select-arrow-gray.svg';

const Accordion = ({ title, children, isActive, onToggle, hideToggle}) => {

  return (
    <div className="accordion-tile-wrap">
      <button onClick={onToggle} className={`accordion-tile ${isActive && hideToggle === false ? 'accordion-open'  : ""}`}>
              <p className="accordion-title">{isActive ? ` ${title}` : ` ${title}`}</p>
              {hideToggle === false && (
                  <span className="accordion-arrow"><img src={downArrow} alt="down arrow" loading="lazy" /></span>
              )}
      </button>
      {isActive && hideToggle === false && (
        <div className="accordion-content">
                  {children}
                  <p className="job-posting-link"><a href={`mailto:resumes@cwponline.com?subject=${encodeURIComponent(title)}`}>Apply</a></p>
        </div>
      )}
    </div>
  );
};

Accordion.defaultProps = {
    hideToggle: false
};

Accordion.propTypes = {
    title: PropTypes.string,
    children: PropTypes.string,
    isActive: PropTypes.any,
    onToggle: PropTypes.func,
    hideToggle: PropTypes.bool
};
export default Accordion;
 
