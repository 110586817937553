import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DragDropContainer from '../../DragAndDropContainer';
import { updateBenefitsOrder } from '../../../store/hrbenefits/actions';

export const BenefitContainer = ({ benefits, onEdit, onDelete }) => {
    const dispatch = useDispatch();

    if (!benefits) {
        return <h2>No Benefits & Policies exist.</h2>;
    }

    const [localCategories, setLocalCategories] = useState(benefits);
    const [trackOrderCategories, setTrackOrderCategories] = useState(benefits);

    useEffect(() => {
        if (benefits) {
            setLocalCategories(benefits);
        }
    }, [benefits]);

    const moveCategory = (fromIndex, toIndex, makeApiCall) => {
        const updatedCategories = [...localCategories];
        const [movedItem] = updatedCategories.splice(fromIndex, 1);
        updatedCategories.splice(toIndex, 0, movedItem);

        const newUpdatedCategories = updatedCategories.map((category, index) => ({
            ...category,
            order: index,
        }));

        setLocalCategories(newUpdatedCategories);
        setTrackOrderCategories(newUpdatedCategories);
        if (makeApiCall) {
            setLocalCategories(trackOrderCategories);

            const ids = trackOrderCategories.map((category) => category.id).join(',');
            dispatch(updateBenefitsOrder(ids));
        }
    };

    const renderCategoryItem = (benefit) => (
        <div className="admin-drag-box" key={benefit.id}>
            <span className="admin-title-name-wrap">
            <span className="admin-drag-box-name">{benefit.title}</span>
            {benefit.benefitCompanies.length > 0 ?
                <span className="admin-drag-box-companies">
                    {benefit.benefitCompanies.map((item) => item.company?.abbreviatedName).join(', ')}                 
                </span>
                : null}
            </span>
            <span className="admin-drag-option-wrap">
                <button
                    className="admin-drag-option edit"
                    onClick={() => onEdit(benefit)}
                >
                    <span className="material-symbols-rounded">edit</span>
                </button>
                <button
                    className="admin-drag-option delete"
                    onClick={() => onDelete(benefit)}
                >
                    <span className="material-symbols-rounded">delete</span>
                </button>
            </span>
        </div>
    );

    return (
        <DragDropContainer
            items={localCategories}
            moveItem={moveCategory}
            renderItem={renderCategoryItem}
            isHorizontal={false}
        />
    );
};
BenefitContainer.propTypes = {
    benefits: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
