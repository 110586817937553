import api from '../utils/api';

export const saveInterests = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/interest/add`,
        method: "POST",
        data: data,
        ...options,
    });
}
export const editInterests = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/interest/edit`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const deleteInterests = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/interest/delete/${data}`,
        method: "DELETE",
        data: data,
        ...options,
    });
}

export const getInterestsList = (searchByName = null, config = {}) => {
  const { data, options } = config;
  return api({
    url: searchByName ? `api/interest/get-all/${searchByName}` : 'api/interest/get-all',
    method: "GET",
    params: data,
    ...options,
  });
}