import React, { useState, useContext, useEffect } from "react";
import { PageEditorContext } from "../ModuleWrapper/ModuleEditorContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import PropTypes from 'prop-types';


const ckEditorConfig = {
    toolbar: [
        'Bold',
        'Italic',
        'NumberedList',
        'BulletedList',
        'Link',
        'Unlink',
        'ImageUpload',
        "mediaEmbed",
        'doclibrary'
    ],
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            },
        },
        addTargetToExternalLinks: true,
        defaultProtocol: 'http://',
        validate: function (url) {

            return /^(?:(?:https?|ftp):\/\/)?(?:\/)?(?:[^@\n]+@)?(?:www\.)?[^.]+\.[^.]+/.test(url);
        }
    },
    image: {
        toolbar: [
            "imageTextAlternative",
            "toggleImageCaption",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
        ],
    },
    simpleUpload: {
        uploadUrl: "api/media/upload-image"
    },
};


export const Freeform = ({ id, content }) => {
    const [editorData, setEditorData] = useState("");
    const { updateModuleContent } = useContext(PageEditorContext);

    useEffect(() => {
        if (editorData) {
            updateModuleContent(id, editorData);
        }
    }, [editorData]);
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const handlePaste = (event, editor, data) => {
        event.stop();

        // Get plain text from clipboard
        const plainText = data.dataTransfer.getData('text/plain');

        // Insert plain text at the current selection/cursor
        editor.model.change((writer) => {
            // Remove any selection and insert plain text
            writer.insertText(plainText, editor.model.document.selection.getFirstPosition());
        });

        // Update your state with the new content (plain text)
        const updatedContent = editor.getData();
        setEditorData(updatedContent);

    };

    Freeform.propTypes = {
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    };
    return (
        <div>
            <CKEditor
                editor={Editor}
                config={ckEditorConfig}
                data={editorData}
                onChange={handleEditorChange}
                onReady={(editor) => {

                    editor.setData(content);


                    // Listen for the clipboardInput event to intercept paste
                    editor.editing.view.document.on('clipboardInput', (evt, data) => {
                        handlePaste(evt, editor, data);
                    });

                }}

            />

        </div>
    );
};
