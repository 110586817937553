import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumberAsYouType } from '../../../helpers/formatterHelper';

const CustomMultiValue = memo(({ data, innerRef, innerProps, removeProps, onMouseEnterParent, isShow, onLeaveEnterParent, userDetails }) => {
    console.log("isShow:", isShow);
    const [isHovered, setIsHovered] = useState(isShow);

    const handleMouseEnter = () => {
        if (!isHovered) {
            onMouseEnterParent(data);
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        onLeaveEnterParent(false)
    };

    const handleRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (removeProps) {
            removeProps.onClick();
            setIsHovered(false);
            onLeaveEnterParent(false)
        }
    };


    return (
        <>
            <div
                className="selectBadge"
                style={{
                    position: 'relative',
                }}
                ref={innerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                {...innerProps}
            >
                <div className="name-badge"
                    style={{
                        display: 'flex',
                        borderRadius: '6em',
                        background: '#757575',
                        color: '#ffffff',
                        padding: '0.1em 0.25em',
                        cursor: 'pointer',
                    }}
                >
                    <div
                        style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            borderRadius: '2px',
                            padding: '3px',
                            paddingLeft: '6px',
                        }}
                    >{data.fullName || data.name}</div>
                    {removeProps && (
                        <div role="button"
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                borderRadius: '2px',
                                paddingLeft: '4px',
                                paddingRight: '4px',
                            }}
                            {...removeProps} onClick={handleRemove}>
                            <svg height="15" width="15" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                                style={{
                                    display: 'inline-block',
                                    fill: 'currentColor',
                                    lineHeight: '1',
                                    stroke: 'currentColor',
                                    strokeWidth: '0',
                                }}
                            >
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                            </svg>
                        </div>
                    )}
                </div>
                {isShow &&
                    <div className="people-dropdown">
                        <ul className="admin-modal-scroll">
                            {Array.isArray(userDetails) && userDetails.map((item, index) => (
                                <li key={index}>
                                    <span>{formatPhoneNumberAsYouType(item?.mobile) || ''}</span>
                                    <span>{item?.fullName || ''}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                }

            </div>




        </>
    );
});
CustomMultiValue.displayName = 'CustomMultiValue';

CustomMultiValue.propTypes = {
    data: PropTypes.object.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    innerProps: PropTypes.object.isRequired,
    removeProps: PropTypes.object,
    onMouseEnterParent: PropTypes.func.isRequired,
    isShow: PropTypes.bool.isRequired,
    onLeaveEnterParent: PropTypes.func.isRequired,
    userDetails: PropTypes.arrayOf(PropTypes.shape({
        mobile: PropTypes.string,
        fullName: PropTypes.string,
    })),

};

export default CustomMultiValue;
