import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminError from '../../../components/admin/admin-error';
import AdminTextInput from '../../../components/admin/admin-text-input.js';
import MediaUploader from '../../../components/media/MediaUploader.js';
import { employeeItem } from './employeeItem.js';
import { submitEmployee, getEmployeeByIdEdit } from '../../../api/employee';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Interests from '../../../components/admin/employee/interests';
import { formatPhoneNumberAsYouType } from '../../../helpers/formatterHelper';
import { getCompanies, getDepartmentListByCompanyId, getShirtSizes } from '../../../store/employee/actions';
import { getSkillsOnLoad } from '../../../store/skill/actions';
import { getAllEmployeeEdit } from '../../../store/skill/actions';
import DragDropContainer from '../../../components/DragAndDropContainer';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";

export function AddEmployee() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const defaultDepartment = { };
    const [error, setError] = useState('');
    const [employee, setEmployee] = useState(employeeItem);
    const { employeeId } = useParams();
    const { companiesList, departmentByCompanyIdList, shirtSizes } = useSelector(state => state.employee);
    const [selectedPrimaryCompany, setSelectedPrimaryCompany] = useState(employee?.primaryCompanyId);
    const [selectedSecondaryCompanies, setSelectedSecondaryCompanies] = useState([]);
    const [startDatInputValue, setStartDatInputValue] = useState(employee?.startDate ? new Date(employee?.startDate) : new Date());
    const [birthDateInputValue, setBirthDateInputValue] = useState(employee?.birthDate ? new Date(employee?.birthDate) : new Date());
    const { skillListData } = useSelector(state => state.skill);
    const [selectedSecondaryDepartment, setSelectedSecondaryDepartment] = useState([]);
    const [selectedPrimaryDepartment, setSelectedPrimaryDepartment] = useState(defaultDepartment);
    const [selectedPrimarySkill, setSelectedPrimarySkill] = useState(0);
    const [otherSkills, setOtherSkills] = useState([]);
    const [skillsList, setSkillsList] = useState([]);
    const [editLink, setEditLink] = useAdminMenuContext('');

    const moveItem = (fromIndex, toIndex, canDrop) => {
        if (canDrop) {
            const updatedSkills = [...otherSkills];
            const [movedItem] = updatedSkills.splice(fromIndex, 1);
            updatedSkills.splice(toIndex, 0, movedItem);
            const newUpdatedSkills = updatedSkills.map((skill, index) => ({
                ...skill,
                order: index,
            }));
            setOtherSkills(newUpdatedSkills);
        }
    };

    const renderSkillItem = (skill) => {
        const data = skillsList.find(x => x.id === skill.skillId);
        return (
            <div className={`admin-drag-box`} key={skill?.id}>
                <span className="admin-drag-box-name">{data?.name}</span>
            </div>
        );
    };

    useEffect(() => {
        if (employeeId > 0) {
            dispatch(showLoading());
            getEmployeeByIdEdit(employeeId).then((response) => {
                let employeeResponse = response.data.data;
                setEmployee(employeeResponse);
                setSelectedPrimarySkill(employeeResponse.primaryPositionId);
                setSelectedPrimaryCompany(employeeResponse.primaryCompanyId);
                setOtherSkills(employeeResponse.userSkills);
                const userCompanies = employeeResponse.userCompanies;
                setSelectedSecondaryCompanies(userCompanies);

                const secondaryDepartment = employeeResponse.userDepartments.filter(x => x.isSecondaryDepartment === true);
                setSelectedSecondaryDepartment(secondaryDepartment);

                var primaryDepartments = employeeResponse.userDepartments.filter(item => !item.isSecondaryDepartment);
                if (primaryDepartments === undefined) {
                    primaryDepartments = employeeResponse.userDepartments[0] || defaultDepartment;
                }
                setSelectedPrimaryDepartment(primaryDepartments[0]);

                setStartDatInputValue(employeeResponse?.startDate ? new Date(employeeResponse?.startDate) : new Date());
                setBirthDateInputValue(employeeResponse?.birthDate ? new Date(employeeResponse?.birthDate) : new Date());
                setEditLink(`/profile/${employeeId}`);
                console.log(editLink);
                dispatch(hideLoading());
            });
        }
        else {
            setEditLink("");
        }
        dispatch(getSkillsOnLoad());
        dispatch(getAllEmployeeEdit({}));
    }, []);

    const handleSecondaryCompanyToggle = (e, companyId) => {
        const checked = e.target.checked;
        const updatedSecondaryCompanies = checked
            ? [...selectedSecondaryCompanies, { companyId: companyId, SiteUserId: 0 }]
            : selectedSecondaryCompanies.filter(company => company.companyId !== companyId);

        setSelectedSecondaryCompanies(updatedSecondaryCompanies);
    }

    const handleDepartmentCompanyToggle = () => {

        setSelectedSecondaryDepartment([]);
    }

    const handleSecondaryDepartmentCompanyToggle = (e, departmentId) => {
        const checked = e.target.checked;
        const updatedDepartmentCompanies = checked
            ? [...selectedSecondaryDepartment, { departmentId: departmentId, SiteUserId: 0 }]
            : selectedSecondaryDepartment.filter(department => department.departmentId !== departmentId);

        setSelectedSecondaryDepartment(updatedDepartmentCompanies);
    }

    useEffect(() => {
        document.title = 'Employee | CWP Employee Portal';
        dispatch(getCompanies());
        dispatch(getShirtSizes());
    }, []);

    useEffect(() => {
        dispatch(getDepartmentListByCompanyId(selectedPrimaryCompany));
    }, [selectedPrimaryCompany]);

    const [validation, setValidation] = useState({
        firstName: true,
        lastName: true,
        primaryCompany: true,
        mobile: true,
        department: true,
        startDate: true,
        dateOfBirth: true,
        secondaryDepartment: true,
        workEmailValidate: true,
    });

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleEmployeeSave = () => {
        dispatch(showLoading());
        employee.primaryCompanyId = selectedPrimaryCompany;
        employee.primaryPositionId = selectedPrimarySkill;
        employee.userCompanies = selectedSecondaryCompanies;
        employee.userSkills = otherSkills;

        let departments = [];
        if (selectedPrimaryDepartment != null) {
            if (typeof selectedPrimaryDepartment === 'number' || typeof selectedPrimaryDepartment === 'string')
                departments.push({ departmentId: selectedPrimaryDepartment, SiteUserId: 0, isSecondaryDepartment: false });
            else
                departments.push({ departmentId: selectedPrimaryDepartment.departmentId, SiteUserId: 0, isSecondaryDepartment: false });
        }
        if (selectedSecondaryDepartment != null && selectedSecondaryDepartment != undefined) {
            selectedSecondaryDepartment?.map((x) => (departments.push({ departmentId: x.departmentId, SiteUserId: 0, isSecondaryDepartment: true })));
        }
        employee.userDepartments = departments;

        //validate Employee
        var isValid = true;

        var validate = {
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            primaryCompany: 0,
            primaryCompanyError: '',
            mobile: '',
            mobileError: '',
            department: '',
            departmentError: '',
            startDate: '',
            startDateError: '',
            dateOfBirth: '',
            dateOfBirthError: '',
            secondaryDepartment: '',
            secondaryDepartmentError: '',
            primaryPositionSkill: '',
            primaryPositionSkillError: '',
            workEmailError: '',
            workEmailValidate: true
        };

        if (employee == null || employee.firstName == null || employee.firstName == "") {
            validate.firstName = false;
            validate.firstNameError = "First Name is required";
            isValid = false;
        }
        if (employee == null || employee.lastName == null || employee.lastName == "") {
            validate.lastName = false;
            validate.lastNameError = "Last Name is required";
            isValid = false;
        }
        if (employee == null || employee.mobile == null || employee.mobile == "") {
            validate.mobile = false;
            validate.mobileError = "Mobile Number is required";
            isValid = false;
        }

        if (selectedPrimaryCompany == null || selectedPrimaryCompany == 0) {
            validate.primaryCompany = false;
            validate.primaryCompanyError = "Primary Company is required";
            isValid = false;
        }


       if (!selectedPrimaryDepartment || selectedPrimaryDepartment == null || selectedPrimaryDepartment == 0 || Object.keys(selectedPrimaryDepartment).length === 0) {
            validate.department = false;
            validate.departmentError = "Primary Department is required";
            isValid = false;
        }

        if (employee == null || employee.startDate == null || employee.startDate == "" || validation.startDate === false) {
            validate.startDate = false;
            validate.startDateError = "Start Date is required";
            isValid = false;
        }
        if (employee == null || employee.birthDate == null || employee.birthDate == "" || validation.dateOfBirth === false) {
            validate.dateOfBirth = false;
            validate.dateOfBirthError = "Birth Date is required";
            isValid = false;
        }

        if (employee?.workEmail && !validateEmail(employee.workEmail)) {
            validate.workEmailValidate = false;
            validate.workEmailError = "Invalid email format";
            isValid = false;
        }
    
        setValidation({ ...validation, ...validate });
        if (isValid == false) {
            dispatch(hideLoading());
            return;

        }

        submitEmployee(employee)
            .then((response) => {
                if (response.data.success == false) {
                    dispatch(hideLoading());
                    setError(response.data.message);
                    return;
                }
                if (response.data.success) {
                    dispatch(hideLoading());
                    navigate('/siteadmin/employee');
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                if (error.response.data && error.response.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError(error.response.responseText);
                }
            });
    };

    const handleSkill = (e, id) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            const updatedSkills = [...otherSkills, { skillId: id, SiteUserId: 0 }];
            setOtherSkills(updatedSkills);
        } else {
            const updatedSkills = otherSkills.filter(skill => skill.skillId !== id);
            setOtherSkills(updatedSkills);
        }
    };

	const filterSkills = (skills) => {
		var result = [];
		if (skills.length > 0) {
			var primarySkill = skills.find(skill => Number(skill.id) === Number(selectedPrimarySkill));
			var allSkillsButPrimary = skills.filter(skill => Number(skill.id) !== Number(selectedPrimarySkill));
			var primaryCompanySkills = allSkillsButPrimary.filter(skill => skill.skillCompanies.some(company => company?.companyId === selectedPrimaryCompany));
			var secondaryCompanySkills = allSkillsButPrimary.filter(skill => selectedSecondaryCompanies.some(secondary => skill.skillCompanies.some(company => secondary.companyId.toString() === company?.companyId.toString())));

			//Add all skills from Primary Company and then Add in Secondary Company Skills
			result = primaryCompanySkills;
			if (secondaryCompanySkills.length > 0) {
				//Loop through all skillsAssociatedWithSecondaryCompanies and add to skillsAssociatedWithPrimaryCompany if there is no name match also check primary name is not same as secondary
				secondaryCompanySkills.forEach(skill => {
					if (!result.some(x => x.name === skill.name) && skill.name !== primarySkill?.name) {
						result.push(skill);
					}
				});

				//order results alphabetically
				result.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
			}
		}
		return result;
	};

    const handleInterest = (e) => {
        const interestId = parseInt(e.target.value);
        const interestName = e.target.name; // Assuming the name is retrieved from the checkbox

        const isChecked = e.target.checked;

        if (isChecked) {
            // If checkbox is checked, add the skill with ID and Name
            setEmployee(prevEmployee => ({
                ...prevEmployee,

                userInterests: [
                    ...prevEmployee.userInterests,
                    {
                        interestId: interestId,
                        interest: {
                            name: interestName
                        }
                    }
                ]
            }));
        } else {
            // If checkbox is unchecked, remove the skill based on ID
            setEmployee(prevEmployee => ({
                ...prevEmployee,
                userInterests: prevEmployee.userInterests.filter(interest => interest.interestId !== interestId)
            }));
        }
    }

    const isValidDateFormat = (dateString) => {
        // Define a regular expression pattern for the desired format (mm/dd/yyyy)
        const dateFormatPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

        // Check if the dateString matches the pattern
        return dateFormatPattern.test(dateString);
    }


    const removeUserInterest = (interestId) => {
        setEmployee(prevEmployee => ({
            ...prevEmployee,
            userInterests: prevEmployee.userInterests.filter(interest => interest.interestId !== interestId)
        }))
    }

    const handleDateFormate = (date) => {
        const inputDate = date;
        let formattedDate = inputDate.replace(/\D/g, ''); // Remove all non-digit characters
        if (formattedDate.length > 2 && formattedDate.length <= 4) {
            formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
        } else if (formattedDate.length > 4) {
            formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2, 4) + '/' + formattedDate.slice(4, 8);
        }
        return formattedDate;
    };

    const handleStartDateChange = (event) => {
        if (event.type === "change") {
            const formattedDate = handleDateFormate(event.target.value);
            setStartDatInputValue(formattedDate);
            if (isValidDateFormat(formattedDate)) {
                setEmployee({ ...employee, startDate: new Date(formattedDate) });
                setValidation({ ...validation, startDate: true, startDateError: "" });
            } else {
                setValidation({ ...validation, startDate: false, startDateError: "Invalid Date Format" });
            }
        }
    };

    const handleBirthDateChange = (event) => {
        if (event.type === "change") {
            const formattedDate = handleDateFormate(event.target.value);
            setBirthDateInputValue(formattedDate);
            if (isValidDateFormat(formattedDate)) {
                setEmployee({ ...employee, birthDate: new Date(formattedDate) });
                setValidation({ ...validation, dateOfBirth: true, dateOfBirthError: "" });
            } else {
                setValidation({ ...validation, dateOfBirth: false, dateOfBirthError: "Invalid Date Format" });
            }
        }
    };

    const handleWorkEmailValidate = (value) => {
        let emailIsValid = true;
        let emailErrorMessage = '';

        if (value && !validateEmail(value)) {
            emailIsValid = false;
            emailErrorMessage = "Invalid email format";
        }

        setValidation((prevValidation) => ({
            ...prevValidation,
            workEmailValidate: emailIsValid,
            workEmailError: emailErrorMessage
        }));
    };

    const handleSelectShirtSize = (e) => {
        const sizeId = e.target.value;
        setEmployee({ ...employee, shirtSizeId: sizeId });
    }

    const handleSelectPrimaryPosition = (e) => {
        const positionId = e.target.value;
        setSelectedPrimarySkill(positionId);
        setEmployee({ ...employee, primaryPositionId: parseInt(positionId) });
        const updatedSkills = otherSkills.filter(skill => skill.skillId !== parseInt(positionId));
        setOtherSkills(updatedSkills);
      
    }


    useEffect(() => {
        const filteredSkills = filterSkills(skillListData); 
        setSkillsList(filteredSkills);
        const primarySkill = skillListData.find(item => item.id === employee.primaryPositionId);

        setEmployee(prevEmployee => ({
            ...prevEmployee,
            userSkills: prevEmployee.userSkills.filter(userSkill =>
                filteredSkills.some(skill => skill.id === userSkill.skillId)
            ),
            primaryPositionId: primarySkill === undefined ? null : prevEmployee.primaryPositionId

        }));

    }, [skillListData, selectedPrimaryCompany, selectedSecondaryCompanies, setEmployee, selectedPrimarySkill]);

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin/employee'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">Add/Edit Employee</h1>
                <AdminError
                    message={error}
                    onClose={() => {
                        setError('');
                    }}
                ></AdminError>

                <div className="admin-section admin-section-narrow">
                    <div className="admin-section-row">
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="First Name"
                                id="first-name"
                                value={employee.firstName ?? ''}
                                isRequired={true}
                                onChange={(value) => {
                                    setEmployee({ ...employee, firstName: value });
                                    setValidation({ ...validation, firstName: value != '' });
                                }}
                                validated={validation.firstName}
                                requiredText={validation.firstNameError}
                            />
                        </div>
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="Last Name"
                                id="last-name"
                                value={employee.lastName || ''}
                                isRequired={true}
                                onChange={(value) => {
                                    setEmployee({ ...employee, lastName: value });
                                    setValidation({ ...validation, lastName: value != '' });
                                }}
                                validated={validation.lastName}
                                requiredText={validation.lastNameError}
                            />

                        </div>
                    </div>
                    <div className="admin-section-row">
                        <div className="admin-section-cell extra-wide">
                            <AdminTextInput
                                label="Nickname"
                                id="nick-name"
                                value={employee.nickname || ''}
                                onChange={(value) => {
                                    setEmployee({ ...employee, nickname: value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="admin-section-row circle">
                        <div className="admin-section-cell full">
                            <p className="admin-section-small-headline">Profile Image</p>
                            <MediaUploader
                                requireAltText={false}
                                imageOnly={true}
                                allowGallery={false}
                                forceCrop={true}
                                isPlaceHolder={false}
                                mediaItems={(employee?.profileImage != undefined && employee?.profileImage !== null ? [employee.profileImage] : [])}
                                saveMediaItems={(mediaItems) => {
                                    setEmployee({
                                        ...employee,
                                        profileImage: (mediaItems[0] != undefined && mediaItems[0] !== null) ? mediaItems[0] : null,
                                        profileImageId: (mediaItems[0] != undefined && mediaItems[0] !== null) ? mediaItems[0].id : null
                                    })
                                }}
                            />

                        </div>
                    </div>

                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <label className="admin-default-label">
                                Primary Company<span>*</span>
                            </label>
                            {!validation.primaryCompany && (
                                <p className="input-validation-error"><span className="validation-error-message">{validation.primaryCompanyError}</span></p>
                            )}
                      
                            {companiesList.map(company => (
                                <label className="admin-radio-label inline" key={company?.id}>
                                    <input
                                        className="admin-radio"
                                        type="radio"
                                        value={company.id}
                                        checked={selectedPrimaryCompany === company?.id}
                                        onChange={() => {
                                            setSelectedPrimaryCompany(company?.id);
                                            setSelectedSecondaryCompanies([]);
                                            setSelectedSecondaryDepartment([]);
                                            setSelectedPrimaryDepartment(defaultDepartment);
                                            setSelectedPrimarySkill(0);
                                            setOtherSkills([]);
                                            setValidation({ ...validation, primaryCompany: true });
                                        }}
                                    />
                                    {company.abbreviatedName}
                                </label>
                            ))}
                        </div>
                    </div>

                    {departmentByCompanyIdList.length > 0 &&
                        <div className="admin-section-row">
                            <div className="admin-section-cell wide">
                                <label className="admin-default-label" htmlFor="short-desc">Primary Department<span>*</span></label>
                                {!validation.department && (
                                    <p className="input-validation-error"><span className="validation-error-message">{validation.departmentError}</span></p>
                                )}
                                <select className="admin-default-select"
                                    onChange={(e) => {
                                        handleDepartmentCompanyToggle(e, e.target.value);
                                        setSelectedPrimaryDepartment(e.target.value > 0 ? e.target.value : null);
                                        setValidation({ ...validation, department: e.target.value != '' });
                                    }}
                                    value={selectedPrimaryDepartment?.departmentId || selectedPrimaryDepartment || 0}
                                >
                                    <option value="0">Primary Department</option>
                                    {departmentByCompanyIdList
                                        .map((department) =>
                                            <option key={department.departmentId} value={department.departmentId}>
                                                {department.departmentName}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    }

                    {selectedPrimaryCompany != 0 && (
                        <>
                            <div className="admin-section-row">
                                <div className="admin-section-cell full">
                                    <label className="admin-default-label" htmlFor="short-desc">Secondary Departments</label>

                                    <div className="departments-services">
                                        {(() => {
                                            let deptComplist = departmentByCompanyIdList.filter(x => x.departmentId?.toString() !== selectedPrimaryDepartment?.toString());
                                            let getPrintColumnCount = Math.ceil(deptComplist.length / 3);
                                            return (
                                                <>
                                                    {/* First loop */}
                                                    <div className="departments-services-col">
                                                        {deptComplist.slice(0, getPrintColumnCount).map((department, index) => (
                                                            <label
                                                                className={"admin-checkbox-label col-" + Math.ceil((index + 1) / getPrintColumnCount)}
                                                                key={department.departmentId}
                                                            >
                                                                <input
                                                                    className="admin-inline-checkbox"
                                                                    id={`secondary-company-${department.departmentId}`}
                                                                    type="checkbox"
                                                                    checked={selectedSecondaryDepartment?.map(x => x.departmentId.toString()).includes(department.departmentId.toString())}
                                                                    value={department.departmentId}
                                                                    onChange={(e) => handleSecondaryDepartmentCompanyToggle(e, e.target.value)}
                                                                />
                                                                <span className="admin-inline-label-text">{department.departmentName}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {/* Second loop */}
                                                    <div className="departments-services-col">
                                                        {deptComplist.slice(getPrintColumnCount, getPrintColumnCount * 2).map((department, index) => (
                                                            <label
                                                                className={"admin-checkbox-label col-" + Math.ceil((index + 1) / getPrintColumnCount)}
                                                                key={department.departmentId}
                                                            >
                                                                <input
                                                                    className="admin-inline-checkbox"
                                                                    id={`secondary-company-${department.departmentId}`}
                                                                    type="checkbox"
                                                                    checked={selectedSecondaryDepartment?.map(x => x.departmentId.toString()).includes(department.departmentId.toString())}
                                                                    value={department.departmentId}
                                                                    onChange={(e) => handleSecondaryDepartmentCompanyToggle(e, e.target.value)}
                                                                />
                                                                <span className="admin-inline-label-text">{department.departmentName}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {/* Third loop */}
                                                    <div className="departments-services-col">
                                                        {deptComplist.slice(getPrintColumnCount * 2).map((department, index) => (
                                                            <label
                                                                className={"admin-checkbox-label col-" + Math.ceil((index + 1) / getPrintColumnCount)}
                                                                key={department.departmentId}
                                                            >
                                                                <input
                                                                    className="admin-inline-checkbox"
                                                                    id={`secondary-company-${department.departmentId}`}
                                                                    type="checkbox"
                                                                    checked={selectedSecondaryDepartment?.map(x => x.departmentId.toString()).includes(department.departmentId.toString())}
                                                                    value={department.departmentId}
                                                                    onChange={(e) => handleSecondaryDepartmentCompanyToggle(e, e.target.value)}
                                                                />
                                                                <span className="admin-inline-label-text">{department.departmentName}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </>
                                            );
                                        })()}
                                    </div>
                                </div>
                            </div>


                            <div className="admin-section-row">
                                <div className="admin-section-cell full">
                                    <label className="admin-default-label">Primary Position & Qualification</label>
                                    {!validation.primaryPositionSkill && (
                                        <p className="input-validation-error"><span className="validation-error-message">{validation.primaryPositionSkillError}</span></p>
                                    )}
                                    <select
                                        className="admin-default-select"
                                        onChange={handleSelectPrimaryPosition}
                                        value={selectedPrimarySkill}
                                    >
                                        <option value="0">Select Primary Position & Qualification</option>
                                        {skillListData && skillListData.length > 0 &&
                                            skillListData
                                                .filter(skill =>
                                                    skill.skillCompanies.some(company => company?.companyId === selectedPrimaryCompany)
                                                )
                                                .map(filteredSkill => (
                                                    <option key={filteredSkill.id} value={filteredSkill.id}>
                                                        {filteredSkill.skillCompanies[0]?.departmentName} - {filteredSkill.name}
                                                    </option>
                                                ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="admin-section-row">
                                <div className="admin-section-cell full">
                                    <label className="admin-default-label">
                                        Secondary Positions & Qualifications
                                    </label>

                                    <div className="">
                                        <div className="departments-services"> 
                                            {(() => {
                                                // Group the filtered skills by department
                                                let filerSkillList = filterSkills(skillListData);
                                                const groupedByDepartment = filerSkillList.reduce((acc, skill) => {
                                                    skill.skillCompanies.forEach(company => {
                                                        const departmentName = company.departmentName;
                                                        if (!acc[departmentName]) {
                                                            acc[departmentName] = [];
                                                        }
                                                        acc[departmentName].push({
                                                            id: skill.id,
                                                            name: skill.name,
                                                            deleted: skill.deleted,
                                                            createdById: skill.createdById,
                                                            createdDate: skill.createdDate,
                                                            skillCount: skill.skillCount,
                                                            skillCompanies: skill.skillCompanies
                                                        });
                                                    });
                                                    return acc;
                                                }, {});

                                                // Get the list of department names and determine the number of departments per column
                                                const departmentNames = Object.keys(groupedByDepartment).sort(); // Sorting A-Z
                                                const departmentCount = departmentNames.length;
                                                const getPrintColumnCount = Math.ceil(departmentCount / 3);

                                                // Function to render a column of departments and their skills
                                                const renderColumn = (start, end) => {
                                                    return (
                                                        <div className="departments-services-col">
                                                            {departmentNames.slice(start, end).map(departmentName => (
                                                                <div key={departmentName} className="departments-services-box">
                                                                    <p className="departments-services-name">{departmentName}</p> {/* Display department name */}
                                                                    {groupedByDepartment[departmentName].map(({ id, name }) => (
                                                                        <label className="admin-checkbox-label" key={id}>
                                                                            <input
                                                                                className="admin-inline-checkbox"
                                                                                id={`secondary-skill-${id}`}
                                                                                type="checkbox"
                                                                                checked={otherSkills.some(skill => skill.skillId === id)}
                                                                                onChange={(e) => handleSkill(e, id)}
                                                                            />
                                                                            <span className="admin-inline-label-text">{name}</span>
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    );
                                                };

                                                return (
                                                    <>
                                                        {/* First column */}
                                                        {renderColumn(0, getPrintColumnCount)}

                                                        {/* Second column */}
                                                        {renderColumn(getPrintColumnCount, getPrintColumnCount * 2)}

                                                        {/* Third column */}
                                                        {renderColumn(getPrintColumnCount * 2, departmentCount)}
                                                    </>
                                                );
                                            })()}

                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="admin-section-row">
                                <div className="admin-section-cell full">
                                    <label className="admin-default-label">
                                        Selected Secondary Positions & Qualifications
                                    </label>

                                    <div className="positions-qualifications-list">
                                        <div>
                                            {otherSkills.length > 0 && (
                                                <DragDropContainer
                                                    items={otherSkills}
                                                    moveItem={moveItem}
                                                    renderItem={renderSkillItem}
                                                    isHorizontal={false}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="admin-section-row">
                                <div className="admin-section-cell full">
                                    <label className="admin-default-label">Secondary Companies</label>
                                    {companiesList
                                        .filter((company) => company.id !== selectedPrimaryCompany)
                                        .map((secondaryCompany) => (
                                            <label className="admin-checkbox-label inline" key={secondaryCompany.id}>
                                                <input
                                                    className="admin-inline-checkbox"
                                                    id="secondary-company"
                                                    type="checkbox"
                                                    checked={selectedSecondaryCompanies.some(company => company.companyId === secondaryCompany.id)}
                                                    onChange={e => handleSecondaryCompanyToggle(e, secondaryCompany.id)}
                                                />
                                                <span className="admin-inline-label-text">{secondaryCompany.abbreviatedName}</span>
                                            </label>
                                        ))}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="admin-section-row">
                        <div className="admin-section-cell">
                            <label className="admin-default-label">Start Date<span>*</span></label>
                            <DatePicker
                                className="admin-default-input-text"
                                id="startDate"
                                selected={(employee?.startDate ? new Date(employee?.startDate) : new Date())}
                                placeholder="mm/dd/yyyy"
                                autoComplete="off"
                                onChange={(date) => {
                                    setEmployee({ ...employee, startDate: date });
                                    setValidation({ ...validation, startDate: date != '' });
                                }}
                                onChangeRaw={handleStartDateChange}
                                value={startDatInputValue}
                            />
                            {!validation.startDate && (
                                <p className="input-validation-error"><span className="validation-error-message">{validation.startDateError}</span></p>

                            )}
                        </div>
                        <div className="admin-section-cell">
                            <div className="admin-label-flex">
                                <label className="admin-default-label">Birth Date<span>*</span></label>
                                <label className="admin-default-label">
                                    <input
                                        className="admin-inline-checkbox"
                                        id="test"
                                        type="checkbox"
                                        checked={employee.hideBirthDate}
                                        onChange={(e) => {
                                            setEmployee({ ...employee, hideBirthDate: e.target.checked });
                                        }}
                                    />
                                    <span className="admin-inline-label-text">Hide</span>
                                </label>
                            </div>


                            <DatePicker
                                className="admin-default-input-text"
                                id="birthDate"
                                selected={(employee?.birthDate ? new Date(employee?.birthDate) : "")}
                                placeholder="mm/dd/yyyy"
                                autoComplete="off"
                                onChange={(date) => {
                                    setEmployee({ ...employee, birthDate: date });
                                    setValidation({ ...validation, dateOfBirth: date != '' });
                                }}
                                maxDate={new Date()}
                                onChangeRaw={handleBirthDateChange}
                                value={birthDateInputValue}
                            />

                            {!validation.dateOfBirth && (
                                <p className="input-validation-error"><span className="validation-error-message">{validation.dateOfBirthError}</span></p>

                            )}
                        </div>
                    </div>

                    <div className="admin-section-row">
                        <div className="admin-section-cell wide">
                            <div className="admin-section-absolute-label">
                                <label className="admin-default-label">
                                    <input
                                        className="admin-inline-checkbox"
                                        id="hideMobile"
                                        type="checkbox"
                                        checked={employee.hideMobile}
                                        onChange={(e) => {
                                            setEmployee({ ...employee, hideMobile: e.target.checked });
                                        }}
                                    />
                                    <span className="admin-inline-label-text">Hide</span>
                                </label>
                            </div>
                            <AdminTextInput
                                label="Mobile Number"
                                id="mobile-number"
                                value={formatPhoneNumberAsYouType(employee.mobile) || ''}
                                isRequired={true}
                                onChange={(value) => {
                                    const formatted = formatPhoneNumberAsYouType(value);
                                    setEmployee({ ...employee, mobile: formatted });
                                    setValidation({ ...validation, mobile: value != '' });
                                }}
                                validated={validation.mobile}
                                requiredText={validation.mobileError}

                            />
                        </div>
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="Work Phone Number"
                                id="work-phone-number"
                                value={formatPhoneNumberAsYouType(employee.workPhone) || ''}
                                onChange={(value) => {
                                    const formatted = formatPhoneNumberAsYouType(value);
                                    setEmployee({ ...employee, workPhone: formatted });
                                }}

                            />
                        </div>
                      
                        <div className="admin-section-cell">
                            <AdminTextInput
                                label="Ext."
                                id="ext"
                                value={employee.workPhoneExt || ''}
                                onChange={(value) => {
                                    setEmployee({ ...employee, workPhoneExt: value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="admin-section-row">
                        {/* work email*/}
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="Work Email"
                                id="work-email"
                                value={employee.workEmail || ''}
                                onChange={(value) => {
                                    setEmployee({ ...employee, workEmail: value });
                                    handleWorkEmailValidate(value);
                                }}
                            />
                            {!validation.workEmailValidate && (
                                <p className="input-validation-error"><span className="validation-error-message">{validation.workEmailError}</span></p>
                            )}
                        </div>
                    </div>
                    <div className="admin-section-row">
                        <div className="admin-section-cell">
                            <AdminTextInput
                                label="Alternate First Name"
                                id="alternate-first-name"
                                value={employee.alternateContactFirstName || ''}
                                onChange={(value) => {
                                    setEmployee({ ...employee, alternateContactFirstName: value });
                                }}
                            />
                        </div>
                        <div className="admin-section-cell">
                            <AdminTextInput
                                label="Alternate Last Name"
                                id="alternate-last-name"
                                value={employee.alternateContactLastName || ''}
                                onChange={(value) => {
                                    setEmployee({ ...employee, alternateContactLastName: value });
                                }}
                            />
                        </div>
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="Alternate Phone Number"
                                id="alter-phone-number"
                                value={formatPhoneNumberAsYouType(employee.alternatePhone) || ''}
                                onChange={(value) => {
                                    const formatted = formatPhoneNumberAsYouType(value);
                                    setEmployee({ ...employee, alternatePhone: formatted });

                                }}
                            />
                        </div>
                    </div>

                    <div className="admin-section-row">
                        <div
                            className={
                                'admin-section-cell full'
                            }
                        >
                            <label className="admin-default-label" htmlFor="short-desc">
                                Bio
                            </label>
                            <textarea className="admin-default-textarea" rows="3" defaultValue={employee.biography || ''} onChange={(e) => { setEmployee({ ...employee, biography: e.target.value }); }} />
                        </div>
                    </div>

                    <div className="admin-section-row">
                        <div className="admin-section-cell wide">
                            <label className="admin-default-label">Shirt Size</label>
                            <select
                                className="admin-default-select"
                                onChange={handleSelectShirtSize}
                                value={employee.shirtSizeId || ''}
                            >
                                <option value="0">Select Shirt Size</option>
                                {shirtSizes && shirtSizes.length > 0 &&
                                    shirtSizes.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>



                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <label className="admin-default-label">
                                Interests
                            </label>

                            <div className="admin-column-wrap inline-tags">
                                {employee.userInterests.map(interest => (
                                    <span className="admin-interest" key={interest.interestId}>{interest.interest.name}
                                        <span className="material-symbols-rounded" onClick={() => removeUserInterest(interest.interestId)}>close</span>
                                    </span>
                                ))}

                                <Interests
                                    handleInterest={handleInterest}
                                    userInterests={employee.userInterests}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="admin-section-separator">&nbsp;</div>
                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <label className="admin-default-label">
                                Tool Access
                            </label>
                            <label className="admin-checkbox-label inline">
                                <input
                                    className="admin-inline-checkbox"
                                    id="PartnerCWP-Partner"
                                    type="checkbox"
                                    checked={employee.toolCWPPartnerVisible}
                                    onChange={(event) => {
                                        setEmployee({ ...employee, toolCWPPartnerVisible: event.target.checked });
                                    }}
                                />
                                <span className="admin-inline-label-text admin-section-small-headline">CWP Partner</span>
                            </label>
                            <label className="admin-checkbox-label inline">
                                <input
                                    className="admin-inline-checkbox"
                                    id="Computool"
                                    type="checkbox"
                                    value="Computool"
                                    onChange={(event) => {
                                        setEmployee({ ...employee, toolComputoollVisible: event.target.checked });
                                    }}
                                    checked={employee.toolComputoollVisible}
                                />
                                <span className="admin-inline-label-text admin-section-small-headline">Computool</span>
                            </label>
                            <label className="admin-checkbox-label inline">
                                <input
                                    className="admin-inline-checkbox"
                                    id="Innergy-ERP"
                                    type="checkbox"
                                    value="Innergy ERP"
                                    onChange={(event) => {
                                        setEmployee({ ...employee, toolInnergyERPVisible: event.target.checked });
                                    }}
                                    checked={employee.toolInnergyERPVisible}
                                />
                                <span className="admin-inline-label-text admin-section-small-headline">Innergy ERP</span>
                            </label>
                            <label className="admin-checkbox-label inline">
                                <input
                                    className="admin-inline-checkbox"
                                    id="dashboard"
                                    type="checkbox"
                                    value="Dashboard"
                                    onChange={(event) => {
                                        setEmployee({ ...employee, toolDashboardVisible: event.target.checked });
                                    }}
                                    checked={employee.toolDashboardVisible}
                                />
                                <span className="admin-inline-label-text admin-section-small-headline">Dashboard</span>
                            </label>
                            <label className="admin-checkbox-label inline">
                                <input
                                    className="admin-inline-checkbox"
                                    id="qwpi-sales"
                                    type="checkbox"
                                    value="QWPI Sales"
                                    onChange={(event) => {
                                        setEmployee({ ...employee, toolQWPISalesVisible: event.target.checked });
                                    }}
                                    checked={employee.toolQWPISalesVisible}
                                />
                                <span className="admin-inline-label-text admin-section-small-headline">QWPI Sales</span>
                            </label>
                            <label className="admin-checkbox-label inline">
                                <input
                                    className="admin-inline-checkbox"
                                    id="qwpi-shop"
                                    type="checkbox"
                                    value="QWPI Shop"
                                    onChange={(event) => {
                                        setEmployee({ ...employee, toolQWPIShopVisible: event.target.checked });
                                    }}
                                    checked={employee.toolQWPIShopVisible}
                                />
                                <span className="admin-inline-label-text admin-section-small-headline">QWPI Shop</span>
                            </label>
                        </div>
                    </div>

                    {employee.toolCWPPartnerVisible && (
                        <div className="admin-section-row">
                            <div className="admin-section-cell wide">
                                <AdminTextInput
                                    label="CWP Partner User Name to Auto Login"
                                    id="cwppartner-username"
                                    value={employee.cwpPartnerUserName || ''}
                                    onChange={(value) => {
                                        setEmployee({ ...employee, cwpPartnerUserName: value });
                                    }}
                                />
                            </div>
                        </div>
                    )}

                </div>


                <div className="admin-floating-button-wrap">
                    <button
                        className="admin-floating-button"
                        onClick={() =>
                            navigate('/siteadmin/employee')
                        }
                    >
                        Cancel
                    </button>
                    <button
                        className="admin-floating-button power"
                        onClick={handleEmployeeSave}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
            {/*< />*/}
        </div>
    );
}