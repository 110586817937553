import React, { useState, useEffect } from 'react';
import './Admin.css';
import AdminLinkTile from '../../components/admin/admin-link-tile';
import { getLoginUserDetails } from '../../api/adminuserapi';

import { useDispatch } from "react-redux";
import { showLoading, hideLoading, delayedHideLoading } from '../../store/loading-reducer';
import { useAdminMenuContext } from "../../context/AdminMenuContext";

function Admin() {
    const dispatch = useDispatch();
    const [loginUser, setLoginUser] = useState(null);
    const [editLink, setEditLink] = useAdminMenuContext('');

    useEffect(() => {
        dispatch(showLoading());
    }, [dispatch]);

    useEffect(() => {
        document.title = 'Dashboard | CWP Employee Portal';
        dispatch(showLoading());
        getLoginUserDetails()
            .then((response) => {
                setLoginUser(response?.data);
                dispatch(delayedHideLoading());
            }).catch(() => {
                dispatch(hideLoading());
            });
        setEditLink('');
        console.log(editLink);
    }, []);

    console.log("loginUser", loginUser);




    return (
        <div className="admin-page-wrap">
         
            <div className="admin-top-bar-wrap">
                &nbsp;               
            </div>          
           
            <div className="admin-page-content">
               
                 
               
                <div className="admin-link-tile-wrap">
                    <AdminLinkTile
                        href="/siteadmin/events"
                        icon="calendar_today"
                        name="Events"
                    />
                    <AdminLinkTile
                        href="/siteadmin/news"
                        icon="newsmode"
                        name="Happenings"
                    />
                    <AdminLinkTile
                        href="/siteadmin/hrbenefits"
                        icon="verified"
                        name="HR & Benefits"
                    />
                    <AdminLinkTile
                        href="/siteadmin/employee"
                        icon="group"
                        name="Employees"
                    />
                    <AdminLinkTile
                        href="/siteadmin/departments"
                        icon="news"
                        name="Departments"
                    />
                    <AdminLinkTile
                        href="/siteadmin/skills"
                        icon="work"
                        name="Positions & Qualifications"
                    />
                    <AdminLinkTile
                        href="/siteadmin/interests"
                        icon="interests"
                        name="Interests"
                    />
                    <AdminLinkTile
                        href="/siteadmin/projects"
                        icon="perm_media"
                        name="Installed Projects"
                    />
                    <AdminLinkTile
                        href="/siteadmin/financials"
                        icon="local_atm"
                        name="Financials"
                    />
                    <AdminLinkTile
                        href="/siteadmin/textalerts"
                        icon="notifications"
                        className="material-symbols-rounded-outline"
                        name="Text Alerts"
                    />
                    <AdminLinkTile
                        href="/siteadmin/document-library"
                        icon="folder"
                        name="Document Library"
                    />
                    <AdminLinkTile
                        href="/siteadmin/ggob-resources"
                        icon="info"
                        name="GGOB Resources"
                    />
                </div>
            </div>
        </div>
    );
}

export default Admin;