import api from '../utils/api';

export const AddNewCompose = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/addcomposed`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const getScheduledData = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/getAllScheduledQueue`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const cancelScheduleDate = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/composed/cancelScheduleQueue/${id}`,
        method: 'DELETE',
        ...options,
    });
};


export const getComposeById = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/getComposedById/${data}`,
        method: "GET",
        ...options,
    });
}
export const GetCountPeoples = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/getCountPeoples`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const getAllMessage = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/getAllMessageLoge`,
        method: "POST",
        data: data,
        ...options,
    });
}


export const getSiteUsersByDepartmentIds = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/user-by-deptids`,
        method: "POST",
        data: data, 
        ...options,
    });
}


export const getFailedReason = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/composed/getFailedReason?ComposeId=${data.ComposeId}`,
        method: "GET",
        ...options,
    });
}