import React, { useState, useEffect } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { useDispatch, useSelector } from 'react-redux';
import { /*formatDate,*/ formatDateTimeForUserTimeZone } from '../../../components/common/DateTimeFormatter';
import { getSiteUsersByDepartmentIds, getFailedReason } from '../../../store/compose/actions';
import { formatPhoneNumberAsYouType } from '../../../helpers/formatterHelper';
import { getAllMessage } from '../../../store/compose/actions';


export function SentMessages() {
    const dispatch = useDispatch();
    const [getSentMessageModal, setGetSentMessageModal] = useState(false)
    const [filter, setFilter] = useState({ sort: 1 });
    const [viewComposeData, setViewComposeData] = useState(null)
    const { allMessageLogList } = useSelector(state => state.compose);
    const [userDetails, setUserDetails] = useState([]);
    const [messageErrorList, setMessageErrorList] = useState([]);
    const sentMessageModal = (composeId) => {
        let composeData = allMessageLogList.find(x => x.id == composeId);
        let composeDataCopy = { ...composeData };
        if (composeDataCopy?.recipients) {
            composeDataCopy.recipients = composeDataCopy?.recipients ? parseRecipients(composeDataCopy?.recipients) : '';
        }
        setViewComposeData(composeDataCopy);
        setGetSentMessageModal(true)
    }
    useEffect(() => {
        dispatch(getAllMessage(filter));
        const intervalId = setInterval(() => {
            dispatch(getAllMessage(filter));
        }, 5000);
        return () => clearInterval(intervalId);
    }, [dispatch, filter]);


    const handleClick = (event) => {
        event.stopPropagation()
    }

    const handleSortChange = (newSort) => {
        setFilter({ ...filter, sort: newSort });
    };
    const parseRecipients = (recipients) => {
        try {
            return JSON.parse(recipients);
        } catch (error) {
            console.error('Error parsing recipients JSON:', error);
            return [];
        }
    };

    const handleMouseOver = async (recipient, type) => {
        setUserDetails([]);
        switch (type) {
            case 'indivisible':
                setUserDetails([{ "mobile": recipient.data.mobile, "fullName": recipient.data.fullName }]);
                break;

            case 'alternatemobile':
                break;

            case 'group':

                try {
                    const result = await dispatch(getSiteUsersByDepartmentIds(recipient.data)).unwrap();
                    if (result?.data) {
                        const newDetails = result.data.map(user => ({
                            mobile: user.mobile,
                            fullName: user.fullName
                        }));
                        // Then append new data while preserving the previous state
                        setUserDetails([...newDetails]);
                    }


                    // Handle result if needed
                } catch (error) {
                    console.error('Error fetching site users:', error);
                }

                break;

            default:
                return null;
        }
    };
    const getMessageFailedReason = async (composeId) => {
        try {
            const result = await dispatch(getFailedReason({ ComposeId: composeId })).unwrap();
            if (result?.data && result?.data.length > 0) {
                setMessageErrorList(result?.data);
            }
            else {
                setMessageErrorList(null);
            }

            // Handle result if needed
        } catch (error) {
            console.error('Error fetching Err Reasons:', error);
        }

    };

    return (
        <div>
            <section className="text-alert-section">
                <div className="admin-tab-content">
                    <h2 className="admin-section-headline">Sent Messages</h2>
                    <div className="custom-select-scroll scrollbar-inner">
                        <Scrollbar>
                            <table className="admin-table rsvp-table">
                                <thead className="rsvp-table-header">
                                    <tr className="header-row">
                                        <th onClick={() => handleSortChange(filter.sort === 0 ? 1 : 0)} className={filter.sort === 0 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Date/Time</th>
                                        <th onClick={() => handleSortChange(filter.sort === 2 ? 3 : 2)} className={filter.sort === 2 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 3 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}># of Recipients</th>
                                        <th onClick={() => handleSortChange(filter.sort === 4 ? 5 : 4)} className={filter.sort === 4 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 5 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}># of Errors</th>
                                        <th onClick={() => handleSortChange(filter.sort === 6 ? 7 : 6)} className={filter.sort === 6 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 7 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Message</th>
                                        <th className="admin-table-name-cell sortable"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allMessageLogList && allMessageLogList.length > 0 ? (
                                        allMessageLogList.map((item) => (
                                            <tr onClick={() => sentMessageModal(item.id)} key={`${item?.composeId}-${item?.dateTimeSend}`}>
                                                <td className="admin-table-name-cell" style={{ cursor: 'pointer' }}>
                                                    {formatDateTimeForUserTimeZone(item?.dateTimeSend)}
                                                </td>
                                                <td className="admin-table-name-cell">{item.peopleCount}</td>
                                                <td className="admin-table-name-cell">
                                                    {item?.failedCount > 0 ? (
                                                        <span className="erroes-member-list" onMouseOver={() => getMessageFailedReason(item?.composeId)}>
                                                            {item?.failedCount}
                                                            {messageErrorList && messageErrorList.length > 0 &&
                                                                <div className="people-dropdown">
                                                                    <ul className="admin-modal-scroll">
                                                                        {messageErrorList.map((errMessage, index) => (
                                                                            <li key={index}>
                                                                                <span>{formatPhoneNumberAsYouType(errMessage?.mobile) || ''}</span>
                                                                                <span>{errMessage?.failedReason || ''}</span>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </span>
                                                    )
                                                        :
                                                        (
                                                            <span>
                                                                {item?.failedCount}
                                                            </span>
                                                        )}
                                                </td>
                                                <td className="admin-table-name-cell">{item?.content}</td>
                                                <td></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="admin-table-name-cell" colSpan="4">
                                                No sent messages found
                                            </td>
                                        </tr>
                                    )}

                                </tbody>

                            </table>
                        </Scrollbar>
                    </div>

                </div>
            </section>

            {/*Sent Message Modal*/}

            {getSentMessageModal &&
                <div className="admin-modal-overlay text-alert-modal" onClick={() => setGetSentMessageModal(false)}>
                    <div className="admin-modal admin-add-role" onClick={handleClick}>
                        <div className="modal-header">
                            <h2 className="admin-modal-heading">Sent Message</h2>
                            <button className="admin-modal-close" onClick={() => setGetSentMessageModal(false)}><span className="material-symbols-rounded">close</span></button>
                        </div>
                        <div className="admin-modal-scroll">
                            <div className="admin-modal-row">
                                <label className="admin-default-label" htmlFor="organization">To</label>
                                <div className="sent-people-list">
                                    <div className="people-flex">
                                        {viewComposeData?.recipients && viewComposeData?.recipients.map((recipient, index) => {

                                            switch (recipient.type) {
                                                case 'indivisible':
                                                    return (
                                                        <div key={index} className="peole-count-badge">
                                                            <span className="name-badge" onMouseOver={() => handleMouseOver(recipient, recipient.type)}> {recipient.data.fullName || ''}</span>
                                                            <div className="people-dropdown">
                                                                <ul className="admin-modal-scroll">
                                                                    {Array.isArray(userDetails) && userDetails.map((item, index) => (
                                                                        <li key={index}>
                                                                            <span>{formatPhoneNumberAsYouType(item?.mobile) || ''}</span>
                                                                            <span>{item?.fullName || ''}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    );
                                                case 'alternatemobile':
                                                    return (

                                                        <div key={index} className="peole-count-badge">
                                                            <span className="name-badge" onMouseOver={() => handleMouseOver(recipient, recipient.type)}>{viewComposeData && recipient.data.name || ''}</span>
                                                        </div>

                                                    );

                                                case 'group':
                                                    return (

                                                        <div key={index} className="peole-count-badge">
                                                            <span className="name-badge" onMouseOver={() => handleMouseOver(recipient, recipient.type)}>{viewComposeData && recipient.data.name || ''}</span>
                                                            <div className="people-dropdown">
                                                                <ul className="admin-modal-scroll">
                                                                    {Array.isArray(userDetails) && userDetails.map((item, index) => (
                                                                        <li className="group-hover" key={index}>
                                                                            <span>{formatPhoneNumberAsYouType(item?.mobile) || ''}</span>
                                                                            <span>{item?.fullName || ''}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })}

                                    </div>
                                </div>
                            </div>
                            <div className="admin-modal-row">
                                <label className="admin-default-label" htmlFor="organization">Date/Time Sent</label>
                                <p>
                                    {viewComposeData && formatDateTimeForUserTimeZone(viewComposeData?.dateTimeSend) || ''}
                                </p>
                            </div>
                            <div className="admin-modal-row">
                                <label className="admin-default-label" htmlFor="organization">Message</label>
                                <p>
                                    {viewComposeData && viewComposeData?.content || ''}
                                </p>
                            </div>
                        </div>
                        <div className="admin-modal-button-wrap">
                            <button className="admin-modal-button power" onClick={() => setGetSentMessageModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}

