import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isAuthenticated: false,
		loading: false,
		token: null,
		refreshToken: null,
		displayName: '',
		revalidatingToken: false,
		isAdmin: false,
		siteUserId: 0,
		primaryCompanyId: 0,
		isShowAccessErrorPopup: false
	},
	reducers: {
		logoutUser(state) {
			state.isAuthenticated = false;
			state.loading = false;
			state.token = null;
			state.refreshToken = null;
			state.displayName = '';
			state.email = '';
			state.isAdmin = false;
			state.siteUserId = 0,
			state.primaryCompanyId= 0
		},
		ShowAccessErrorPopup(state) {
			state.isShowAccessErrorPopup = true;
			state.isAuthenticated = false;
		},
		HideAccessErrorPopup(state) {
			state.isShowAccessErrorPopup = false
		}

	},
	extraReducers: (builder) => {

		// Admin User Login
		builder.addCase(actions.siteUserLogin.pending, state => {
			state.loading = true;
		})
		builder.addCase(actions.siteUserLogin.fulfilled, (state, action) => {
			const { token, refreshToken, displayName, isAdmin, siteUserId, primaryCompanyId } = action.payload?.data.data || {};
			state.isAuthenticated = true;
			state.token = token;
			state.refreshToken = refreshToken;
			state.displayName = displayName;
			state.isAdmin = isAdmin;
			state.loading = false;
			state.siteUserId = siteUserId;
			state.primaryCompanyId = primaryCompanyId;
		})
		builder.addCase(actions.siteUserLogin.rejected, state => {
			state.loading = false;
		})

		// User Login
		builder.addCase(actions.userLogin.pending, state => {
			state.loading = true;
		})
		builder.addCase(actions.userLogin.fulfilled, (state, action) => {
			const { token, refreshToken, displayName, isAdmin, siteUserId, primaryCompanyId } = action.payload?.data.data || {};
			state.isAuthenticated = true;
			state.token = token;
			state.refreshToken = refreshToken;
			state.displayName = displayName;
			state.isAdmin = isAdmin;
			state.loading = false;
			state.siteUserId = siteUserId;
			state.primaryCompanyId = primaryCompanyId;
		})
		builder.addCase(actions.userLogin.rejected, state => {
			state.loading = false;
		})

		//get new token for shop screen
		builder.addCase(actions.renewToken.pending, state => {
			state.loading = true;
		})
		builder.addCase(actions.renewToken.fulfilled, (state, action) => {
			const { token } = action.payload?.data || {};
			state.token = token;
			state.loading = false;
		})
		builder.addCase(actions.renewToken.rejected, state => {
			state.loading = false;
		})


		//Refresh token
		builder.addCase(actions.getNewToken.pending, state => {
			state.loading = true;
			state.revalidatingToken = true;
		})
		builder.addCase(actions.getNewToken.fulfilled, (state, action) => {
			const { token } = action.payload?.data || {};
			if (action.payload?.success) {
				state.token = token,
					state.loading = false;
			} else {
				state.loading = false;
				state.isAuthenticated = false,
					state.token = null,
					state.refreshToken = null,
					state.name = ''
			}
			state.revalidatingToken = false;
		})
		builder.addCase(actions.getNewToken.rejected, state => {
			if (!state.revalidatingToken) {
				state.loading = false;
				state.isAuthenticated = false,
					state.token = null,
					state.refreshToken = null,
					state.name = ''
			}
		})

		//Check if authenticated
		builder.addCase(actions.checkAuth.pending, state => {
			state.loading = true;
		});
		builder.addCase(actions.checkAuth.fulfilled, (state, action) => {
			state.loading = false;
			const { success } = action.payload?.data || {};
			if (success === false && state.refreshToken !== null) {
				console.log('not authenticated, may need to refesh token');
			}
		})


		//Site Admin Refresh Token
		builder.addCase(actions.getSiteAdminNewToken.pending, state => {
			state.loading = true;
			state.revalidatingToken = true;
		})
		builder.addCase(actions.getSiteAdminNewToken.fulfilled, (state, action) => {
			const { token } = action.payload?.data || {};
			if (action.payload?.success) {
				state.token = token,
					state.loading = false;
			} else {
				state.loading = false;
				state.isAuthenticated = false,
					state.token = null,
					state.refreshToken = null,
					state.name = ''
			}
			state.revalidatingToken = false;
		})
		builder.addCase(actions.getSiteAdminNewToken.rejected, state => {
			if (!state.revalidatingToken) {
				state.loading = false;
				state.isAuthenticated = false,
					state.token = null,
					state.refreshToken = null,
					state.name = ''
			}
		})

	}
});

export const { logoutUser, ShowAccessErrorPopup, HideAccessErrorPopup } = authSlice.actions

export default authSlice.reducer;
