import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaImageModal from './MediaImageModal';
import MediaVideoModal from './MediaVideoModal';
import axios from 'axios';
import PageLoadingSpinner from '../FullPageLoader';
import profile from '../../assets/profile.jpg';

/*import './mediacomponent.css';*/


function MediaDisplay({ imageId, imageSrc, imageAlt, children }) {
    return (
        <div className="galleryItem" data-element="galleryItem" data-id={imageId}>
            <img className="galleryImage" src={imageSrc} alt={imageAlt} />
            {children}
        </div>
    )
}
MediaDisplay.propTypes = {
    imageId: PropTypes.number,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    children: PropTypes.node
}

function MediaGallery({ mediaItems, onMediaDelete, onMediaEdit, isPlaceHolder }) {

    const handleEditItem = (e) => {
        let itemId = e.target.closest('[data-element="galleryItem"]').dataset.id;
        onMediaEdit(itemId);
    }

    const handleDeleteMedia = (e) => {
        let itemId = e.target.closest('[data-element="galleryItem"]').dataset.id;
        if (itemId !== undefined && itemId != null)
            onMediaDelete(itemId);
    }

    if (mediaItems.length > 0) {
        return (
            <div className="mediaGallery">
                {mediaItems.map((item, idx) =>
                    <MediaDisplay
                        key={'mediaItem_' + idx}
                        imageId={item.id}
                        imageSrc={item.previewsrc || ''}
                        imageAlt={item.altText}
                    >
                        <div className="media-thumbnail-action-wrap">
                            {/* Hiding edit for now until we get logic for it in place. User can always delete and add item */}

                            <button className="media-thumbnail-action red margin-bt-edit-image"
                                onClick={handleEditItem}
                            >
                                <span className="material-symbols-rounded">edit</span>
                            </button>
                            <button className="media-thumbnail-action red"
                                onClick={handleDeleteMedia}
                            >
                                <span className="material-symbols-rounded">delete</span>
                            </button>
                        </div>
                    </MediaDisplay>
                )}
            </div>
        )
    }
    else {
        return (
            isPlaceHolder ? <img className="galleryImage" src={profile} alt='profileImage' /> : null
        )
    }
}
MediaGallery.propTypes = {
    mediaItems: PropTypes.array,
    onMediaDelete: PropTypes.func,
    onMediaEdit: PropTypes.func,
    isPlaceHolder: PropTypes.bool
}

export default function MediaUploader({
    forceCrop, allowGallery, imageOnly, videoOnly, requireAltText,
    mediaItems, saveMediaItems, customImageSave,isPlaceHolder }) {

    const [showImageModal, setShowImageModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [ogImageSrc, setOgImageSrc] = useState(null);
    const [editImageObj, setEditImageObj] = useState(null);
    const [mediaLibrary, setLibrary] = useState(mediaItems ?? []);
    const [showSaving, setShowSaving] = useState(false);

    const handleSaveMedia = async (mediaObj) => {
        setShowSaving(true);
        axios({
            url: '/api/media/add',
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: mediaObj
        })
            .then((result) => {
                console.log("result:", result);
                let newMediaItem = {
                    id: result?.data?.data?.id,
                    altText: result?.data?.data?.altText && result?.data?.data?.altText.trim() !== "" ? result?.data?.data?.altText : result?.data?.data?.photo?.originalFileName,
                    previewsrc: result?.data?.previewsrc,
                    mediaitemid: result?.data?.data.id,
                    photo: result?.data?.data?.photo,
                    cropValues: result?.data?.data?.cropValues

                }
                setOgImageSrc(null);
                setShowImageModal(false);
                setShowVideoModal(false);              
                setLibrary([newMediaItem]);
                saveMediaItems([newMediaItem]);
                setShowSaving(false);
            })
            .catch((error) => {
                setShowSaving(false);
                alert(error);
            });
    }


    const handleMediaDelete = (mediaId) => {
        setLibrary(mediaLibrary.filter(x => x.id != mediaId));
        saveMediaItems(mediaLibrary.filter(x => x.id != mediaId));
    }
    const handleMediaEdit = (mediaId) => {
        let mediaObj = mediaLibrary.filter(x => x.id == mediaId)[0];
        console.log(mediaId, mediaObj);
        setEditImageObj(mediaObj);
        setShowImageModal(true);
    }

    useEffect(() => {
        setLibrary(mediaItems);
    }, [mediaItems]);

    return (
        <>
            <MediaGallery
                mediaItems={mediaLibrary}
                onMediaDelete={handleMediaDelete}
                onMediaEdit={handleMediaEdit}
                isPlaceHolder={isPlaceHolder}
            />
            {(!allowGallery && mediaLibrary.length > 0) ? null : (
                <div className="mediaOptions">
                    {(imageOnly || !videoOnly) ? (
                        <label className="mediaAddImage">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    setShowImageModal(true);
                                    setOgImageSrc(e.target.files[0])
                                }} />
                            <span className="mediaAdd-button">Add Image</span>
                        </label>
                    ) : (<></>)}
                    {(videoOnly || !imageOnly) ? (
                        <button
                            className="mediaAddVideo"
                            onClick={() => {
                                if (showImageModal) setShowImageModal(false);
                                setShowVideoModal(true)
                            }}
                        >
                            <span className="mediaAdd-button">Add Video</span>
                        </button>
                    ) : (<></>)}
                </div>
            )}
            <MediaVideoModal
                requireAltText={requireAltText}
                showModal={showVideoModal}
                onCloseModal={() => setShowVideoModal(false)}
                onSaveModal={handleSaveMedia}
            />
            <MediaImageModal
                requireAltText={requireAltText}
                showModal={showImageModal}
                forceCrop={forceCrop}
                uploadImageObj={ogImageSrc}
                editImageObj={editImageObj}
                onCloseModal={() => setShowImageModal(false)}
                onSaveModal={handleSaveMedia}
                onCustomImageSave={customImageSave}
            />
            <PageLoadingSpinner show={showSaving} />
        </>
    );
}

MediaUploader.propTypes = {
    forceCrop: PropTypes.bool,
    imageOnly: PropTypes.bool,
    videoOnly: PropTypes.bool,
    requireAltText: PropTypes.bool,
    allowGallery: PropTypes.bool,
    mediaItems: PropTypes.array,
    saveMediaItems: PropTypes.func,
    customImageSave: PropTypes.func,
    isPlaceHolder: PropTypes.bool
}

MediaUploader.defaultProps = {
    forceCrop: true,
    imageOnly: false,
    videoOnly: false,
    requireAltText: false,
    allowGallery: true,
    mediaItems: [],
    saveMediaItems: null,
    customImageSave: null
}