import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export function DepartmentsAddEdit({ show, onClose, handleAddEditPosting, modelPosting }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [orgErrorMessage, setOrgErrorMessage] = useState(null);
    const [formDataJobDepartment, setFormDataDepartment] = useState(null);
    const { companiesList } = useSelector(state => state.employee);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [allOrganization, setAllOrganization] = useState(false);
    const [inEditMode, setInEditMode] = useState(modelPosting.id > 0);
    const [popupText, setPopupText] = useState('Add Company Department');

    const modalRef = useRef(null);
    useEffect(() => {
        if (inEditMode) {
            setPopupText('Edit Company Department');
        } else {
            setPopupText('Add Company Department');
        }

    }, [inEditMode]);
    useEffect(() => {
        setAllOrganization(false);
        if (modelPosting?.departmentCompanies.length > 0) {
            const allItemsSelected = companiesList.every(company => {
                return modelPosting.departmentCompanies.some(item => item.companyId === company.id)
            });
            setAllOrganization(allItemsSelected);
        }
        setInEditMode(modelPosting.id > 0);
        setFormDataDepartment(modelPosting);
        setSelectedOrganization(modelPosting?.departmentCompanies);
    }, [modelPosting, show]);

    useEffect(() => {
        if (formDataJobDepartment !== null)
            setFormDataDepartment({ ...formDataJobDepartment, departmentCompanies: selectedOrganization });
    }, [selectedOrganization]);

    const handleSave = () => {
        if (!formDataJobDepartment.name) {
            setErrorMessage('Department is required.');
            return
        }
        else {
            setErrorMessage(null);
        }

        if (!formDataJobDepartment.departmentCompanies.length > 0) {
            setOrgErrorMessage('At least one organization is required.');
            return
        }
        else {
            setOrgErrorMessage(null);
        }

        if (formDataJobDepartment.name) {
            handleAddEditPosting(formDataJobDepartment);
            setErrorMessage(null);
            setAllOrganization(false);
            setOrgErrorMessage(null);
            onClose();  
        }
    };

    const handleOnClose = () => {
        onClose();    
        setErrorMessage(null);
        setOrgErrorMessage(null);
        setAllOrganization(false);
    };

    if (!show) {
        return null;
    }

    const handleOverlayClick = (e) => {
        if (modalRef.current.contains(e.target)) {
            return;
        }
        handleOnClose();
    };
    
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role" ref={modalRef}>
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">{popupText}</h2>

                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="email-domain">Department Name</label>
                            <input
                                type="text"
                                id="email-domain"
                                label="Email Url"
                                name="domain"
                                value={formDataJobDepartment.name || ''}
                                onChange={(event) => {
                                    setFormDataDepartment({ ...formDataJobDepartment, name: event.target.value });
                                    setErrorMessage(null);                       
                                }}
                            />

                            {errorMessage && <p className="error-messagess">{errorMessage}</p>}
                        </div>
                    </div>
                    {!inEditMode && (
                        <div className="admin-modal-row">
                            <div className="admin-modal-cell">
                                <label className="admin-default-label" htmlFor="organization">Organization</label>
                                <div className="admin-column-wrap inline-tags">
                                    <label className="admin-checkbox-label inline">
                                        <input
                                            className="admin-inline-checkbox"
                                            type="checkbox"
                                            checked={allOrganization === true ? true : false}
                                            onChange={(e) => {
                                                setAllOrganization(e.target.checked);
                                                if (e.target.checked) {
                                                    const updatedOrganization = companiesList.map(item => ({ companyId: item.id, company: item }));
                                                    setSelectedOrganization(updatedOrganization);
                                                    setOrgErrorMessage(null);
                                                }
                                            }}
                                        />
                                        All
                                    </label>
                                    {companiesList.map(item => (
                                        <label className="admin-checkbox-label inline" key={item.id}>
                                            <input className="admin-inline-checkbox"
                                                type="checkbox"
                                                checked={selectedOrganization.some(x => x.companyId === item.id)}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    const updatedOrganization = checked
                                                        ? [...selectedOrganization, { companyId: item.id, company: item }]
                                                        : selectedOrganization.filter(selectedItem => selectedItem.companyId !== item.id);
                                                    setSelectedOrganization(updatedOrganization);
                                                    if (checked === false) {
                                                        setAllOrganization(false);
                                                    }
                                                    setOrgErrorMessage(null);
                                                }}
                                            />
                                            {item.abbreviatedName}
                                        </label>
                                    ))}
                                </div>
                                {orgErrorMessage && <p className="error-messagess">{orgErrorMessage}</p>}
                            </div>
                        </div>
                    )}
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

DepartmentsAddEdit.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddEditPosting: PropTypes.func.isRequired,
    modelPosting: PropTypes.object
};