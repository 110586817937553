import api from '../utils/api';

export const GetAllFinancialReport = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/financials/getfinancialreport/',
        method: 'POST',
        ...options,
    });
};

