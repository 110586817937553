import React, { useState, useEffect } from 'react';
import './detail.css';
import Sidebar from '../../components/SideBar/Sidebar.js';
import celebrateIcon from '../../assets/person-celebrate.svg';
import profileImage from '../../assets/placeholder-closeup.jpg';
import { getEmployeeWorkAnniversaries } from '../../store/employee/actions';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import { useDispatch, useSelector } from 'react-redux';
import { calculateDateDiff } from '../../helpers/formatterHelper';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PropTypes from 'prop-types';
import { shopMonitorSliderTime } from '../../helpers/enums';
import { getBadges } from '../../helpers/badges';
export default function ShopAnniversary({ handleloadinTiming }) {
	const dispatch = useDispatch();
	const [filter, setFilter] = useState({ companyId: 0, userId: 0, sort: 6, timezone: GetTimeZone() });
	const isLoading = useSelector((state) => state.loading);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const { employeeAnniversaries } = useSelector(state => state.employee);

	useEffect(() => {
		document.title = 'Shop Anniversary | CWP Team Connect';
		dispatch(showLoading());
		handleloadinTiming(shopMonitorSliderTime.LeadingTime);
		let sharpCompanyId = localStorage.getItem('monitor_mode_company');
		if (sharpCompanyId !== undefined && sharpCompanyId !== null) {
			setFilter({ ...filter, companyId: parseInt(sharpCompanyId)});
		}
	}, []);
	useEffect(() => {
		if (filter.companyId > 0) {
			dispatch(getEmployeeWorkAnniversaries(filter)).unwrap().then(() => {
				dispatch(hideLoading());
				handleloadinTiming(shopMonitorSliderTime.default);
				setIsDataLoaded(true);
			});
		}
	}, [filter]);

	useEffect(() => {
		if (isLoading && isDataLoaded) {
			dispatch(hideLoading());
			handleloadinTiming(shopMonitorSliderTime.default);
		}
	}, [isLoading, isDataLoaded]);

	return (
		<div className="monitors-inner">
			<div className="monitors-item">
				<div className="monitors-header-wrap">
					<p className="monitors-header-title">Anniversaries</p>
					<span className="monitors-header-icon"><img src={celebrateIcon} alt="Celebrate Icon" /></span>
				</div>
				<div className="monitors-item-event-wrap">
					{employeeAnniversaries && employeeAnniversaries.length > 0 && (
						<>
							{employeeAnniversaries.map(anniversarie => (
								<div className="monitors-item-event-item" key={anniversarie.id}>
									<div className="monitors-item-event-item-inner">
										<div className="monitors-ann-img-wrap">
											<img src={anniversarie.profileImage ? anniversarie.profileImage.previewsrc : profileImage} alt="profile" />
										</div>
										<div className="badgesIcon badgesIconAnn">
											{getBadges(calculateDateDiff(anniversarie?.startDate).yearsDiff)}
										</div>
										<div className="monitors-item-event-title-wrap">
											<p className="monitors-event-title no-padd">Congratulations, {anniversarie.fullName}!</p>
										</div>
									</div>
									<div className="monitors-ann-length">
										<div className="monitors-ann-cta">
											{calculateDateDiff(anniversarie.startDate).yearsDiff} {calculateDateDiff(anniversarie?.startDate).yearsDiff > 1 ? 'years' : 'year'}
										</div>
									</div>
								</div>
							))}
						</>
					)}


				</div>
			</div>
			<Sidebar pageType={"anniversaries"} />
		</div>
	);
}

ShopAnniversary.propTypes = {
	handleloadinTiming: PropTypes.func
};