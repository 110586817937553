import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../../store/employee/actions';
import { deleteEvent, getPastEvents } from '../../../store/events/actions';
import { formatDate, GetTimeZone } from '../../../components/common/DateTimeFormatter';
import { EventConfrimationModal } from './EventDeleteConfirmation';
function PastEvents() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ query: '', companyId: 0, sort: 6, IsShowPastEvent: true, start: 1, length: 25, timezone: GetTimeZone() });
    const { companiesList } = useSelector(state => state.employee);
    const { pastEventsList } = useSelector(state => state.event);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [eventModel, setEvent] = useState({});
    const [deleteMessage, setdeleteMessage] = useState('');

    const totalCountEvents = pastEventsList[0]?.totalCount;
    
    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        dispatch(getPastEvents(filter));
    }, [filter]);

   

    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(totalCountEvents / filter.length); i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => {
                        const newStart = (i - 1) * filter.length + 1;
                        setFilter({ ...filter, start: newStart });
                    }}
                    className={filter.start === (i - 1) * filter.length + 1 ? 'active' : ''}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };


    const handleDeleteTrigger = (id, title) => {  
        setdeleteMessage('Are you sure you want to delete this event?');
        setEvent({ id: id, title: title });
        setShowDeleteModal(true);
    }
    const handleDeleteEvent = (item) => {
        dispatch(deleteEvent(item.id)).unwrap().then((res) => {
            if (res && res.success) {
                dispatch(getPastEvents(filter));
            }
        });
    }
    const handleFilterCompany = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        filter.start = 1;
        setFilter({ ...filter, companyId: selectedCompanyId });
    };

    return (
        <>
            <EventConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteEvent={handleDeleteEvent}
                modelEvent={eventModel}
                deletemessage={deleteMessage}
                resetEventModel={() => setEvent({})}
            />

            <div className="pageContainer">
                <div className="past-events-table-wrap">
                    <h1 className="admin-page-heading events-heading-margin">Past Events</h1>
                    
                    <div className="pt">
                        <table className="admin-table events-table">
                            <tbody>
                                <tr>
                                    <th onClick={() => setFilter({ ...filter, sort: filter.sort == 1 ? 2 : 1 })} className={filter.sort == 1 ? 'events-col-one admin-table-name-cell sortable sorted-dn' : (filter.sort == 2 ? 'events-col-one admin-table-name-cell sortable sorted-up' : 'events-col-one admin-table-name-cell sortable')}>Event Title</th>
                                    <th onClick={() => setFilter({ ...filter, sort: filter.sort == 3 ? 4 : 3 })} className={filter.sort == 3 ? 'events-col-two admin-table-name-cell sortable sorted-dn' : (filter.sort == 4 ? 'events-col-two admin-table-name-cell sortable sorted-up' : 'events-col-two admin-table-name-cell sortable')}>Organization</th>
                                    <th onClick={() => setFilter({ ...filter, sort: filter.sort == 5 ? 6 : 5 })} className={filter.sort == 5 ? 'events-col-three table-nowrap admin-table-name-cell sortable sorted-dn' : (filter.sort == 6 ? 'events-col-three table-nowrap admin-table-name-cell sortable sorted-up' : 'events-col-three table-nowrap admin-table-name-cell sortable')}>Event Date</th>
                                    <th onClick={() => setFilter({ ...filter, sort: filter.sort == 7 ? 8 : 7 })} className={filter.sort == 7 ? 'events-col-four table-nowrap admin-table-name-cell sortable sorted-dn' : (filter.sort == 8 ? 'events-col-four table-nowrap admin-table-name-cell sortable sorted-up' : 'events-col-four table-nowrap admin-table-name-cell sortable')}># of total RSVPs</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                    <th className="admin-table-action-cell">
                                        <div className="admin-bottom-bar filter-bars admin-filter-position">
                                            <div className="admin-bottom-bar-left">
                                                <select className="admin-default-select quoteradius admin-filter-select" onChange={handleFilterCompany} value={filter.companyId}>
                                                    <option value="0">All</option>
                                                    {companiesList && companiesList.length > 0 &&
                                                        companiesList.map(item => (
                                                            <option key={item.id} value={item.id}>{item.abbreviatedName}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                {pastEventsList && pastEventsList.length > 0 ? (
                                    pastEventsList.map((item) =>
                                    
                                        <tr key={`${item.id}-${item.eventDate}`}>
                                                <td className="admin-table-name-cell">{item.title}</td>
                                                <td className="admin-table-name-cell">
                                                    {item.companyInfo }
                                                </td>
                                                <td className="admin-table-name-cell table-nowrap">
                                                <span>{formatDate(item.eventDate)} @ {item.startTime}</span>
                                                </td>
                                                <td className="admin-table-name-cell">
                                                    {item.rsvpTotalCount}
                                                </td>
                                                <td className="admin-table-name-cell"></td>
                                                {item.title === eventModel.title &&
                                                    item.eventCompanies[0]?.companyId === eventModel.companyId &&
                                                    item.id === eventModel.id ? (
                                                    <>
                                                        <td className="admin-table-name-cell"></td>
                                                        <td className="admin-table-name-cell"></td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="admin-table-name-cell">
                                                            <button
                                                                className="admin-table-action"
                                                                onClick={() =>
                                                                    navigate('/siteadmin/event/edit/' + item.baseId + '/' + item.eventLogId)
                                                                }
                                                            >
                                                                <span className="material-symbols-rounded">edit</span>
                                                            </button>
                                                        </td>
                                                        <td className="admin-table-name-cell">
                                                            <button
                                                                className="admin-table-action"
                                                                onClick={() =>
                                                                    handleDeleteTrigger(item.id, item.title, item.eventLog || [])
                                                                }
                                                            >
                                                                <span className="material-symbols-rounded">delete</span>
                                                            </button>
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))
                                    
                                    : (
                                    <tr>
                                        <td className="admin-table-name-cell" colSpan="7">
                                            No Past Events
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {((totalCountEvents) > pastEventsList.length) &&
                        <div className="pagination-wrap">
                            <button
                                onClick={() => setFilter({ ...filter, start: filter.start - filter.length })}
                                disabled={filter.start <= 1}
                            >
                                Previous
                            </button>
                            {generatePageNumbers()}
                            <button
                                onClick={() => setFilter({ ...filter, start: filter.start + filter.length })}
                                disabled={(filter.start + filter.length) > (totalCountEvents)}
                            >
                                Next
                            </button>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}

export default PastEvents;