import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DragDropContainer from '../../../components/DragAndDropContainer';
import { updateGGOBResourcesOrder } from '../../../store/ggobResources/actions';

export const Container = ({ ggobResourcesList, onEdit, onDelete }) => {
    const dispatch = useDispatch();

    const [localCategories, setLocalCategories] = useState(ggobResourcesList);
    const [trackOrderCategories, setTrackOrderCategories] = useState(ggobResourcesList);

    useEffect(() => {
        if (ggobResourcesList) {
            setLocalCategories(ggobResourcesList);
        }
    }, [ggobResourcesList]);

    if (!ggobResourcesList) {
        return <h2>No GGOB Resources exist.</h2>;
    }

    const moveCategory = (fromIndex, toIndex, makeApiCall) => {
        const updatedCategories = [...localCategories];
        const [movedItem] = updatedCategories.splice(fromIndex, 1);
        updatedCategories.splice(toIndex, 0, movedItem);

        const newUpdatedCategories = updatedCategories.map((category, index) => ({
            ...category,
            order: index,
        }));

        setLocalCategories(newUpdatedCategories);
        setTrackOrderCategories(newUpdatedCategories);
        if (makeApiCall) {
            setLocalCategories(trackOrderCategories);

            const ids = trackOrderCategories.map((category) => category.id).join(',');
            dispatch(updateGGOBResourcesOrder(ids));
        }
    };

    const renderCategoryItem = (ggobResourcesList) => (
        <div className="admin-drag-box" key={ggobResourcesList.id}>
            <span className="admin-title-name-wrap">
                <span className="admin-drag-box-name">{ggobResourcesList.title}</span>
                {ggobResourcesList?.ggobResourcesCompanies?.length > 0 ?
                    <span className="admin-drag-box-companies">
                        {ggobResourcesList.ggobResourcesCompanies.map((item) => item.company?.abbreviatedName).join(', ')}
                    </span>
                    : null}
            </span>
            <span className="admin-drag-option-wrap">
                <button
                    className="admin-drag-option edit"
                    onClick={() => onEdit(ggobResourcesList)}
                >
                    <span className="material-symbols-rounded">edit</span>
                </button>
                <button
                    className="admin-drag-option delete"
                    onClick={() => onDelete(ggobResourcesList)}
                >
                    <span className="material-symbols-rounded">delete</span>
                </button>
            </span>
        </div>
    );


    return (
        <DragDropContainer
            items={localCategories}
            moveItem={moveCategory}
            renderItem={renderCategoryItem}
            isHorizontal={false}
        />
    );
};

Container.propTypes = {
    ggobResourcesList: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

Container.defaultProps = {
    ggobResourcesList: [],
};
