import { createAsyncThunk } from '@reduxjs/toolkit';
import * as adminProjects from '../../api/projects';

export const getProjects = createAsyncThunk('getProjects', async (data) => {
    const res = await adminProjects.getProjects(data);
    return res.data;
});


export const addEditProjects = createAsyncThunk('addEditProject', async (data) => {
    const res = await adminProjects.addEditProjects({ data: data });
    return res.data;
});


export const deleteJobProjects = createAsyncThunk('deleteJobProjects', async (data) => {
    const res = await adminProjects.deleteJobProjects({ id: data });
    return res.data;
});


export const updateProjectOrder = createAsyncThunk('updateProjectOrder', async (ids) => {
    const res = await adminProjects.updateProjectOrder({
        options: {
            params: { Ids: ids },
        },
    });
    return res.data;
});