import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../../store/employee/actions';
import { getEvents, deleteEvent } from '../../../store/events/actions';
import { formatDate, GetTimeZone } from '../../../components/common/DateTimeFormatter';
import { EventConfrimationModal } from './EventDeleteConfirmation';
import PastEvents from './pastevents';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";

function Events() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ query: '', companyId: 0, sort: 5, IsShowPastEvent: false, start: 1, length: 25, timezone: GetTimeZone() });
    const { companiesList } = useSelector(state => state.employee);
    const { eventsList } = useSelector(state => state.event);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteMessage, setdeleteMessage] = useState('');
    const [eventModel, setEvent] = useState({}); 
    const totalCountEvents = eventsList[0]?.totalCount;
    const [showPastEvents, setShowPastEvents] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); 
    const [editLink, setEditLink] = useAdminMenuContext('');

    const handleFilterCompany = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        setFilter({ ...filter, companyId: selectedCompanyId });
    };

    const handleShowPastEvents = () => {
        setShowPastEvents(true);
    };

    const handleShowHideEvents = () => {
        setShowPastEvents(false);
    };

    useEffect(() => { 
        document.title = 'Events | CWP Employee Portal';
        dispatch(getCompanies());
        setEditLink('/events');
        console.log(editLink);
    }, []);

    useEffect(() => { 
        dispatch(getEvents(filter));
    }, [filter]);

    
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(totalCountEvents / filter.length); i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => setCurrentPageAndUpdateFilter(i)} // Update current page and filter together
                    className={currentPage === i ? 'active' : ''}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };
    const handleDeleteTrigger = (id, title, eventLog) => {
        const deleteMessage = eventLog.length > 1
            ? 'This event has multiple occurrences. Are you sure you want to delete all of them?'
            : 'Are you sure you want to delete this event?';
        setdeleteMessage(deleteMessage);
        setEvent({ id: id, title: title, eventLog: eventLog });
        setShowDeleteModal(true);
    }
    const handleDeleteEvent = (item) => {
        dispatch(deleteEvent(item.id));
    }

    const setCurrentPageAndUpdateFilter = (page) => {
        setCurrentPage(page);
        const newStart = (page - 1) * filter.length + 1;
        setFilter({ ...filter, start: newStart });
    };


    const handlePreviousPage = () => {
        if (filter.start > 1) {
            const newStart = Math.max(filter.start - filter.length, 1);
            setFilter({ ...filter, start: newStart });
            if (currentPage > 1) {
                const newPage = currentPage - 1;
                setCurrentPageAndUpdateFilter(newPage);
            }
        }
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(totalCountEvents / filter.length);
        if (filter.start + filter.length <= totalCountEvents) {
            const newStart = filter.start + filter.length;
            setFilter({ ...filter, start: newStart });
            if (currentPage < totalPages) {
                const newPage = currentPage + 1;
                setCurrentPageAndUpdateFilter(newPage);
            }
        }
    };

    useEffect(() => {
        // If there are no events on the current page, go to the previous page
        if (eventsList.length === 0 && currentPage > 1) {
            handlePreviousPage();
        }
    }, [eventsList]);

    return (
        <>
            <EventConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteEvent={handleDeleteEvent}
                modelEvent={eventModel}
                deletemessage={deleteMessage}
                resetEventModel={() => setEvent({})}
            />

            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link className="admin-top-bar-back" to={'/siteadmin'}>Back</Link>
                    </div>
                </div>

                <div className="pageContainer">
                    <div className="admin-page-content">
                        <div className="admin-page-title-wrap">
                            <h1 className="admin-page-heading events-heading-margin">Upcoming Events</h1>
                            <button className="admin-button"
                                onClick={() =>
                                    navigate('/siteadmin/event/create')
                                }
                            >
                                <span>Add Event</span>
                                <span className="material-symbols-rounded">add</span>
                            </button>
                        </div>
                       

                        <div className="pt">
                            <table className="admin-table events-table">
                                <tbody>
                                    <tr>
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 1 ? 2 : 1 })} className={filter.sort == 1 ? 'events-col-one admin-table-name-cell sortable sorted-dn' : (filter.sort == 2 ? 'events-col-one admin-table-name-cell sortable sorted-up' : 'events-col-one admin-table-name-cell sortable')}>Event Title</th>
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 3 ? 4 : 3 })} className={filter.sort == 3 ? 'events-col-two admin-table-name-cell sortable sorted-dn' : (filter.sort == 4 ? 'events-col-two admin-table-name-cell sortable sorted-up' : 'events-col-two admin-table-name-cell sortable')}>Organization</th>
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 5 ? 6 : 5 })} className={filter.sort == 5 ? 'events-col-three table-nowrap admin-table-name-cell sortable sorted-dn' : (filter.sort == 6 ? 'events-col-three table-nowrap admin-table-name-cell sortable sorted-up' : 'events-col-three table-nowrap admin-table-name-cell sortable')}>Event Date</th>
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 7 ? 8 : 7 })} className={filter.sort == 7 ? 'events-col-four table-nowrap admin-table-name-cell sortable sorted-dn' : (filter.sort == 8 ? 'events-col-four  table-nowrap admin-table-name-cell sortable sorted-up' : 'events-col-four table-nowrap admin-table-name-cell sortable')}># of total RSVPs</th>
                                        <th className="admin-table-action-cell events-col-five">&nbsp;</th>
                                        <th className="admin-table-action-cell events-col-six">&nbsp;</th>
                                        <th className="admin-table-action-cell events-col-six">
                                            <div className="admin-bottom-bar filter-bars admin-filter-position">
                                                <div className="admin-bottom-bar-left">
                                                    <label>
                                                        <select className="admin-default-select quoteradius admin-filter-select" onChange={handleFilterCompany} value={filter.companyId}>
                                                            <option value="0">All</option>
                                                            {companiesList && companiesList.length > 0 &&
                                                                companiesList.map(item => (
                                                                    <option key={item.id} value={item.id}>{item.abbreviatedName}</option>
                                                            ))}
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </th>
                                    </tr> 
                                    {eventsList && eventsList.length > 0 ? (
                                        eventsList.map((item) =>
                                         
                                            <tr key={`${item.id}-${item.eventDate}`}>
                                                    <td className="admin-table-name-cell">{item.title}</td>
                                                    <td className="admin-table-name-cell">
                                                        {item.companyInfo}
                                                    </td>
                                                    <td className="admin-table-name-cell table-nowrap">
                                                    <span>{formatDate(item.eventDate)} {item.startTime && '@' + item.startTime}</span>
                                                    </td>
                                                    <td className="admin-table-name-cell">
                                                    {item.rsvpTotalCount}
                                                    </td>
                                                    <td className="admin-table-name-cell"></td>
                                                    {item.title === eventModel.title &&
                                                        item.eventCompanies[0]?.companyId === eventModel.companyId &&
                                                        item.id === eventModel.id ? (
                                                        <>
                                                            <td className="admin-table-name-cell"></td>
                                                            <td className="admin-table-name-cell"></td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td className="admin-table-name-cell">
                                                                <button
                                                                    className="admin-table-action"
                                                                    onClick={() =>
                                                                        navigate('/siteadmin/event/edit/' + item.baseId + '/' + item.eventLogId)
                                                                    }
                                                                >
                                                                    <span className="material-symbols-rounded">edit</span>
                                                                </button>
                                                            </td>
                                                            <td className="admin-table-name-cell">
                                                                <button
                                                                    className="admin-table-action"
                                                                        onClick={() =>
                                                                            handleDeleteTrigger(item.id, item.title, item.eventLog || [])
                                                                    }
                                                                >
                                                                    <span className="material-symbols-rounded">delete</span>
                                                                </button>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                        )                                  
                                    ) : (
                                        <tr>
                                            <td className="admin-table-name-cell" colSpan="7">
                                                No active Events
                                            </td>
                                        </tr>
                                    )} 
                                </tbody>
                            </table>

                        </div>
                        

                        {((totalCountEvents) > eventsList.length) &&
                            <div className="pagination-wrap">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={filter.start <= 1}
                                >
                                    Previous
                                </button>
                                {generatePageNumbers()}
                                <button
                                    onClick={handleNextPage}
                                    disabled={(filter.start + filter.length) > (totalCountEvents)}
                                >
                                    Next
                                </button>
                            </div>
                        }


                        <br></br>
                        {showPastEvents && (<PastEvents />)}


                        <div className="admin-section-row">
                            {showPastEvents ? (
                                <div className="admin-button-wrap">
                                    <button className="admin-button" onClick={handleShowHideEvents}>
                                        Hide Past Events
                                    </button>
                                </div>
                            ) : (
                                <div className="admin-button-wrap">
                                    <button className="admin-button" onClick={handleShowPastEvents}>
                                        Show Past Events
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Events;