import React, { useState, useEffect} from 'react';
import './portalAlert.css';
export default function PortalAlert() {
    const [routeDevOrStage, setRouteDevOrStage] = useState("");

    useEffect(() => {
        const { hostname } = new URL(window.location.href);
        let routerDev = "dev.azurewebsites.net";
        let routerStage = "stage.azurewebsites.net";

        if (hostname.includes(routerDev)) {
            setRouteDevOrStage("dev");
        }
        else if (hostname.includes(routerStage)) {
            setRouteDevOrStage("stage");
        }
        else {
            setRouteDevOrStage("");
        }
    }, []);

    return (
        <>
            {routeDevOrStage && (
                <div className="portal-alert">
                    {routeDevOrStage === 'dev' && <span>portal.{routeDevOrStage}</span>}
                    {routeDevOrStage === 'stage' && <span>portal.{routeDevOrStage}</span>}
                </div>
            )}
        </>
    );
}