import React from 'react';
import PropTypes from 'prop-types';
export function RsvpConfirmModal({ show, onClose, confirmMessage }) {
    if (!show) {
        return null;
    }
    const handleCancel = () => {
        onClose(); // Close the modal
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleCancel();
        }
    };


    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal">
                <h2 className="admin-modal-heading">
                    {confirmMessage}
                </h2>
               
                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={handleCancel}>
                        Ok
                    </button>
                   
                </div>
            </div>
        </div>
    );
}

RsvpConfirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    confirmMessage: PropTypes.string,
};