import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Sidebar.css';
import QRCode from 'qrcode.react';


import tourIcon from '../../assets/tour-sm.svg';
import calendarIcon from '../../assets/calendar_today-sm.svg';
import { getTodayEvents } from '../../store/events/actions';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import PropTypes from 'prop-types';
export default function Sidebar({ pageType }) {

    const dispatch = useDispatch();
    const { todayEventNotifications } = useSelector(state => state.event);

    const [filter] = useState({ companyId: 0, timezone: GetTimeZone() });
    useEffect(() => {
        let sharpCompanyId = localStorage.getItem('monitor_mode_company');
        filter.companyId = (sharpCompanyId != null && sharpCompanyId !== undefined) ? parseInt(sharpCompanyId) : 0;
        dispatch(getTodayEvents(filter));
    }, []);

    const baseUrl = window.location.origin;
    let qrCodeValue = '';
    let qrAltText = '';

    switch (pageType) {
        case "bonus-level":
            qrCodeValue = baseUrl + "/bonuslevels";
            qrAltText = "Bonus QR Code";
            break;
        case "anniversaries":
        case "bonus-level-ytd":
        case "install-project":
            qrCodeValue = baseUrl + "/dashboard";
            qrAltText = "Dashboard QR Code";
            break;
        case "events":
            qrCodeValue = baseUrl + "/events";
            qrAltText = "Events QR Code";
            break;
        case "financial":
            qrCodeValue = baseUrl + "/financial-scoreboard";
            qrAltText = "Financial QR Code";
            break;
        case "today":
            qrCodeValue = baseUrl + "/today";
            qrAltText = "Today QR Code";
            break;
        default:
            qrCodeValue = '';
    }


    return (
        <div className="monitors-item right-sidebar">
            <div className="right-sidebar-upper">
                <div className="sidebar-header-wrap">
                    {pageType === "events" ?
                        <a href="/events" className="sidebar-header text-decoration">Access Team Connect</a>
                        :
                        <p className="sidebar-header">Access Team Connect</p>
                    }

                    <div className="qr-img-wrap">
                        {qrCodeValue && <QRCode value={qrCodeValue} alt={qrAltText} size={287} renderAs="svg" level="H" />}
                    </div>
                </div>
            </div>
            <div className="right-sidebar-inner">

                <div className="monthly-events-list-wrap">
                    {todayEventNotifications &&
                        todayEventNotifications
                            .filter(eventLogDate => eventLogDate.eventType?.name === 'Tour')
                            .slice(0, 4)
                            .map(eventLogDate => (
                                <div className="monthly-events-item" key={eventLogDate.id}>
                                    <div className="monthly-event-icon">
                                        <div className="monthly-event-icon-img-wrap">
                                            <img src={tourIcon} alt="Tour Icon" loading="lazy" />
                                        </div>
                                    </div>
                                    <div className="monthly-event-content">
                                        <p className="monthly-event-title">{eventLogDate.title}</p>
                                        <p className="monthly-event-date">{'@' + ' ' + eventLogDate.eventLog[0].startTime}</p>
                                    </div>
                                </div>
                            ))}

                    {todayEventNotifications &&
                        todayEventNotifications
                            .filter(eventLogDate => eventLogDate.eventType?.name === 'Visitor')
                            .slice(0, 4 - Math.min(todayEventNotifications.filter(event => event.eventType?.name === 'Tour').length, 4)) // Limit to remaining slots
                            .map(eventLogDate => (
                                <div className="monthly-events-item" key={eventLogDate.id}>
                                    <div className="monthly-event-icon">
                                        <div className="monthly-event-icon-img-wrap">
                                            <img src={calendarIcon} alt="Calendar Icon" loading="lazy" />
                                        </div>
                                    </div>
                                    <div className="monthly-event-content">
                                        <p className="monthly-event-title">{eventLogDate.title}</p>
                                        <p className="monthly-event-date">{'@' + ' ' + eventLogDate.eventLog[0].startTime}</p>
                                    </div>
                                </div>
                            ))}
                </div>


            </div>
        </div>
    );
}

Sidebar.propTypes = {
    pageType: PropTypes.string,
};