import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { Scrollbar } from 'react-scrollbars-custom';
import { getEventsRSVPs } from '../../../store/events/actions';

export function RspvModal({ show, onClose, modelPosting, eventId }) {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ sort: 0, EventId: eventId, companyId:0 });
    const [errorMessage, setErrorMessage] = useState(null);
    const  getEventsRSVPsList  = useSelector(state => state.event.getEventsRSVPs);
    const [formDataJobPosting, setFormDataJobPosting] = useState(null);

    const { companiesList } = useSelector(state => state.employee);

    useEffect(() => {
        if (eventId > 0) {
            setFilter({ ...filter, EventId: eventId }); 
        }        
    }, [eventId]);

    useEffect(() => {
        setFormDataJobPosting(modelPosting)
    }, [modelPosting]);

    useEffect(() => {
        dispatch(getEventsRSVPs(filter));
    }, [filter]);

    useEffect(() => {
        if (formDataJobPosting !== null)
            setFormDataJobPosting({ ...formDataJobPosting });
    }, []);

    const handleOnClose = () => {
        onClose();   
        setErrorMessage(null);
        setFilter({ ...filter, sort: 0, companyId: 0 }); 
    };

    const handleFilterCompany = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        filter.start = 1;
        setFilter({ ...filter, companyId: selectedCompanyId }); 
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay rsvp-overlay') {
            handleOnClose();      
        }
    };
     
    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay rsvp-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    {errorMessage && <p className="error-messagess">{errorMessage}</p>}
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">RSVPs for Event</h2>
                    <div className="admin-bottom-bar filter-bars">
                        <div className="admin-bottom-bar-left">
                            <select className="admin-default-select quoteradius" onChange={handleFilterCompany} value={filter.companyId}>
                                <option value="0">All</option>
                                {companiesList && companiesList.length > 0 &&
                                    companiesList.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="pt">
                        <div className="custom-select-scroll scrollbar-inner">
                            <Scrollbar>
                                <table className="admin-table rsvp-table">
                                    <thead className="rsvp-table-header">
                                        <tr className="header-row">
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 1 ? 2 : 1 })} className={`admin-table-name-cell sortable ${filter.sort == 2 ? ' sorted-up' : ' sorted-dn'}`}>First Name</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 3 ? 4 : 3 })} className={`admin-table-name-cell sortable ${filter.sort == 4 ? ' sorted-up' : ' sorted-dn'}`}>Last Name</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 5 ? 6 : 5 })} className={`admin-table-name-cell sortable ${filter.sort == 6 ? ' sorted-up' : ' sorted-dn'}`}>Organization</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 7 ? 8 : 7 })} className={`admin-table-name-cell sortable ${filter.sort == 8 ? ' sorted-up' : ' sorted-dn'}`}>Attendees</th>
                                        </tr>
                                    </thead>
                          
                               <tbody>
                                
                                {getEventsRSVPsList && getEventsRSVPsList.length > 0 ? (
                                    getEventsRSVPsList.map(item => (
                                        <tr key={item.id}>
                                            <td className="admin-table-name-cell">{item?.firstName}</td>
                                            <td className="admin-table-name-cell">{item?.lastName}</td>
                                            <td className="admin-table-name-cell">{item?.organization}</td>
                                            <td className="admin-table-name-cell">{item?.numPlus1s}</td>
                                            <td className="admin-table-name-cell">
                                                {item.companyInfo}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="admin-table-name-cell" colSpan="6">No RSVPs</td>
                                    </tr>
                                )}
                                    </tbody>
                              
                        </table>
                            </Scrollbar>
                        </div> 
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

RspvModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired, 
    modelPosting: PropTypes.object,
    eventId: PropTypes.number
};