import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './dashboard.css';
import ProfileImage from '../../assets/profile-lg.png';
import Bonus from '../../assets/bonus.svg';
import Financial from '../../assets/financial.svg';
import NewsTiles from '../../components/news-tiles/news-tiles';
import Slider from "react-slick";
import Flag from "../../assets/tour.png";
import Check from "../../assets/check-circle-dark.svg";
import { getTodayEvents, mostRecentUpcomingEvents } from '../../store/events/actions';
import { calculateDateDiff } from '../../helpers/formatterHelper';
import { getBadges } from '../../helpers/badges';
import { getCompanies, getEmployeeWorkAnniversaries, getLoggedInEmployeeById } from '../../store/employee/actions';
import { getNewsList } from '../../store/news/actions';
import { GetTimeZone, formatDate } from '../../components/common/DateTimeFormatter';
import HeaderProfile from '../../components/HeaderProfile';
import { getEmployeesCompanyList } from '../../api/events';
import { hideLoading, showLoading } from '../../store/loading-reducer';
export default function HomeDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { todayEventNotifications } = useSelector(state => state.event);
    const { employeeAnniversaries, companiesList } = useSelector(state => state.employee);
    const { siteUserId, primaryCompanyId } = useSelector(state => state.auth);
    const { upcomingEvents } = useSelector(state => state.event);
    const { newsData } = useSelector(state => state.news);
    const [filter, setFilter] = useState({ companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? primaryCompanyId : 0), userId: siteUserId, sort: 6, timezone: GetTimeZone() });
    const initialTodayFilter = primaryCompanyId !== undefined && primaryCompanyId !== 0 ? primaryCompanyId : 0;
    const [selectedCompany, setSelectedCompany] = useState(initialTodayFilter);
    const [employeeCompany, setEmployeeCompany] = useState([]);
    const showSlider = todayEventNotifications && todayEventNotifications.length > 0;

    const [isTodayEvent, setIsTodayEvent] = useState(false);
    const [isAnniversaries, setIsAnniversaries] = useState(false);
    const [isEvents, setIsEvents] = useState(false);
    const [isNews, setIsNews] = useState(false);

    let settings = {
        dots: true,
        infinite: true,
        speed: 300,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        variableWidth: true,
        slidesToShow: todayEventNotifications.length > 2 ? 2.5 : todayEventNotifications.length === 2 ? 2 : 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    const RsvpTypes = {
        NO: 'no',
        YES: 'yes',
        ONE_PLUS: 'oneplus',
    };

    useEffect(() => {
        document.title = 'Dashboard | CWP Team Connect'
        dispatch(showLoading());
        dispatch(getLoggedInEmployeeById({ id: siteUserId }));
        dispatch(getCompanies());

        getEmployeesCompanyList(siteUserId).then((response) => {
            let employeeCompanyResponse = response.data.data;
            setEmployeeCompany(employeeCompanyResponse);
        });
    }, []);

    useEffect(() => {
        dispatch(getTodayEvents(filter)).unwrap().then(() => {
            setIsTodayEvent(true);
        });
        dispatch(getEmployeeWorkAnniversaries(filter)).unwrap().then(() => {
            setIsAnniversaries(true);
        });
        dispatch(mostRecentUpcomingEvents(filter)).unwrap().then(() => {
            setIsEvents(true);
        });
        dispatch(getNewsList(filter)).unwrap().then(() => {
            setIsNews(true);
        });
    }, [filter]);

    useEffect(() => {
        if (isTodayEvent === true && isAnniversaries === true && isEvents === true && isNews === true) {
            dispatch(hideLoading());
        }
    }, [isTodayEvent, isAnniversaries, isEvents, isNews]);

    let checkDate = '';
    const hideEventDate = (date) => {
        if (checkDate != date) {
            checkDate = date;
            return true;
        }
        return false;
    }

    const viewScoreBoard = (type) => {
        let modeType = localStorage.getItem('monitor_mode');
        if (modeType !== undefined && modeType !== null && modeType === "shop-screen") {
            if (type === 'bonus')
                navigate('/shopmonitors/bonuslevels');
            else
                navigate('/shopmonitors/financial-scoreboard');
        }
        else {
            if (type === 'bonus')
                navigate('/bonuslevels');
            else
                navigate('/financial-scoreboard');
        }
    }

    const GetQuarterlyBonus = () => {
        const month = new Date().getMonth();
        if (month >= 0 && month <= 2) {
            return "Q1";
        }
        else if (month >= 3 && month <= 5) {
            return "Q2";
        }
        else if (month >= 6 && month <= 8) {
            return "Q3";
        }
        else if (month >= 9 && month <= 11) {
            return "Q4";
        }
    }

    return (
        <div className="directory-wrap dashboard-wrap">
          {/*  <Navigation />*/}
            <div className="events-page-wrap">
                <div className="page-inner events-inner dashboard-inner-outer">
                    <div className="profile-wrap-outer dashboard-profile mobile-show">
                        <HeaderProfile />
                        <div className="filter-bars">
                            <label>
                                <select className="menu-wrap org-dropdown" name="slct" id="slct" value={selectedCompany} onChange={(event) => {
                                    setSelectedCompany(event.target.value);
                                    const selectedCompanyId = parseInt(event.target.value);
                                    setFilter({ ...filter, companyId: selectedCompanyId });
                                }}>
                                    <option value="0">All</option>
                                    {companiesList.map(company => (
                                        <option key={company.id} value={company.id}>{company.abbreviatedName}</option>
                                    ))}
                                </select>
                            </label>
                         
                        </div>
                     
                    </div>
                
                    <div className="dashboard-scrollbar-wrap">
                     {/*   <PortalAlert />*/}
                        <div className="profile-wrap-outer dashboard-profile">
                            <HeaderProfile />
                            <div className="filter-bars">
                                <label>
                                    <select className="menu-wrap org-dropdown" name="slct" id="slct" value={selectedCompany} onChange={(event) => {
                                        setSelectedCompany(event.target.value);
                                        const selectedCompanyId = parseInt(event.target.value);
                                        setFilter({ ...filter, companyId: selectedCompanyId });
                                    }}>
                                        <option value="0">All</option>
                                        {companiesList.map(company => (
                                            <option key={company.id} value={company.id}>{company.abbreviatedName}</option>
                                        ))}
                                    </select>
                                </label>  
                                
                            </div>
                                                  
                        </div>
                       
                        {showSlider && (
                            <div className="dashboard-slider-wrap">
                                <div className="dashboard-slider">
                                    <Slider {...settings}>
                                        {todayEventNotifications && todayEventNotifications.length > 0 && todayEventNotifications.map(notification => (
                                            notification.eventLog.map(log => (
                                                <a className="dashboard-slides" key={log.id} onClick={() => navigate(`/event-detail/${notification.baseId}/${log.id}`)}>
                                                    <span className="today-label-slide">Today</span>
                                                    <img src={Flag} alt="flag" className="flag-image" loading="lazy" />
                                                    <span className="description-slide">{notification.title}</span>
                                                    <span className="date-slide" key={log.id}>
                                                        {log?.eventDate ? formatDate(log.eventDate, "MMM dd") : ''}
                                                        {log?.startTime && ' @ ' + log.startTime.toLowerCase().replace(" ", "")}  {log?.endTime && 'to ' + log?.endTime.toLowerCase().replace(" ", "")}
                                                    </span>
                                                </a>
                                            ))
                                        ))}

                                    </Slider>
                                </div>
                            </div>
                        )}

                        <div className="dashboard-outer-wrap">

                            <h1 className="sr-only">Dashboard</h1>
                            <div className="dashboard-companies-financial-wrap">
                                <div className="dashboard-financial-wrap">
                                    <p className="dashboard-section-title">{new Date().getFullYear()} Financials</p>
                                    <div className="dashboard-financial-inner">
                                        <div className="financial-wrap" onClick={() => viewScoreBoard('bonus')}>
                                            <img src={Bonus} alt="bonus levels icon" loading="lazy" />
                                            <span className="financial-title">{new Date().getFullYear()} {GetQuarterlyBonus()} Bonus Levels</span>
                                        </div>
                                        <div className="financial-wrap" onClick={() => viewScoreBoard('financial')}>
                                            <img src={Financial} alt="financial scoreboard icon" loading="lazy" />
                                            <span className="financial-title">Financial Scoreboard</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-events-anniversary-wrap">
                                <div className="dashboard-events-wrap">
                                    {upcomingEvents && upcomingEvents.length > 0 && (
                                        <>
                                            <p className="dashboard-section-title">Upcoming Events</p>
                                            <div className="dashboard-events-inner">
                                                {upcomingEvents && upcomingEvents.length > 0 && upcomingEvents.slice(0, 4).map(log => (
                                                    <a onClick={() => navigate('/event-detail/' + log.event?.baseId + '/' + log.id)} className="events-listing-item" key={log.id}>
                                                        <div className="events-date-wrap">
                                                            {hideEventDate(formatDate(log?.eventDate, "MMM d")) && (
                                                                <div className="events-date-wrap-inner">
                                                                    <span className="events-month-title">{formatDate(log.eventDate, "EEE")}</span>
                                                                    <span className="events-day-title">{formatDate(log.eventDate, "d")}</span>
                                                                </div>
                                                            )}

                                                        </div>
                                                        <div className="events-title-wrap">
                                                            <div className="events-title-inner">
                                                                <p className="flex-wraping">

                                                                    <span className="flex-wrap">
                                                                        {log.event.eventCompanies && log.event.eventCompanies.length > 0 && (
                                                                            <>
                                                                                {log.event.eventCompanies.map(item => (
                                                                                    <span key={item.companyId} className="news-day-title">
                                                                                        {companiesList.find(company => company.id === item.companyId)?.abbreviatedName}
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </span>

                                                                    <span>{log.event?.title}</span>
                                                                </p>
                                                                <div className="events-rsvp-wrap">
                                                                    <p>{log?.startTime}</p>
                                                                    {log.event.eventCompanies && log.event.eventCompanies.length > 0 && log.event.eventCompanies.some(company => employeeCompany.find(empCompany => empCompany.id === company.companyId)) && (

                                                                        log.event && (log.event.rsvpType === RsvpTypes.YES || log.event.rsvpType === RsvpTypes.ONE_PLUS) && (
                                                                            <>
                                                                                {(log.event?.eventRSVPs?.siteUserId !== siteUserId || (log.event?.eventRSVPs?.siteUserId === siteUserId && log.event?.eventRSVPs?.numPlus1s === 0)) && (
                                                                                    <button className="events-rsvp-cta">RSVP Here</button>
                                                                                )}
                                                                                {log.event?.eventRSVPs?.siteUserId === siteUserId && log.event?.eventRSVPs?.numPlus1s > 0 && (
                                                                                    <p className="dashboard-check-status">
                                                                                        <img src={Check} alt="check" loading="lazy" />
                                                                                        <span>RSVP&#39;d</span>
                                                                                    </p>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="dashboard-anniversary-wrap">
                                    {employeeAnniversaries && employeeAnniversaries.length > 0 && (
                                        <>
                                            <p className="dashboard-section-title">Anniversaries</p>
                                            <div className="dashboard-anniversary-inner">
                                                {employeeAnniversaries.map(anniversarie => (
                                                    <a onClick={() => navigate('/profile/' + anniversarie.id)} className="dashboard-anniversary-block-wrap purple" key={anniversarie.id}>
                                                        <span className="dashboard-anniversary-profile-wrap">
                                                            <img src={anniversarie.profileImage ? anniversarie.profileImage.previewsrc : ProfileImage} alt="profile" loading="lazy" />
                                                        </span>
                                                        <div className="badgesIcon badgesIconAnn">
                                                            {getBadges(calculateDateDiff(anniversarie?.startDate).yearsDiff)}
                                                        </div>
                                                        <span className="dashboard-anniversary-inner-wrap">
                                                            <span className="dashboard-anniversary-text-wrap">
                                                                <span className="gray-text">Congratulations,</span> {anniversarie.fullName}!
                                                            </span>
                                                            <span className="dashboard-anniversary-years-wrap">                                                                
                                                                <span>{calculateDateDiff(anniversarie.startDate).yearsDiff} {calculateDateDiff(anniversarie?.startDate).yearsDiff > 1 ? 'years' : 'year'}</span>
                                                            </span>
                                                        </span>
                                                    </a>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                            <div className="dashboard-news-outer-wrap">
                                <p className="dashboard-section-title">Happenings</p>
                                <div className="dashboard-news-inner">
                                    <div className="dashboard-news-tiles-wrapper">
                                        {newsData && newsData.length > 0 && newsData.slice(0, 3).map(news => (
                                            <NewsTiles key={news.id} newsItem={news} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
