﻿import { Plugin } from '@ckeditor/ckeditor5-core';
import DoclibraryCommand from './doclibrarycommand';
import { TwoStepCaretMovement, inlineHighlight } from 'ckeditor5/src/typing.js';

export default class DoclibraryEditing extends Plugin {

	static get requires() {
		// Clipboard is required for handling cut and paste events while typing over the link.
		return [TwoStepCaretMovement];
	}

	init() {
		this._defineSchema();
		this._defineConverters();

		this.editor.commands.add(
			'addAbbreviation', new DoclibraryCommand(this.editor)
		);

		const twoStepCaretMovementPlugin = this.editor.plugins.get(TwoStepCaretMovement);
		twoStepCaretMovementPlugin.registerAttribute('abbreviation');


		inlineHighlight(this.editor, 'abbreviation', 'a', 'ck-docCenter');

		this._enableSelectionAttributesFixer();
	}
	_defineSchema() {
		const schema = this.editor.model.schema;	

		schema.extend('$text', {
			allowAttributes: ['abbreviation']
		});
	}	

	_defineConverters() {
		const conversion = this.editor.conversion;

		// Conversion from a model attribute to a view element
		conversion.for('downcast').attributeToElement({
			model: 'abbreviation',

			// Callback function provides access to the model attribute value
			// and the DowncastWriter
			view: (modelAttributeValue, conversionApi) => {
				const { writer } = conversionApi;
				return writer.createAttributeElement('a', {
					href: modelAttributeValue,
					class: 'ck-docCenter',
					target: '_blank',
					rel: 'noopener noreferrer'
				});
			}
		});

		// Conversion from a view element to a model attribute
		conversion.for('upcast').elementToAttribute({
			view: {
				name: 'a',
				attributes: {
					href: true,
					class: true,
					target: true,
					rel: true
				}
			},
			model: {
				key: 'abbreviation',

				// Callback function provides access to the view element
				value: viewElement => {
					const title = viewElement.getAttribute('href');
					return title;
				}
			}
		});
	}


	_enableSelectionAttributesFixer() {
		const editor = this.editor;
		const model = editor.model;
		const selection = model.document.selection;
		this.listenTo(selection, 'change:attribute', (evt, { attributeKeys }) => {
			if (!attributeKeys.includes('abbreviation') || selection.hasAttribute('abbreviation')) {
				return;
			}
			model.change(writer => {
				removeLinkAttributesFromSelection(writer, getLinkAttributesAllowedOnText(model.schema));
			});
		});
	}
}

/**
* Make the selection free of link-related model attributes.
* All link-related model attributes start with "link". That includes not only "linkHref"
* but also all decorator attributes (they have dynamic names), or even custom plugins.
*/
function removeLinkAttributesFromSelection(writer, linkAttributes) {
	writer.removeSelectionAttribute('abbreviation');
	for (const attribute of linkAttributes) {
		writer.removeSelectionAttribute(attribute);
	}
}
/**
 * Returns an array containing names of the attributes allowed on `$text` that describes the link item.
 */
function getLinkAttributesAllowedOnText(schema) {
	const textAttributes = schema.getDefinition('$text').allowAttributes;
	return textAttributes.filter(attribute => attribute.startsWith('abbr'));
}