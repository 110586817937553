import React from 'react';
import PropTypes from 'prop-types';
//import { formatDate } from '../../helpers/formatterHelper';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../common/DateTimeFormatter';
 

const HrEvents = ({ eventsItem, siteUserId, employeeCompany }) => {   
    const navigate = useNavigate(); 
    const RsvpTypes = {
        NO: 'no',
        YES: 'yes',
        ONE_PLUS: 'oneplus',
    };
 
    return (
        <div className="events-listing-wrap hrevents-listing-wrap">
            {eventsItem && ( 
                <a onClick={() => navigate('/event-detail/' + eventsItem.event.baseId + '/' + eventsItem.id)} className={`hrevents-listing-item ${eventsItem.isRsvp ? 'hrevents-rsvp' : ''} pink`} key={eventsItem.id}>
                    <span className="hrevents-date-wrap">
                            <div className="events-date-wrap-inner">
                            <span className="events-month-title">{formatDate(eventsItem.eventDate, "MMM")}</span>
                            <span className="events-day-title">{formatDate(eventsItem.eventDate, "d")}</span>
                            </div>
                    </span>
                    <span className="hrevents-title-wrap">                   
                        {eventsItem.event.eventCompanies && eventsItem.event.eventCompanies.length > 0 && (
                            <div className="flex-wrap">
                                {eventsItem.event.eventCompanies.map(item =>
                                    <span key={item.companyId} className="news-day-title">{item.companies?.abbreviatedName}</span>
                                )}
                            </div>
                        )}
                        
                        <span>{eventsItem.event.title}</span>
                    </span> 

                    {eventsItem.event.eventCompanies && eventsItem.event.eventCompanies.length > 0 && eventsItem.event.eventCompanies.some(company => employeeCompany.find(empCompany => empCompany.id === company.companyId)) && (
                        (eventsItem.event.rsvpType === RsvpTypes.YES || eventsItem.event.rsvpType === RsvpTypes.ONE_PLUS) && (
                            <span className="hrevents-rsvp-wrap">

                                {eventsItem.event.eventRSVPs?.siteUserId !== siteUserId && (
                                    <button className="events-rsvp-cta">RSVP Here</button>
                                )}
                                {eventsItem.event.eventRSVPs?.siteUserId === siteUserId && eventsItem.event.eventRSVPs?.eventLogId !== eventsItem.id && (
                                    <button className="events-rsvp-cta">RSVP Here</button>
                                )}
                                {eventsItem.event.eventRSVPs?.siteUserId === siteUserId && eventsItem.event.eventRSVPs?.eventLogId === eventsItem.id && (
                                    <button className="events-rsvp-cta selected">RSVP&rsquo;d</button>
                                )}
                            </span>
                        )
                    )}

                    
                    </a>
            )}
        </div>
    );
};

HrEvents.propTypes = {
    eventsItem: PropTypes.object,
    siteUserId: PropTypes.number,
    employeeCompany: PropTypes.array
};

export default HrEvents; 
 