import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { MediaItem } from './MediaItem';
import './mediacomponent.css';
import './react-image-crop.css';

const defaultCropSettings = {
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: 1
}

function EditImageArea({ ShowCrop, imageObj, setCropImage, handleCancelClick }) {
  
    if (imageObj && imageObj.photo) {
        const [crop, setCrop] = useState('cropValues' in imageObj ? JSON.parse(imageObj.cropValues) : defaultCropSettings);

        const handleCropChange = (crop, percentage) => {
            setCrop(percentage);
            setCropImage(percentage);
        }
       // const cropValues = ('cropValues' in imageObj ? JSON.parse(imageObj.cropValues) : defaultCropSettings); // use if we dont want recrop
        // Use split to get the parts of the URL after the last slash  
        const filenameParts = imageObj.photo.originalFileName.split('.');

        // Get the last part of the array (file extension)
        const fileExtension = filenameParts[filenameParts.length - 1];

        const parts = imageObj.previewsrc.split('/');

        // Remove the last part of the array (file name) and join the remaining parts
        const urlBeforeLastSlash = parts.slice(0, -2).join('/');
        const displayImageUrl = urlBeforeLastSlash + '/og/' + imageObj.photo.fileGuid + '.' + fileExtension;
        if (ShowCrop) {
            return (
                <ReactCrop
                    //crop={cropValues} //use if we dont want recrop
                    crop={crop}
                    onChange={(c, p) => handleCropChange(c, p)}
                    aspect={1}
                >
                    <img className="uploadImage"
                        src={displayImageUrl} />
                </ReactCrop>
            )
        }
        else {

            return (<div className="uploadImageWrapper">
                <img className="uploadImage"
                    src={displayImageUrl} />
            </div>);
        }
    }
    else {
        handleCancelClick();
    }
}

EditImageArea.propTypes = {
    ShowCrop: PropTypes.bool,
    imageObj: PropTypes.object,
    setCropImage: PropTypes.func,    
    handleCancelClick: PropTypes.func
}
function ShowImageArea({ ShowCrop, BaseImage, CropImage, setCropImage }) {

    const baseImageUrl = URL.createObjectURL(BaseImage);
    useEffect(() => {
        if (BaseImage != null) {
            // Create an image element to calculate the aspect ratio
            const img = new Image();
            img.src = URL.createObjectURL(BaseImage);
            img.onload = () => {
                // Determine the aspect ratio of the uploaded image
                const aspectRatio = img.width / img.height;         
                var cropWidth = 50; // Set your desired width here
                const cropHeight = aspectRatio >= 1 ? (cropWidth / aspectRatio) : (cropWidth / 1.5) * aspectRatio;
                cropWidth = aspectRatio >= 1 ? cropHeight / aspectRatio : cropWidth * aspectRatio;
                // Update defaultCropSettings based on aspect ratio
                const updatedCropSettings = {
                    unit: '%',
                    x: (100 - cropWidth) / 2, // Center the crop horizontally
                    y: (100 - cropHeight) / 2, // Center the crop vertically
                    width: cropWidth,
                    height: cropHeight,
                    aspect: 1
                };

                setCropImage(updatedCropSettings);
            };
        }
    }, []);

    const handleCropChange = (crop, percentage) => {
        setCropImage(percentage);
    }

    if (ShowCrop) {
        return (
            <ReactCrop
                crop={CropImage}
                onChange={(c, p) => handleCropChange(c, p)}
                aspect={1}
            >
                <img className="" src={baseImageUrl} />
            </ReactCrop>
        );
    }
    else {
        return (<div className="uploadImageWrapper">
            <img className="uploadImage"
                src={baseImageUrl} />
        </div>);
    }
}
ShowImageArea.propTypes = {
    ShowCrop: PropTypes.bool,
    BaseImage: PropTypes.object,
    CropImage: PropTypes.object,
    setCropImage: PropTypes.func
}

export default function MediaImageModal({
    requireAltText, showModal, forceCrop, uploadImageObj, editImageObj,
    onCloseModal, onSaveModal, onCustomSaveModal }) {
    const [showCrop, setShowCrop] = useState(forceCrop);
    const [cropImage, setCropImage] = useState(defaultCropSettings);
    const [imageObj, setImageObj] = useState(editImageObj ?? MediaItem);

    useEffect(() => {
        // Update imageObj when editImageObj changes
        if (editImageObj && editImageObj.cropValues) {
            setCropImage(JSON.parse(editImageObj.cropValues));
        } else {
            // Reset to default MediaItem if editImageObj is not available
            setCropImage(defaultCropSettings);
        }
    }, [editImageObj]);

    useEffect(() => {
        // Update imageObj when editImageObj changes
        if (editImageObj) {
            setImageObj(editImageObj);
        } else {
            // Reset to default MediaItem if editImageObj is not available
            setImageObj(MediaItem);
        }
    }, [editImageObj]);

    useEffect(() => {
        //console.log(editImageObj);
        if (editImageObj != null && 'isCropped' in editImageObj) {
            setShowCrop(editImageObj.isCropped);
        }
    }, []);

    const handleSaveModal = async () => {

        if (requireAltText && imageObj.altText === '') {
            document.getElementById('alt-text').classList.add('input-validation-error');
            return;
        }
        const mediaData = JSON.parse(JSON.stringify(imageObj));
        mediaData.cropValues = JSON.stringify(cropImage);
        mediaData.imageFile = uploadImageObj;
        mediaData.isCropped = showCrop;

        onSaveModal(mediaData);

        setShowCrop(true);
        setCropImage(defaultCropSettings);
        setImageObj(MediaItem);
    }

    const handleCancelClick = () => {
        if (showCrop) {
            //setShowCrop(false);
            onCloseModal();
        }
        else
            onCloseModal();
    }

    const handleShowCropModal = () => {
        setShowCrop(true);
    }

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleCancelClick();
        }
    };

    if (showModal) {
        return (
            <div data-section="CropImageOverlayPreview" className="admin-modal-overlay" onClick={handleOverlayClick}>
                <div className="admin-modal admin-add-role">
                    <div className="admin-modal-scroll">
                        <button className="admin-modal-close" onClick={handleCancelClick}>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <p className="admin-modal-heading">Image Preview</p>
                        {/*  { to do you want to custom alt test}*/}
                        {/*<div className="admin-modal-row">*/}
                        {/*    <div className="admin-modal-cell">*/}
                        {/*        {requireAltText ? (*/}
                        {/*            <label className="admin-default-label" htmlFor="alt-text">Alt Text (Required)</label>*/}
                        {/*        ) : (*/}
                        {/*            <label className="admin-default-label" htmlFor="alt-text">Alt Text</label>*/}
                        {/*        )}*/}
                        {/*        <input*/}
                        {/*            id="alt-text"*/}
                        {/*            type="text"*/}
                        {/*            className="admin-default-input-text"*/}
                        {/*            value={imageObj.altText}*/}
                        {/*            onChange={(e) => setImageObj({*/}
                        {/*                ...imageObj,*/}
                        {/*                altText: e.target.value*/}
                        {/*            })} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="admin-modal-row full">
                            <div className="admin-modal-cell full">
                                {uploadImageObj !== null ?
                                    <ShowImageArea
                                        ShowCrop={showCrop}
                                        BaseImage={uploadImageObj}
                                        CropImage={cropImage}
                                        setCropImage={setCropImage}
                                    /> : <EditImageArea
                                        imageObj={editImageObj}
                                        ShowCrop={showCrop}                                      
                                        setCropImage={setCropImage}
                                        handleCancelClick={handleCancelClick}
                                    />}
                            </div>
                        </div>
                        <div className="admin-modal-button-wrap">
                            <button className="admin-modal-button power"
                                onClick={handleCancelClick}
                            >Cancel</button>
                            {!showCrop ?
                                <button className="admin-modal-button"
                                    onClick={handleShowCropModal}
                                >Crop</button> : <></>}
                            <button className="admin-modal-button"
                                onClick={onCustomSaveModal ?? handleSaveModal}
                            >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else return (<></>);
}

MediaImageModal.propTypes = {
    requireAltText: PropTypes.bool,
    mediaObj: PropTypes.object,
    showModal: PropTypes.bool,
    forceCrop: PropTypes.bool,
    uploadImageObj: PropTypes.object,
    editImageObj: PropTypes.object,
    onCloseModal: PropTypes.func,
    onSaveModal: PropTypes.func,
    onCustomSaveModal: PropTypes.func
}

MediaImageModal.defaultProps = {
    requireAltText: false,
    forceCrop: false,
    uploadImageObj: null,
    editImageObj: null,
    onCustomSaveModal: null
}
