import React from 'react';
import PropTypes from 'prop-types';

export default function ShowAccessError({ show }) {


    if (!show) {
        return null;
    }

    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal">
                <h2 className="admin-modal-heading">
                    Your session has expired. Please log in again to continue.
                </h2>

                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={() => {
                        window.location.reload();               
                    }}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
}


ShowAccessError.propTypes = {
    show: PropTypes.bool.isRequired
};