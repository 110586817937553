import React from 'react';
import { useAdminMenuContext } from '../../context/AdminMenuContext'; // Adjust the path based on your folder structure
import style from './adminEditLink.module.css'; // Adjust the path based on your folder structure

export default function AdminEditLink() {
    const [editLink] = useAdminMenuContext();

    const linkText = editLink && editLink.includes('/siteadmin') ? 'Edit' : 'View';

    return editLink ? (
        <a
            className={`${style.adminDrawerViewPage} ${style[linkText]}`}
            href={editLink}
            target="_blank"
            title={`${linkText} Page`}
            rel="noreferrer"
        >
            {linkText} Page <span className="material-symbols-rounded">
                visibility
            </span>

        </a>
    ) : null;
}
