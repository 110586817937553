import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../../store/employee/actions';
import { getNews, deleteNews } from '../../../store/news/actions';
import { NewsConfrimationModal } from './NewsDeleteConfirmation';
import { formatDate } from '../../../helpers/formatterHelper';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { AddUpdatePin } from '../../../api/news';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";
function News() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ query: '', companyId: 0, sort: 6, IsShowPastEvent :false });
    const { companiesList } = useSelector(state => state.employee);
    const { newsList } = useSelector(state => state.news); 
    const [newsModel, setNewsModel] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editLink, setEditLink] = useAdminMenuContext('');
    const handlePinned = (pinData) => {
            handleAddEditPinned(pinData);
        }

    const handleFilterCompany = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        setFilter({ ...filter, companyId: selectedCompanyId });
    };
    useEffect(() => {     
        document.title = 'Happenings | CWP Employee Portal';
        dispatch(showLoading());
        dispatch(getCompanies());
        setEditLink('/news-tiles');
        console.log(editLink)
    }, []);
     
    useEffect(() => {
        dispatch(getNews(filter)).unwrap().then(() => {
            dispatch(hideLoading());
        });
    }, [filter]);

    const handleDeleteTrigger = (id ,title) => {
        setNewsModel({ id: id, title: title });
        setShowDeleteModal(true);
    }
    const handleDeleteNews = (item) => {
        dispatch(deleteNews(item.id));
    }

    const handleAddEditPinned = (pinData) => {
        AddUpdatePin(pinData)
            .then((response) => {            
                if (response.data.success) {
                    dispatch(getNews(filter));
                    dispatch(hideLoading());
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                if (error.response.data && error.response.data.message) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error.response.responseText);
                }
            });
  
    }

    return (
        <>
            <NewsConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteNews={handleDeleteNews}
                modelNews={newsModel}
            />
            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link className="admin-top-bar-back" to={'/siteadmin'}>Back</Link>
                    </div>
                </div>

                <div className="pageContainer">
                    <div className="admin-page-content">
                        <div className="admin-page-title-wrap">
                            <h1 className="admin-page-heading">Happenings</h1>
                            <button className="admin-button"
                                onClick={() =>
                                    navigate('/siteadmin/news/create')
                                }
                            >
                                <span>Add Happenings</span>
                                <span className="material-symbols-rounded">add</span>
                            </button>
                        </div>
                        <div className="admin-bottom-bar filter-bars">
                            <div className="admin-bottom-bar-left">
                                <select className="admin-default-select quoteradius" onChange={handleFilterCompany} value={filter.companyId}>
                                    <option value="0">All</option>
                                    {companiesList && companiesList.length > 0 &&
                                        companiesList.map(item => (
                                            <option key={item.id} value={item.id}>{item.abbreviatedName}</option>
                                        ))}
                                </select>
                            </div>
                        </div>

                        <div className="pt">
                            <table className="admin-table">
                                <tbody>
                                    <tr> 
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 1 ? 2 : 1 })} className={filter.sort == 1 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 2 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Happenings Title</th>
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 3 ? 4 : 3 })} className={filter.sort == 3 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 4 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Organization</th>
                                        <th onClick={() => setFilter({ ...filter, sort: filter.sort == 5 ? 6 : 5 })} className={filter.sort == 5 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 6 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Publish Date</th>                                                                            
                                        <th className="admin-table-action-cell">&nbsp;</th>
                                        <th className="admin-table-action-cell">&nbsp;</th>
                                        <th className="admin-table-action-cell">&nbsp;</th>
                                    </tr>
                                    {newsList && newsList.length > 0 ? (
                                        newsList.map(item => (
                                            <tr key={item.id}>                                               
                                                <td className="admin-table-name-cell">{item.title}</td>
                                                <td className="admin-table-name-cell">
                                                    {item.companyInfo}
                                                </td>

                                          
                                                <td className="admin-table-name-cell">
                                                    {item.publishDate && item.publishDate.length > 0 ? ( 
                                                        <span>{formatDate(item.publishDate)}</span>     
                                                       
                                                    ) : (
                                                        <span></span>
                                                    )}</td>
                                                <td className="admin-table-name-cell">
                                                    <button className="admin-table-action" onClick={() => handlePinned(item)}>
                                                        {item?.pinnedDateTime ? (
                                                            <>
                                                                <span className="material-symbols-rounded">keep</span>
                                                            </>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                                                                <path d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Zm-286 80h252l-46-46v-314H400v314l-46 46Zm126 0Z" />
                                                            </svg>
                                                        )}
                                                    </button>
                                                </td>

                                                <td className="admin-table-name-cell">
                                                    <button className="admin-table-action" onClick={() => navigate('/siteadmin/news/edit/' + item.baseId)}>
                                                        <span className="material-symbols-rounded">edit</span>
                                                    </button>
                                                </td>

                                                <td className="admin-table-name-cell">
                                                    <button className="admin-table-action" onClick={() => handleDeleteTrigger(item.id, item.title)}>
                                                        <span className="material-symbols-rounded">delete</span>
                                                    </button>
                                                </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="admin-table-name-cell" colSpan="6">No active Happenings</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default News;