import { createAsyncThunk } from '@reduxjs/toolkit';
import * as eventsApi from '../../api/events';

export const getEvents = createAsyncThunk('getEvents', async (data) => {
    const res = await eventsApi.getEvents({ data: data });
    return res.data;
});  

export const getTypeEvent = createAsyncThunk('getTypeEvent', async (data) => {
    const res = await eventsApi.getTypeEvent({ data: data });
    return res.data;
});  
 
export const deleteEvent = createAsyncThunk('deleteEvent', async (data) => {
    const res = await eventsApi.deleteEvent({ data: data });
    return res.data;
});

export const getPastEvents = createAsyncThunk('getPastEvents', async (data) => {
    const res = await eventsApi.getEvents({ data: data });
    return res.data;
}); 

export const getEventsRSVPs = createAsyncThunk('getEventsRSVPs', async (data) => {
    const res = await eventsApi.getEventsRSVPs({ data: data });
    return res.data;
}); 

export const getEventByIdEdit = createAsyncThunk('getEventByIdEdit', async (data) => {
    const res = await eventsApi.getEventByIdEdit({ data: data });
    return res.data;
}); 
export const getEvenRsvptUpdated = createAsyncThunk('getEvenRsvptUpdated', async (data) => {
    const res = await eventsApi.EventsRSVPsUpdate({ data: data });
    return res.data;
}); 

export const getTodayEvents = createAsyncThunk('Event/getTodayEvents', async (data) => {
    const res = await eventsApi.getTodayEventNotifications({ data: data });
    return res.data;
}); 


export const getCalendarEvents = createAsyncThunk('getCalendarEvents', async (data) => {
    const res = await eventsApi.getCalendarEvents({ data: data });
    return res.data;
}); 


export const mostRecentUpcomingEvents = createAsyncThunk('mostRecentUpcomingEvents', async (data) => {
    const res = await eventsApi.mostRecentUpcomingEvents({ data: data });
    return res.data;
}); 

export const getallhrevents = createAsyncThunk('getallhrevents', async (data) => {
    const res = await eventsApi.getallhrevents({ data: data });
    return res.data;
}); 

//export const getEventDetailByBaseId = createAsyncThunk('getEventDetailByBaseId', async (data) => {
//    const res = await eventsApi.getEventDetailByBaseId({ data: data });
//    return res.data;
//});

export const getEventImage = createAsyncThunk('getEventImage', async (data) => {
    const res = await eventsApi.getEventImage({ data: data });
    return res.data;
});  