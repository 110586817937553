export const shopMonitorSliderTime = {
    default : 10000,
    LeadingTime: 300000
}


export const templateDocuments = {
    bonusLevels: '/templatefiles/Bonus_Levels_Template.csv',
    bonusLevelsFileName: 'Bonus_Levels_Template.csv',
    scoreboard: '/templatefiles/Organization_Scoreboard_Template.csv',
    scoreboardFileName: 'Organization_Scoreboard_Template.csv',
}