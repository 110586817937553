import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './detail.css';
import Sidebar from '../../components/SideBar/Sidebar.js';
import calendarIcon from '../../assets/calendar_today-sm.svg';
import { mostRecentUpcomingEvents } from '../../store/events/actions';
import { GetTimeZone, formatDate } from '../../components/common/DateTimeFormatter';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PropTypes from 'prop-types';
import { shopMonitorSliderTime } from '../../helpers/enums';

export default function ShopMonitors({ handleloadinTiming }) {
    const dispatch = useDispatch();
    const { upcomingEvents } = useSelector(state => state.event);
    const [filter, setFilter] = useState({ companyId: 0, userId: 0, sort: 6, timezone: GetTimeZone() });
    const isLoading = useSelector((state) => state.loading);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const companiesList = useSelector(state => state.employee.companiesList);

    useEffect(() => {
        document.title = 'Events | CWP Team Connect';
        dispatch(showLoading());       
        handleloadinTiming(shopMonitorSliderTime.LeadingTime);
        let sharpCompanyId = localStorage.getItem('monitor_mode_company');
        if (sharpCompanyId !== undefined && sharpCompanyId !== null && sharpCompanyId != '0' && sharpCompanyId != 0) {
            setFilter({ ...filter, companyId: parseInt(sharpCompanyId) });
        }
        else {
            let defaultCompany= companiesList && companiesList.length > 0 ? companiesList[0].id : 0;               
            localStorage.setItem('monitor_mode_company', defaultCompany);
            setFilter({ ...filter, companyId: parseInt(defaultCompany) });
        }
       
    }, []);

    useEffect(() => {
        if (filter.companyId > 0) {
            dispatch(mostRecentUpcomingEvents(filter)).unwrap().then(() => {
                dispatch(hideLoading());
                handleloadinTiming(shopMonitorSliderTime.default);
                setIsDataLoaded(true);
            });
        }
    }, [filter]);


    useEffect(() => {
        if (isLoading && isDataLoaded) {
            dispatch(hideLoading());
            handleloadinTiming(shopMonitorSliderTime.default);
        }
    }, [isLoading, isDataLoaded]);

    const uniqueEvents = upcomingEvents.reduce((acc, event) => {
        if (!acc.find(item => item.eventId === event.eventId)) {
            acc.push(event);
        }
        return acc;
    }, []);

    return (
        <div className="monitors-inner">
            <div className="monitors-item">
                <div className="monitors-header-wrap">
                    <p className="monitors-header-title">Events</p>
                    <span className="monitors-header-icon"><img src={calendarIcon} alt="Calendar Icon" /></span>
                </div>
                <div className="monitors-item-event-wrap">
                    {uniqueEvents.slice(0, 3).map((log, index) => (
                        <div className="monitors-item-event-item" key={index}>
                            <div className="monitors-item-event-date-wrap">
                                <p className="monitors-event-month">{formatDate(log.eventDate, "MMM")}</p>
                                <p className="monitors-event-day">{formatDate(log.eventDate, "d")}</p>
                            </div>
                            <div className="monitors-item-event-title-wrap">
                                <p className="monitors-event-day-thin">{formatDate(log.eventDate, "EEEE")}</p>
                                <p className="monitors-event-title">{log.event?.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Sidebar
                pageType={"events"}
            />
        </div>
    );
}

ShopMonitors.propTypes = {
    handleloadinTiming: PropTypes.func
};