import { createAsyncThunk } from '@reduxjs/toolkit';
import * as departmentApi from '../../api/department';

export const getDepartments = createAsyncThunk('getDepartments', async (data) => {
    const res = await departmentApi.getDepartments(data);
    return res.data;
});
export const getDepartmentsV2 = createAsyncThunk('getDepartmentsV2', async ({ searchByName = null, companyId = 0, config }) => {
    const res = await departmentApi.getDepartmentsV2(searchByName, companyId, config);
    return res.data;
});

export const getDepartmentAndCompanyData = createAsyncThunk('getDepartmentAndCompanyData', async (data) => {
    const res = await departmentApi.getDepartmentAndCompanyData({ data: data });
    return res.data;
});

export const updateDepartmentsOrder = createAsyncThunk('updateDepartmentsOrder', async (companyId, departmentId) => {
    const res = await departmentApi.updateDepartmentsOrder({
        options: {
            params: { companyId: companyId, departmentId: departmentId },
        },
    });
    return res.data;
});

export const addEditCompanyDepartment = createAsyncThunk('addEditCompanyDept', async (data) => {
    const res = await departmentApi.addEditCompanyDepartment({ data: data });
    return res.data;
});

export const updateDepartmentOrder = createAsyncThunk('updateDepartmentOrder', async (data) => {
    const res = await departmentApi.updateDepartmentOrder({
        options: {
            params: { Ids: data.ids, companyId: data.companyId },
        },
    });
    return res.data;
});