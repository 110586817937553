import React from "react"
import { useSelector } from 'react-redux';
import { getBadges } from '../helpers/badges';
import { calculateDateDiff } from '../helpers/formatterHelper';
export default function HeaderProfile() {
	const { loggedInEmployee } = useSelector(state => state.employee);
	const { siteUserId } = useSelector(state => state.auth);
    return (
		<a className="profile-wrapper" href={`/profile/${siteUserId}`}>
			<span className="profile-picture">
				<span className="profile-image">
					{(loggedInEmployee && loggedInEmployee.profileImage && loggedInEmployee.profileImage.previewsrc) ?
						<span className="profile-image">
							<img src={loggedInEmployee.profileImage.previewsrc} alt={loggedInEmployee.profileImage.altText} loading="lazy" />
						</span>
						:
						<span className="navigation-menu-sticky-profile-image">
							<img src="../../profile-lg.png" alt="Placeholder Profile Image" loading="lazy" />
						</span>
					}
				</span>
			</span>
			<div className="badgesIcon">
					{getBadges(calculateDateDiff(loggedInEmployee?.startDate).yearsDiff)}
			</div>
			{loggedInEmployee && loggedInEmployee.fullName && (
				<span className="profile-content">
					<span className="profile-name">{loggedInEmployee.fullName}</span>
					<span className="profile-title">
						<span>{loggedInEmployee.departmentDisplay}</span>
					</span>
				</span>
			)}			
		</a>
    )
}