import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminTextInput from '../../../components/admin/admin-text-input';
import { addDocument, getDetails, deleteDocumentFile } from '../../../api/documentLibraryApi';
import AdminError from '../../../components/admin/admin-error';
import { hideLoading, showLoading } from '../../../store/loading-reducer';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const getCurrentDateTime = () => {
    const now = new Date();
    const formattedDateTime =
        now.getFullYear().toString() +
        (now.getMonth() + 1).toString().padStart(2, '0') +
        now.getDate().toString().padStart(2, '0') +
        now.getHours().toString().padStart(2, '0') +
        now.getMinutes().toString().padStart(2, '0') +
        now.getSeconds().toString().padStart(2, '0');

    return formattedDateTime
};
export default function DocumentLibraryAddEdit() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { baseId } = useParams();
    const { token } = useSelector(state => state.auth);
    const [document, setDocument] = useState({ baseId: 0, name: '', formFile: null, uid: uuidv4(), dateTime: getCurrentDateTime() });
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    useEffect(() => {
        if (baseId != null) {
            dispatch(showLoading());
            getDetails(baseId).then(results => {                
                setDocument({ ...results.data.data, formFile: null });
                dispatch(hideLoading());
                // setIsLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        setIsLoading(false);
        if (document?.baseId > 0 && fileName==='') {
            setFileName(document.fileName);
        }
    }, [document]);

    const handleAddCorti = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        setIsLoading(true);
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            setDocument({ ...document, formFile: file });
        }
    };

    const deleteFileName = () => {
        dispatch(showLoading());
        if (baseId > 0) {
            deleteDocumentFile(baseId).then(results => {
                if (results.data.success) {
                    dispatch(hideLoading());
                    setFileName('');
                }
            });
        }
        else {
            setFileName('');
        }
    } 

    const onSave = () => {

        if (!document.formFile && document.baseId === 0) {
            setError('Please upload a document file.');
            return;
        }

        dispatch(showLoading());  
        if (!document.formFile && document.baseId !== 0) {
            addDocument(document).then((response) => {
                if (response.data.success == false) {
                    dispatch(hideLoading());
                    setError(response.data.message);
                    return;
                }
                if (response.data.success) {
                    dispatch(hideLoading());
                    navigate('/siteadmin/document-library');
                }
            })
        } else {
            handleChunkedUpload(document.formFile);
        }        
    };


    const handleChunkedUpload = (file) => {
        const chunkSize = 5 * 1024 * 1024; // 5 MB chunk size
        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;

        const filepath = `${document.uid}/${document.dateTime}`;



        const uploadNextChunk = async () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('docChunk', chunk);
            formData.append('chunkNumber', currentChunk);
            formData.append('fileName', file.name);
            formData.append('filepath', filepath);

            const chunkDocument = {
                formFile: chunk,
                chunkNumber: currentChunk,
                fileName: file.name,
                uID: document.uid,
                dateTime: document.dateTime
            }

            try {
                const response = await axios.post('/api/documents/uploadChunk', chunkDocument, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log('Chunk upload response:', response);

                currentChunk++;

                if (currentChunk < totalChunks) {
                    uploadNextChunk();
                } else {
                    finalizeUpload(file.name, totalChunks);
                }
            } catch (error) {
                console.error('Error uploading chunk', error);
                // Handle error as needed
            }
        };

        uploadNextChunk();
    };


    const finalizeUpload = async (fileName, totalChunks) => {
        try {

   
            const chunkDocument = {
                totalChunks: totalChunks,
                fileName: fileName,
                uID: document.uid,
                dateTime: document.dateTime,
                name: document.name,
                baseId: document.baseId
            }

            const response = await axios.post('/api/documents/finalizeUpload',
                chunkDocument,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response) {
                setIsLoading(false);
                if (response.data.success == false) {
                    dispatch(hideLoading());
                    setError(response.data.message);
                    return;
                }
                if (response.data.success) {
                    dispatch(hideLoading());
                    navigate('/siteadmin/document-library');
                }
            }
        } catch (error) {
            console.error('Error finalizing upload', error);            
        }
    };

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin/document-library'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">Add/Edit Document Library</h1>
                <AdminError
                    message={error}
                    onClose={() => {
                        setError('');
                    }}
                ></AdminError>
                <div className="admin-section admin-section-narrow">             
                    <div className="admin-section-row">
                        <div className="admin-section-cell extra-wide">
                            <AdminTextInput
                                label="Name"
                                id="name"
                                value={document.name || ''}
                                onChange={(value) => {
                                    setDocument({ ...document, name: value });
                                }}
                            />
                        </div>
                    </div>
                    <div className="small-space">&nbsp;</div>            
                    <div className="admin-section-row">
                        <div className="admin-section-cell extra-wide document-file-update">

                            {(fileName === undefined || fileName === '') && (
                                <label className="mediaAddImage">
                                    <input
                                        type="file"
                                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf, .csv"
                                        onChange={handleFileChange} />
                                    <span className="mediaAdd-button upload-with-spinner">{baseId ? 'Update Document' : 'Add Document'}</span>
                                </label>
                            )}
               
                            {(fileName !== undefined && fileName !== '') && (

                                <div className="admin-skill-tile financial-skill-tile">
                                    <span className="admin-skill-tile-name">{(fileName !== undefined && fileName != null && fileName != '') ? fileName : document.fileName}</span>
                                    <span className="admin-skill-tile-action-wrap">
                                        <button className="admin-skill-tile-action upload-csv upload-with-spinner" onClick={handleAddCorti}>Replace Document {isLoading && (<span className="loader"></span>)}</button>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf, .csv"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <button className="admin-skill-tile-action">
                                            <span className="material-symbols-rounded" onClick={deleteFileName}>delete</span>
                                        </button>
                                    </span>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="admin-floating-button-wrap">
                        <button
                            className="admin-floating-button"
                            onClick={() =>
                                navigate('/siteadmin/document-library')
                            }
                        >
                            Cancel
                        </button>
                        <button
                            className="admin-floating-button power"
                            onClick={onSave}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}