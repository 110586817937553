import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isProjectLoaded: false,
    projectsList: [],
    isProjectSaving: false
}

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    extraReducers: (builder) => {

        // Get Projects
        builder.addCase(actions.getProjects.pending, (state) => {
            state.isProjectLoaded = false;
        })
        builder.addCase(actions.getProjects.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.projectsList = action.payload.data;
            } else {
                state.projectsList = [];
            }
            state.isProjectLoaded = true;
        })
        builder.addCase(actions.getProjects.rejected, (state) => {
            state.isProjectLoaded = false;
        });


        // add/edit Job Postings
        builder.addCase(actions.addEditProjects.pending, (state) => {
            state.isProjectSaving = true;
        })
        builder.addCase(actions.addEditProjects.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                const postingsObj = state.projectsList.find(x => x.id === returnObj.id);
                if (postingsObj !== undefined && postingsObj !== null) {
                    postingsObj.title = returnObj.title;          
                    postingsObj.active = returnObj.active;
                    postingsObj.media = returnObj.media;
                    postingsObj.mediaId = returnObj.mediaId;
                    postingsObj.projectMedias = returnObj.projectMedias;
                } else {
                    state.projectsList.unshift(returnObj);
                }
            }
            state.isProjectSaving = false;
        })
        builder.addCase(actions.addEditProjects.rejected, (state) => {
            state.isProjectSaving = false;
        });


        // Delete Job Posting
        builder.addCase(actions.deleteJobProjects.pending, (state) => {
            state.isProjectSaving = true;
        })
        builder.addCase(actions.deleteJobProjects.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.projectsList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.projectsList.splice(enityIndex, 1);
                }
            }
            state.isProjectSaving = false;
        })
        builder.addCase(actions.deleteJobProjects.rejected, (state) => {
            state.isProjectSaving = false;
        });

        // order Benefits
        builder.addCase(actions.updateProjectOrder.pending, (state) => {
            state.isProjectSaving = true;
        })
        builder.addCase(actions.updateProjectOrder.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.projectsList = action.payload.data;
            } else {
                state.projectsList = [];
            }
            state.isProjectSaving = false;
        })
        builder.addCase(actions.updateProjectOrder.rejected, (state) => {
            state.isProjectSaving = false;
        });
    }
});

export default projectsSlice.reducer;