import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isComposeSaving: false,
    isScheduledDataLoaded: false,
    scheduledList: [],

    isComposedDataLoaded: false,
    composedByIdList: [],

    isGetCountPeoplesLoaded: false,
    GetCountPeoplesList: [],
    isAllMessageLog: false,
    allMessageLogList: [],
    addcomposeData: [],
    isSiteUsersListByDepartmentIdsLoaded: false,
    siteUsersListByDepartmentIds: [],
    getFailedReasonLoaded: false,
    getFailedReason: []
}

const composeSlice = createSlice({
    name: 'compose',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(actions.AddNewCompose.pending, (state) => {
            state.isComposeSaving = true;
        })
        builder.addCase(actions.AddNewCompose.fulfilled, (state, action) => {
            state.isComposeSaving = false;
            if (action.payload.success) {
                state.addcomposeData = action.payload.data;
            } else {
                state.addcomposeData = [];
            }
               
        
        })
        builder.addCase(actions.AddNewCompose.rejected, (state) => {
            state.isComposeSaving = false;
        });

        //get scheduled Data
        builder.addCase(actions.getScheduledData.pending, (state) => {
            state.isScheduledDataLoaded = false;
        })
        builder.addCase(actions.getScheduledData.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.scheduledList = action.payload.data;
            } else {
                state.scheduledList = [];
            }
            state.isScheduledDataLoaded = true;
        })
        builder.addCase(actions.getScheduledData.rejected, (state) => {
            state.scheduledList = false;
        });

        //get AllMessage log Data
        builder.addCase(actions.getAllMessage.pending, (state) => {
            state.isAllMessageLog = false;
        })
        builder.addCase(actions.getAllMessage.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.allMessageLogList = action.payload.data;
            } else {
                state.allMessageLogList = [];
            }
            state.isAllMessageLog = true;
        })
        builder.addCase(actions.getAllMessage.rejected, (state) => {
            state.allMessageLogList = false;
        });


        //get Comosed Data by Id
        builder.addCase(actions.getComposedById.pending, (state) => {
            state.isComposedDataLoaded = false;
        })
        builder.addCase(actions.getComposedById.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.composedByIdList = action.payload.data;
            } else {
                state.composedByIdList = [];
            }
            state.isComposedDataLoaded = true;
        })
        builder.addCase(actions.getComposedById.rejected, (state) => {
            state.isComposedDataLoaded = false;
        });

        //get GetCountPeoples
        builder.addCase(actions.GetCountPeoples.pending, (state) => {
            state.isComposedDataLoaded = false;
        })
        builder.addCase(actions.GetCountPeoples.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.GetCountPeoplesList = action.payload.data;
            } else {
                state.GetCountPeoplesList = [];
            }
            state.isGetCountPeoplesLoaded = true;
        })
        builder.addCase(actions.GetCountPeoples.rejected, (state) => {
            state.isGetCountPeoplesLoaded = false;
        });

        //get SiteUsersByDepartmentIds log Data
        builder.addCase(actions.getSiteUsersByDepartmentIds.pending, (state) => {
            state.isSiteUsersListByDepartmentIdsLoaded = false;
        })
        builder.addCase(actions.getSiteUsersByDepartmentIds.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.siteUsersListByDepartmentIds = action.payload.data;
            } else {
                state.siteUsersListByDepartmentIds = [];
            }
            state.isSiteUsersListByDepartmentIdsLoaded = true;
        })
        builder.addCase(actions.getSiteUsersByDepartmentIds.rejected, (state) => {
            state.siteUsersListByDepartmentIds = false;
        });

        //getFailedReason
        builder.addCase(actions.getFailedReason.pending, (state) => {
            state.getFailedReasonLoaded = false;
        })
        builder.addCase(actions.getFailedReason.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.getFailedReason = action.payload.data;
            } else {
                state.getFailedReason = [];
            }
            state.getFailedReasonLoaded = true;
        })
        builder.addCase(actions.getFailedReason.rejected, (state) => {
            state.getFailedReasonLoaded = false;
        });
    }
});

export default composeSlice.reducer;