import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

const AdminMenuContext = createContext();

// Function component using the context provider
export default function AdminNavEditLink({ children }) {
    const [editLink, setEditLink] = useState('');

    return (
        <AdminMenuContext.Provider value={[editLink, setEditLink]}>
            {children}
        </AdminMenuContext.Provider>
    );
}
AdminNavEditLink.propTypes = {
    children: PropTypes.node.isRequired, // Ensure children is a valid React node and required
};
// Hook to access the context
export function useAdminMenuContext() {
    return useContext(AdminMenuContext);
}
