import React from 'react';
import FileItem from './fileItem';
import PropTypes from 'prop-types';

function FinancialItem({ companyData }) {
    return (
        <>
            <div className="admin-page-item">
                <h3>{companyData?.abbreviatedName}</h3>
                <div className="admin-tile-flex no-flex">
                    <label className="small-label">Scoreboard</label>
                    <FileItem type="Scoreboard"
                        file={(companyData?.financial?.status == 0 && companyData?.totalFinancialRecords >= 0 && companyData?.financial?.baseId != 0) ? companyData?.financial?.scoreBoardFile : undefined}
                        companyId={companyData?.id}
                        scoreBoardId={companyData?.financial?.scoreBoardId}
                        financialId={companyData?.financial?.id}
                        baseId={companyData?.financial?.baseId}
                        totalFinancialRecords={companyData?.totalFinancialRecords} />
                </div>
            </div>
        </>
    );
}

FinancialItem.defaultProps = {
    companyData: undefined
}

FinancialItem.propTypes = {
    companyData: PropTypes.object
};

export default FinancialItem;