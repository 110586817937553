import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';

import { InterestSkillModal } from './InterestSkillModal';
import { getInterestsList } from '../../../store/interest/actions';

export default function Interests({ handleInterest, userInterests }) {
  const dispatch = useDispatch();

  const [showTypeModal, setShowTypeModal] = useState(false);
  const { interestsList } = useSelector(state => state.interest);
  const [searchByName, setSearchByName] = useState(null);

  useEffect(() => {
    dispatch(getInterestsList());
  }, []);

  useEffect(() => {
    if (searchByName && searchByName.length >= 1) {
      dispatch(getInterestsList(searchByName));
    }
    else if (searchByName === '') {
      dispatch(getInterestsList());
    }
  }, [searchByName]);

    const handleAddInterest = (e) => {
        setTimeout(() => {
            handleInterest(e);
        }, 450);
    }

  return (
    <>
      <div className="admin-button-wrap">
        <button onClick={() => { setShowTypeModal(true) }} className="admin-button add">Add Interests</button>
      </div>
      {showTypeModal && (
        <InterestSkillModal
          modalTitle="Add/Edit Interests"
          show={showTypeModal}
          onClose={() => { setShowTypeModal(false); setSearchByName(''); }}
        >
          <div className="admin-modal-row full" style={{ display: 'unset' }}>

            <div className="admin-bottom-bar-left">
              <input
                className="admin-default-search quoteradius"
                type="text"
                placeholder="Search Interest"
                value={searchByName || ''}
                onChange={(e) => {
                  setSearchByName(e.target.value);
                }}
              />
            </div>

            {interestsList && interestsList.length > 0 &&
              interestsList.map(item => (
                !userInterests.some(interest => interest.interestId === item.id) &&
                <label className="admin-radio-label" key={item.id} htmlFor={item.id}>
                  <input
                    className="admin-radio"
                    type="checkbox"
                    id={item.id}
                    name={item.name}
                    value={item.id}
                    onChange={handleAddInterest}
                  />
                  <span>{item.name}</span>
                </label>

              ))}

          </div>
        </InterestSkillModal>)}
    </>
  );
}

Interests.propTypes = {
  handleInterest: PropTypes.func.isRequired,
  userInterests: PropTypes.any
};