import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import MediaImageModal from './MediaImageModal';
import PageLoadingSpinner from '../FullPageLoader';
//import { MediaItem } from './MediaItem';
import VideoProcessing from "../../assets/video-processing.jpg";
import { v4 as uuidv4 } from 'uuid';
import { processUrl } from '../../helpers/formatterHelper';

export default function MediaVideoModal({ showModal, onCloseModal, onSaveModal, editVideoObj, onMediaDelete }) {
    const [mediaData, setMediaData] = useState(undefined);
    const [showImageModal, setShowImageModal] = useState(false);
    const [ogImageSrc, setOgImageSrc] = useState(null);
    const [showSaving, setShowSaving] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState('');
    const [videoSource, setVideoSource] = useState(null);
    const [isLargeVideo, setIsLargeVideo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [processingMessage, setProcessingMessage] = useState('');
    const [selectVideoSourceError, setSelectVideoSourceError] = useState('');
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const fileInputRef = useRef(null);
    const modalRef = useRef(null);
    const handleDeleteMedia = () => {

        mediaData?.id && onMediaDelete(mediaData?.id);
        setMediaData(undefined);
    }

    useEffect(() => {
        if (editVideoObj) {
            setIsPlaying(false);
            let videoUrl = editVideoObj?.videoSrc;
            if (editVideoObj?.videoSrc) {
                const mediaData = processUrl(editVideoObj?.videoSrc);

                if (mediaData) {
                    if (editVideoObj?.videoType === 0) {
                        videoUrl = editVideoObj?.videoSrc;
                        //videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`;
                    } else if (mediaData?.type === "vimeo") {
                        if (editVideoObj?.selectedVideoSrc != 'videoLink') {
                            videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&loop=1`;
                        }
                        else {
                            videoUrl = editVideoObj?.videoSrc;
                        }
                    } else if (mediaData?.type === "htmlVideo") {
                        videoUrl = editVideoObj?.videoSrc;
                    }
                }
            }
            const updatedVideoObj = { ...editVideoObj, videoSrc: videoUrl };
            setMediaData(updatedVideoObj);
            setVideoSource(updatedVideoObj?.selectedVideoSrc || null);
        }
    }, [editVideoObj]);


    const fetchVideoThumbnailFromWeb = async (e) => {
        let videoUrl = e.target.value;
        let newVideo = await getVideoImageFromUrl(videoUrl);
        if (newVideo) {
            setMediaData({
                ...mediaData,
                videoId: newVideo.videoId,
                videoSrc: document.getElementById('video-url').value,
                videoThumbnailUrl: newVideo.videoThumbnail,
                videoType: newVideo.videoType,
                type: 'Video',
                imageFile: null,
                selectedVideoSrc: videoSource
            })
        }
    }
    const SelectedVideoSrc = async (e) => {
        let VideoSrc = e.target.value;
        if (VideoSrc != '') {
            setSelectVideoSourceError('');
        }
        setVideoSource(VideoSrc);
        setMediaData({
            ...mediaData,
            videoId: null,
            videoSrc: null,
            videoThumbnailUrl: null,
            videoType: null,
            selectedVideoSrc: VideoSrc
        });

    }
    const removeCustomThumbnail = async () => {
        setMediaData({
            ...mediaData,
            videoCustomThumbnailUrl: undefined,
            isCropped: false,
            photoId: undefined,
            photo: undefined,
            cropPhotoId: undefined,
            cropPhoto: undefined,
            cropValues: undefined
        });
    }

    const getVideoImageFromUrl = async (url) => {
        let videoObj = {
            videoId: "",
            videoThumbnail: "",
            videoType: null
        };
        if (url == null) return videoObj;
        if (url.indexOf('youtu') > -1) {
            if (url.indexOf('youtu.be') > -1) {
                if (url.match('.be/([^&#]*)') !== null)
                    videoObj.videoId = url.match('.be/([^&#]*)')[1];
            }
            else {
                if (url.match('[\\?&]v=([^&#]*)'))
                    videoObj.videoId = url.match('[\\?&]v=([^&#]*)')[1];
            }
            videoObj.videoThumbnail = "https://img.youtube.com/vi/" + videoObj.videoId + "/maxresdefault.jpg";
            videoObj.videoType = "YouTube";
            return videoObj;
        } else if (url.indexOf('vimeo') > -1) {
            videoObj.videoId = url.match('com/([0-9]*)')[1];
            videoObj.videoType = "Vimeo";
            const response = await fetch('https://vimeo.com/api/v2/video/' + videoObj.videoId + '.json');
            const data = await response.json();
            if (data[0].thumbnail_large) {
                videoObj.videoThumbnail = data[0].thumbnail_large;
            } else {
                videoObj.videoThumbnail = data[0].thumbnail_medium;
            }
            return videoObj;
        }
        else
            return videoObj;
    }

    const handleCloseModal = async () => {
        setMediaData(undefined);
        setIsPlaying(false);
        onCloseModal();
    }

    const handleSaveModal = async () => {
        setValidationErrorMsg("");
        if (videoSource === null || videoSource === 'Select Video Source') {
            setValidationError(true);
            setSelectVideoSourceError("Please select a video source.");
            return false;
        }

        if ((uploadProgress > 0 && uploadProgress < 100) || (processingMessage !== '' && uploadProgress >= 100)) {
            setValidationError(true);
            setValidationErrorMsg("Video is in process.");
            return false;
        }

        if (!mediaData?.videoSrc && videoSource === "videoLink") {
            setValidationError(true);
            setValidationErrorMsg("URL is required.");
            return false;
        }
        if (!mediaData?.videoSrc && videoSource === "uploadVideo") {
            setValidationError(true);
            setValidationErrorMsg("Video is required.");
            return false;
        }

        setValidationError(false);
        onSaveModal(mediaData);
        setMediaData(undefined);

    }

    const handleThumbnailImageChange = (e) => {
        setShowImageModal(true);
        setOgImageSrc(e.target.files[0]);
    };


    const handleChunkedUpload = (file) => {
        const chunkSize = 5 * 1024 * 1024; // 5 MB chunk size
        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let extension = file.name.split('.').pop(); // Get file extension
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.'); // Handle multiple periods
        const uuid = uuidv4(); // Generate UUIDv4
        const newFileName = `${fileNameWithoutExtension}_${uuid}.${extension}`;
        const uploadFileName = newFileName;
        let lastProgress = 0;
        const uploadNextChunk = async () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('videoChunk', chunk);
            formData.append('chunkNumber', currentChunk);
            formData.append('fileName', file.name);
            formData.append('uploadFileName', uploadFileName);
            try {
                await axios.post('/api/media/uploadChunk', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                   
                    onUploadProgress: (progressEvent) => {
                        // Calculate overall progress
                        const chunkProgress = (progressEvent.loaded / progressEvent.total) * (1 / totalChunks);
                        const overallProgress = Math.floor(((currentChunk + chunkProgress) / totalChunks) * 100);

                        // Only update state if the new progress is greater than the last progress
                        if (overallProgress > lastProgress) {
                            const updateProgress = (start, end) => {
                                if (start <= end) {
                                    setUploadProgress(start);
                                    requestAnimationFrame(() => updateProgress(start + 1, end));
                                }
                            };
                            updateProgress(lastProgress + 1, overallProgress);
                            lastProgress = overallProgress;
                        }
                    }
                });

                currentChunk++;

                if (currentChunk < totalChunks) {
                    uploadNextChunk();
                } else {

                    setUploadProgress(100);
                    finalizeUpload(file.name, totalChunks, uploadFileName);
                }
            } catch (error) {
                console.error('Error uploading chunk', error);
                // Handle error as needed
            }
        };

        uploadNextChunk();
    };

    // Function to finalize upload after all chunks are uploaded
    const finalizeUpload = async (fileName, totalChunks, uploadFileName) => {
        setProcessingMessage('Processing...');
        try {

            const response = await axios.post('/api/media/finalizeUpload', { fileName, totalChunks, uploadFileName });
            const result = response;

            if (result.data?.success) {
                setProcessingMessage('Video uploaded successfully');
                setIsPlaying(false);
                setTimeout(function () {
                    setMediaData(prevMediaData => ({
                        ...prevMediaData,
                        videoId: result?.data?.data?.videoId,
                        videoSrc: result?.data?.data?.videoSrc,
                        videoThumbnailUrl: result?.data?.data?.videoThumbnailUrl === '/images/video-processing.jpg' ? VideoProcessing : result?.data?.data?.videoThumbnailUrl,
                        videoType: result?.data?.data?.videoType,
                        vimeoVideoFileName: result?.data?.data?.vimeoVideoFileName,
                        type: 'Video',
                        imageFile: null,
                        selectedVideoSrc: videoSource
                    }));

                    setShowSaving(false);
                    setProcessingMessage('');
                    setUploadProgress(0);
                    setValidationErrorMsg('');
                }, 2000);
            }
            
            // Handle response from server, e.g., update UI
            console.log('Media added successfully:', result);

        } catch (error) {
            console.error('Error finalizing upload', error);
            // Handle error as needed
        }
    };


    const handleVideoUploadChange = (e) => {
        setIsLargeVideo(false);
        //to do
        const maxFileSize = 1 * 1024 * 1024 * 1024; // 1 GB
        //const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
        const selectedFile = e.target.files[0];

        //todo
        if (selectedFile != undefined && selectedFile != null) {
            if (selectedFile.size > maxFileSize) {
                setIsLargeVideo(true);
                return;
            }
            handleChunkedUpload(selectedFile);
        }
    };

    const uploadCustomThumbnail = async (mediaObj) => {
        setShowSaving(true);
        axios({
            url: '/api/media/add',
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: mediaObj
        })
            .then((result) => {

                setMediaData({
                    ...mediaData,
                    videoCustomThumbnailUrl: result?.data?.previewsrc,
                    isCropped: result?.data?.data?.isCropped,
                    photoId: result?.data?.data?.photoId,
                    photo: result?.data?.data?.photo,
                    cropPhotoId: result?.data?.data?.cropPhotoId,
                    cropPhoto: result?.data?.data?.cropPhoto,
                    cropValues: result?.data?.data?.cropValues
                });

                setOgImageSrc(null);
                setShowImageModal(false);
                setShowSaving(false);
            })
            .catch((error) => {
                setShowSaving(false);
                alert(error);
            });
    }




    const handleOverlayClick = (e) => {
        if (modalRef.current.contains(e.target)) {
            return;
        }
        handleCloseModal();
    };


    

    const handleMediaPlay = () => {       
        setIsPlaying(!isPlaying);
    };

    if (showModal) {
        return (
            <>
                <div data-section="CropImageOverlayPreview" className="admin-modal-overlay installed-add-modal" onClick={handleOverlayClick}>
                    <div className="admin-modal" ref={modalRef}>
                        <button className="admin-modal-close" onClick={handleCloseModal}>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <p className="admin-modal-heading">Add Video</p>
                        <div className="admin-modal-row full">
                            <div className="admin-modal-cell full">
                                <div className="financial-skill-tile">
                                    <select className="admin-default-select" onChange={SelectedVideoSrc} value={videoSource ? videoSource : null}>
                                        <option value={null}>Select Video Source</option>
                                        <option value="uploadVideo">Upload Video</option>
                                        <option value="videoLink">Enter Link</option>
                                    </select>
                                    {selectVideoSourceError != '' && < p className="error-messagess">{selectVideoSourceError}</p>}
                                </div>


                                {(mediaData?.videoSrc) ? (
                                    <>
                                        <div className="financial-skill-tile">
                                            <label className="admin-default-label" htmlFor="video-url">
                                                {videoSource === 'videoLink' ? 'URL Link' : 'Video'}
                                            </label>
                                            {videoSource === 'videoLink' && (
                                                <>
                                                    <span>{mediaData?.videoSrc.replace('https://', '')}</span>
                                                    <button className="media-thumbnail-action" onClick={handleDeleteMedia}>
                                                        <span className="material-symbols-rounded">delete</span>
                                                    </button>
                                                </>
                                                
                                            )}
                                     

                                            {videoSource === 'uploadVideo' && (
                                                <div className="video-priew-section">
                                                    <div className="video-priew">
                                                        {isPlaying ? <>
                                                            <iframe
                                                                ref={videoRef}
                                                                src={mediaData.videoSrc}
                                                                title=""
                                                                frameBorder="0"
                                                                allow="autoplay;fullscreen"
                                                            >
                                                            </iframe>  
                                                            <button className="media-thumbnail-action" onClick={handleDeleteMedia}>
                                                                <span className="material-symbols-rounded">close</span>
                                                            </button>                                                            
                                                            </>
                                                            : <> 
                                                         <img
                                                                src={mediaData?.videoCustomThumbnailUrl ? mediaData?.videoCustomThumbnailUrl : mediaData?.videoThumbnailUrl}
                                                                alt="Video Thumbnail"
                                                            />

                                                          
                                                            <button className="media-thumbnail-action" onClick={handleDeleteMedia}>
                                                            <span className="material-symbols-rounded">close</span>
                                                        </button>
                                                                {!isPlaying && mediaData?.videoSrc.includes('vimeo.com') ?
                                                                    <button className="media-thumbnail-action play-icon" onClick={handleMediaPlay} >
                                                                <span className="material-symbols-rounded">
                                                                    {isPlaying ? 'pause' : 'play_arrow'}
                                                                </span>
                                                                        </button>
                                                                        :
                                                                        <button className="media-thumbnail-action play-icon" >
                                                                            <span className="material-symbols-rounded">
                                                                                {isPlaying ? 'pause' : 'play_arrow'}
                                                                            </span>
                                                                        </button>

                                                        }
                                                        </>

                                                        }
                                                        
                                                    </div>
                                                </div>                                                
                                            )}                                  

                                        </div>

                                        <div className="admin-modal-cell full video-thumbnail-cell">
                                            <label className="admin-default-label" htmlFor="video-url">Thumbnail</label>
                                            <div className="videoThumbWrap video-thumbnail">
                                                <img
                                                    className="videoThumbPreview video-thumbnail-image"
                                                    src={mediaData?.videoCustomThumbnailUrl ? mediaData?.videoCustomThumbnailUrl : mediaData?.videoThumbnailUrl}
                                                    alt="Thumbnail"
                                                />
                                                <div className={`media-thumbnail-action-wrap ${validationError ? ' input-validation-error' : ''}`}>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleThumbnailImageChange}
                                                        style={{ display: 'none' }}
                                                        ref={fileInputRef}
                                                    />
                                                    <button
                                                        className="media-thumbnail-action red margin-bt-edit-image"
                                                        onClick={() => fileInputRef.current.click()}
                                                    >
                                                        <span className="material-symbols-rounded">edit</span>
                                                    </button>
                                                    {mediaData?.videoCustomThumbnailUrl && (
                                                        <button className="media-thumbnail-action red" onClick={removeCustomThumbnail}>
                                                            <span className="material-symbols-rounded">delete</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <MediaImageModal
                                            showModal={showImageModal}
                                            forceCrop={false}
                                            uploadImageObj={ogImageSrc}
                                            editImageObj={null}
                                            onCloseModal={() => setShowImageModal(false)}
                                            onSaveModal={uploadCustomThumbnail}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {videoSource == 'videoLink' && (
                                            <div className="financial-skill-tile">
                                                <label className="admin-default-label" htmlFor="video-url">Enter URL*</label>
                                                <input
                                                    id="video-url"
                                                    data-imvideosection="videoUrl"
                                                    className={`admin-default-input-text ${validationError ? ' input-validation-error' : ''}`}
                                                    type="text"
                                                    onChange={fetchVideoThumbnailFromWeb}
                                                    defaultValue={mediaData?.videoSrc}
                                                />
                                            </div>
                                        )}
                                        {videoSource == 'uploadVideo' && (
                                            <div className="financial-skill-tile">
                                                    {uploadProgress === 0 && processingMessage == '' && (
                                                        <button className="admin-modal-button">
                                                            Upload Video
                                                            <input
                                                                type="file"
                                                                accept="video/*"
                                                                onChange={handleVideoUploadChange}
                                                            />
                                                        </button>
                                                    )}                                                
                                                    <div>
                                                        <div>
                                                            {uploadProgress > 0 && uploadProgress < 100 && (
                                                                <span>Uploading file: {Math.round(uploadProgress)}%</span>
                                                            )}
                                                            {processingMessage && uploadProgress >= 100 && (
                                                                <span>{processingMessage}</span>
                                                            )}
                                                        </div>
                                                    </div>



                                                {isLargeVideo && < p className="error-messagess"> File size exceeds the 1GB limit.</p>}                                        </div>
                                        )}
                                    </>
                                )}


                                <div className="upload-video-loader">
                                    <PageLoadingSpinner show={showSaving} />
                                </div>

                            </div>
                        </div>
                        <div className="admin-modal-button-wrap">
                            <p className="error-messagess">
                                {validationErrorMsg || ''}
                            </p>
                            <button className="admin-modal-button"
                                data-action="closeOverlay"
                                onClick={handleCloseModal}
                            >Cancel</button>
                            <button className="admin-modal-button power"
                                onClick={handleSaveModal}
                            >Save Changes</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else return (<></>);
}

MediaVideoModal.propTypes = {
    requireAltText: PropTypes.bool,
    mediaObj: PropTypes.object,
    showModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onSaveModal: PropTypes.func,
    editVideoObj: PropTypes.object,
    isVideoThumbnail: PropTypes.bool,
    onMediaDelete: PropTypes.func,

}

MediaVideoModal.defaultProps = {
    requireAltText: false,
    editVideoObj: null
}