import { createAsyncThunk } from '@reduxjs/toolkit';
import * as adminHrBenefitsApi from '../../api/hrbenefits';

export const getJobPostings = createAsyncThunk('getJobPostings', async (data) => {
    const res = await adminHrBenefitsApi.getJobPostings(data);
    return res.data;
});

export const getjobpostingbyid = createAsyncThunk('getjobpostingbyid', async (data) => {
    const res = await adminHrBenefitsApi.getjobpostingbyid({ id: data });
    return res.data;
});


export const addEditJobPosting = createAsyncThunk('addEditJobPosting', async (data) => {
    const res = await adminHrBenefitsApi.addEditJobPostings({ data: data });
    return res.data;
});

export const deleteJobPosting = createAsyncThunk('deleteJobPosting', async (data) => {
    const res = await adminHrBenefitsApi.deleteJobPosting({ id: data });
    return res.data;
});


export const updateJobPostingOrder = createAsyncThunk('updateJobPostingOrder', async (ids) => {
    const res = await adminHrBenefitsApi.updateJobPostingOrder({
        options: {
            params: { Ids: ids },
        },
    });
    return res.data;
});


export const getBenefits = createAsyncThunk('getBenefits', async (data) => {
    const res = await adminHrBenefitsApi.getAllBenefits(data);
    return res.data;
});

export const getbenefitbyid = createAsyncThunk('getbenefitbyid', async (data) => {
    const res = await adminHrBenefitsApi.getbenefitbyid({ id: data });
    return res.data;
});


export const updateBenefitsOrder = createAsyncThunk('updateBenefitsOrder', async (ids) => {
    const res = await adminHrBenefitsApi.updateBenefitsOrder({
        options: {
            params: { Ids: ids },
        },
    });
    return res.data;
});

export const addEditBenefit = createAsyncThunk('addEditBenefit', async (data) => {
    const res = await adminHrBenefitsApi.addEditBenefit({ data: data });
    return res.data;
});

export const deleteBenefit = createAsyncThunk('deleteBenefit', async (data) => {
    const res = await adminHrBenefitsApi.deleteBenefit({ id: data });
    return res.data;
});


export const getJobPostingsByFilter = createAsyncThunk('getJobPostingsByFilter', async (data) => {
    const res = await adminHrBenefitsApi.getJobPostingsByFilter({ data: data });
    return res.data;
});

export const getBenefitsByFilter = createAsyncThunk('getBenefitsByFilter', async (data) => {
    const res = await adminHrBenefitsApi.getBenefitsByFilter({ data: data });
    return res.data;
});


export const getDocumentList = createAsyncThunk('getDocumentList', async (data) => {
    const res = await adminHrBenefitsApi.getDocumentList({ data: data });
    return res.data;
});