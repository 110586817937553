import React, { useState, useEffect } from 'react';
import { ShopMonitorTabs } from '../../components/ShopMonitorTabs/ShopMonitorTab.js';
import { useLocation } from 'react-router-dom';
import ShopMonitors from './events';
import ShopAnniversary from './anniversaries';
import ShopFinancialScoreboard from './financial-scoreboard';
import ShopInstalledProjects from './installed-projects';
import ShopMonitorsToday from './today';
import ShopBonusLevels from './bonus-levels';
import ShopBonusLevelsYTD from './bonus-level-ytd';
//import PortalAlert from '../../components/portalAlert/portalAlert';
import { renewToken } from '../../store/auth/actions';
import { useDispatch } from 'react-redux';
export default function ShopMonitorsIndex() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const [extraClass, setExtraClass] = useState('');
    const [videoTime, setVideoTime] = useState(10000);
    const [section, setSection] = useState('');

    const handleVideoTiming = (time) => {
        if (time) {
            setVideoTime(time)
        }
    }

    useEffect(() => {
        const handleShopScreen = async () => {
        await dispatch(renewToken()).unwrap();
        };
        handleShopScreen();
    }, []);

    useEffect(() => {
        switch (true) {
            case pathname.includes('/shopmonitors/events'):
                setExtraClass('events-wrap');
                setSection('events');
                break;
            case pathname.includes('/shopmonitors/anniversaries'):
                setExtraClass('anniversary-wrap');
                setSection('anniversaries');
                break;
            case pathname.includes('/shopmonitors/financial-scoreboard'):
                setExtraClass('financial-scoreboard-wrap financial-scoreboard-expanded');
                setSection('financial-scoreboard');
                break;
            case pathname.includes('/shopmonitors/installed-projects'):
                setExtraClass('today-wrap installed-projects-wrap');
                setSection('installed-projects');
                break;
            case pathname.includes('/shopmonitors/today'):
                setExtraClass('today-wrap');
                setSection('today');
                break;
            case pathname.includes('/shopmonitors/bonuslevels'):
                setExtraClass('shop-bonus-levels bonus-levels-wrap');
                setSection('bonuslevels');
                break;
            case pathname.includes('/shopmonitors/bonus-ytd'):
                setExtraClass('shop-bonus-levels bonus-levels-wrap');
                setSection('bonuslevelsytd');
                break;
            default:
                break;
        }
    }, [pathname]);

    return (
        <>
            <div className={`directory-wrap monitors-wrap monitors-img-wrap ${extraClass}`}>
                <div className="monitors-center">
                    {/*<div className="inner-pages-alert todays-alert">*/}
                    {/*    <PortalAlert />*/}
                    {/*</div>*/}
                    {(section === "events") &&
                        <ShopMonitors key={pathname} handleloadinTiming={handleVideoTiming} />
                    }
                    {(section === "anniversaries") &&
                        <ShopAnniversary key={pathname} handleloadinTiming={handleVideoTiming} />
                    }
                    {(section === "financial-scoreboard") &&
                        <ShopFinancialScoreboard key={pathname} handleloadinTiming={handleVideoTiming} />
                    }
                    {(section === "installed-projects") &&
                        <ShopInstalledProjects key={pathname} handleVideoTiming={handleVideoTiming} />
                    }
                    {(section === "today") &&
                        <ShopMonitorsToday key={pathname} handleloadinTiming={handleVideoTiming} />
                    }
                    {(section === "bonuslevels") &&
                        <ShopBonusLevels key={pathname} handleloadinTiming={handleVideoTiming} />
                    }
                    {(section === "bonuslevelsytd") &&
                        <ShopBonusLevelsYTD key={pathname} handleloadinTiming={handleVideoTiming} />
                    }
                    <ShopMonitorTabs timing={videoTime} />
                </div>
            </div>
        </>
    );
}
