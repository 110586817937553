import React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

// Item Component
const Item = ({ item, sourceCompanyId, sourceDepartmentId, onEdit, onDelete }) => {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'ITEM',
        item: {
            ...item,
            sourceCompanyId,  // Ensure these properties are included
            sourceDepartmentId 
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
 
    return (
        <div className="admin-drag-option-wrap" ref={drag} style={{ display: 'block', cursor: 'move' }} data-isDragging={isDragging}>
            <span className="admin-drag-box-name">{item.name}</span>
            <div className="admin-drag-action-btn">
                <button
                    className="admin-drag-option edit"
                    onClick={() => onEdit(item)}
                >
                    <span className="material-symbols-rounded">edit</span>
                </button>
                <button
                    className="admin-drag-option delete"
                    onClick={() => onDelete(item.id, item.name)}
                >
                    <span className="material-symbols-rounded">delete</span>
                </button>
            </div>
   
</div>

        
    );
};

Item.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
        
    }).isRequired,
    sourceCompanyId: PropTypes.string.isRequired,  // Add validation for companyId
    sourceDepartmentId: PropTypes.string.isRequired,  // Add validation for departmentId
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

// Department Component
const Department = ({ department, companyId, moveItem, onEdit, onDelete }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'ITEM',
        drop: (draggedItem) => {
            moveItem(draggedItem, department.id, companyId);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));
    return (

        <div className="position-skill-list-outer">
            <p className="position-department-name">{department.name}</p>
        <div className={`admin-drag-box position-skill-drag-box`} style={{
                display: 'block',
                minHeight: '2.5em',
                height: 'auto',
                cursor: 'auto',
                opacity: isOver ? '0.5' : '',
                border: isOver ? 'solid 1px #333' : 'none'

            }}
                key={department.id} ref={drop}>               
                {department.skills.map((item) => (
                <>
                   <Item   
                    key={`${department.id}-${item.id}`}  // Make the key unique
                    item={item}
                    sourceCompanyId={companyId}  // Pass companyId to Item
                    sourceDepartmentId={department.id}  // Pass departmentId to Item
                    onEdit={onEdit}
                    onDelete={onDelete}
                    />
                    </>
            ))}
            
        </div>
        </div>

        
    );
};

Department.propTypes = {
    department: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        skills: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    companyId: PropTypes.string.isRequired,
    moveItem: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

// Company Component
const Company = ({ company, moveItem, onEdit, onDelete }) => {  
    return (
        <div className="company-heading-outer-wrap">
            <p className="position-company-heading">{company?.abbreviatedName}</p>
            {company.departments.map((department) => (
                <Department
                    key={department.id}
                    department={department}
                    companyId={company.id}
                    moveItem={moveItem}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            ))}
        </div>
    );
};

Company.propTypes = {
    company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        abbreviatedName: PropTypes.string.isRequired,
        departments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        name: PropTypes.string.isRequired,
                    })
                ).isRequired,
            })
        ).isRequired,
    }).isRequired,
    moveItem: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export { Item, Department, Company };
