import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './detail.css';
import './financial-scoreboard.css';
import Sidebar from '../../components/SideBar/Sidebar.js';
import { GetAllFinancialReport } from '../../api/finanicalapi';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import { startOfMonth, getWeek, startOfWeek, isSameMonth, endOfMonth, eachDayOfInterval, getDay, addDays, isBefore } from 'date-fns';
import { getCompanies } from '../../store/employee/actions';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { formatUSD } from "../../helpers/formatterHelper";
import PropTypes from 'prop-types';
import { shopMonitorSliderTime } from '../../helpers/enums';

export default function ShopFinancialScoreboard({ handleloadinTiming }) {
    const dispatch = useDispatch();
    const [finanicals, setFinanicals] = useState([]);
    const [filter] = useState({ companyId: 0, timezone: GetTimeZone() });
    const [sharpCompanyId, setSharpCompanyId] = useState(0);
    const companiesList = useSelector(state => state.employee.companiesList);
    const [filterdCompany, setFilterdCompany] = useState({});
    const isLoading = useSelector((state) => state.loading);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        document.title = 'Financial Scoreboard | CWP Team Connect';
        dispatch(showLoading());
        handleloadinTiming(shopMonitorSliderTime.LeadingTime);
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        let sharpCompanyId = localStorage.getItem('monitor_mode_company');
        if (sharpCompanyId !== undefined && sharpCompanyId !== null && sharpCompanyId != '0' && sharpCompanyId != 0) {
            setSharpCompanyId(parseInt(sharpCompanyId));
        }
        else {
            let defaultCompany = companiesList && companiesList.length > 0 ? companiesList[0].id : 0;
            localStorage.setItem('monitor_mode_company', defaultCompany);
            setSharpCompanyId(parseInt(defaultCompany))
        }
    }, []);

    useEffect(() => {
        if (sharpCompanyId !== 0) { // Add a condition to prevent the effect from running when sharpCompanyId is 0
            const filterComp = companiesList.filter(x => x.id === sharpCompanyId);
            if (filterComp.length > 0) { // Check if filtered company exists
                setFilterdCompany(filterComp[0]); // Update filtered company with the first matching item
            }
        }
    }, [sharpCompanyId, companiesList]);

    useEffect(() => {
        if (sharpCompanyId && sharpCompanyId !== undefined && sharpCompanyId !== null) {
            filter.companyId = sharpCompanyId;

            GetAllFinancialReport({ options: { data: filter } }).then((response) => {
                if (response.data.success) {
                    setFinanicals(response.data.data);
                    //setLoadingData(false);
                }
                dispatch(hideLoading());
                handleloadinTiming(shopMonitorSliderTime.default);
                setIsDataLoaded(true);
            });
        }
    }, [sharpCompanyId]);

    useEffect(() => {
        if (isLoading && isDataLoaded) {
            dispatch(hideLoading());
            handleloadinTiming(shopMonitorSliderTime.default);
        }
    }, [isLoading, isDataLoaded]);

    const getCurrentWeek = () => {
        const date = new Date();

        const startDateOfMonth = startOfMonth(date);
        const startWeekNumber = getWeek(startDateOfMonth, { weekStartsOn: 1 });
        const startDateOfWeek = startOfWeek(date, { weekStartsOn: 1 });
        const isInCurrentMonth = isSameMonth(startDateOfWeek, startDateOfMonth);

        // Calculate the week number of the current date
        let currentWeekNumber;
        if (isInCurrentMonth) {
            currentWeekNumber = getWeek(date, { weekStartsOn: 1 }) - startWeekNumber + 1;
        } else {
            currentWeekNumber = 5; // Assuming maximum of 5 weeks in a month
        }

        return currentWeekNumber;
    }

    const countMondaysInMonth = () => {
        const date = new Date();
        const startOfMonthDate = startOfMonth(date);
        const endOfMonthDate = endOfMonth(date);

        // Get all days between start and end of the month
        const daysOfMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });

        // Count Mondays
        const mondayCount = daysOfMonth.reduce((count, day) => {
            if (getDay(day) === 1) { // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
                return count + 1;
            }
            return count;
        }, 0);

        return mondayCount;
    };

    const getCurrentMonthWeekNumber = () => {
        const date = new Date();
        const startOfMonthDate = startOfMonth(date);

        let currentWeekNumber = 0;
        let currentDate = startOfMonthDate;

        // Iterate through each day starting from the start of the month
        while (isSameMonth(currentDate, date) && isBefore(currentDate, date) || currentDate.getDate() === date.getDate()) {
            if (getDay(currentDate) === 1) { // Check if it's Monday
                currentWeekNumber++;
            }
            // Move to the next day
            currentDate = addDays(currentDate, 1);
        }

        return currentWeekNumber;
    };


    const week = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].week : 0;
    const weekMonth = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].weekMonth : null;
    const weekYear = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].weekYear : 0;
    const budgetMonthName = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].budgetMonthName : null;
    const budgetYear = finanicals && finanicals.length > 0 && finanicals[0].subTypes ? finanicals[0].subTypes[0].budgetYear : 0;

    return (
        /* This class will add expanded view for the green bar: "financial-scoreboard-expanded"*/
        <div className="monitors-inner">
            <div className="monitors-item collapsible-financial-table financial-scoreboard-expanded">
                <div className="financial-scoreboard-table-wrap financial-scoreboard-expanded-table">
                    <div className="finacial-scoreboard-table-company">
                        <p className="monitors-name expand-monitor-name">{filterdCompany?.abbreviatedName}</p>
                    </div>
                    <table className="financial-scoreboard-table">
                        <thead>
                            <tr>
                                <th colSpan="2" className="fixed-column">
                                    <div className="monitor-header-wrap">
                                        <p className="monitors-header-title">Financial Scoreboard</p>
                                        <span className="monitors-header-icon"><svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.75 20V18.75H23.25V20H0.75ZM2 16.5385V10H4.5V16.5385H2ZM7.81731 16.5385V3.75H10.3173V16.5385H7.81731ZM13.6587 16.5385V7.5H16.1587V16.5385H13.6587ZM19.5 16.5385V0H22V16.5385H19.5Z" fill="#00FF38" />
                                        </svg></span>
                                    </div>
                                </th>
                                {/*<th>&nbsp;</th>*/}
                                <th className="font-700 budget-grad-first-child ">{finanicals.length > 0 ? finanicals[0].monthBudgetName : new Date().toLocaleString('default', { month: 'long' })} Budget</th>
                                <th className="font-700 grad-first-child">
                                    <div className="monitors-name-wrap nonexpanded-monitor-name-wrap">
                                        <p className="monitors-name nonexpanded-monitor-name">{filterdCompany?.name}</p>
                                        <p>Week 1</p>
                                    </div>
                                </th>
                                <th className="expanded-col font-700" hidden>
                                    <div>
                                        <p>Week 2</p>
                                    </div>
                                </th>
                                <th className="expanded-col font-700" hidden>
                                    <div className="monitors-name-wrap">                            
                                        <p>Week 3</p>
                                    </div>
                                </th>
                                <th  className={`expanded-col font-700 ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'}`} hidden>
                                    <div>
                                        <p>Week 4</p>
                                    </div>
                                </th>
                                {countMondaysInMonth() >= getCurrentWeek() ?
                                    <th className="expanded-col font-700 grad-last-child" hidden>
                                        <div>
                                            <p>Week 5</p>
                                        </div>
                                    </th> : ''
                                }

                                <th className="font-700">{(budgetMonthName != undefined && budgetMonthName != null) ? `${budgetMonthName} ${new Date().getFullYear() !== parseInt(budgetYear) ? budgetYear : ""}` : ""} Consolidated Budget</th>
                                <th className="font-700">
                                    <div className="monitors-text-header-wrap">
                                        <p className="monitors-text-header">Consolidated</p>
                                        <p> {week === 0 ? "Weekly Consolidated" : `${weekMonth} ${new Date().getFullYear() !== parseInt(weekYear) ? weekYear : ""} Week ${week} Consolidated`}</p>
                                    </div>
                                </th>
                                <th className="font-700 td-last-row-child">Variance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {finanicals.map((financialItem, index) => (
                                <React.Fragment key={index}>
                                    <tr className="first-row">
                                        <td className="fixed-column sales-cell font-700 " rowSpan={financialItem.subTypes.filter(subType => !subType.subType.toLowerCase().includes('operating income')).length + 1}>
                                            <span className="fixed-column-rotate-text">{financialItem.type}</span>
                                        </td>
                                    </tr>

                                    {financialItem.subTypes
                                        .filter(subType => !subType.subType.toLowerCase().includes('operating income'))
                                        .map((subType, subIndex) => (
                                            <tr key={`${index}-${subType.subType}-${subIndex}`} className={subType.subType === 'Total Sales' ? "" : subType.subType.includes('Total') || subType.subType.includes('Gross') ? "selected-row" : subType.subType.includes('Operating Income') ? "green-selected-row" : ""}>

                                                <td className="text-align-left font-700 border-top ">{subType.subType}</td>

                                                {((subType.subType !== 'Total Sales' && subType.subType.includes('Total')) || subType.subType.includes('Gross') || subType.subType.includes('Operating Income')) ? (
                                                    <>
                                                        <td className="font-400 budget-dark-gray-grad">
                                                            {subType.subType === 'Operating Income %' ? subType.totalBudgetAmount + "%" : subType.totalBudgetAmount < 0 ? "-" + formatUSD(Math.abs(subType.totalBudgetAmount)) : formatUSD(subType.totalBudgetAmount)}
                                                        </td>
                                                        <td className={`font-${getCurrentMonthWeekNumber() == 1 ? 700 : 400} ${getCurrentMonthWeekNumber() === 1 && (getCurrentWeek() >= 1 && subType.week1 === 0) ? 'statusPending' : ''} dark-gray-grad`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week1 + "%" : subType.week1 < 0 ? "-" + formatUSD(Math.abs(subType.week1)) : getCurrentWeek() >= 1 && subType.week1 == 0 ? "Pending" : formatUSD(subType.week1)}
                                                        </td>
                                                        <td className={`fontexpanded-col font-${getCurrentMonthWeekNumber() == 2 ? 700 : 400} ${getCurrentMonthWeekNumber() === 2 && (getCurrentWeek() >= 2 && subType.week2 === 0) ? 'statusPending' : ''} dark-gray-grad hidden`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week2 + "%" : subType.week2 < 0 ? "-" + formatUSD(Math.abs(subType.week2)) : getCurrentWeek() >= 2 && subType.week2 == 0 ? "Pending" : formatUSD(subType.week2)}
                                                        </td>
                                                        <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 3 ? 700 : 400} ${getCurrentMonthWeekNumber() === 3 && (getCurrentWeek() >= 3 && subType.week3 === 0) ? 'statusPending' : ''} dark-gray-grad table-big-size-font hidden`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week3 + "%" : subType.week3 < 0 ? "-" + formatUSD(Math.abs(subType.week3)) : getCurrentWeek() >= 3 && subType.week3 == 0 ? "Pending" : formatUSD(subType.week3)}
                                                        </td>
                                                        <td className={`expanded-col ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'} font-${getCurrentMonthWeekNumber() == 4 ? 700 : 400} ${getCurrentMonthWeekNumber() === 4 && (getCurrentWeek() >= 4 && subType.week4 === 0) ? 'statusPending' : ''} dark-gray-grad hidden`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week4 + "%" : subType.week4 < 0 ? "-" + formatUSD(Math.abs(subType.week4)) : getCurrentWeek() >= 4 && subType.week4 == 0 ? "Pending" : formatUSD(subType.week4)}
                                                        </td>
                                                        {countMondaysInMonth() >= getCurrentWeek() ?
                                                            <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 5 ? 700 : 400} ${getCurrentMonthWeekNumber() === 5 && (getCurrentWeek() >= 5 && subType.week5 === 0) ? 'statusPending' : ''} dark-gray-grad grad-last-child hidden`}>
                                                                {subType.subType === 'Operating Income %' ? subType.week5 + "%" : subType.week5 < 0 ? "-" + formatUSD(Math.abs(subType.week5)) : getCurrentWeek() >= 5 && subType.week5 == 0 ? "Pending" : formatUSD(subType.week5)}
                                                            </td> : ''
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="font-400 budget-dark-grad">
                                                            {subType.subType === 'Operating Income %' ? subType.totalBudgetAmount + "%" : subType.totalBudgetAmount < 0 ? "-" + formatUSD(Math.abs(subType.totalBudgetAmount)) : formatUSD(subType.totalBudgetAmount)}
                                                        </td>
                                                            <td className={`font-${getCurrentMonthWeekNumber() == 1 ? 700 : 400} ${getCurrentMonthWeekNumber() === 1 && (getCurrentWeek() >= 1 && subType.week1 === 0) ? 'statusPending' : ''} dark-grad`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week1 + "%" : subType.week1 < 0 ? "-" + formatUSD(Math.abs(subType.week1)) : getCurrentWeek() >= 1 && subType.week1 == 0 ? "Pending" : formatUSD(subType.week1)}
                                                        </td>
                                                            <td className={`fontexpanded-col font-${getCurrentMonthWeekNumber() == 2 ? 700 : 400} ${getCurrentMonthWeekNumber() === 2 && (getCurrentWeek() >= 2 && subType.week2 === 0) ? 'statusPending' : ''} dark-grad hidden`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week2 + "%" : subType.week2 < 0 ? "-" + formatUSD(Math.abs(subType.week2)) : getCurrentWeek() >= 2 && subType.week2 == 0 ? "Pending" : formatUSD(subType.week2)}
                                                        </td>
                                                            <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 3 ? 700 : 400} ${getCurrentMonthWeekNumber() === 3 && (getCurrentWeek() >= 3 && subType.week3 === 0) ? 'statusPending' : ''} dark-grad hidden`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week3 + "%" : subType.week3 < 0 ? "-" + formatUSD(Math.abs(subType.week3)) : getCurrentWeek() >= 3 && subType.week3 == 0 ? "Pending" : formatUSD(subType.week3)}
                                                        </td>
                                                            <td className={`expanded-col ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'} font-${getCurrentMonthWeekNumber() == 4 ? 700 : 400} ${getCurrentMonthWeekNumber() === 4 && (getCurrentWeek() >= 4 && subType.week4 === 0) ? 'statusPending' : ''} dark-grad hidden`}>
                                                            {subType.subType === 'Operating Income %' ? subType.week4 + "%" : subType.week4 < 0 ? "-" + formatUSD(Math.abs(subType.week4)) : getCurrentWeek() >= 4 && subType.week4 == 0 ? "Pending" : formatUSD(subType.week4)}
                                                        </td>
                                                        {countMondaysInMonth() >= getCurrentWeek() ?
                                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 5 ? 700 : 400} ${getCurrentMonthWeekNumber() === 5 && (getCurrentWeek() >= 5 && subType.week5 === 0) ? 'statusPending' : ''} dark-grad grad-last-child hidden`}>
                                                                {subType.subType === 'Operating Income %' ? subType.week5 + "%" : subType.week5 < 0 ? "-" + formatUSD(Math.abs(subType.week5)) : getCurrentWeek() >= 5 && subType.week5 == 0 ? "Pending" : formatUSD(subType.week5)}
                                                            </td> : ''
                                                        }

                                                    </>)}
                                                <td className="font-700">
                                                    {subType.subType === 'Operating Income %' ? subType.consolidated + "%" : subType.consolidated < 0 ? "-" + formatUSD(Math.abs(subType.consolidated)) : formatUSD(subType.consolidated)}
                                                </td>
                                                <td className="font-400">
                                                    {subType.subType === 'Operating Income %' ? subType.weeklyConsolidated + "%" : subType.weeklyConsolidated < 0 ? "-" + formatUSD(Math.abs(subType.weeklyConsolidated)) : formatUSD(subType.weeklyConsolidated)}
                                                </td>
                                                <td className="font-400 td-last-row-child">
                                                    {subType.subType === 'Operating Income %' ? subType.variance + "%" : subType.variance < 0 ? "-" + formatUSD(Math.abs(subType.variance)) : formatUSD(subType.variance)}
                                                </td>
                                            </tr>
                                        ))}

                                </React.Fragment>
                            ))
                            }

                            {finanicals.map((financialItem, index) => (
                                <React.Fragment key={index}>
                                    {financialItem.subTypes
                                        .filter(subType => subType.subType.toLowerCase().includes('operating income'))
                                        .map((subType, subIndex) => (
                                            <tr key={`${index}-${subType.subType}-${subIndex}`} className={subType.subType.toLowerCase().includes('operating income') ? "green-selected-row first-green-selected" : "green-selected-row"}>
                                                {subIndex === 0 && (
                                                    <td className="fixed-column sales-cell blank-rowspan" rowSpan="2"></td>
                                                )}

                                                <td className="text-align-left font-700 fixed-second-column">{subType.subType}</td>
                                                <td className="font-400 budget-dark-green-grad">
                                                    {subType.subType === 'Operating Income %' ? subType.totalBudgetAmount + "%" : subType.totalBudgetAmount < 0 ? "-" + formatUSD(Math.abs(subType.totalBudgetAmount)) : formatUSD(subType.totalBudgetAmount)}
                                                </td>
                                                <td className={`font-${getCurrentMonthWeekNumber() == 1 ? 700 : 400} ${getCurrentMonthWeekNumber() === 1 && (getCurrentWeek() >= 1 && subType.week1 === 0) ? 'statusPending' : ''} dark-green-grad`}> {subType.subType === 'Operating Income %' ? getCurrentWeek() >= 1 && subType.week1 == 0 ? "Pending" : subType.week1 + "%" : subType.week1 < 0 ? "-" + formatUSD(Math.abs(subType.week1)) : getCurrentWeek() >= 1 && subType.week1 == 0 ? "Pending" : formatUSD(subType.week1)}</td>
                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 2 ? 700 : 400} ${getCurrentMonthWeekNumber() === 2 && (getCurrentWeek() >= 2 && subType.week2 === 0) ? 'statusPending' : ''} dark-green-grad hidden`}> {subType.subType === 'Operating Income %' ? getCurrentWeek() >= 2 && subType.week2 == 0 ? "Pending" : subType.week2 + "%" : subType.week2 < 0 ? "-" + formatUSD(Math.abs(subType.week2)) : getCurrentWeek() >= 2 && subType.week2 == 0 ? "Pending" : formatUSD(subType.week2)}</td>
                                                <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 3 ? 700 : 400} ${getCurrentMonthWeekNumber() === 3 && (getCurrentWeek() >= 3 && subType.week3 === 0) ? 'statusPending' : ''} dark-green-grad hidden `}> {subType.subType === 'Operating Income %' ? getCurrentWeek() >= 3 && subType.week3 == 0 ? "Pending" : subType.week3 + "%" : subType.week3 < 0 ? "-" + formatUSD(Math.abs(subType.week3)) : getCurrentWeek() >= 3 && subType.week3 == 0 ? "Pending" : formatUSD(subType.week3)}</td>
                                                <td className={`expanded-col ${countMondaysInMonth() >= getCurrentWeek() ? '' : 'grad-last-child'} font-${getCurrentMonthWeekNumber() == 4 ? 700 : 400} ${getCurrentMonthWeekNumber() === 4 && (getCurrentWeek() >= 4 && subType.week4 === 0) ? 'statusPending' : ''} dark-green-grad hidden `}> {subType.subType === 'Operating Income %' ? getCurrentWeek() >= 4 && subType.week4 == 0 ? "Pending" : subType.week4 + "%" : subType.week4 < 0 ? "-" + formatUSD(Math.abs(subType.week4)) : getCurrentWeek() >= 4 && subType.week4 == 0 ? "Pending" : formatUSD(subType.week4)}</td>

                                                {countMondaysInMonth() >= getCurrentWeek() ?
                                                    <td className={`expanded-col font-${getCurrentMonthWeekNumber() == 5 ? 700 : 400} ${getCurrentMonthWeekNumber() === 5 && (getCurrentWeek() >= 5 && subType.week5 === 0) ? 'statusPending' : ''} dark-green-grad grad-last-child hidden `}> {subType.subType === 'Operating Income %' ? getCurrentWeek() >= 5 && subType.week5 == 0 ? "Pending" : subType.week5 + "%" : subType.week5 < 0 ? "-" + formatUSD(Math.abs(subType.week5)) : getCurrentWeek() >= 5 && subType.week5 == 0 ? "Pending" : formatUSD(subType.week5)}</td>
                                                    : ''
                                                }


                                                <td className="font-700"> {subType.subType === 'Operating Income %' ? subType.consolidated + "%" : subType.consolidated < 0 ? "-" + formatUSD(Math.abs(subType.consolidated)) : formatUSD(subType.consolidated)}</td>
                                                <td className="font-400"> {subType.subType === 'Operating Income %' ? subType.weeklyConsolidated + "%" : subType.weeklyConsolidated < 0 ? "-" + formatUSD(Math.abs(subType.weeklyConsolidated)) : formatUSD(subType.weeklyConsolidated)}</td>
                                                <td className="font-400 td-last-row-child">  {subType.subType === 'Operating Income %' ? subType.variance + "%" : subType.variance < 0 ? "-" + formatUSD(Math.abs(subType.variance)) : formatUSD(subType.variance)}</td>
                                            </tr>
                                        ))}

                                </React.Fragment>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Sidebar pageType={"financial"} />
        </div>
    );
}


ShopFinancialScoreboard.propTypes = {
    handleloadinTiming: PropTypes.func
};