import React from 'react';
import Navigation from '../../components/navigation/Navigation.jsx';
import './error.css';
export default function PageNotFound() {
  return (
    <div className="error-page-wrap">
      <Navigation />
      <div className="error-page-content">
        <h1 className="error-page-headline">Page Not Found</h1>
        <p className="error-page-text">The page you are looking for does not exist. <a href="/">Click here to return home.</a></p>
      </div>
    </div>
  )
}
