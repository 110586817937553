// Layout
import { DefaultLayout } from '../layouts';

// EUI Pages
import MyAccount from '../pages/my-account/myaccountindex.js';
import Profile from '../pages/profile/profile.jsx';
import EditProfile from '../pages/profile/editprofile.jsx';
import Directory from '../pages/directory/directory.jsx';
import FinancialScoreboard from '../pages/financial-scoreboard/financial-scoreboard';
import BonusLevels from '../pages/bonus-levels/bonus-levels';
import Events from '../pages/events/events';
import HrBenefits from '../pages/hr-benefits/hrbenefits.jsx';
import Today from '../pages/today/today.jsx';
import Dashboard from '../pages/dashboard/dashboard.jsx';
import EventDetail from '../pages/eventdetail/eventdetail.jsx';
import News from '../pages/news';
import NewsDetail from '../pages/newsdetail'
import GetHome from '../pages/home/home';
import JobPostings from '../pages/hr-benefits/jobpostings';
import Policies from '../pages/hr-benefits/policies';
import ShopMonitorsIndex from '../pages/shopmonitors/shop-monitors-index';
import DocCenter from '../pages/doc-center/docCenter';
import BonusLevelsNew from '../pages/bonus-levels/bonus-levels-new';
import DocumentUploader from '../pages/doc-center/DocumentUploader';
import GGOBResources from '../pages/ggob-resources/ggobResources';

export const appRoutes = [
    {
        path: '/my-account',
        element: MyAccount,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/profile/:employeeId',
        element: Profile,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/profile/edit',
        element: EditProfile,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/company-directory',
        element: Directory,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/financial-scoreboard',
        element: FinancialScoreboard,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/events',
        element: Events,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/events',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/hrbenefits',
        element: HrBenefits,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/today',
        element: Today,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/anniversaries',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/financial-scoreboard',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/installed-projects/:projectId',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/today/:fetchNext',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/dashboard',
        element: Dashboard,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/news-tiles',
        element: News,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/news-details/:baseId',
        element: NewsDetail,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/event-detail/:baseId/:eventLogId?',
        element: EventDetail,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/home',
        element: GetHome,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/hr-benefits',
        element: HrBenefits,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/bonuslevels',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/bonuslevels',
        element: BonusLevels,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/bonuslevels-new',
        element: BonusLevelsNew,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/bonuslevels/:qid',
        element: BonusLevels,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/hr-benefits/job-postings',
        element: JobPostings,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/hr-benefits/policies',
        element: Policies,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/doccenter/:uid',
        element: DocCenter,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/shopmonitors/bonus-ytd',
        element: ShopMonitorsIndex,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/doccenter/get/documents',
        element: DocumentUploader,
        layout: DefaultLayout,
        secure: false
    },
    {
        path: '/ggobResources',
        element: GGOBResources,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/company-directory/:paramCompanyId',
        element: Directory,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/company-directory/:paramCompanyId/skill/:paramSearchText?',
        element: Directory,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/company-directory/:paramCompanyId/department/:paramSearchText?',
        element: Directory,
        layout: DefaultLayout,
        secure: true
    },
    {
        path: '/company-directory/:paramCompanyId/interest/:paramIntrestId/:paramSearchText?',
        element: Directory,
        layout: DefaultLayout,
        secure: true
    }
];
