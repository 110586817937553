import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './today.css'; 
import { getTodayEvents } from '../../store/events/actions';
import { getCompanies } from '../../store/employee/actions';
import { getLoggedInEmployeeById } from '../../store/employee/actions';
import Navigation from '../../components/navigation/Navigation';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import HeaderProfile from '../../components/HeaderProfile';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PortalAlert from '../../components/portalAlert/portalAlert';

export default function Today() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { siteUserId, primaryCompanyId } = useSelector(state => state.auth); 
	const { todayEventNotifications } = useSelector(state => state.event);
	const { companiesList } = useSelector(state => state.employee);
	const [filter, setFilter] = useState({ companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? primaryCompanyId : 0), timezone: GetTimeZone() });

	const initialTodayFilter = primaryCompanyId !== undefined && primaryCompanyId !== 0 ? primaryCompanyId : 0;

	const [todayFilter, setTodayFilter] = useState(initialTodayFilter);

	useEffect(() => {
		document.title = 'Today | CWP Team Connect';
		dispatch(showLoading());
		dispatch(getCompanies());
		dispatch(getLoggedInEmployeeById({ id: siteUserId }));
	}, []);

	useEffect(() => {
		dispatch(getTodayEvents(filter)).unwrap().then(() => {
			dispatch(hideLoading());
		});
	}, [filter]);


	return (
		<div className="directory-wrap events-wrap">
			<div className="drawer-menu-collapse-wrap"></div>
			<Navigation />
			<div className="events-page-wrap">
				<div className="page-inner events-inner">
					<div className="profile-wrap-outer mobile-show">
						<HeaderProfile />						
					</div>				

				
					<div className="events-content-scrollable-wrap">
						<div className="inner-pages-alert todays-alert">
							<PortalAlert />
						</div>
						<div className="profile-wrap-outer">
							<HeaderProfile />
						</div>
						<div className="events-header-wrap hrbenefits-header-wrap">

							<div className="events-header-wrap-inner">
								<h1 className="page-title today-page-title">Today</h1>
								<div className="filter-bars">
									<label>
										<select className="menu-wrap org-dropdown" name="slct" id="slct" value={todayFilter} onChange={(event) => {
											setTodayFilter(event.target.value);
											const selectedCompanyId = parseInt(event.target.value);
											setFilter({ ...filter, companyId: selectedCompanyId });
										}}>
											<option value="0">All</option>
											{companiesList.map(company => (
												<option key={company.id} value={company.id}>{company.abbreviatedName}</option>
											))}
										</select>
									</label>
								</div>
							</div>

						</div>
						<div className="events-content-wrap hrbenefits-content-wrap">
							<div className="events-content-wrap-inner">
								<div className="events-listing-wrap hrbenefits-listing-wrap">						
									{todayEventNotifications && todayEventNotifications.length > 0 && todayEventNotifications.map(notification => (
										notification.eventLog.map(notifaicationdate => (
											<a onClick={() => navigate(`/event-detail/${notification.baseId}/${notifaicationdate.id}`)} className="news-listing-item today-listing-item light-blue" key={notifaicationdate.id}> 
											<span className="news-title-wrap">
												<span className="news-title-inner">
														<span className="news-day-title normal-title">{notification.eventType?.name === 'Visitor' ? notification.eventType?.name : `${notification.eventType?.name} ${notification.eventLog.length > 0 && notifaicationdate.startTime ? '@ ' + notifaicationdate.startTime : ''}`}</span>
													<span className="news-description">{notification.title}</span>
												</span>
											</span>
											</a>
										))
									))}
									{todayEventNotifications && todayEventNotifications.length === 0 && (
										<a href="#" className="news-listing-item today-listing-item light-blue" onClick={(e) => e.preventDefault()}>
											<span className="news-title-wrap">
												<span className="news-title-inner">							
													<span className="news-description">No Notifications Today</span>
												</span>
											</span>
										</a>
									)}									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}