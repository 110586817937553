import React from 'react';
import './myaccount.css';
import $ from 'jquery';

export default function MyAccount() {
	$(document).ready(function () {
		$(".admin-table").parents('div.admin-table-wrap').addClass("admin-table-data");
	});

    return (
        <div className="myaccount-table-center">
            <div className="product-header-wrap">
                <div>
                    <div className="product-breadcrumbs-wrap">
                        <p>
                            <a className="breadcrumb" href="/">Home</a><span>&nbsp;/&nbsp;</span>
                            <span>My Account</span>
                        </p>
                    </div>
                </div>
                <div className="product-main-title-wrap">
                    <h1 className="product-main-title">My Account</h1>
                    <a className="product-main-logout-button" href="/account/logout">Logout</a>
                </div>
            </div>
            <div className="my-account-btn">
                <a href="/">Go Home</a></div>
        </div>
    );
}