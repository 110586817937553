import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './directory.css';
import Navigation from '../../components/navigation/Navigation.jsx';
import debounce from '../../helpers/debounce';
import profile from '../../assets/profile-lg.png';
import { Link } from "react-router-dom";
import { getSkillsListV2 } from '../../store/skill/actions';
import { getInterestsList } from '../../store/interest/actions';
import { getDepartmentsV2 } from '../../store/department/actions';
import { getEmployeeList, getCompanies } from '../../store/employee/actions';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { Scrollbar } from 'react-scrollbars-custom';
import PortalAlert from '../../components/portalAlert/portalAlert';
//import { getBadges } from '../../helpers/badges';
//import { calculateDateDiff } from '../../helpers/formatterHelper';
export default function Directory() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const { paramCompanyId, paramSearchText, paramIntrestId  } = useParams();
    const location = window.location.pathname;
    const { siteUserId, primaryCompanyId } = useSelector(state => state.auth);
    const companyId = paramCompanyId ? Number(paramCompanyId) : (primaryCompanyId || 0);

    const skillStringObject = localStorage.getItem('skillObject');
    const storedSkillObject = skillStringObject && skillStringObject !== "undefined" && JSON.parse(skillStringObject);
    
    const interestStringObject = localStorage.getItem('interestObject');
    const storedInterestString = interestStringObject && interestStringObject !== "undefined"  && JSON.parse(interestStringObject);

    const employeeList = useSelector(state => state.employee.employeeList);
    const companiesList = useSelector(state => state.employee.companiesList);
    const skillsListV2 = useSelector(state => state.skill.skillsListV2);
    const interestList = useSelector(state => state.interest.interestsList);
    const isSkillLoaded = useSelector(state => state.skill.isSkillLoaded);
    const [showSearchOverlay, setShowSearchOverlay] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [distinctSkills, setDistinctSkills] = useState([]);
    const [popularSkills, setPopularSkills] = useState([]);
    const [alphaSkills, setAlphaSkills] = useState([]);
    const [viewAll, setViewAll] = useState(false);
    const [filter, setFilter] = useState({ query: '', companyId: companyId, skillId: 0, interestId: 0, departmentId: 0, sort: 0, skill: null, department: null });
    const [selectedCompany, setSelectedCompany] = useState({ id: companyId, name: (companyId !== undefined && companyId !== null ? companiesList.find(x => x.id === companyId)?.abbreviatedName : 'All') });
    const filteredSkills = skillsListV2?.filter(skill => skill.name.includes(searchText));
    const filteredInterests = interestList?.filter(interest => interest.name.includes(searchText));
    const departmentsListV2 = useSelector(state => state.departments.departmentsListV2);
    const filteredDepartmentV2 = departmentsListV2?.filter(department => department.name.includes(searchText));
    const { loggedInEmployee } = useSelector(state => state.employee);
   

    const resultTypeItems = {
        all: true,
        employee: false,
        skill: false,
        interest: false,
        department: false,
    };
    const [resultsType, setResultsType] = useState(resultTypeItems);
  

    const handleClearSearch = () => {
        setSearchText('');
        const updatedFilter = { ...filter, query: '', skillId: 0, interestId: 0, departmentId: 0, sort: 0, skill: null, department: null };
        setFilter(updatedFilter);
        setResultsType({ ...resultTypeItems, all: true, employee: false, skill: false, interest: false, department: false });
        dispatch(getCompanies());
        dispatch(getEmployeeList(updatedFilter));
        localStorage.removeItem('skillObject');
        localStorage.removeItem('interestObject');
        localStorage.removeItem('departmentObject');
        resetUrl(companyId); 
    };

    useEffect(() => {
        document.title = 'Company Directory | CWP Team Connect'
        dispatch(showLoading());

        dispatch(getCompanies()).unwrap().then((response) => {
            if (response && response.success) {
                setSelectedCompany({ ...selectedCompany, id: (companyId !== undefined && companyId !== null ? companyId : 0), name: (companyId !== undefined && companyId !== null ? response.data.find(x => x.id === companyId)?.abbreviatedName : 'All') });
            }
        });

        if (storedSkillObject) {
            setSearchText(storedSkillObject.name);
            setResultsType({ ...resultTypeItems, all: false, employee: false, skill: true, interest: false, department: false });
            let localFilter = { ...filter, companyId: companyId ? companyId : 0, skillId: 0, interestId: 0, departmentId: 0, sort: 0, skill: storedSkillObject.name };
            setFilter(localFilter);
            dispatch(getSkillsListV2(searchText));
            dispatch(getCompanies());
            dispatch(getEmployeeList(localFilter)).unwrap().then(() => {
                dispatch(hideLoading());
            });
        }
        else if (storedInterestString) {
            setSearchText(storedInterestString.name);
            setResultsType({ ...resultTypeItems, all: false, employee: false, skill: false, interest: true, department: false });
            let localFilter = { ...filter, companyId: companyId ? companyId : 0, skillId: 0, interestId: storedInterestString.id, departmentId: 0, sort: 0 };
            setFilter(localFilter);
            dispatch(getCompanies());
            dispatch(getSkillsListV2(searchText));
            dispatch(getEmployeeList(localFilter)).unwrap().then(() => {
                dispatch(hideLoading());
            });
        }
        else {
            //

           
            if (paramSearchText) {
                // Determine search type from the URL path
                dispatch(getCompanies()).unwrap().then((res) => {
                    if (res.success) {
                        let companiesListData = res?.data;
                    
                if (companyId !== null && companyId !== undefined && companyId !== '') {

                    let company = (companiesListData && companyId !== 0)
                        ? { id: companyId, name: companiesListData.find(x => x.id === companyId)?.abbreviatedName }
                        : { id: 0, name: 'All' };                

                    if (location.includes('/skill/')) {
                        if (company) {
                            setSelectedCompany(company);
                            setSearchText(paramSearchText);
                            setResultsType({ ...resultTypeItems, all: false, employee: false, skill: true, interest: false, department: false });
                            let localFilter = { ...filter, query: '', companyId: company.id, skillId: 0, interestId: 0, departmentId: 0, skill: paramSearchText, department: null };
                            setFilter(localFilter);
                            dispatch(getEmployeeList(localFilter)).unwrap().then(() => {
                                dispatch(hideLoading());
                            });
                        }

                    }
                    else if (location.includes('/interest/')) {
                        if (company && paramIntrestId) {
                            setSelectedCompany(company);
                            setSearchText(paramSearchText);
                            setResultsType({ ...resultTypeItems, all: false, employee: false, skill: false, interest: true, department: false });
                            let localFilter = { ...filter, query: '', companyId: company.id, skillId: 0, interestId: paramIntrestId, departmentId: 0, skill: null, department: null };
                            setFilter(localFilter);
                            dispatch(getEmployeeList(localFilter)).unwrap().then(() => {
                                dispatch(hideLoading());
                            });
                        }

                    } else if (location.includes('/department/')) {
                        if (company) {
                            setSelectedCompany(company);
                            setSearchText(paramSearchText);
                            setResultsType({ ...resultTypeItems, all: false, employee: false, skill: false, interest: false, department: true });
                            let localFilter = { ...filter, query: '', companyId: company.id, skillId: 0, interestId: 0, departmentId: 0, skill: null, department: paramSearchText };
                            setFilter(localFilter);
                            dispatch(getEmployeeList(localFilter)).unwrap().then(() => {
                                dispatch(hideLoading());
                            });
                        }
                    }
                }
                else {
                    setFilter({ ...filter, companyId: 0 });
                    dispatch(getEmployeeList(filter)).unwrap().then(() => {
                        dispatch(hideLoading());
                    });
                        }
                    }

                });
            }
            else {
                dispatch(getCompanies());
                setFilter({ ...filter, companyId: 0 });
                dispatch(getEmployeeList(filter)).unwrap().then(() => {
                    dispatch(hideLoading());
                });
            }

            //let comId = companyId && parseInt(companyId);
            let searchByName = searchText;        
            dispatch(getSkillsListV2({ searchByName, companyId }));
            dispatch(getInterestsList(searchText));
           // dispatch(getDepartments(searchText));
            dispatch(getDepartmentsV2({ searchByName, companyId }));

     
          
        }
        setFilter({ ...filter, companyId: (companyId !== undefined && companyId !== null ? companyId : 0) });

    }, []);
 

    useEffect(() => {
        if (isSkillLoaded) {
            if (skillsListV2) {
            const grouped = skillsListV2.reduce((acc, item) => {
                if (!acc[item.name]) {
                    acc[item.name] = {
                        ...item,
                        skillCount: 0 // Initialize SkillCount for summing
                    };
                }
                acc[item.name].skillCount += item.skillCount;
                return acc;
            }, {});

            // Step 2: Get the values from the grouped object
                const result = Object.values(grouped);
                setPopularSkills(
                    result
                        .filter(skill => skill.skillCount > 0)
                        .sort((a, b) => b.skillCount - a.skillCount)
                        .slice(0, 10)
                );
                setAlphaSkills(
                    result
                        .filter(skill => skill.skillCount > 0)
                        .slice(0).sort((a, b) => a.name
                            .localeCompare(b.name)));
                setDistinctSkills(result.filter(x => x.skillCount > 0));
        }     
        }
    }, [skillsListV2]);

    useEffect(() => {
        if (showSearchOverlay && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showSearchOverlay]);

    const matchedText = (text) => {
        if (text) {
            let startIndex = text.toLowerCase().indexOf(searchText.toLowerCase());
            if (startIndex === -1) {
                return (
                    <>{text}</>
                );
            } else {
                let startText = text.substring(0, startIndex);
                let matchedText = text.substring(startIndex, startIndex + searchText.length);
                let endText = text.substring(startIndex + searchText.length, text.length);
                return (
                    <>{startText}<span className="match">{matchedText}</span>{endText}</>
                );
            }
        } else {
            return (
                <></>
            );
        }
    };

    const searchOnSkill = (skill, company) => {
        setSelectedCompany(company);
        setSearchText(skill.name);
        setResultsType({ ...resultTypeItems, all: false, employee: false, skill: true, interest: false, department: false });
        let localFilter = { ...filter, query: '', companyId: company.id, skillId: 0, interestId: 0, departmentId: 0, skill: skill.name, department: null };
        setFilter(localFilter);
        dispatch(getEmployeeList(localFilter));
        changeUrl(company.id, skill.name,'skill');
    }

    const searchOnInterest = (interest, company) => {
        setSelectedCompany(company);
        setSearchText(interest.name);
        setResultsType({ ...resultTypeItems, all: false, employee: false, skill: false, interest: true, department: false });
        let localFilter = { ...filter, query: '', companyId: company.id, skillId: 0, interestId: interest.id, departmentId: 0, skill: null, department: null };
        setFilter(localFilter);
        dispatch(getEmployeeList(localFilter));
        changeInterestUrl(company.id, interest.name, interest.id, 'interest');
   

    }

    const searchOnDepartment = (department, company) => {
        setSelectedCompany(company);
        setSearchText(department.name);
        setResultsType({ ...resultTypeItems, all: false, employee: false, skill: false, interest: false, department: true });
        let localFilter = { ...filter, query: '', companyId: company.id, skillId: 0, interestId: 0, departmentId: 0, skill: null, department: department.name };
        setFilter(localFilter);
        dispatch(getEmployeeList(localFilter));
        changeUrl(company.id, department.name,'department');

    }

    const search = (query, company) => {

        if (!query) query = '';
        if (!company) company = { id: 0, name: 'All' };
        setSelectedCompany(company);
        setResultsType({ ...resultTypeItems, all: true, employee: false, skill: false, interest: false, department: false });
        let localFilter = { ...filter, query: query, companyId: company.id, skillId: 0, interestId: 0, departmentId: 0, skill: null, department: null };
        setFilter(localFilter);
        dispatch(getEmployeeList(localFilter));
        let companyId = parseInt(company.id);       
        let searchByName = query;    
        dispatch(getSkillsListV2({ searchByName, companyId }));
        dispatch(getInterestsList(query));
        dispatch(getDepartmentsV2({ searchByName, companyId }));
    }

    const closeOverlay = () => {
        setViewAll(false);
        setShowSearchOverlay(false);
    }

    const resultCount = () => {
        let count = 0;

        if (resultsType.all && searchText !== '') {        
            count = employeeList.length + distinctSkills.length + interestList.length + departmentsListV2.length;

        } else if (resultsType.employee || (resultsType.all && searchText === '')) {
            count = employeeList.length;
        } else if (resultsType.skill) {
            count = employeeList.length;
        } else if (resultsType.interest) {
            count = employeeList.length;
        } else if (resultsType.department) {
            count = employeeList.length;
        }

        if (selectedCompany && selectedCompany.id != 0) {
            return (
                <p className="directory-results-status">{count} Results in {selectedCompany.name}</p>
            )
        } else {
            return (
                <p className="directory-results-status">{count} Results</p>
            )
        }
    }

    const handleOverlayClick = (e) => {
        if (e.target.className === 'directory-search-overlay-wrap') {
            closeOverlay();
        }
    };


    const searchOverlay = () => {
        return (
            <div className="directory-search-overlay-wrap" onClick={handleOverlayClick}>
                <div className="directory-search-overlay">
                    <div className="directory-search-overlay-top">
                        <p className="directory-search-overlay-headline">Search</p>
                        <button className="directory-search-overlay-close" onClick={(e) => { e.preventDefault(); closeOverlay(); }}>
                            <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00036 5.79037L1.19553 9.59518C1.09169 9.69903 0.961168 9.75215 0.803956 9.75455C0.646756 9.75697 0.513831 9.70384 0.405181 9.59518C0.296519 9.48653 0.242188 9.3548 0.242188 9.2C0.242188 9.04521 0.296519 8.91348 0.405181 8.80483L4.20999 5L0.405181 1.19518C0.301331 1.09134 0.248206 0.960818 0.245806 0.803605C0.243394 0.646405 0.296519 0.51348 0.405181 0.40483C0.513831 0.296168 0.645556 0.241837 0.800356 0.241837C0.955156 0.241837 1.08688 0.296168 1.19553 0.40483L5.00036 4.20964L8.80518 0.40483C8.90902 0.30098 9.03954 0.247855 9.19675 0.245455C9.35395 0.243043 9.48688 0.296168 9.59553 0.40483C9.70419 0.51348 9.75852 0.645205 9.75852 0.800005C9.75852 0.954805 9.70419 1.08653 9.59553 1.19518L5.79072 5L9.59553 8.80483C9.69938 8.90867 9.7525 9.03919 9.7549 9.19641C9.75732 9.35361 9.70419 9.48653 9.59553 9.59518C9.48688 9.70384 9.35515 9.75817 9.20035 9.75817C9.04555 9.75817 8.91383 9.70384 8.80518 9.59518L5.00036 5.79037Z" fill="currentColor" />
                            </svg>
                        </button>
                            <div className="directory-search-overlay-search-wrap">
                            <input className="directory-search-overlay-search" type="search" inputMode="search" placeholder="by person or qualifications or interests or departments" value={searchText} ref={inputRef}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        debounce(() => {
                                            search(e.target.value, selectedCompany);
                                        });
                                    }}
                                    onKeyDown={handleKeyPress}
                                />
                                <button className="directory-search-overlay-submit" onClick={(e) => { e.preventDefault(); closeOverlay(); }}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6 11.5333L6.86667 7.8C6.53333 8.06667 6.15 8.27778 5.71667 8.43333C5.28333 8.58889 4.82222 8.66667 4.33333 8.66667C3.12222 8.66667 2.09733 8.24733 1.25867 7.40867C0.419556 6.56956 0 5.54444 0 4.33333C0 3.12222 0.419556 2.09711 1.25867 1.258C2.09733 0.419333 3.12222 0 4.33333 0C5.54444 0 6.56956 0.419333 7.40867 1.258C8.24733 2.09711 8.66667 3.12222 8.66667 4.33333C8.66667 4.82222 8.58889 5.28333 8.43333 5.71667C8.27778 6.15 8.06667 6.53333 7.8 6.86667L11.55 10.6167C11.6722 10.7389 11.7333 10.8889 11.7333 11.0667C11.7333 11.2444 11.6667 11.4 11.5333 11.5333C11.4111 11.6556 11.2556 11.7167 11.0667 11.7167C10.8778 11.7167 10.7222 11.6556 10.6 11.5333ZM4.33333 7.33333C5.16667 7.33333 5.87511 7.04178 6.45867 6.45867C7.04178 5.87511 7.33333 5.16667 7.33333 4.33333C7.33333 3.5 7.04178 2.79156 6.45867 2.208C5.87511 1.62489 5.16667 1.33333 4.33333 1.33333C3.5 1.33333 2.79156 1.62489 2.208 2.208C1.62489 2.79156 1.33333 3.5 1.33333 4.33333C1.33333 5.16667 1.62489 5.87511 2.208 6.45867C2.79156 7.04178 3.5 7.33333 4.33333 7.33333Z" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                    </div>
                    <Scrollbar
                        wrapperProps={{ className: 'custom-scrollbar-wrapper' }}
                        trackYProps={{ className: 'custom-scrollbar-track-y' }}
                        thumbYProps={{ className: 'custom-scrollbar-thumb-y' }}
                        trackXProps={{ className: 'custom-scrollbar-track-x' }}
                        thumbXProps={{ className: 'custom-scrollbar-thumb-x' }}
                    >
                    <div className="directory-search-overlay-bottom">
                        {searchText === '' && (
                            <div className="directory-search-overlay-bottom-content">
                                <div className="directory-search-overlay-heading-flex">
                                        <p className="directory-search-overlay-heading">{viewAll ? "All" : "Popular"} Qualifications</p>
                                    {!viewAll &&
                                        <button className="directory-search-overlay-all" onClick={(e) => { e.preventDefault(); setViewAll(true); }}>View All</button>
                                    }
                                </div>
                                <div className="directory-search-overlay-skill-wrap">
                                    {!viewAll && popularSkills && popularSkills.map((skill) => {
                                        return (
                                            <button className="directory-search-overlay-skill" key={skill.id} onClick={(e) => { e.preventDefault(); searchOnSkill(skill, selectedCompany); closeOverlay(); }}>
                                                <span className="directory-search-overlay-skill-text">{skill.name}</span>
                                                <span className="directory-search-overlay-skill-icon">
                                                    <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                            </button>
                                        )
                                    })}
                                    {viewAll && alphaSkills && alphaSkills.map((skill) => {
                                        return (
                                            <button className="directory-search-overlay-skill" key={skill.id} onClick={(e) => { e.preventDefault(); searchOnSkill(skill, selectedCompany); closeOverlay(); }}>
                                                <span className="directory-search-overlay-skill-text">{skill.name}</span>
                                                <span className="directory-search-overlay-skill-icon">
                                                    <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        {searchText !== '' && (
                            <div className="directory-search-overlay-bottom-content">
                                {employeeList && employeeList.map((employee) => {
                                    return (
                                        <button className="directory-search-overlay-autocomplete-option" key={employee.id} onClick={(e) => { e.preventDefault(); navigate(`/profile/${employee.id}`); }}>
                                            <span className="directory-search-overlay-autocomplete-image">
                                                {employee && employee.profileImage ? (
                                                    <>
                                                        <img className="profile-page-image-backdrop" src={employee.profileImage.previewsrc} alt={employee.profileImage.altText} loading="lazy" />
                                                        <img src={employee.profileImage.previewsrc} alt={employee.profileImage.altText} loading="lazy" />
                                                    </>
                                                ) :
                                                    (
                                                        <>
                                                            <img className="profile-page-image-backdrop" src={profile} alt='profileImage' loading="lazy" />
                                                            <img src={profile} alt='profileImage' loading="lazy" />
                                                        </>
                                                    )
                                                }
                                            </span>
                                            <span className="directory-search-overlay-autocomplete-info">
                                                {employee.nickname &&
                                                    <span className="directory-search-overlay-autocomplete-name">&ldquo;{matchedText(employee.nickname)}&rdquo; {matchedText(employee.fullName)}</span>
                                                }
                                                {!employee.nickname &&
                                                    <span className="directory-search-overlay-autocomplete-name">{matchedText(employee.fullName)}</span>
                                                }
                                                <span className="directory-search-overlay-autocomplete-title">{employee.departmentDisplay}</span>
                                            </span>
                                            <span className="directory-search-overlay-autocomplete-icon">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                    )
                                })}
                    

                                        {
                                        resultsType.all && distinctSkills && distinctSkills.map((skill) => {
                                    return (
                                        <button className="directory-search-overlay-autocomplete-option" key={skill.id} onClick={(e) => { e.preventDefault(); searchOnSkill(skill, selectedCompany); closeOverlay(); }}>
                                            <span className="directory-search-overlay-autocomplete-info skill-rectangle-border">
                                                {resultsType.skill && filteredSkills ? matchedText(filteredSkills[0].name) : matchedText(skill.name)}
                                            </span>
                                            <span className="directory-search-overlay-autocomplete-icon">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                    )
                                })}
                              
                                {resultsType.all && interestList && interestList.map((interest) => {
                                    return (
                                        <button className="directory-search-overlay-autocomplete-option" key={interest.id} onClick={(e) => { e.preventDefault(); searchOnInterest(interest, selectedCompany); closeOverlay(); }}>
                                            <span className="directory-search-overlay-autocomplete-info interest-rounded-border">
                                                <span className="directory-search-overlay-autocomplete-name">{matchedText(interest.name)}</span>
                                            </span>
                                            <span className="directory-search-overlay-autocomplete-icon">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                    )
                                })}
                                    {
                                        resultsType.all && departmentsListV2 && departmentsListV2.map((department) => {
                                        
                                            

                                    return (
                                        <button className="directory-search-overlay-autocomplete-option" key={department.id} onClick={(e) => { e.preventDefault(); searchOnDepartment(department, selectedCompany); closeOverlay(); }}>
                                            <span className="directory-search-overlay-autocomplete-info">
                                                <span className="directory-search-overlay-autocomplete-name">{matchedText(department.name)}</span>
                                            </span>
                                            <span className="directory-search-overlay-autocomplete-icon">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                    )
                                })}
                            </div>
                        )}
                        </div>
                    </Scrollbar>
                </div>
            </div>
        );
    }
    const changeUrl = (companyId, searchText, type) => {
        let url = `/company-directory/${companyId}`;
        if (type || type == "") {
            url += `/${type}`;
        }
        if (searchText || searchText == "") {
            url += `/${searchText}`;
        }
        navigate(url);
    };
    const changeUrlByCompanyId = (companyId) => {
        const currentUrl = new URL(window.location.href);  // Get the full current URL

        // Split the path into segments
        const pathSegments = currentUrl.pathname.split('/');
        pathSegments[2] = companyId;  // Replace companyId (assuming it's the 3rd segment)

        // Rebuild the pathname with the updated companyId
        currentUrl.pathname = pathSegments.join('/');

        // Navigate to the new URL
        navigate(currentUrl.pathname + currentUrl.search);  // Keep the query params intact, if any
    };

    const changeInterestUrl = (companyId, searchText,intrestId, type) => {
        let url = `/company-directory/${companyId}`;
        if (type || type == "") {
            url += `/${type}`;
        }
        if (intrestId || intrestId == "") {
            url += `/${intrestId}`;
        }
        if (searchText || searchText == "") {
            url += `/${searchText}`;
        }
        navigate(url);
    };
    const resetUrl = (companyId) => {
        let url = `/company-directory/${companyId}`;        
        navigate(url);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); closeOverlay();
        }
    };
 
    return (
        <div className="directory-wrap company-directory">
            <Navigation />
            <div className="directory-results">
                <div className="directory-results-inner">
                    <div className="profile-wrap-outer mobile-show">
                        <Link className="profile-wrapper" to={`/profile/${siteUserId}`}>
                            <span className="profile-picture">
                                <span className="profile-image">
                                    {(loggedInEmployee && loggedInEmployee.profileImage && loggedInEmployee.profileImage.previewsrc) ?
                                        <span className="profile-image">
                                            <img src={loggedInEmployee.profileImage.previewsrc} alt={loggedInEmployee.profileImage.altText} loading="lazy" />
                                        </span>
                                        :
                                        <span className="navigation-menu-sticky-profile-image">
                                            <img src="../../profile-lg.png" alt="Placeholder Profile Image" loading="lazy" />
                                        </span>
                                    }
                                </span>
                                {/*<div className="badgesIcon">*/}
                                {/*    {getBadges(calculateDateDiff(employee?.startDate).yearsDiff)}*/}
                                {/*</div>*/}
                            </span>
                            <span className="profile-content">
                                <span className="profile-name">{loggedInEmployee.fullName}</span>
                                <span className="profile-title">
                                    {/*<span>Solid Wood Processing,</span>*/}
                                    <span>{loggedInEmployee.departmentDisplay}</span>
                                </span>
                            </span>
                        </Link>
                    </div>                   
                    <div className="directory-results-bottom directory-results-scrollable-wrap">
                        <div className="inner-pages-alert">
                            <PortalAlert />
                        </div>
                        <div className="directory-results-bottom-inner">
                        <div className="profile-wrap-outer">
                            <Link className="profile-wrapper" to={`/profile/${siteUserId}`}>
                                <span className="profile-picture">
                                    <span className="profile-image">
                                        {(loggedInEmployee && loggedInEmployee.profileImage && loggedInEmployee.profileImage.previewsrc) ?
                                            <span className="profile-image">
                                                <img src={loggedInEmployee.profileImage.previewsrc} alt={loggedInEmployee.profileImage.altText} loading="lazy" />
                                            </span>
                                            :
                                            <span className="navigation-menu-sticky-profile-image">
                                                <img src="../../profile-lg.png" alt="Placeholder Profile Image" />
                                            </span>
                                        }
                                    </span>
                                </span>
                                <span className="profile-content">
                                    <span className="profile-name">{loggedInEmployee.fullName}</span>
                                    <span className="profile-title">                                
                                        <span>{loggedInEmployee.departmentDisplay}</span>
                                    </span>
                                </span>
                            </Link>
                        </div>
                        <div className="directory-results-top">
                            <h1 className="page-title directory-results-heading">Company Directory</h1>
                            <div className="directory-search-flex">
                                    <div className="directory-search-wrap">
                                        <button className="directory-search-button" onClick={(e) => { e.preventDefault(); setShowSearchOverlay(true);}}>
                                        <span className="directory-search-button-text">{searchText === '' ? "Search" : searchText}</span>
                                        <span className="directory-search-button-icon">
                                            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6 11.5333L6.86667 7.8C6.53333 8.06667 6.15 8.27778 5.71667 8.43333C5.28333 8.58889 4.82222 8.66667 4.33333 8.66667C3.12222 8.66667 2.09733 8.24733 1.25867 7.40867C0.419556 6.56956 0 5.54444 0 4.33333C0 3.12222 0.419556 2.09711 1.25867 1.258C2.09733 0.419333 3.12222 0 4.33333 0C5.54444 0 6.56956 0.419333 7.40867 1.258C8.24733 2.09711 8.66667 3.12222 8.66667 4.33333C8.66667 4.82222 8.58889 5.28333 8.43333 5.71667C8.27778 6.15 8.06667 6.53333 7.8 6.86667L11.55 10.6167C11.6722 10.7389 11.7333 10.8889 11.7333 11.0667C11.7333 11.2444 11.6667 11.4 11.5333 11.5333C11.4111 11.6556 11.2556 11.7167 11.0667 11.7167C10.8778 11.7167 10.7222 11.6556 10.6 11.5333ZM4.33333 7.33333C5.16667 7.33333 5.87511 7.04178 6.45867 6.45867C7.04178 5.87511 7.33333 5.16667 7.33333 4.33333C7.33333 3.5 7.04178 2.79156 6.45867 2.208C5.87511 1.62489 5.16667 1.33333 4.33333 1.33333C3.5 1.33333 2.79156 1.62489 2.208 2.208C1.62489 2.79156 1.33333 3.5 1.33333 4.33333C1.33333 5.16667 1.62489 5.87511 2.208 6.45867C2.79156 7.04178 3.5 7.33333 4.33333 7.33333Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                    </button>
                                    {searchText === '' ? '' :
                                        <button className="directory-search-reset" onClick={(e) => { e.preventDefault(); setSearchText(''); handleClearSearch(); }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.00036 5.79037L1.19553 9.59518C1.09169 9.69903 0.961168 9.75215 0.803956 9.75455C0.646756 9.75697 0.513831 9.70384 0.405181 9.59518C0.296519 9.48653 0.242188 9.3548 0.242188 9.2C0.242188 9.04521 0.296519 8.91348 0.405181 8.80483L4.20999 5L0.405181 1.19518C0.301331 1.09134 0.248206 0.960818 0.245806 0.803605C0.243394 0.646405 0.296519 0.51348 0.405181 0.40483C0.513831 0.296168 0.645556 0.241837 0.800356 0.241837C0.955156 0.241837 1.08688 0.296168 1.19553 0.40483L5.00036 4.20964L8.80518 0.40483C8.90902 0.30098 9.03954 0.247855 9.19675 0.245455C9.35395 0.243043 9.48688 0.296168 9.59553 0.40483C9.70419 0.51348 9.75852 0.645205 9.75852 0.800005C9.75852 0.954805 9.70419 1.08653 9.59553 1.19518L5.79072 5L9.59553 8.80483C9.69938 8.90867 9.7525 9.03919 9.7549 9.19641C9.75732 9.35361 9.70419 9.48653 9.59553 9.59518C9.48688 9.70384 9.35515 9.75817 9.20035 9.75817C9.04555 9.75817 8.91383 9.70384 8.80518 9.59518L5.00036 5.79037Z" fill="currentColor" />
                                            </svg>
                                        </button>
                                    }
                                </div>
                                <div className="directory-filter-wrap">
                                        <label>
                                            <select className="directory-filter" value={filter.companyId} onChange={(e) => {
                                                e.preventDefault();
                                                if (resultsType.skill) {
                                                    if (storedSkillObject) {
                                                        searchOnSkill(storedSkillObject, { id: e.target.value, name: e.target.options[e.target.selectedIndex].getAttribute('name') });
                                                    }
                                                    else if (filteredSkills) {
                                                        searchOnSkill(filteredSkills[0], { id: e.target.value, name: e.target.options[e.target.selectedIndex].getAttribute('name') });
                                                    }
                                                }
                                                else if (resultsType.interest) {
                                                    if (storedInterestString) {
                                                        searchOnInterest(storedInterestString, { id: e.target.value, name: e.target.options[e.target.selectedIndex].getAttribute('name') });
                                                    }
                                                    else if (filteredInterests) {
                                                        searchOnInterest(filteredInterests[0], { id: e.target.value, name: e.target.options[e.target.selectedIndex].getAttribute('name') });
                                                    }
                                                }
                                                else if (resultsType.department) {
                                                   
                                                    if (filteredDepartmentV2) {
                                                        searchOnDepartment(filteredDepartmentV2[0], { id: e.target.value, name: e.target.options[e.target.selectedIndex].getAttribute('name') });
                                                    }
                                                }
                                                else {
                                                    search(searchText, { id: e.target.value, name: e.target.options[e.target.selectedIndex].getAttribute('name') });
                                                }
                                                //navigate(`/company-directory/${e.target.value}`);
                                                changeUrlByCompanyId(e.target.value)
                                                
                                            }}>
                                                <option value="0" name="All">All</option>
                                                {companiesList && companiesList.map((company) => {
                                                    return (
                                                        <option value={company.id} name={company.abbreviatedName} key={company.id}>{company.abbreviatedName}</option>
                                                    )
                                                })}
                                            </select>
                                        </label>
                                </div>
                            </div>
                        </div>
                        <div className="directory-results-inner-wrap">
                            {resultCount()}
                            <div className="directory-results-wrap">
                                {(resultsType.all || resultsType.employee || resultsType.skill || resultsType.interest || resultsType.department) && employeeList && employeeList.map((employee) => {
                                    return (
                                        <Link className="directory-result" to={`/profile/${employee.id}`} key={employee.id}>
                                            <span className="directory-result-image">
                                                {employee && employee.profileImage ? (
                                                    <>
                                                        <img className="profile-page-image-backdrop" src={employee.profileImage.previewsrc} alt={employee.profileImage.altText} />
                                                        <img src={employee.profileImage.previewsrc} alt={employee.profileImage.altText} />
                                                    </>
                                                ) :
                                                    (
                                                        <>
                                                            <img className="profile-page-image-backdrop" src={profile} alt='profileImage' />
                                                            <img src={profile} alt='profileImage' />
                                                        </>
                                                    )
                                                }
                                            </span>
                                            <span className="directory-result-content">
                                                {employee.nickname &&
                                                    <span className="directory-result-name">&ldquo;{matchedText(employee.nickname)}&rdquo; {matchedText(employee.fullName)}</span>
                                                }
                                                {!employee.nickname &&
                                                    <span className="directory-result-name">{matchedText(employee.fullName)}</span>
                                                }
                                                <span className="directory-result-title">{employee.departmentDisplay}</span>
                                            </span>
                                            <span className="directory-result-arrow">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </Link>
                                    )
                                })}
                                        {
                                        (resultsType.all) && searchText !== '' && distinctSkills && distinctSkills.map((skill) => {
                                    return (
                                        <a className="directory-result" key={skill.id} onClick={(e) => { e.preventDefault(); searchOnSkill(skill, selectedCompany); }}>
                                            <span className="directory-result-content">
                                                <span className="directory-result-name">{matchedText(skill.name)}</span>
                                            </span>
                                            <span className="directory-result-arrow">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    )
                                })}
                                {(resultsType.all) && searchText !== '' && interestList && interestList.map((interest) => {
                                    return (
                                        <a className="directory-result" key={interest.id} onClick={(e) => { e.preventDefault(); searchOnInterest(interest, selectedCompany); }}>
                                            <span className="directory-result-content">
                                                <span className="directory-result-name">{matchedText(interest.name)}</span>
                                            </span>
                                            <span className="directory-result-arrow">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    )
                                })}
                                    {/*(resultsType.all) && searchText !== '' && departmentsList && departmentsList.map((department) => {*/
                                        (resultsType.all) && searchText !== '' && departmentsListV2 && departmentsListV2.map((department) => {
                                    return (
                                        <a className="directory-result" key={department.id} onClick={(e) => { e.preventDefault(); searchOnDepartment(department, selectedCompany); }}>
                                            <span className="directory-result-content">
                                                <span className="directory-result-name">{matchedText(department.name)}</span>
                                            </span>
                                            <span className="directory-result-arrow">
                                                <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.61204 6.53185L0.712044 1.63185C0.545378 1.46518 0.464822 1.27074 0.470377 1.04851C0.475933 0.826291 0.562044 0.631847 0.728711 0.46518C0.895377 0.298513 1.08982 0.21518 1.31204 0.21518C1.53427 0.21518 1.72871 0.298513 1.89538 0.46518L7.02871 5.58185C7.16204 5.71518 7.26204 5.86518 7.32871 6.03185C7.39538 6.19851 7.42871 6.36518 7.42871 6.53185C7.42871 6.69851 7.39538 6.86518 7.32871 7.03185C7.26204 7.19851 7.16204 7.34851 7.02871 7.48185L1.89538 12.6152C1.72871 12.7818 1.53149 12.8624 1.30371 12.8568C1.07593 12.8513 0.878711 12.7652 0.712044 12.5985C0.545378 12.4318 0.462044 12.2374 0.462044 12.0152C0.462044 11.793 0.545378 11.5985 0.712044 11.4318L5.61204 6.53185Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSearchOverlay && searchOverlay()}
        </div>
    );
}