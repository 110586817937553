import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isJobPostingsLoaded: false,
    jobPostingsList: [],
    isJobPostingSaving: false,
    benefitsList: [],
    isBenefitsLoaded: false,
    isBenefitsSaving: false,
    jobPostingsListByfilter: [],
    benefitsListByfilter: [],
    documentList: [],
    isdocumentListLoaded: false
}

const hrBenefitsSlice = createSlice({
    name: 'hrbenefits',
    initialState,
    extraReducers: (builder) => {

        // Get Job Postings
        builder.addCase(actions.getJobPostings.pending, (state) => {
            state.isJobPostingsLoaded = false;
        })
        builder.addCase(actions.getJobPostings.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.jobPostingsList = action.payload.data;
            } else {
                state.jobPostingsList = [];
            }
            state.isJobPostingsLoaded = true;
        })
        builder.addCase(actions.getJobPostings.rejected, (state) => {
            state.isJobPostingsLoaded = false;
        });

        // add/edit Job Postings
        builder.addCase(actions.addEditJobPosting.pending, (state) => {
            state.isJobPostingSaving = true;
        })
        builder.addCase(actions.addEditJobPosting.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                const postingsObj = state.jobPostingsList.find(x => x.id === returnObj.id);
                if (postingsObj !== undefined && postingsObj !== null) {
                    postingsObj.title = returnObj.title;
                    postingsObj.description = returnObj.description;
                    postingsObj.active = returnObj.active;  
                    postingsObj.jobPostingCompanies = returnObj.jobPostingCompanies;
                } else {
                    state.jobPostingsList.unshift(returnObj);
                }
            }
            state.isJobPostingSaving = false;
        })
        builder.addCase(actions.addEditJobPosting.rejected, (state) => {
            state.isJobPostingSaving = false;
        });


        // Delete Job Posting
        builder.addCase(actions.deleteJobPosting.pending, (state) => {
            state.isJobPostingSaving = true;
        })
        builder.addCase(actions.deleteJobPosting.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.jobPostingsList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.jobPostingsList.splice(enityIndex, 1);
                }
            }
            state.isJobPostingSaving = false;
        })
        builder.addCase(actions.deleteJobPosting.rejected, (state) => {
            state.isJobPostingSaving = false;
        });

        // order Job Posting
        builder.addCase(actions.updateJobPostingOrder.pending, (state) => {
            state.isJobPostingSaving = true;
        })
        builder.addCase(actions.updateJobPostingOrder.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.jobPostingsList = action.payload.data;
            } else {
                state.jobPostingsList = [];
            }
            state.isJobPostingSaving = false;
        })
        builder.addCase(actions.updateJobPostingOrder.rejected, (state) => {
            state.isJobPostingSaving = false;
        });


        // Get All Benefits
        builder.addCase(actions.getBenefits.pending, (state) => {
            state.isBenefitsLoaded = false;
        })
        builder.addCase(actions.getBenefits.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.benefitsList = action.payload.data;
            } else {
                state.benefitsList = [];
            }
            state.isBenefitsLoaded = true;
        })
        builder.addCase(actions.getBenefits.rejected, (state) => {
            state.isBenefitsLoaded = false;
        });


        // add/edit Benefit
        builder.addCase(actions.addEditBenefit.pending, (state) => {
            state.isBenefitsSaving = true;
        })
        builder.addCase(actions.addEditBenefit.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                const postingsObj = state.benefitsList.find(x => x.baseId === returnObj.baseId);
                if (postingsObj !== undefined && postingsObj !== null) {
                    postingsObj.title = returnObj.title;
                    postingsObj.description = returnObj.description;
                    postingsObj.benefitCompanies = returnObj.benefitCompanies;
                    postingsObj.order = returnObj.order;
                    postingsObj.id = returnObj.id;
                } else {
                    state.benefitsList.unshift(returnObj);
                }
            }
            state.isBenefitsSaving = false;
        })
        builder.addCase(actions.addEditBenefit.rejected, (state) => {
            state.isBenefitsSaving = false;
        });


        // Delete Benefits
        builder.addCase(actions.deleteBenefit.pending, (state) => {
            state.isBenefitsSaving = true;
        })
        builder.addCase(actions.deleteBenefit.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.benefitsList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.benefitsList.splice(enityIndex, 1);
                }
            }
            state.isBenefitsSaving = false;
        })
        builder.addCase(actions.deleteBenefit.rejected, (state) => {
            state.isBenefitsSaving = false;
        });


        // order Benefits
        builder.addCase(actions.updateBenefitsOrder.pending, (state) => {
            state.isBenefitsSaving = true;
        })
        builder.addCase(actions.updateBenefitsOrder.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.benefitsList = action.payload.data;
            } else {
                state.benefitsList = [];
            }
            state.isBenefitsSaving = false;
        })
        builder.addCase(actions.updateBenefitsOrder.rejected, (state) => {
            state.isBenefitsSaving = false;
        });


        builder.addCase(actions.getJobPostingsByFilter.pending, (state) => {
            state.isJobPostingsLoaded = false;
        })
        builder.addCase(actions.getJobPostingsByFilter.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.jobPostingsListByfilter = action.payload.data;
            } else {
                state.jobPostingsListByfilter = [];
            }
            state.isJobPostingsLoaded = true;
        })
        builder.addCase(actions.getJobPostingsByFilter.rejected, (state) => {
            state.isJobPostingsLoaded = false;
        });


        // Get All Benefits
        builder.addCase(actions.getBenefitsByFilter.pending, (state) => {
            state.isBenefitsLoaded = false;
        })
        builder.addCase(actions.getBenefitsByFilter.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.benefitsListByfilter = action.payload.data;
            } else {
                state.benefitsListByfilter = [];
            }
            state.isBenefitsLoaded = true;
        })
        builder.addCase(actions.getBenefitsByFilter.rejected, (state) => {
            state.isBenefitsLoaded = false;
        });


        builder.addCase(actions.getDocumentList.pending, (state) => {
            state.isdocumentListLoaded = true;
        })
        builder.addCase(actions.getDocumentList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.documentList = action.payload.data;
            } else {
                state.documentList = [];
            }
            state.isdocumentListLoaded = false;
        })
        builder.addCase(actions.getDocumentList.rejected, (state) => {
            state.isdocumentListLoaded = false;
        });
    }
});

export default hrBenefitsSlice.reducer;