import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediaItem } from './MediaItem';

function ShowVideoThumbnail({ thumbnailSrc }) {
  if (thumbnailSrc !== undefined && thumbnailSrc !== '') {
    return (<div className="videoThumbWrap">
      <img className="videoThumbPreview" src={thumbnailSrc} />
    </div>);
  } else return null;
}
ShowVideoThumbnail.propTypes = {
  thumbnailSrc: PropTypes.string
}

const getVideoImageFromUrl = async (url) => {
  let videoObj = {
    videoId: "",
    videoThumbnail: "",
    videoType: null
  };
  if (url == null) return videoObj;
  if (url.indexOf('youtu') > -1) {
    if (url.indexOf('youtu.be') > -1) videoObj.videoId = url.match('.be/([^&#]*)')[1];
    else videoObj.videoId = url.match('[\\?&]v=([^&#]*)')[1];
    videoObj.videoThumbnail = "https://img.youtube.com/vi/" + videoObj.videoId + "/maxresdefault.jpg";
    videoObj.videoType = "YouTube";
    return videoObj;
  } else if (url.indexOf('vimeo') > -1) {
    videoObj.videoId = url.match('com/([0-9]*)')[1];
    videoObj.videoType = "Vimeo";
    const response = await fetch('https://vimeo.com/api/v2/video/' + videoObj.videoId + '.json');
    const data = await response.json();
    if (data[0].thumbnail_large) {
      videoObj.videoThumbnail = data[0].thumbnail_large;
    } else {
      videoObj.videoThumbnail = data[0].thumbnail_medium;
    }
    return videoObj;
  }
  else
    return videoObj;
}


export default function MediaVideoModal({ requireAltText, showModal, onCloseModal, onSaveModal }) {

  const [thumbNail, setThumbNail] = useState('');
  const [videoObj, setVideoObj] = useState(MediaItem);

  const handleSaveModal = async () => {
    if (requireAltText && videoObj.alttext === '') {
      document.getElementById('video-alt-text').classList.add('input-validation-error');
      return;
    }

    if (videoObj) {
      let mediaData = videoObj;
      mediaData.type = 'Video';
      mediaData.imageFile = null;
      //save video and return as media object
      onSaveModal(mediaData);

      setVideoObj(MediaItem);
      setThumbNail('');
    }
  }

  const handleCloseModal = async () => {
    setVideoObj({});
    setThumbNail('');
    onCloseModal();
  }

  const handleVideoUrl = async (e) => {
    let videoUrl = e.target.value;
    let newVideo = await getVideoImageFromUrl(videoUrl);
    console.log(newVideo);
    if (newVideo)
      setThumbNail(newVideo.videoThumbnail);

    setVideoObj({
      ...videoObj,
      videoId: newVideo.videoId,
      videoSrc: document.getElementById('video-url').value,
      videoThumbnailUrl: newVideo.videoThumbnail,
      videoType: newVideo.videoType
    });
    console.log(videoObj);
  }


    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleCloseModal();
        }
    };


  if (showModal) {
    return (
      <div data-section="CropImageOverlayPreview" className="admin-modal-overlay" onClick={handleOverlayClick}>
        <div className="admin-modal">
          <button className="admin-modal-close" onClick={handleCloseModal}>
            <span className="material-symbols-rounded">close</span>
          </button>
          <p className="admin-modal-heading">Video</p>
          <div className="admin-modal-row">
            <div className="admin-modal-cell">
              {/*{requireAltText ? (*/}
              {/*  <label className="admin-default-label" htmlFor="video-alt-text">Alt Text (Required)</label>*/}
              {/*) : (*/}
              {/*  <label className="admin-default-label" htmlFor="video-alt-text">Alt Text</label>*/}
              {/*)}*/}
              {/*<input*/}
              {/*  id="video-alt-text"*/}
              {/*  type="text"*/}
              {/*  className="admin-default-input-text"*/}
              {/*  value={videoObj.altText}*/}
              {/*  onChange={(e) => {*/}
              {/*    setVideoObj({*/}
              {/*      ...videoObj,*/}
              {/*      altText: e.target.value*/}
              {/*    })*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </div>
          <div className="admin-modal-row full">
            <div className="admin-modal-cell full">
              <label className="admin-default-label" htmlFor="video-url">Video URL</label>
              <input
                id="video-url"
                data-imvideosection="videoUrl"
                className="admin-default-input-text"
                type="text"
                onChange={handleVideoUrl}
              />
              <ShowVideoThumbnail
                thumbnailSrc={thumbNail}
              />
              { /* To Do: Add ImageUploader for custom thumbnail */}
              <div className="overlayImageWrap">
                <img className="previewImage"
                  data-obj="previewImage"
                  src="" />
              </div>
            </div>
          </div>
          {/*<div className="admin-modal-row">*/}
          {/*  <div className="admin-modal-cell">*/}
          {/*    <p className="admin-default-label">or</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="admin-modal-row full">*/}
          {/*  <div className="admin-modal-cell">*/}
          {/*    <label className="mediaAddImage">*/}
          {/*      <input*/}
          {/*        type="file"*/}
          {/*        accept="video/*"*/}
          {/*        onChange={() => {*/}
          {/*          alert('ToDo: Custom video upload');*/}
          {/*          //setShowImageModal(true);*/}
          {/*          //setOgImageSrc(e.target.files[0])*/}
          {/*        }} />*/}
          {/*      <span className="mediaAdd-button">Upload Video</span>*/}
          {/*    </label>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="admin-modal-button-wrap">
            <button className="admin-modal-button"
              data-action="closeOverlay"
              onClick={handleCloseModal}
            >Close</button>
            <button className="admin-modal-button power"
              onClick={handleSaveModal}
            >Save</button>
          </div>
        </div>
      </div>
    )
  }
  else return (<></>);
}


MediaVideoModal.propTypes = {
  requireAltText: PropTypes.bool,
  mediaObj: PropTypes.object,
  showModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSaveModal: PropTypes.func
}

MediaVideoModal.defaultProps = {
  requireAltText: false
}
