export const newsItem = {
    id: 0,
    title: '',
    publishDate: new Date(),
    hrNews: false,
    content: '',
    baseId: 0,
    newsCompanies: [],
    media: [],
    pinned: null
}      