import React from 'react';
import PropTypes from 'prop-types';
import AdminDrawer from '../components/admin/drawer';
import ShowAccessError from '../components/common/showAccessError';
import { useSelector } from 'react-redux';
import AdminNavEditLink from "../context/AdminMenuContext";

export default function AdminLayout({ children }) {
	const { isShowAccessErrorPopup } = useSelector(state => state.auth);
	return (
		<div className="admin-full-wrap">
			<AdminNavEditLink>
			<AdminDrawer />
				{children}
			</AdminNavEditLink>
			<ShowAccessError show={isShowAccessErrorPopup} />
		</div>
	);
}

AdminLayout.propTypes = {
	children: PropTypes.any,
};
