import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './news.css';
import Navigation from '../../components/navigation/Navigation';
import { getNewsList } from "../../store/news/actions";
import { formatDate } from "../../helpers/formatterHelper";
import { getCompanies } from "../../store/employee/actions";
import { getLoggedInEmployeeById } from '../../store/employee/actions';
import HeaderProfile from "../../components/HeaderProfile";
import { hideLoading, showLoading } from '../../store/loading-reducer';
import NewsImages from "../../components/news-tiles/NewsImages";
import PortalAlert from '../../components/portalAlert/portalAlert';
import { v4 as uuidv4 } from "uuid";
export default function News() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { siteUserId, primaryCompanyId } = useSelector(state => state.auth);
    const [filter, setFilter] = useState({ query: '', companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? primaryCompanyId : 0), sort: 6, IsShowPastEvent: false });
    const initialTodayFilter = primaryCompanyId !== undefined && primaryCompanyId !== 0 ? primaryCompanyId : 0;
    const { newsData } = useSelector(state => state.news);
    const { companiesList } = useSelector(state => state.employee);
    const [newsFilter, setNewsFilter] = useState(initialTodayFilter);
    


    useEffect(() => {
        document.title = 'Happenings | CWP Team Connect';
        dispatch(getCompanies());
        dispatch(getLoggedInEmployeeById({ id: siteUserId }));
    }, []);

    useEffect(() => {
        dispatch(showLoading());
        dispatch(getNewsList(filter)).unwrap().then(() => {           
            dispatch(hideLoading());
        });        
    }, [filter]);    

    return (
        <div className="directory-wrap news-wrap">
            <div className="drawer-menu-collapse-wrap"></div>
            <Navigation />
            <div className="events-page-wrap">
                <div className="page-inner events-inner">
                    <div className="profile-wrap-outer mobile-show">
                        <HeaderProfile />                        
                    </div>
                    
                    <div className="events-content-scrollable-wrap">
                        <div className="inner-pages-alert">
                            <PortalAlert />
                        </div>
                        <div className="profile-wrap-outer">
                            <HeaderProfile />
                        </div>
                        <div className="events-header-wrap hrbenefits-header-wrap">

                            <div className="events-header-wrap-inner">
                                <h1 className="page-title news-page-title">Happenings</h1>
                                <div className="filter-bars">
                                    <label>
                                        <select className="menu-wrap org-dropdown" name="slct" id="slct" value={newsFilter} onChange={(event) => {
                                            setNewsFilter(event.target.value);
                                            const selectedCompanyId = parseInt(event.target.value);
                                            setFilter({ ...filter, companyId: selectedCompanyId });
                                        }}>
                                            <option value="0">All</option>
                                            {companiesList.map(company => (
                                                <option key={company.id} value={company.id}>{company.abbreviatedName}</option>
                                        ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="events-content-wrap hrbenefits-content-wrap">
                            <div className="events-content-wrap-inner">
                                <div className="events-listing-wrap hrbenefits-listing-wrap">
                                    {newsData.map((newsItem) => (
                                       /* NOTE FOR BE : ADD "pin-list" class  in <a> tag to apply background color for the pin item.*/
                                        <a onClick={() => navigate('/news-details/' + newsItem.baseId)}
                                            className={`news-listing-item news-item light-blue ${newsItem?.pinnedDateTime ? 'pin-list' : ''}`} 
                                            key = {`newsItem${newsItem.id}`}>
                                            <span className="news-title-wrap">
                                                <span className="news-title-inner">                                                    
                                                    <span className="flex-wrap">
                                                    {newsItem.newsCompanies && newsItem.newsCompanies.length > 0 && (
                                                        <>
                                                            {newsItem.newsCompanies.map(item =>
                                                                <span key={uuidv4()} className="news-day-title">
                                                                    {item.company.abbreviatedName}
                                                                </span>
                                                            )}
                                                        </>
                                                        )}
                                                    </span>
                                                    <span className="news-day-date">{formatDate(newsItem.publishDate, "MMM dd, y")}</span>
                                                    <span className="news-description">{newsItem.title}</span>
                                                </span>
                                            </span>
                                            <span className="pin-img-wrap">
                                                {newsItem?.pinnedDateTime &&
                                                    <span className="pin-text-wrap">
                                                        <span className="pin-text">Top Story</span>
                                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.59138 6.53363L7.93275 7.875V9H4.52888V13.125L3.96638 13.6875L3.40388 13.125V9H0V7.875L1.34138 6.53363V1.125H0.591375V0H7.34138V1.125H6.59138V6.53363Z" fill="#9F9F9F" />
                                                        </svg>
                                                    </span>
                                                }

                                                <span className="news-image-wrap">
                                                    {newsItem && newsItem.media && newsItem.media.length > 0 && (() => {
                                                        const media = newsItem.media.filter(x => !x.mediaItem?.videoThumbnailUrl?.includes("/static/media/"));                                           
                                                        if (media.length > 0) {
                                                            return (
                                                                <div className="video-event-wrap" key={`media_${media[0]?.id}`}>
                                                                    <NewsImages mediaItem={media[0]?.mediaItem} />                                                                
                                                                </div>
                                                            );
                                                        }
                                                    })()}

                                                    </span>
                                            </span>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}