import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInterestsList, deleteInterests, editInterests, saveInterests } from '../../../store/interest/actions';
import { InterestsAddEditModal } from './InterestsAddEditModal';
import Loader from '../../../components/Loader';
import { InterestDeleteConfrimationModal } from './InterestDeleteModal';
function interestsList() {
    const dispatch = useDispatch();

    const defaultInterestObj = { id: 0, name: '', Deleted: false }
    const { interestsList } = useSelector(state => state.interest);
    const [showAddModal, setShowAddModal] = useState(false);
    const [InterestInterestModel, setInterestModel] = useState(defaultInterestObj);
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        document.title = 'Interests | Skills & Interests | CWP Employee Portal';
        setLoading(true);
        dispatch(getInterestsList());
        setLoading(false);
    }, []);

    const handleAddInterest = () => {
        setInterestModel(defaultInterestObj);
        setShowAddModal(true);
    }

    const handleAddEditInterest = (data) => {
        if (data != undefined && data != null && data.id > 0) {
            dispatch(editInterests(data));
        }
        else {
            dispatch(saveInterests(data));
        }
    }

    const handleEditTrigger = (id, name) => {
        setInterestModel({ id: id, name: name });
        setShowAddModal(true);
    }

    const handleDeleteTrigger = (id, name) => {
        setInterestModel({ id: id, name: name });
        setShowDeleteModal(true);
    }

    const handleDeleteInterest = (data) => {
        dispatch(deleteInterests(data.id));
    }

    return (
        <div className="admin-page-content">
            <div className="admin-page-title-wrap">
                <h1 className="admin-page-heading">Interests</h1>
                <div className="admin-section-title-bar">
                    <div className="admin-section-title-action-wrap">
                        <button className="admin-button" onClick={() => handleAddInterest(true)}>
                            <span className="admin-button-text">Add Interest</span>
                            <span className="material-symbols-rounded" aria-hidden="true">add</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="admin-section">
                <InterestsAddEditModal
                    show={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    handleAddEditInterest={handleAddEditInterest}
                    modelInterest={InterestInterestModel}
                />
                <InterestDeleteConfrimationModal
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    handleDeleteInterest={handleDeleteInterest}
                    modelInterest={InterestInterestModel}
                />

                <div className="admin-tile-flex">
                    {interestsList && interestsList.length > 0 ? (
                        interestsList
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(item =>
                                <div className="admin-interest-tile" key={item.id}>
                                    <span className="admin-interest-tile-name">{item.name} {' (' + item.interestCount + ')'}</span>
                                    <span className="admin-interest-tile-action-wrap">
                                        <button className="admin-interest-tile-action" onClick={() => handleEditTrigger(item.id, item.name)}>
                                            <span className="material-symbols-rounded">edit</span>
                                        </button>
                                        <button className="admin-interest-tile-action" key={item.id} onClick={() => handleDeleteTrigger(item.id, item.name)}>
                                            <span className="material-symbols-rounded">delete</span>
                                        </button>
                                    </span>
                                </div>
                            )) : (
                        loading ? <Loader /> : <></>
                    )}
                </div>
            </div>
        </div>
    );
}

export default interestsList;
