﻿import { Plugin } from '@ckeditor/ckeditor5-core';
import { View, ButtonView, Dialog } from '@ckeditor/ckeditor5-ui';
import getRangeText from './utils.js';
import { findAttributeRange } from '@ckeditor/ckeditor5-typing';
import documentIcon from './icons/doc-library.svg';

export default class DoclibraryUI extends Plugin {
    static get requires() {
        return [Dialog];
    }

    init() {
        const editor = this.editor;

        this._dialog = this.editor.plugins.get(Dialog);
        this.dialogView = this._createDialogView();
        this._enableDocumentDialogActivators();

        // Register the button in the editor's UI component factory.
        editor.ui.componentFactory.add('doclibrary', () => {
            const button = new ButtonView();

            button.label = 'Document Link';
            button.tooltip = true;
            //button.withText = true;
            button.icon = documentIcon,

            this.listenTo(button, 'execute', () => {
                this._showUI();
            });

            return button;
        });

        const view = editor.editing.view;
        view.document.on('dblclick', (event, data) => {
            // Handle the double-click event.
            console.log('Double-click detected:', data.domEvent);
        });

    }

    _showUI() {
        var content = this.dialogView;
        if (content) {
            this._dialog.show({
                id: 'doclibrarypopup',
                isModal: true,
                title: 'Document Link',
                content: content,
                className: 'ck-doclibrarypopup',
                actionButtons: [
                    {
                        label: 'OK',
                        class: 'ck-button-action',
                        withText: true,
                        onExecute: () => {
                            const { value, text, docError } = this._getSelectedValueAndTextFromIframeDropdown();

                            console.log("value", value);
                            console.log("text", text);
                            console.log("docError", docError);

                            if (docError != null) {
                                this._showErrorMessage(docError);
                                return;
                            }

                            if (value == '' || value == undefined || value == null) {
                                this._showErrorMessage('Please select a document.');
                                return;
                            }

                            /*if (!value || !text) {
                                //console.error('No value or text selected from iframe dropdown.');
                                this._showErrorMessage('Please save the document you uploaded before proceeding.');
                                return;
                            }*/

                            const itemValue = {
                                abbr: text.trim(),
                                title: value
                            };
                            this.editor.execute('addAbbreviation', itemValue);

                            this._dialog.hide();
                        }
                    },
                    {
                        label: 'Cancel',
                        withText: true,
                        onExecute: () => this._dialog.hide()
                    }
                ],
                onShow: dialog => {
                    dialog.view.on('close', (evt, data) => {
                        if (data.source === 'escKeyPress') {
                            evt.stop();
                        }
                    }, { priority: 'high' });
                },
                onHide: dialog => {
                    console.log(dialog);
                },

            });
        }



        this.editor.editing.view.focus();
    }

    _hideUI() {
        this.editor.editing.view.focus();
    }


    _createDialogView() {
        const dialogBody = new View();

        const bind = dialogBody.bindTemplate;

        dialogBody.setTemplate({
            tag: 'iframe',
            attributes: {
                src: '/doccenter/get/documents',
                class: [
                    'ck',
                    'ck-reset_all',
                    'ck-doclibrary'
                ],
            },
            on: {
                load: bind.to('loaded')
            }
        });

        dialogBody.on('loaded', () => {
            console.log("ifram loaded");
            const model = this.editor.model;
            const selection = model.document.selection;           

            console.log("selection", selection);

            const commandValue = this.editor.commands.get('addAbbreviation').value;
            let docUrlName = null;
            let docUrl = null;

            if (commandValue) {
                docUrl = commandValue.title;
                docUrlName = commandValue.abbr;
            } else {
                const firstRange = selection.getFirstRange();

                console.log("isCollapsed", firstRange.isCollapsed);

                if (firstRange.isCollapsed) {
                    if (selection.hasAttribute('linkHref')) {
                        const attributeValue = selection.getAttribute('linkHref');

                        // Find the entire range containing the abbreviation under the caret position.
                        const abbreviationRange = findAttributeRange(selection.getFirstPosition(), 'linkHref', attributeValue, model);

                        docUrl = attributeValue;
                        docUrlName = getRangeText(abbreviationRange);
                    }
                }
                else {
                    if (selection.hasAttribute('linkHref')) {
                        const attributeValue = selection.getAttribute('linkHref');
                        const abbreviationRange = findAttributeRange(selection.getFirstPosition(), 'linkHref', attributeValue, model);
                        if (abbreviationRange.containsRange(firstRange, true)) {
                            docUrl = attributeValue;
                            docUrlName = getRangeText(firstRange);
                        }
                    }
                    else {
                        docUrlName = getRangeText(selection.getFirstRange());                   
                    }
                }
            }           

            /*const iframeDocument = this.dialogView.element.contentDocument;
            if (commandValue && iframeDocument) {
                *//*setTimeout(function () {
                    const dropdown = iframeDocument.getElementById('selectDocument');
                    console.log("dropdown", dropdown);
                    if (dropdown) {
                        dropdown.value = commandValue.title;
                        console.log("Dropdown value set to:", commandValue.title);
                    }
                }, 5000);*//*
            }*/

            const iframeWindow = dialogBody.element.contentWindow;
            if (iframeWindow && (docUrlName != null || docUrl != null)) {
                //setTimeout(function () {
                    iframeWindow.setupEditSlide(docUrlName, docUrl);
                //}, 2000);
            }
        }); 

        return dialogBody;
    }


    _getSelectedValueAndTextFromIframeDropdown() {
        let docError = null;
        // Replace this with actual logic to fetch selected value and text from iframe dropdown
        const iframeDocument = this.dialogView.element.contentDocument;
        if (!iframeDocument) {
            console.error('Iframe document not accessible.');
            return { value: null, text: null };
        }

        const dropdown = iframeDocument.getElementById('selectDocument');
        if (!dropdown) {
            console.error('Dropdown element not found in iframe.');
            return { value: null, text: null };
        }
        const selectedOption = dropdown.options[dropdown.selectedIndex];
        const value = selectedOption.value;
        let text = selectedOption.textContent;
        const linkText = dropdown.getAttribute('data-linktext');
        if (linkText != '' && linkText != null) {
            text = linkText;
        }

        const uploading = dropdown.getAttribute('data-uploading');
        if (uploading == 'fileSelected') {
            docError = 'Please save the document you uploaded before proceeding.';
        }

        return { value, text, docError };
    }

    _showErrorMessage(message) {
        // Remove any existing error message
        this._removeErrorMessage();

        // Create error message element
        const errorContainer = document.createElement('div');
        errorContainer.textContent = message;
        errorContainer.style.color = 'red'; // Example styling
        errorContainer.style.marginBottom = '10px'; // Example styling
        errorContainer.classList.add('ck-error-message');

        const errorDiv = this.dialogView.element.contentDocument.getElementById('showdocumenterror');
        if (errorDiv) {
            errorDiv.appendChild(errorContainer);
            errorDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    _removeErrorMessage() {
        const existingError = this.dialogView.element.contentDocument.querySelector('.ck-error-message');
        if (existingError) {
            existingError.remove();
        }
    }

    _enableDocumentDialogActivators() {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        this.listenTo(viewDocument, 'click', (evt, data) => {
            const targetElement = data.target;
            if (targetElement.hasClass && targetElement.hasClass('docCenter')) {
                console.log("docCenter");
                //this._showUI();
            }
        });
    }

}