import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function ClickOutsideWrapper({ children, onClose }) {
	const wrapperRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	return <div ref={wrapperRef}>{children}</div>;
}

ClickOutsideWrapper.propTypes = {
	children: PropTypes.any,
	onClose: PropTypes.any,
};
