import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';


const initialState = {
    isBonusLoaded: false,
    quarterlyBonus: [],
    bonusList: [],
    ytdBonusList: [],
    isYtdListLoaded: false
}

const bonusLevelsSlice = createSlice({
    name: 'bonusLevels',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(actions.GetQuarterlyBonus.pending, (state) => {
            state.isBonusLoaded = false;
        })
        builder.addCase(actions.GetQuarterlyBonus.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.quarterlyBonus = action.payload.data;
            } else {
                state.quarterlyBonus = [];
            }
            state.isBonusLoaded = true;
        })
        builder.addCase(actions.GetQuarterlyBonus.rejected, (state) => {
            state.isBonusLoaded = false;
        });


        builder.addCase(actions.getBonusLevels.pending, (state) => {
            state.isBonusLoaded = false;
        })
        builder.addCase(actions.getBonusLevels.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.bonusList = action.payload.data;
            } else {
                state.bonusList = [];
            }
            state.isBonusLoaded = true;
        })
        builder.addCase(actions.getBonusLevels.rejected, (state) => {
            state.isBonusLoaded = false;
        });

        //YTD Bonus List
        builder.addCase(actions.GetYTDBonus.pending, (state) => {
            state.isYtdListLoaded = false;
        })
        builder.addCase(actions.GetYTDBonus.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.ytdBonusList = action.payload.data;
            } else {
                state.ytdBonusList = [];
            }
            state.isYtdListLoaded = true;
        })
        builder.addCase(actions.GetYTDBonus.rejected, (state) => {
            state.isYtdListLoaded = false;
        });

    }
});

export default bonusLevelsSlice.reducer;