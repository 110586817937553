import { createAsyncThunk } from '@reduxjs/toolkit';
import * as skillsApi from '../../api/skill';

//export const getSkillsList = createAsyncThunk('getSkillsList', async (data) => {
//  const res = await skillsApi.getSkillsList(data);
//    return res.data;
//});
export const getSkillsList = createAsyncThunk('getSkillsList', async ({ searchByName=null, companyId=0, config }) => {
    const res = await skillsApi.getSkillsList(searchByName, companyId, config);
    return res.data;
});
export const getSkillsListV2 = createAsyncThunk('getSkillsListV2', async ({ searchByName = null, companyId = 0, config }) => {
    const res = await skillsApi.getSkillsListV2(searchByName, companyId, config);
    return res.data;
});

export const getAllEmployeeEdit = createAsyncThunk('getAllEmployeeEdit', async ({ companyId = 0, config }) => {
    const res = await skillsApi.getAllEmployeeEdit(companyId, config);
    return res.data;
});

export const saveSkill = createAsyncThunk('saveSkill', async (data) => {
  const res = await skillsApi.saveSkill({ data: data });
    return res.data;
});

export const editSkill = createAsyncThunk('editSkill', async (data) => {
  const res = await skillsApi.editSkill({ data: data });
    return res.data;
});

export const deleteSkill = createAsyncThunk('deleteSkill', async (data) => {
  const res = await skillsApi.deleteSkill({ data: data });
  return res.data;
});



export const getSkillAndCompanyData = createAsyncThunk('getSkillAndCompanyData', async (data) => {
    const res = await skillsApi.getSkillAndCompanyData({ data: data });
    return res.data;
});
export const getSkillAndCompanyDataV2 = createAsyncThunk('getSkillAndCompanyDataV2', async (data) => {
    const res = await skillsApi.getSkillAndCompanyDataV2({ data: data });
    return res.data;
});


export const updateSkillOrder = createAsyncThunk('updateSkillOrder', async (data) => {
    const res = await skillsApi.updateSkillOrder({
        options: {
            params: { skillId: data.skillId, companyId: data.companyId, departmentId: data.toDepartmentId},
        },
    });
    return res.data;
});


export const getSkillsOnLoad = createAsyncThunk('getSkillsOnLoad', async (data) => {
    const res = await skillsApi.getAllEmployeeEdit(data);
    return res.data;
});