import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export function SelectModuleModal({
	modalTitle,
	show,
	onClose,
	onSave,
	children,
	adminModalClass
}) {
	const inputRef = useRef(null);

	const handleModalClose = (e) => {
		if (onClose) onClose(e);
	};
	const handleSave = () => {
		if (onSave) onSave();
	};


	useEffect(() => {
		if(show) {
			const handleEnterKey = (e) => {
				if(e.key === 'Enter' || e.keyCode === 13) {
					setTimeout(() => {
						handleSave();
					}, 50); 
				}
			};
			if (inputRef.current) {
				inputRef.current.focus();
			}
			window.addEventListener('keydown', handleEnterKey);
			return () => {
				window.removeEventListener('keydown', handleEnterKey);
			};
		}
	}, [show, onSave]);


	if (!show) return null;


	return (
		<div className="admin-modal-overlay module-type-modal">
			<div className={"admin-modal admin-add-role wide " + adminModalClass}>
				<div className="admin-modal-scroll">
				<button className="admin-modal-close" onClick={handleModalClose}>				
						<span className="material-symbols-rounded">close</span>
				</button>
				<h2 className="admin-modal-heading">{modalTitle}</h2>
				{children}
				</div>
			</div>
		</div>
	);
}

SelectModuleModal.propTypes = {
	modalTitle: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	children: PropTypes.node,	
	adminModalClass: PropTypes.string
	
};