import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLoginUserDetails } from '../../api/adminuserapi';
import CWPLogo from "../../assets/favicon.ico";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading, delayedHideLoading } from '../../store/loading-reducer';
import AdminEditLink from './adminEditLink';
function Drawer() {
    const dispatch = useDispatch();
    //const [filter] = useState({ start: 1, length: 999, search: '', status: -1, sort: 0 });
    const [loginUser, setLoginUser] = useState(null);

    console.log("loginUser", loginUser);
    const { displayName } = useSelector((state) => state.auth);
    const location = useLocation();
    const match = location.pathname.match(/\/admin\/products\/\d+\/edit\/(\d+)$/);
    const productId = match ? match[1] : null;
    const [isCollapseActive, setIsCollapseActive] = useState(false);
    const modalRef = useRef(null);
    const toggleActiveClass = () => {
        setIsCollapseActive(!isCollapseActive);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setIsCollapseActive(false);
        }
    };

    useEffect(() => {
        dispatch(showLoading());
    }, []);

    useEffect(() => {
        dispatch(showLoading());
        getLoginUserDetails()
            .then((response) => {
                setLoginUser(response?.data);
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });
    }, []);

    // To do later for access permission
	//const adminClaim = loginUser?.data?.userClaims;
	//var isAdminUsersAccess = adminClaim?.filter(x => x.key === 'adminusers')?.length > 0;

    return (
        <nav className={`admin-drawer-wrap ${!isCollapseActive ? 'active' : ''}`} ref={modalRef} onClick={handleClickOutside}>
            {productId ? (
                <a
                    className="admin-drawer-view-page"
                    href={`/products/${productId}/preview`}
                >
                    View Page
                </a>
            ) : (
                ''
            )}
            <AdminEditLink />
            <div className="admin-drawer-info-wrap">
                <span className="collapse-menu" onClick={toggleActiveClass}>
                    {!isCollapseActive ? (
                        <span className="material-symbols-rounded">
                            menu
                        </span>
                    ) : null}
                    {isCollapseActive ? (
                        <span className="material-symbols-rounded">
                            close
                        </span>
                    ) : null}
                </span>          
                <a className="admin-drawer-info-image decoration" href="/siteadmin">
                    <img src={CWPLogo} alt="CWP Employee Portal" />
                </a>
                <a className="admin-drawer-info-title decoration" href="/siteadmin">CWP Employee Portal</a>
                <a className="admin-view-dash-btn" href="/" rel="noreferrer" target="_blank">
                    View Dashboard
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.53814 11.6667C1.20139 11.6667 0.916341 11.55 0.683008 11.3167C0.449674 11.0833 0.333008 10.7983 0.333008 10.4615V1.53851C0.333008 1.20175 0.449674 0.916707 0.683008 0.683374C0.916341 0.450041 1.20139 0.333374 1.53814 0.333374H5.24324C5.38512 0.333374 5.50392 0.381241 5.59964 0.476975C5.69536 0.572697 5.74323 0.691496 5.74323 0.833374C5.74323 0.975252 5.69536 1.09405 5.59964 1.18977C5.50392 1.2855 5.38512 1.33336 5.24324 1.33336H1.53814C1.48685 1.33336 1.43984 1.35472 1.39709 1.39746C1.35436 1.4402 1.33299 1.48722 1.33299 1.53851V10.4615C1.33299 10.5128 1.35436 10.5598 1.39709 10.6026C1.43984 10.6453 1.48685 10.6667 1.53814 10.6667H10.4612C10.5125 10.6667 10.5595 10.6453 10.6022 10.6026C10.645 10.5598 10.6663 10.5128 10.6663 10.4615V6.75644C10.6663 6.61456 10.7142 6.49576 10.8099 6.40004C10.9056 6.30432 11.0244 6.25646 11.1663 6.25646C11.3082 6.25646 11.427 6.30432 11.5227 6.40004C11.6184 6.49576 11.6663 6.61456 11.6663 6.75644V10.4615C11.6663 10.7983 11.5496 11.0833 11.3163 11.3167C11.083 11.55 10.7979 11.6667 10.4612 11.6667H1.53814ZM10.6663 2.03591L4.83043 7.87181C4.73811 7.96412 4.62209 8.01134 4.48236 8.01348C4.34261 8.01561 4.22445 7.96839 4.12787 7.87181C4.0313 7.77523 3.98301 7.65814 3.98301 7.52054C3.98301 7.38293 4.0313 7.26584 4.12787 7.16926L9.96377 1.33336H7.83298C7.6911 1.33336 7.5723 1.2855 7.47657 1.18977C7.38085 1.09405 7.33299 0.975252 7.33299 0.833374C7.33299 0.691496 7.38085 0.572697 7.47657 0.476975C7.5723 0.381241 7.6911 0.333374 7.83298 0.333374H11.6663V4.16671C11.6663 4.30859 11.6184 4.42739 11.5227 4.52311C11.427 4.61883 11.3082 4.66669 11.1663 4.66669C11.0244 4.66669 10.9056 4.61883 10.8099 4.52311C10.7142 4.42739 10.6663 4.30859 10.6663 4.16671V2.03591Z" fill="currentColor" />
                    </svg>
                </a>
                <p className="admin-drawer-info-user">{(displayName == null || displayName=='' ) ? "Full Name" : displayName}</p>
            </div>
            <div className="admin-drawer-link-wrap">
                <a className="admin-drawer-link" href="/siteadmin/events">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        calendar_today
                    </span>
                    <span className="admin-drawer-link-text">Events</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/news">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        newsmode
                    </span>
                    <span className="admin-drawer-link-text">Happenings</span>
                </a>
                <a className="admin-drawer-link" href="/siteadmin/hrbenefits">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        verified
                    </span>
                    <span className="admin-drawer-link-text">HR & Benefits</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/employee">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        group
                    </span>
                    <span className="admin-drawer-link-text">Employees</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/departments">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        news
                    </span>
                    <span className="admin-drawer-link-text">Departments</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/skills">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded material-symbols-rounded-outline"
                        aria-hidden="true"
                    >
                        work
                    </span>
                    <span className="admin-drawer-link-text">Positions & Qualifications</span>
                </a>
                <a className="admin-drawer-link" href="/siteadmin/interests">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded material-symbols-rounded-outline"
                        aria-hidden="true"
                    >
                        interests
                    </span>
                    <span className="admin-drawer-link-text">Interests</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/projects">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        perm_media
                    </span>
                    <span className="admin-drawer-link-text">Installed Projects</span>
                </a>
                <a className="admin-drawer-link" href="/siteadmin/financials">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        local_atm
                    </span>
                    <span className="admin-drawer-link-text">Financials</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/textalerts">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded material-symbols-rounded-outline"
                        aria-hidden="true"
                    >
                        notifications
                    </span>                    
                    <span className="admin-drawer-link-text">Text Alerts</span>
                </a>

                <a className="admin-drawer-link" href="/siteadmin/document-library">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded material-symbols-rounded-outline"
                        aria-hidden="true"
                    >
                        folder
                    </span>
                    <span className="admin-drawer-link-text">Document Library</span>
                </a>
                <a className="admin-drawer-link" href="/siteadmin/ggob-resources">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded material-symbols-rounded-outline"
                        aria-hidden="true"
                    >
                        info
                    </span>

                    <span className="admin-drawer-link-text">GGOB Resources</span>
                </a>

                
                <a className="admin-drawer-link" href="/siteadmin/logout">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        logout
                    </span>
                    <span className="admin-drawer-link-text">Logout</span>
                </a>
            </div>
        </nav>
    );
}

export default Drawer;
