export const eventItem = {
    id: 0,
    title: '',
    publishDate: new Date(),
    hrEvent: false,
    eventTypeId: 0,
    content: '',
    eventLocation: '',
    baseId: 0,
    eventCompanies: [],
    eventLog: [],
    media: [],
    rsvpType: 'no',
    rsvpTotalCount: 0,
    isActive: false,
    activeDate: new Date()
}      