import React, { useState, useEffect } from "react";
import './hrbenefits.css';
import Navigation from '../../components/navigation/Navigation';
import Accordion from '../../components/accordion/accordion';
import NewsTiles from '../../components/news-tiles/news-tiles';
import HrEvents from '../../components/hrevents-tiles/hrevents-tiles';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanies } from '../../store/employee/actions';
import { getallhrevents } from '../../store/events/actions';
import { getNewsList } from '../../store/news/actions';
import { getJobPostingsByFilter, getBenefitsByFilter, getjobpostingbyid, getbenefitbyid } from '../../store/hrbenefits/actions';
import { getLoggedInEmployeeById } from '../../store/employee/actions';
import HeaderProfile from "../../components/HeaderProfile";
import { GetTimeZone } from "../../components/common/DateTimeFormatter";
import { useNavigate } from 'react-router-dom';
import { getEmployeesCompanyList } from '../../api/events';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PortalAlert from '../../components/portalAlert/portalAlert';
import Modules from '../../components/Module/Modules';

export default function HrBenefits() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { siteUserId, primaryCompanyId } = useSelector(state => state.auth);
    const [activePanel, setActivePanel] = useState(null);
    const [benfitsactivePanel, setbenfitsActivePanel] = useState(null);
    const { companiesList } = useSelector(state => state.employee);
    const initialNewsFilter = primaryCompanyId !== undefined && primaryCompanyId !== 0 ? primaryCompanyId : '0';
    const [newsFilter, setNewsFilter] = useState(initialNewsFilter);
    const [filter, setFilter] = useState({ query: '', companyId: initialNewsFilter, sort: 6, IsShowPastEvent: false, userId: siteUserId, timezone: GetTimeZone() });
    const { allhrevents } = useSelector(state => state.event);
    const { newsData } = useSelector(state => state.news);
    const { jobPostingsListByfilter } = useSelector(state => state.hrBenefits);
    const { benefitsListByfilter } = useSelector(state => state.hrBenefits);
    const [employeeCompany, setEmployeeCompany] = useState([]);

    const [isJobPostings, setIsJobPostings] = useState(false);
    const [isBenefits, setIsBenefits] = useState(false);
    const [isHrEvents, setIsHrEvents] = useState(false);
    const [isNews, setIsNews] = useState(false);
    const [jobpanelDescription, SetjobpanelDescription] = useState(null);
    const [benefitpanelDescription, SetbenefitpanelDescription] = useState(null);

    const [isJobPostingLoading, setIsJobPostingLoading] = useState(false);
    const [isBenefitLoading, setIsBenefitLoading] = useState(false);

    const handlePanelToggle = async (panel, isOpen) => {
        setActivePanel(prevActivePanel => {
            return prevActivePanel === panel.title ? null : panel.title;
        });
        if (isOpen) {
            setIsJobPostingLoading(true);
            const result = await dispatch(getjobpostingbyid(panel.id)).unwrap();
            if (result?.data) {
                SetjobpanelDescription(result?.data?.description)
            }
            setIsJobPostingLoading(false);
        }
        else {
            SetjobpanelDescription(null);
        }

    };


    const benfitsPanelToggle = async (panel, isOpen) => {
        setbenfitsActivePanel(prevActivePanel => {
            return prevActivePanel === panel.title ? null : panel.title;
        });
        if (isOpen) {
            setIsBenefitLoading(true);
            const result = await dispatch(getbenefitbyid(panel.id)).unwrap();
            if (result?.data) {
                SetbenefitpanelDescription(result?.data?.description)
            }
            setIsBenefitLoading(false);
        }
        else {
            SetbenefitpanelDescription(null);
        }
    };

    useEffect(() => {
        document.title = 'HR & Benefits | CWP Team Connect'
        if (primaryCompanyId !== undefined && primaryCompanyId !== 0) {
            setNewsFilter(primaryCompanyId.toString());
            setFilter({ ...filter, companyId: primaryCompanyId });
        }
    }, [primaryCompanyId]);

    useEffect(() => {
        dispatch(showLoading());
        dispatch(getCompanies());
        dispatch(getLoggedInEmployeeById({ id: siteUserId }));
        getEmployeesCompanyList(siteUserId).then((response) => {
            let employeeCompanyResponse = response.data.data;
            setEmployeeCompany(employeeCompanyResponse);
        });
    }, []);

    useEffect(() => {
        dispatch(getJobPostingsByFilter(filter)).unwrap().then(() => {
            setIsJobPostings(true);
        });
        dispatch(getBenefitsByFilter(filter)).unwrap().then(() => {
            setIsBenefits(true);
        });
        dispatch(getallhrevents(filter)).unwrap().then(() => {
            setIsHrEvents(true);
        });

        dispatch(getNewsList(filter)).unwrap().then(() => {
            setIsNews(true);
        });
    }, [filter]);


    useEffect(() => {
        if (isJobPostings === true && isBenefits === true && isHrEvents === true && isNews === true) {
            dispatch(hideLoading());
        }
    }, [isJobPostings, isBenefits, isHrEvents, isNews])

    const isValidJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    return (
        <div className="directory-wrap hrevents-wrap hr-benefits-wrapper main-hrbenefits-index">
            <div className="drawer-menu-collapse-wrap"></div>
            <Navigation />
            <div className="events-page-wrap">
                <div className="page-inner events-inner hrbenefits-inner">
                    <div className="profile-wrap-outer mobile-show">
                        <HeaderProfile />
                    </div>


                    <div className="events-content-scrollable-wrap">
                        <div className="inner-pages-alert todays-alert">
                            <PortalAlert />
                        </div>
                        <div className="profile-wrap-outer">
                            <HeaderProfile />
                        </div>
                        <div className="events-header-wrap hrbenefits-header-wrap">
                            <div className="events-header-wrap-inner">
                                <h1 className="page-title">HR & Benefits</h1>
                                <div className="filter-bars">
                                    <label>
                                        <select className="menu-wrap org-dropdown" name="slct" id="slct" value={newsFilter} onChange={(event) => {
                                            setNewsFilter(event.target.value);
                                            const selectedCompanyId = parseInt(event.target.value);
                                            setFilter({ ...filter, companyId: selectedCompanyId });
                                        }}>
                                            <option value="0">All</option>
                                            {companiesList.map(company => (
                                                <option key={company.id} value={company.id}>{company.abbreviatedName}</option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="events-content-wrap hrbenefits-content-wrap">
                            <div className="events-content-wrap-inner">
                                {newsData && newsData.filter((event => event.hrNews)).length > 0 && (
                                    <div className="sticky-content news-sticky-content">
                                        <p className="sticky-content-title">Happenings</p>
                                        <div className="events-span-date">
                                            <p onClick={() =>
                                                navigate('/news-tiles')
                                            }>View All</p>
                                        </div>
                                    </div>
                                )}
                                {newsData.filter(news => news.hrNews).slice(0, 3).map(news => (
                                    <div className="events-content-wrap-inner" key={news.id}>
                                        <NewsTiles newsItem={news} isHrBenefits={true} />
                                    </div>
                                ))}
                            </div>
                            {/*HR Events & Deadlines*/}
                            {allhrevents && allhrevents.length > 0 && (
                                <div className="sticky-content news-sticky-content">
                                    <p className="sticky-content-title">Events & Deadlines</p>
                                    <div className="events-span-date">
                                        <span onClick={() =>
                                            navigate('/events')
                                        }>View All</span>
                                    </div>

                                </div>
                            )}
                            {allhrevents && allhrevents.slice(0, 3).map(event => (
                                <div className="events-content-wrap-inner hrevents" key={event.id}>
                                    <div className="events-listing-wrap hrevents-listing-wrap">
                                        <HrEvents eventsItem={event} siteUserId={siteUserId} employeeCompany={employeeCompany} />
                                    </div>
                                </div>
                            ))}
                            {/*Job Postings */}
                            <div className="events-content-wrap-inner job-postings">
                                {jobPostingsListByfilter && jobPostingsListByfilter.length > 0 && (
                                    <div className="sticky-content news-sticky-content">
                                        <p className="sticky-content-title">Job Postings {jobPostingsListByfilter && jobPostingsListByfilter.length > 0 ? `(${jobPostingsListByfilter.length})` : ''}</p>
                                        <div className="events-span-date">
                                            <p onClick={() =>
                                                navigate('/hr-benefits/job-postings')
                                            }>View All</p>
                                        </div>
                                    </div>
                                )}
                                <div className="accordion-outer-wrap hrbenefits-listing-wrap">
                                    {jobPostingsListByfilter.map(panel => (
                                        <Accordion
                                            key={panel.title}
                                            title={panel.title}
                                            isActive={activePanel === panel.title}
                                            onToggle={() => handlePanelToggle(panel, activePanel === panel.title ? false : true)}
                                            hideToggle={panel.description == '' ? true : false}
                                        >
                                            {isJobPostingLoading && activePanel === panel.title ? (
                                                <div className="loading-spinner"></div> // Replace with a spinner component or CSS loader
                                            ) : (
                                                <Modules
                                                    moduleData={
                                                        jobpanelDescription != null && isValidJSON(jobpanelDescription)
                                                            ? JSON.parse(jobpanelDescription)
                                                            : []
                                                    }
                                                />
                                            )}
                                        </Accordion>
                                    ))}
                                </div>
                            </div>

                            {/*Benefits & Policies*/}
                            <div className="events-content-wrap-inner">
                                {benefitsListByfilter && benefitsListByfilter.length > 0 && (
                                    <div className="sticky-content news-sticky-content">
                                        <p className="sticky-content-title">Benefits & Policies</p>
                                        <div className="events-span-date">
                                            <p onClick={() =>
                                                navigate('/hr-benefits/policies')
                                            }>View All</p>
                                        </div>
                                    </div>
                                )}
                                <div className="accordion-outer-wrap hrbenefits-listing-wrap">
                                    {benefitsListByfilter.map(panel => (
                                        <Accordion
                                            key={panel.title}
                                            title={panel.title}
                                            isActive={benfitsactivePanel === panel.title}
                                            onToggle={() => benfitsPanelToggle(panel, benfitsactivePanel === panel.title ? false : true)}
                                            hideToggle={panel.description == '' ? true : false}
                                        >
                                            {isBenefitLoading && benfitsactivePanel === panel.title ? (
                                                <div className="loading-spinner"></div> // Replace with a spinner component or CSS loader
                                            ) : (
                                                <Modules
                                                    moduleData={
                                                        benefitpanelDescription != null && isValidJSON(benefitpanelDescription)
                                                            ? JSON.parse(benefitpanelDescription)
                                                            : []
                                                    }
                                                />
                                            )}
                                        </Accordion>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}