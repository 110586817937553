import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';


const initialState = {
    isScoreboardLoaded: false,
    scoreboards: [],
    selectedCompanyId:0
}

const financialScoreboardSlice = createSlice({
    name: 'financialscoreboard',
    initialState,
    reducers: {
        selectedScoreboardCompany(state, action) {
            state.selectedCompanyId = action.payload && action.payload?.companyId != 0 ? action.payload?.companyId : 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actions.getFinancialScoreboard.pending, (state) => {
            state.isScoreboardLoaded = false;
        })
        builder.addCase(actions.getFinancialScoreboard.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.scoreboards = action.payload.data;
            } else {
                state.scoreboards = [];
            }
            state.isScoreboardLoaded = true;
        })
        builder.addCase(actions.getFinancialScoreboard.rejected, (state) => {
            state.isScoreboardLoaded = false;
        });
    }
});


export const { selectedScoreboardCompany } = financialScoreboardSlice.actions

export default financialScoreboardSlice.reducer;