import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BenefitContainer } from './benefitContainer';
import { BenefitAddEditModal } from './benefitAddEditModal';
import { BenefitDeleteConfrimationModal } from './benefitDeleteConfrimationModal';
import { addEditBenefit, deleteBenefit, getbenefitbyid } from '../../../store/hrbenefits/actions';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
 
export default function Benefits() {
    const dispatch = useDispatch();

    const defaultPostingObj = { id: 0, title: '', description: null, benefitCompanies:[] }
    const { benefitsList, isBenefitsSaving } = useSelector(state => state.hrBenefits); 
    const [showAddModal, setShowAddModal] = useState(false);
    const [benefitModel, setBenefitModel] = useState(defaultPostingObj);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (benefit) => {
        setBenefitModel(benefit);
        setShowDeleteModal(true)
    };
    useEffect(() => {
        document.title = 'Benefits & Policies | HR & Benefits | CWP Employee Portal';
    },[]);
    const handleEdit = async (benefit) => {
        const result = await dispatch(getbenefitbyid(benefit.id)).unwrap();
        if (result?.data) {
            setBenefitModel(result?.data)
        }
        //setBenefitModel(benefit);
        setShowAddModal(true);
    };

    const handleAddBenefit = () => {
        setBenefitModel(defaultPostingObj);
        setShowAddModal(true);
    }

    const handleAddEditBenefit = (benefit) => {
       dispatch(addEditBenefit(benefit)); 
    }

    const handleDeleteBenefit = (benefit) => {
        if (benefit.id > 0) {
            dispatch(deleteBenefit(benefit.id));
        }
    }

    useEffect(() => {
        if (isBenefitsSaving) {
            dispatch(showLoading());
        } else {
            dispatch(hideLoading());
        }
    }, [isBenefitsSaving]);

    return (
        <div className="admin-tab-content" id="benefits-tab" role="tabpanel" aria-labelledby="tab2">
            <div className="admin-section">
                {showAddModal && (
                    <BenefitAddEditModal
                        show={showAddModal}
                        onClose={() => {
                            setBenefitModel(defaultPostingObj);
                            setShowAddModal(false);
                        }}
                        handleAddEditBenefit={handleAddEditBenefit}
                        modelBenefit={benefitModel}
                    />
                )}
                
                <BenefitDeleteConfrimationModal
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    handleDeleteBenefit={handleDeleteBenefit}
                    modelBenefit={benefitModel}
                />
                <div className="admin-section-title-bar benefits-section">
                    <div className="admin-section-title-action-wrap">
                        <h2 className="admin-section-headline">Benefits & Policies</h2>
                        <button className="admin-button" onClick={() => handleAddBenefit() }>
                            <span className="admin-button-text">Add Benefit/Policy</span>
                            <span className="material-symbols-rounded" aria-hidden="true">add</span>
                        </button>
                    </div>
                </div>
                <BenefitContainer
                    benefits={benefitsList}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </div>
        </div>
    );
}