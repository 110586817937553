import React from 'react'; // Ensure this import is present

const badgeImages = {
    5: "../badges/5-year.svg",
    10: "../badges/10-year.svg",
    15: "../badges/15-year.svg",
    20: "../badges/20-year.svg",
    25: "../badges/25-year.svg",
    30: "../badges/30-year.svg",
    35: "../badges/35-year.svg",
    40: "../badges/40-year.svg",
    45: "../badges/45-year.svg",
    50: "../badges/50-year.svg"
};

export const getBadges = (years) => {
    const badgeSrc =
       years >= 50 ? badgeImages[50] :
       years >= 45 ? badgeImages[45] :
       years >= 40 ? badgeImages[40] :
       years >= 35 ? badgeImages[35] :
       years >= 30 ? badgeImages[30] :
       years >= 25 ? badgeImages[25] :
       years >= 20 ? badgeImages[20] :
       years >= 15 ? badgeImages[15] :
       years >= 10 ? badgeImages[10] :
       years >= 5 ? badgeImages[5] :
       null; 

    return badgeSrc ? <img src={badgeSrc} alt="Badge" /> : null; // Return null if no badgeSrc
};
