import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isEventsLoaded: false,
    eventsList: [],
    typeEventList: [],
    isTypeEventLoaded: false,
    isEventsRSVPLoaded: false,
    getEventsRSVPs:[], 
    isPastEventsLoaded: false,
    pastEventsList: [],
    isgetEventByIdEditoaded: false,
    getEventByIdEdit: [],
    todayEventNotifications: [],
    isNotificationLoaded: false,
    calendarEvents: [],
    upcomingEvents: [],
    getallhrevents: [],
    scrollId: ''
}

const eventsSlice = createSlice({
    name: 'events', 
    initialState,
    reducers: {
        setScrollId(state, action) {     
            state.scrollId = action.payload.scrollId;
        }
    },
    extraReducers: (builder) => { 
        // Get Events
        builder.addCase(actions.getEvents.pending, (state) => {
            state.isEventsLoaded = false;
        })
        builder.addCase(actions.getEvents.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.eventsList = action.payload.data;
            } else {
                state.eventsList = [];
            }
            state.isEventsLoaded = true;
        })
        builder.addCase(actions.getEvents.rejected, (state) => {
            state.isEventsLoaded = false;
        }); 


        // Get Past Events
        builder.addCase(actions.getPastEvents.pending, (state) => {
            state.isPastEventsLoaded = false;
        })

        builder.addCase(actions.getPastEvents.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.pastEventsList = action.payload.data;
            } else {
                state.pastEventsList = [];
            }
            state.isPastEventsLoaded = true;
        })
        builder.addCase(actions.getPastEvents.rejected, (state) => {
            state.isPastEventsLoaded = false;
        }); 


        // Get Events types
        builder.addCase(actions.getTypeEvent.pending, (state) => {
            state.isTypeEventLoaded = false;
        })
        builder.addCase(actions.getTypeEvent.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.typeEventList = action.payload.data;
            } else {
                state.typeEventList = [];
            }
            state.isTypeEventLoaded = true;
        })
        builder.addCase(actions.getTypeEvent.rejected, (state) => {
            state.isTypeEventLoaded = false;
        }); 
        //Delete Event 
        builder.addCase(actions.deleteEvent.pending, (state) => {
            state.saving = true;
        })
        builder.addCase(actions.deleteEvent.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.eventsList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.eventsList.splice(enityIndex, 1);
                }


                const pastEnityIndex = state.pastEventsList.findIndex(x => x.id === id);
                if (pastEnityIndex > -1) {
                    state.pastEventsList.splice(pastEnityIndex, 1);
                }

            }
            state.isSaving = false;
        })
        builder.addCase(actions.deleteEvent.rejected, (state) => {
            state.isSaving = false;
        });


        // Get EventsRSVPs
        builder.addCase(actions.getEventsRSVPs.pending, (state) => {
            state.isEventsRSVPLoaded = false;
        })
        builder.addCase(actions.getEventsRSVPs.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.getEventsRSVPs = action.payload.data;
            } else {
                state.getEventsRSVPs = [];
            }
            state.isEventsRSVPLoaded = true;
        })
        builder.addCase(actions.getEventsRSVPs.rejected, (state) => {
            state.isEventsRSVPLoaded = false;
        }); 

        //GetEventsById
        builder.addCase(actions.getEventByIdEdit.pending, (state) => {
            state.isgetEventByIdEditoaded = false;
        })
        builder.addCase(actions.getEventByIdEdit.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.getEventByIdEdit = action.payload.data;
            } else {
                state.getEventByIdEdit = [];
            }
            state.isgetEventByIdEditoaded = true;
        })
        builder.addCase(actions.getEventByIdEdit.rejected, (state) => {
            state.isgetEventByIdEditoaded = false;
        }); 

        builder.addCase(actions.getTodayEvents.pending, (state) => {
            state.isNotificationLoaded = false;
        })
        builder.addCase(actions.getTodayEvents.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.todayEventNotifications = action.payload.data;
            } else {
                state.todayEventNotifications = [];
            }
            state.isNotificationLoaded = true;
        })
        builder.addCase(actions.getTodayEvents.rejected, (state) => {
            state.isNotificationLoaded = false;
        });

        builder.addCase(actions.getCalendarEvents.pending, (state) => {
            state.isNotificationLoaded = false;
        })
        builder.addCase(actions.getCalendarEvents.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.calendarEvents = action.payload.data;
            } else {
                state.calendarEvents = [];
            }
            state.isNotificationLoaded = true;
        })
        builder.addCase(actions.getCalendarEvents.rejected, (state) => {
            state.isNotificationLoaded = false;
        });

        builder.addCase(actions.mostRecentUpcomingEvents.pending, (state) => {
            state.isNotificationLoaded = false;
        })
        builder.addCase(actions.mostRecentUpcomingEvents.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.upcomingEvents = action.payload.data;
            } else {
                state.upcomingEvents = [];
            }
            state.isNotificationLoaded = true;
        })
        builder.addCase(actions.mostRecentUpcomingEvents.rejected, (state) => {
            state.isNotificationLoaded = false;
        });


        builder.addCase(actions.getallhrevents.pending, (state) => {
            state.isNotificationLoaded = false;
        })
        builder.addCase(actions.getallhrevents.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.allhrevents = action.payload.data;
            } else {
                state.allhrevents = [];
            }
            state.isNotificationLoaded = true;
        })
        builder.addCase(actions.getallhrevents.rejected, (state) => {
            state.isNotificationLoaded = false;
        });
         
    }
});

export const { setScrollId } = eventsSlice.actions

export default eventsSlice.reducer;