import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LoadingOverlay = styled.div`
  align-content: center;
  align-items: center;
  backdrop-filter: blur(.5em);
  background: rgba(255, 255, 255, .7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  [data-filter="true"] & {
    left: 20em;
    width: calc(100% - 20em);
  }
`;
const LoadingSpinnerStyled = styled.div`
  animation: loading-spinner 1s linear infinite;
  border: 5px solid #005288;
  border-radius: 50%;
  border-top-color: transparent;
  display: flex;
  height: 2.6em;
  position: absolute;
  width: 2.6em;
  justify-content:center;
  align-items:center;
  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const PageLoadingSpinner = ({ show }) => {
    if (show)
        return (
            <LoadingOverlay>
                <LoadingSpinnerStyled />
            </LoadingOverlay>
        );
    else return null;
};

PageLoadingSpinner.propTypes = {
    show: PropTypes.bool.isRequired
}
export default PageLoadingSpinner;
