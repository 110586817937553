import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import { siteUserLogin } from '../../store/auth/actions';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HideAccessErrorPopup } from '../../store/auth/index';

function FormValidationSummary({ display, messageList }) {
    if (display) {
        const listElements = messageList.map(message =>
            <li key={message}>{message}</li>
        );
        return (
            <div className="login-form-cell">
                <div className="validation-summary-valid" data-valmsg-summary="true">
                    <ul>
                        {listElements}
                    </ul>
                </div>
            </div>
        );
    }
    else return null;
}

FormValidationSummary.propTypes = {
    display: PropTypes.bool,
    messageList: PropTypes.array
}

export default function Login() {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [messages, setMessages] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const { isAuthenticated, token, refreshToken } = useSelector(state => state.auth);

  const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'Login | CWP Employee Portal';
        if (isAuthenticated && token != null && refreshToken != null) {
            navigate('/');
        }
    }, [isAuthenticated, token]);

  const login = bindActionCreators(siteUserLogin, dispatch);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessages([]);
    setDisplayError(false);

    let hasError = false;
    if (document.querySelector('.input-validation-error')) {
      document.querySelectorAll('.input-validation-error').forEach((element) => {
        element.classList.remove('input-validation-error');
      })
    }

    if (username === '' || username === null) {
      hasError = true;
      document.getElementById('login-email').classList.add('input-validation-error');
      setMessages(messages => [...messages, 'Please enter your username.']);
    }
    if (password === '' || password === null) {
      setMessages(messages => [...messages, 'Please enter your password.']);
      document.getElementById('login-password').classList.add('input-validation-error');
      hasError = true;
    }
    if (hasError) {
      setDisplayError(true);
      return;
    }

    let data = {
      "Username": username,
      "Password": password
    }

    setIsProcessing(true);
    const result = await login({ data: data }).unwrap();

    if (result.data) {
      setIsProcessing(false)
      if (result.data.success) {
        if (result.data.data.isAdmin) navigate('/siteadmin');
          else navigate("/");

          dispatch(HideAccessErrorPopup());
      }
      else {
        setMessages(messages => [...messages, result.data.message]);
        setDisplayError(true);
      }
    }
  }

  useEffect(() => {
    document.querySelectorAll('.fancy-field').forEach((element) => {
      if (element.value != '') {
        element.parentNode.classList.add('input-focused');
      }
      else if (element.parentNode.classList.contains('input-focused')) {
        element.parentNode.classList.remove('input-focused');
      }
    });
  }, [username, password]);    

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <FormValidationSummary
        display={displayError}
        messageList={messages} />
      <div className="login-form-row">
        <div className="login-form-cell">
          <div className="login-form-field fancy-parent">
            <label className="login-label" htmlFor="login-email">Email Address</label>
            <input className="login-input fancy-field"
              id="login-email"
              name="Username"
              onChange={(e) => setUserName(e.target.value)}
              value={username}
              type="text" />
          </div>
        </div>
      </div>
      <div className="login-form-row">
        <div className="login-form-cell">
          <div className="login-form-field fancy-parent">
            <label className="login-label" htmlFor="login-password">Password</label>
            <input className="login-input fancy-field"
              id="login-password"
              name="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password" />
          </div>
        </div>
      </div>
      <div className="login-form-row">
        <div className="login-form-cell">
          {isProcessing ?
            <button type="button" className="login-submit" onClick={() => { }}>Logging in...</button> :
            <button type="submit" className="login-submit">Login</button>
          }
        </div>
      </div>
    </form>
  )
}