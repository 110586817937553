import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
function AdminLinkTile({ badge, href, icon, name }) {
    const badgeValue = typeof badge === 'number' ? badge : 0;
    return (
        <Link className="admin-link-tile" to={href}>
            <span className={`admin-link-tile-icon material-symbols-rounded material-symbols-rounded-outline-${icon}`}>{icon}</span>
            <span className="admin-link-tile-name">{name}</span>
            {badgeValue > 0 ? <span className="admin-link-tile-badge admin-quotes-box">{badgeValue}</span> : ''}
        </Link>

    );
}

AdminLinkTile.propTypes = {
    badge: PropTypes.number,
    href: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
}

export default AdminLinkTile;