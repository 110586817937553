import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../../store/employee/actions';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { Container } from './container';
import { AddEditModal } from './addEditModal';
import { DeleteConfrimationModal } from './deleteConfrimationModal';
import { getGGOBResources, addEditGGOBResources, deleteGGOBResources, getggobbyid } from '../../../store/ggobResources/actions';
import { updateResourceTitle } from '../../../api/ggobResources';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";

export default function GGOBResources() {
    const dispatch = useDispatch();
    const defaultPostingObj = { id: 0, title: '', gGOBResourcesDetails: [] };
    const defaultGgobDetailObj = { id: 0, title: '', resourcesId: 0, description: null, ggobResourcesCompanies: [] }
    const [ggobModel, setGgobModel] = useState(defaultPostingObj);
    const { ggobResourcesList, isGGOBResourcesSaving } = useSelector(state => state.ggobResources);
    const [showAddModal, setShowAddModal] = useState(false);
    const [resourceModel, setResourceModel] = useState(defaultGgobDetailObj);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editLink, setEditLink] = useAdminMenuContext('');

    const handleDelete = (resource) => {
        setResourceModel(resource);
        setShowDeleteModal(true);
    };

    const handleEdit = async (resource) => {
        const result = await dispatch(getggobbyid(resource.id)).unwrap();
        if (result?.data) {
            setResourceModel(result?.data)
        }
        setShowAddModal(true);
    };

    const handleAddResource = () => {
        setResourceModel(defaultGgobDetailObj);
        setShowAddModal(true);
    };

    const handleAddEditResource = (resource) => {
        dispatch(addEditGGOBResources(resource));
    };

    const handleDeleteResource = (resource) => {
        if (resource.id > 0) {
            dispatch(deleteGGOBResources(resource.id));
        }
    };

    useEffect(() => {
        document.title = 'GGOB Resources | CWP Employee Portal';
        dispatch(getGGOBResources());
        dispatch(getCompanies());
        setEditLink('/ggobResources');
        console.log(editLink);
    }, [dispatch]);

    useEffect(() => {
        if (isGGOBResourcesSaving) {
            dispatch(showLoading());
        } else {
            dispatch(getGGOBResources());
            dispatch(hideLoading());
        }
    }, [isGGOBResourcesSaving]);

    useEffect(() => {
        if (ggobResourcesList && ggobResourcesList.length > 0) {
            setGgobModel(ggobResourcesList[0]);
        } else {
            setGgobModel(defaultPostingObj);
        }
    }, [ggobResourcesList]);


    const saveGGOBTitle = async () => {
        try {
            dispatch(showLoading());

            const response = await updateResourceTitle({ options: { data: ggobModel } });
            if (response.data.success) {
                dispatch(hideLoading());
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Error saving GGOB title:", error);
            alert('An error occurred while saving the title. Please try again.');
            dispatch(hideLoading());
        }
    };

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">GGOB Resources</h1>
                <div className="admin-modal-row">
                    <div className="admin-modal-cell">
                        <label className="admin-default-label" htmlFor="page-title">Page title</label>
                        <div className="row-flex gap-1">
                            <input
                                type="text"
                                id="page-title"
                                label="Page title"
                                name="pageTitle"
                                value={ggobModel?.title || ''}
                                onChange={(event) => {
                                    setGgobModel({ ...ggobModel, title: event.target.value });
                                }}
                            />
                            <button className="admin-button" onClick={(e) => { e.preventDefault(); saveGGOBTitle(); }}>Save</button>
                    </div>
                   </div>
                </div>

                <div className="admin-tab-content" id="benefits-tab" role="tabpanel" aria-labelledby="tab2">
                    <div className="admin-section">
                        {showAddModal && (
                            <AddEditModal
                                show={showAddModal}
                                onClose={() => {
                                    setResourceModel(defaultGgobDetailObj);
                                    setShowAddModal(false);
                                }}
                                handleAddEditResource={handleAddEditResource}
                                modelResource={resourceModel}
                            />
                        )}

                        <DeleteConfrimationModal
                            show={showDeleteModal}
                            onClose={() => setShowDeleteModal(false)}
                            handleDeleteResource={handleDeleteResource}
                            modelResource={resourceModel}
                        />
                        <div className="admin-section-title-bar benefits-section">
                            <div className="admin-section-title-action-wrap">
                               {/* <h2 className="admin-section-headline"></h2>*/}
                                <button className="admin-button" onClick={handleAddResource}>
                                    <span className="admin-button-text">Add Accordion</span>
                                    <span className="material-symbols-rounded" aria-hidden="true">add</span>
                                </button>
                            </div>
                        </div>
                        <Container
                            ggobResourcesList={ggobResourcesList && ggobResourcesList.length > 0 ? ggobResourcesList[0]?.ggobResourcesDetails : []}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
