import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import { userLogin } from '../../store/auth/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatPhoneNumberAsYouType } from '../../helpers/formatterHelper';
import { appRoutes } from '../../router/Approuter';
import { HideAccessErrorPopup } from '../../store/auth/index';


function FormValidationSummary({ display, messageList }) {
    if (display) {
        const listElements = messageList.map(message =>
            <li key={message}>{message}</li>
        );
        return (
            <div className="login-form-cell">
                <div className="validation-summary-valid" data-valmsg-summary="true">
                    <ul>
                        {listElements}
                    </ul>
                </div>
            </div>
        );
    }
    else return null;
}

FormValidationSummary.propTypes = {
    display: PropTypes.bool,
    messageList: PropTypes.array
}

export default function Login() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isVerifying, setIsVerifying] = useState(false);
    const [invalidVerificationCode, setInvalidVerificationCode] = useState(false);
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isReSendCode, setIsReSendCode] = useState(false);
    const [reSendCodeMsg, setReSendCodeMsg] = useState(false);
    const { isAuthenticated, token, refreshToken } = useSelector(state => state.auth);
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'Login | CWP Team Connect';

        if (isAuthenticated && token != null && refreshToken != null) {
            navigate('/');
        } else {
            let Is_my_personal_device_selected = localStorage.getItem('Is_my_personal_device_selected');
            if (Is_my_personal_device_selected == null || Is_my_personal_device_selected == undefined) {
                Is_my_personal_device_selected = false;
            }
            setIsChecked(stringToBool(Is_my_personal_device_selected));
            
        }
    }, [isAuthenticated, token]);

    const login = bindActionCreators(userLogin, dispatch);
   

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const returnUrl = queryParams.get('returnUrl');

    function stringToBool(str) {
        if (str != null && str != undefined)
            return str.toString().toLowerCase() === 'true';
    }

    const matchPath = (routePath, url) => {
        const regexPattern = new RegExp('^' + routePath.replace(/:\w+/g, '([^/]+)') + '$');
        return regexPattern.test(url);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessages([]);
        setDisplayError(false);
        setInvalidVerificationCode(false);
        setInvalidPhoneNumber(false);
        setReSendCodeMsg("");

        let hasError = false;
        if (document.querySelector('.input-validation-error')) {
            document.querySelectorAll('.input-validation-error').forEach((element) => {
                element.classList.remove('input-validation-error');
            })
        }

        if (phoneNumber === '' || phoneNumber === null) {
            hasError = true;
            document.getElementById('login-email').classList.add('input-validation-error');
            setErrorMessage('Please enter your phone number.');
        }
        if (hasError) {
            setDisplayError(true);
            return;
        }

        let data = {
            "PhoneNumber": phoneNumber,
            "VerificationCode": verificationCode
        }

        const result = await login({ data: data }).unwrap();
        if (result.data) {
            if (result.data.success) {
                localStorage.removeItem('monitor_mode');
                setIsVerifying(false)
                if (returnUrl != undefined && returnUrl != null) {
                    const isValidURL = appRoutes.some((route) => matchPath(route.path, '/' + returnUrl));
                    if (!isValidURL) {
                        navigate('/');
                    }
                    else {
                        window.history.pushState({ path: '/' }, '', '/');
                        navigate("/" + returnUrl);
                    }
                } 
                else
                    navigate("/");

                dispatch(HideAccessErrorPopup());
            }
            else {
                if (result.data.message === "Phone number does not exist in our system.") {
                    setInvalidPhoneNumber(true);
                    setIsVerifying(false)
                    return;
                }

                //Handle Verification code sent
                if (result.data.message === "Verification code sent.") {
                    if (isReSendCode) {
                        setReSendCodeMsg("Verification code sent.");               
                    }
                    setIsVerifying(true);
                    setIsReSendCode(false);
                    return;
                }

                //Handle Invalid code
                if (result.data.message === "Invalid verification code.") {
                    setInvalidVerificationCode(true);
                    return;
                }
                setErrorMessage(result.data.message);
                if (result?.data?.message) {
                    document.getElementById('login-email').classList.add('input-validation-error');
                }
                setDisplayError(true);     
            }
        }
    }

    const handleChoicePersonalDevice = (event) => {
        setIsChecked(event.target.checked);
        localStorage.setItem('Is_my_personal_device_selected', event.target.checked);
    };

    useEffect(() => {
        document.querySelectorAll('.fancy-field').forEach((element) => {
            if (element.value != '') {
                element.parentNode.classList.add('input-focused');
            }
            else if (element.parentNode.classList.contains('input-focused')) {
                element.parentNode.classList.remove('input-focused');
            }
        });
    }, [phoneNumber, verificationCode]);

    const checkScreenWidth = () => {
        const currentWidth = window.innerWidth;
        const isTabletSize = currentWidth > 900 && currentWidth < 1201 ? false : true;
        setIsChecked(isTabletSize);
        if (isTabletSize) {
            localStorage.setItem('Is_my_personal_device_selected', true);
        }
    };

    useEffect(() => {
        // Initial check of screen width on mount
        checkScreenWidth();

        // Add event listener for resize events
        window.addEventListener('resize', checkScreenWidth);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []); 

    return (
    
        <form className="login-form" onSubmit={handleSubmit}>
            
            <h1 className="login-form-headline">{isVerifying ? "Check Your Phone" : "Employee Portal"}</h1>
            {isVerifying &&
                <div className="login-instruction-wrap">
                    <p className="login-instruction">Code was sent to<br />&zwnj;{formatPhoneNumberAsYouType(phoneNumber)}</p>
                    <button className="login-reenter" onClick={(key) => { console.log("key is:", key); if (key.detail === 1) { setIsVerifying(false); setVerificationCode(""); } }} >Use different number</button>
                </div>
            }
            <FormValidationSummary
                display={displayError}
                messageList={messages} />
            {!isVerifying &&
                <div className={invalidPhoneNumber ? "login-form-row error" : "login-form-row"}>
                    <div className="login-form-cell">
                        <div className="login-form-field fancy-parent">
                            <label className="login-label" htmlFor="login-email">Phone Number</label>
                            <input className="login-input fancy-field"
                                id="login-email"
                                name="Username"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="(555) 555-5555"
                                value={formatPhoneNumberAsYouType(phoneNumber)}
                                type="tel" />
                        </div>
                        <div className="login-input-validation-message">Invalid Phone Number. Please try again.</div>
                        {errorMessage && (
                            <p >
                                <span className="validation-error-message">{errorMessage}</span>
                            </p>
                        )}
                    </div>
                </div>
            }
            {isVerifying &&
                <div className={invalidVerificationCode === true ? "login-form-row error" : "login-form-row"}>
                    <div className="login-form-cell">
                        <div className="login-form-field fancy-parent">
                            <label className="login-label" htmlFor="login-password">Verification Code</label>
                            <input className="login-input fancy-field"
                                id="login-password"
                                name="Password"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                type="number" />
                        </div>
                        <div className="login-input-validation-message">Invalid code. Please try again.</div>
                    </div>
                </div>
            }
            <div className="login-form-row">
                <div className="login-form-cell">
                    {isVerifying ?
                        <button type="submit" className="login-submit">Submit</button> :
                        <button type="submit" className="login-submit">Sign In</button>
                    }
                    <span className="checkbox-container">
                        <input type="checkbox" checked={isChecked} onChange={handleChoicePersonalDevice} />
                        <span className="checkmark"></span>
                        <span className="personal-device-label">This is my personal device <span className="personal-device-label-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" fill="#a4a4a4">
                            <g>
                                <path className="st0" d="M256,0C114.616,0,0,114.612,0,256s114.616,256,256,256s256-114.612,256-256S397.385,0,256,0z M207.678,378.794
		c0-17.612,14.281-31.893,31.893-31.893c17.599,0,31.88,14.281,31.88,31.893c0,17.595-14.281,31.884-31.88,31.884
		C221.959,410.678,207.678,396.389,207.678,378.794z M343.625,218.852c-3.596,9.793-8.802,18.289-14.695,25.356
		c-11.847,14.148-25.888,22.718-37.442,29.041c-7.719,4.174-14.533,7.389-18.769,9.769c-2.905,1.604-4.479,2.95-5.256,3.826
		c-0.768,0.926-1.029,1.306-1.496,2.826c-0.273,1.009-0.558,2.612-0.558,5.091c0,6.868,0,12.512,0,12.512
		c0,6.472-5.248,11.728-11.723,11.728h-28.252c-6.475,0-11.732-5.256-11.732-11.728c0,0,0-5.645,0-12.512
		c0-6.438,0.752-12.744,2.405-18.777c1.636-6.008,4.215-11.718,7.508-16.694c6.599-10.083,15.542-16.802,23.984-21.48
		c7.401-4.074,14.723-7.455,21.516-11.281c6.789-3.793,12.843-7.91,17.302-12.372c2.988-2.975,5.31-6.05,7.087-9.52
		c2.335-4.628,3.955-10.067,3.992-18.389c0.012-2.463-0.698-5.702-2.632-9.405c-1.926-3.686-5.066-7.694-9.264-11.29
		c-8.45-7.248-20.843-12.545-35.054-12.521c-16.285,0.058-27.186,3.876-35.587,8.62c-8.36,4.776-11.029,9.595-11.029,9.595
		c-4.268,3.718-10.603,3.85-15.025,0.314l-21.71-17.397c-2.719-2.173-4.322-5.438-4.396-8.926c-0.063-3.479,1.425-6.81,4.061-9.099
		c0,0,6.765-10.43,22.451-19.38c15.62-8.992,36.322-15.488,61.236-15.429c20.215,0,38.839,5.562,54.268,14.661
		c15.434,9.148,27.897,21.744,35.851,36.876c5.281,10.074,8.525,21.43,8.533,33.38C349.211,198.042,347.248,209.058,343.625,218.852
		z"/>
                            </g>
                            </svg>  <span className="tooltiptext">You will remain logged in for a long period of time. Do not check this if you are on a public computer or public tablet. If unchecked, you will automatically be logged out after 60 seconds of inactivity.</span></span></span> 
                    </span>
                 </div>
            </div>
            {isVerifying &&
                <div className="login-instruction-wrap">
                    <p className="login-information">Didn&apos;t receive code?</p>
                    <button type="submit" className="login-reenter" onClick={() => {
                        setIsReSendCode(true);
                        setVerificationCode("");
                    }}>Re-send code</button>
                    <p className="login-information resend">{reSendCodeMsg}</p>                    
                </div>
            }
                
            </form>
        
    )
}