import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {   
    ggobResourcesList: [],
    isGGOBResourcesLoaded: false,
    isGGOBResourcesSaving: false,
    ggobResourcesListByfilter: [],
    documentList: [],
    isdocumentListLoaded: false
}

const ggobResourcesSlice = createSlice({
    name: 'ggobResources',
    initialState,
    extraReducers: (builder) => {

        // Get All GGOB-Resources
        builder.addCase(actions.getGGOBResources.pending, (state) => {
            state.isGGOBResourcesLoaded = false;
        })
        builder.addCase(actions.getGGOBResources.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.ggobResourcesList = action.payload.data;
            } else {
                state.ggobResourcesList = [];
            }
            state.isGGOBResourcesLoaded = true;
        })
        builder.addCase(actions.getGGOBResources.rejected, (state) => {
            state.isGGOBResourcesLoaded = false;
        });


        // add/edit GGOB-Resources
        builder.addCase(actions.addEditGGOBResources.pending, (state) => {
            state.isGGOBResourcesSaving = true;
        })
        builder.addCase(actions.addEditGGOBResources.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                const postingsObj = state.ggobResourcesList.find(x => x.baseId === returnObj.baseId);
                if (postingsObj !== undefined && postingsObj !== null) {
                    postingsObj.title = returnObj.title;
                    postingsObj.description = returnObj.description;
                    postingsObj.ggobResourcesCompanies = returnObj.ggobResourcesCompanies;
                    postingsObj.order = returnObj.order;
                    postingsObj.id = returnObj.id;
                } else {
                    state.ggobResourcesList.unshift(returnObj);
                }
            }
            state.isGGOBResourcesSaving = false;
        })
        builder.addCase(actions.addEditGGOBResources.rejected, (state) => {
            state.isGGOBResourcesSaving = false;
        });


        // Delete GGOB-Resources
        builder.addCase(actions.deleteGGOBResources.pending, (state) => {
            state.isGGOBResourcesSaving = true;
        })
        builder.addCase(actions.deleteGGOBResources.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.ggobResourcesList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.ggobResourcesList.splice(enityIndex, 1);
                }
            }
            state.isGGOBResourcesSaving = false;
        })
        builder.addCase(actions.deleteGGOBResources.rejected, (state) => {
            state.isGGOBResourcesSaving = false;
        });


        // order GGOB-Resources
        builder.addCase(actions.updateGGOBResourcesOrder.pending, (state) => {
            state.isGGOBResourcesSaving = true;
        })
        builder.addCase(actions.updateGGOBResourcesOrder.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.ggobResourcesList = action.payload.data;
            } else {
                state.ggobResourcesList = [];
            }
            state.isGGOBResourcesSaving = false;
        })
        builder.addCase(actions.updateGGOBResourcesOrder.rejected, (state) => {
            state.isGGOBResourcesSaving = false;
        });




        // Get All GGOB-Resources by filter
        builder.addCase(actions.getGGOBResourcesByFilter.pending, (state) => {
            state.isGGOBResourcesLoaded = false;
        })
        builder.addCase(actions.getGGOBResourcesByFilter.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.ggobResourcesListByfilter = action.payload.data;
            } else {
                state.ggobResourcesListByfilter = [];
            }
            state.isGGOBResourcesLoaded = true;
        })
        builder.addCase(actions.getGGOBResourcesByFilter.rejected, (state) => {
            state.isGGOBResourcesLoaded = false;
        });


        //builder.addCase(actions.getDocumentList.pending, (state) => {
        //    state.isdocumentListLoaded = true;
        //})
        //builder.addCase(actions.getDocumentList.fulfilled, (state, action) => {
        //    if (action.payload.success) {
        //        state.documentList = action.payload.data;
        //    } else {
        //        state.documentList = [];
        //    }
        //    state.isdocumentListLoaded = false;
        //})
        //builder.addCase(actions.getDocumentList.rejected, (state) => {
        //    state.isdocumentListLoaded = false;
        //});
    }
});

export default ggobResourcesSlice.reducer;