import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbar } from 'react-scrollbars-custom';
import { formatDateTimeForUserTimeZone } from '../../../components/common/DateTimeFormatter';

export function ScheduledMessage({ scheduledList, onSortChange, filter, onEditComposeTrigger }) {

    const handleRowClick = (composeId) => {
        onEditComposeTrigger(composeId);
    };
    return (
        <div>
            <section className="text-alert-section">
                <div className="admin-tab-content">
                    <h2 className="admin-section-headline">Scheduled Messages</h2>

                    <div className="custom-select-scroll scrollbar-inner">
                        <Scrollbar>
                            <table className="admin-table rsvp-table">
                                <thead className="rsvp-table-header">
                                    <tr className="header-row">
                                        <th onClick={() => onSortChange(filter.sort === 0 ? 1 : 0)} className={filter.sort === 0 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Date/Time</th>
                                        <th onClick={() => onSortChange(filter.sort === 2 ? 3 : 2)} className={filter.sort === 2 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 3 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}># of Recipients</th>
                                        <th onClick={() => onSortChange(filter.sort === 4 ? 5 : 4)} className={filter.sort === 4 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 5 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Message</th>
                                        <th className="admin-table-name-cell sortable"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scheduledList && scheduledList.length > 0 ? (
                                        scheduledList.map((item) =>
                                            <tr key={`${item.composeId}-${item.sendTime}`} onClick={() => handleRowClick(item.composeId)}>
                                                <td className="admin-table-name-cell" style={{ cursor: 'pointer' }}>
                                                    {formatDateTimeForUserTimeZone(item.sendTime)}
                                                </td>
                                                <td className="admin-table-name-cell">{item.userCount}</td>
                                                <td className="admin-table-name-cell">{item.body}</td>
                                                <td className="admin-table-name-cell">
                                                    <button className="admin-skill-tile-action upload-csv upload-with-spinner" onClick={() => handleRowClick(item.composeId)}>Cancel / Edit</button>
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td className="admin-table-name-cell" colSpan="4">
                                                No active schedule
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Scrollbar>
                    </div>
                </div>
            </section>
        </div>
    );
}

ScheduledMessage.propTypes = {
    scheduledList: PropTypes.array.isRequired,
    onSortChange: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    onEditComposeTrigger: PropTypes.func.isRequired
};
