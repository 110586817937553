import React from "react";
import PropTypes from 'prop-types';
import { formatUSD, getMonthshortName, findNearestIndex } from "../../helpers/formatterHelper";

export default function BudgetedBoxes({ quarterlyBonusMonths, getCurrentMonthNameId, scaleAmount, graphLinesHeight, lastQuarterActualAmount, isNegativeScale }) {
	console.log('********************************************************** isNegativeScale', isNegativeScale, getCurrentMonthNameId);

	const BoxPositivePosition = (amount, i) => {

		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.budgetAmount !== 0);
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.budgetSum));


		let top = itemsIndex[i];		


		let bottom;
		if (itemsIndex[i - 1] != undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (lastQuarterActualAmount != 0 && isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}




		if (items[0].consolidatedBonus?.budgetAmount < 0 && i === 1 && isNegativeScale === false) {	
			const bottomScale = scaleAmount.length - itemsIndex[0];		
			bottom = bottom + bottomScale;	
		}


		let indexCount;
		let css = {};
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: (scaleAmount.length - bottom) * graphLinesHeight, position: 'absolute' }
		}

		css = { ...css, height: indexCount * graphLinesHeight }
		

		




		return css;
	}
	


	const BoxNegativePosition = (amount, i) => {
		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.budgetAmount !== 0);
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.budgetSum));	

		let top = itemsIndex[i];

		let bottom;
		if (i > 0 && itemsIndex[i - 1] !== undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (lastQuarterActualAmount !== 0) {
				bottom = findNearestIndex(scaleAmount, lastQuarterActualAmount);
			} else if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}

		if (i === 0 && isNegativeScale === false) {
			const bottomScale = scaleAmount.length - top;
			bottom = bottom - bottomScale;
			top = top + bottomScale;
		}
		

		let css = {};
		let indexCount;
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}

		css = { ...css, height: indexCount * graphLinesHeight, position: 'absolute' }


		return css;	
	}


	const getActualDivHeight = () => {
		const index = isNegativeScale ? 0 : null;

		if (index !== null) {
			return { bottom: 0 };
		}
		return null;
	}


    return (
		<div className="budgeted-boxes-outer-wrap bonus-budget-negative-wrapper" style={getActualDivHeight()}>
			{quarterlyBonusMonths && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.budgetAmount !== 0).map((month, index) => {
                if (month?.consolidatedBonus?.budgetAmount > 0) {

             
					const boxCss = BoxPositivePosition(month.consolidatedBonus?.budgetSum, index);	

                    return (
						<div className="budgeted-boxes budgeted-boxes-height" key={month.id} style={boxCss}>
							{boxCss && boxCss.height > 20 && (
								<>
									<p className="budgeted-text">{getMonthshortName(month.monthName)} Budgeted</p>
									{boxCss.height > 50 && month.consolidatedBonus?.budgetAmount && (
										<p className="budgeted-text"><b>{formatUSD(month.consolidatedBonus.budgetAmount)}</b></p>
									)}
								</>
							)}
						</div>
                    )
				} else if (month?.consolidatedBonus?.budgetAmount < 0) {

					const negativeBoxCss = BoxNegativePosition(month?.consolidatedBonus?.actualProjectedSum, index);


					return (
						<div className="budgeted-boxes budgeted-boxes-height loss-budget-box" key={month.id} style={negativeBoxCss}>
							{negativeBoxCss && negativeBoxCss.height > 20 && (
								<div>
									<p className="budgeted-text">{getMonthshortName(month.monthName)} Budgeted</p>
									{month.consolidatedBonus?.budgetAmount && (
										<p className="budgeted-text"><b>{formatUSD(month.consolidatedBonus.budgetAmount)}</b></p>
									)}
								</div>
							)}
						</div>
                    )
                }
            })}
		</div>
    );
}

BudgetedBoxes.propTypes = {
	quarterlyBonusMonths: PropTypes.array,
	getCurrentMonthNameId: PropTypes.number,
	scaleAmount: PropTypes.array,
	graphLinesHeight: PropTypes.number,
	lastQuarterActualAmount: PropTypes.number,
	isNegativeScale: PropTypes.bool,
};