import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import ModuleWrapper from '../Editor/ModuleWrapper/ModuleWrapper';

export function PostingAddEditModal({ showJobPost, onClose, handleAddEditPosting, modelPosting }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [orgErrorMessage, setOrgErrorMessage] = useState(null);
    const [formDataJobPosting, setFormDataJobPosting] = useState(null);
    const { companiesList } = useSelector(state => state.employee);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [allOrganization, setAllOrganization] = useState(false);
    const [moduleData, setModuleData] = useState({ modules: [] });

    useEffect(() => {

        if (modelPosting?.jobPostingCompanies.length > 0) {
            if (companiesList.length == modelPosting.jobPostingCompanies.length) {
                setAllOrganization(true);
            }
        }

        setFormDataJobPosting(modelPosting);
        setSelectedOrganization(modelPosting?.jobPostingCompanies);
    }, [modelPosting, companiesList]);

    useEffect(() => {
        if (formDataJobPosting !== null)
            setFormDataJobPosting({ ...formDataJobPosting, jobPostingCompanies: selectedOrganization });
    }, [selectedOrganization]);

    useEffect(() => {
        if (modelPosting && modelPosting.description) {
            try {
                const parsedData = JSON.parse(modelPosting.description);
                setModuleData(parsedData);

            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [showJobPost]);

    const handleSave = () => {

        if (!formDataJobPosting.jobPostingCompanies.length > 0) {
            setOrgErrorMessage('At least one organization is required.');
        }

        if (!formDataJobPosting.title) {
            setErrorMessage('Job title is required.');
        }

        if (errorMessage !== null || orgErrorMessage != null) {
            return;
        }

        const obj = { ...formDataJobPosting, description: moduleData === null ? null : JSON.stringify(moduleData) };

        if (obj.title) {
            handleAddEditPosting(obj);
            setModuleData({ ...moduleData, modules: [] });
            setErrorMessage(null);
            setOrgErrorMessage(null);
            setAllOrganization(false);
            onClose();
        }
    };

    const handleOnClose = () => {
        setModuleData({ ...moduleData, modules: [] });
        setErrorMessage(null);
        setOrgErrorMessage(null);
        setAllOrganization(false);
        onClose();

    };


    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleOnClose();
        }
    };

    if (!showJobPost) {
        return null;
    }
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Add/Edit Job Posting</h2>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-radio-label inline">
                                <input className="admin-inline-checkbox" type="radio" id="active" name="active" value={true} checked={formDataJobPosting?.active === true} onChange={() => setFormDataJobPosting({ ...formDataJobPosting, active: true })} />
                                Active
                            </label>
                            <label className="admin-radio-label inline">
                                <input className="admin-inline-checkbox" type="radio" id="inactive" name="active" value={false} checked={formDataJobPosting?.active === false} onChange={() => setFormDataJobPosting({ ...formDataJobPosting, active: false })} />
                                Inactive
                            </label>
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="organization">Organization*</label>
                            <div className="admin-column-wrap inline-tags">
                                <label className="admin-checkbox-label inline">
                                    <input
                                        className="admin-inline-checkbox"
                                        type="checkbox"
                                        checked={allOrganization === true ? true : false}
                                        onChange={(e) => {
                                            setAllOrganization(e.target.checked);
                                            if (e.target.checked) {
                                                const updatedOrganization = companiesList.map(item => ({ companyId: item.id, company: item }));
                                                setSelectedOrganization(updatedOrganization);
                                            }
                                            setOrgErrorMessage(null);
                                        }}
                                    />
                                    All
                                </label>
                                {companiesList.map(item => (
                                    <label className="admin-checkbox-label inline" key={item.id}>
                                        <input className="admin-inline-checkbox"
                                            type="checkbox"
                                            checked={selectedOrganization.some(x => x.companyId === item.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                const updatedOrganization = checked
                                                    ? [...selectedOrganization, { companyId: item.id, company: item }]
                                                    : selectedOrganization.filter(selectedItem => selectedItem.companyId !== item.id);
                                                setSelectedOrganization(updatedOrganization);
                                                if (checked === false) {
                                                    setAllOrganization(false);
                                                }
                                                setOrgErrorMessage(null);
                                            }}
                                        />
                                        {item.abbreviatedName}
                                    </label>
                                ))}
                            </div>
                            {orgErrorMessage && <p className="error-messagess">{orgErrorMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell ">
                            <label className="admin-default-label" htmlFor="email-domain">Job Title*</label>
                            <input
                                type="text"
                                id="email-domain"
                                label="Email Url"
                                name="domain"
                                value={formDataJobPosting?.title || ''}
                                onChange={(event) => {
                                    setFormDataJobPosting({ ...formDataJobPosting, title: event.target.value });
                                    setErrorMessage(null);
                                }}
                            />

                            {errorMessage && <p className="error-messagess">{errorMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell admin-video-wrap">
                            <ModuleWrapper
                                setValue={moduleData}
                                onChange={(getValue) => setModuleData(getValue)}
                                isEdit={formDataJobPosting?.id}
                            />
                        </div>
                    </div>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

PostingAddEditModal.propTypes = {
    showJobPost: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddEditPosting: PropTypes.func.isRequired,
    modelPosting: PropTypes.object
};