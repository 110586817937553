import { createAsyncThunk } from '@reduxjs/toolkit';
import * as employeeApi from '../../api/employee';

export const getEmployeeList = createAsyncThunk('getEmployeeList', async (data) => {
  const res = await employeeApi.getEmployeeList({ data: data });
  return res.data;
});

export const deleteEmployee = createAsyncThunk('deleteEmployee', async (data) => {
  const res = await employeeApi.deleteEmployee({ data: data });
  return res.data;
});

export const getCompanies = createAsyncThunk('getCompanies', async (data) => {
  const res = await employeeApi.getCompanies(data);
  return res.data;
});

export const getDepartmentListByCompanyId = createAsyncThunk('getDepartmentByCompanyId', async (data) => {
  const res = await employeeApi.getCompanyListByCompanyid({ data: data });
  return res.data;
});

export const getEmployeeById = createAsyncThunk('getEmployeeById', async (data) => {
  const res = await employeeApi.getEmployeeById({ data: data });
  return res.data;
});

export const saveEmployeeInfo = createAsyncThunk('saveEmployeeInfo', async (data) => {
  const res = await employeeApi.saveEmployeeInfo({ data: data });
  return res.data;
});

export const getLoggedInEmployeeById = createAsyncThunk('getLoggedInEmployeeById', async (data) => {
  const res = await employeeApi.getLoggedInEmployeeById({ data: data });
  return res.data;
});

export const getEmployeeWorkAnniversaries = createAsyncThunk('getEmployeeWorkAnniversaries', async (data) => {
    const res = await employeeApi.getEmployeeWorkAnniversaries({ data: data });
    return res.data;
});


export const getShirtSizes = createAsyncThunk('getShirtSizes', async (data) => {
    const res = await employeeApi.getShirtSizes(data);
    return res.data;
});


export const getSearchEmployeeList = createAsyncThunk('getSearchEmployeeList', async (data) => {
    const res = await employeeApi.getSearchEmployee({ data: data });
    return res.data;
});

export const getSecondaryDepartmentListByCompanyId = createAsyncThunk('getSecondaryDepartmentListByCompanyId', async (data) => {
    const res = await employeeApi.getCompanyListByCompanyid({ data: data });
    return res.data;
});