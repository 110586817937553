import api from '../utils/api';

export const getEvents = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/getAllEvents`,
        method: "POST",
        data: data,
        ...options,
    });
}
export const getTypeEvent = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/event/getAllEventType',
        method: 'GET',
        ...options,
    });
};

export const submitEvent = (events) => {
    let url = '/api/event/addUpdateEvent';
    return api({
        url: url,
        method: 'POST',
        data: events
    })
}
export const getEventByIdEdit = (eventBaseId) => {
    return api({
        url: '/api/event/getEventByBaseId/' + eventBaseId,
        method: 'GET'
    })
}

export const deleteEvent = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/deleteEvent/${data}`,
        method: "DELETE",
        data: data,
        ...options,
    });
}

export const getEventsRSVPs = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/getAllEventRSVPsByEventId`,
        method: "Post",
        data: data,
        ...options,
    });
}


export const  EventsRSVPsUpdate = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/addUpdateEventRSVPs`,
        method: "Post",
        data: data,
        ...options,
    });
}


export const getTodayEventNotifications = (config = {}) => {
    const { data,options } = config;
    return api({
        url: `api/event/geteventnotifications`,
        method: "POST",
        data: data,
        ...options,
    });
};


export const getCalendarEvents = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/getCalendarEvents`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const mostRecentUpcomingEvents = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/mostRecentUpcomingEvents`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const getallhrevents = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/event/getallhrevents`,
        method: "POST",
        data: data,
        ...options,
    });
}


export const getEventDetailByBaseId = (eventBaseId, eventLogId) => {
    return api({
        url: '/api/event/geteventdetailbybaseid/' + eventBaseId + '/' + eventLogId,
        method: 'GET'
    })
}

export const getEmployeesCompanyList = (userId) => {
    return api({
        url: '/api/event/getEmployeesCompany/' + userId,
        method: 'GET'
    })
}

export const getEventImage = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/event/geteventimage/' + data,
        method: 'GET',
        ...options,
    });
}; 



export const getCalendarIcsFile = (request) => {
    let url = '/api/event/calendaricsfile';
    return api({
        url: url,
        method: 'POST',
        data: request
    })
}