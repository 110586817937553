import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { getEmployeeList, deleteEmployee, getCompanies } from '../../../store/employee/actions';
import { formatDate } from '../../../components/common/DateTimeFormatter';
import { EmployeeConfrimationModal } from './EmployeeDeleteConfirmation';
import { excelExport } from '../../../api/adminuserapi.js'
import { useAdminMenuContext } from "../../../context/AdminMenuContext";

function Employee() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { employeeList, companiesList } = useSelector(state => state.employee);
    const [filter, setFilter] = useState({ query: '', companyId: 0, sort: 0, searchFirstName: '', searchLastName: '', searchMobile: '' });
    const [employeeModel, setEmployeeModel] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [editLink, setEditLink] = useAdminMenuContext('');

    useEffect(() => { 
        document.title = 'Employee | CWP Employee Portal';
        dispatch(getCompanies());
        setEditLink('/company-directory');
        console.log(editLink)
    }, []);

    useEffect(() => {
        dispatch(getEmployeeList(filter));
    }, [filter]);

    const handleFilterCompany = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        setFilter({ ...filter, companyId: selectedCompanyId });
    };

    const handleDeleteTrigger = (id, firstName, lastName) => {
        setEmployeeModel({ id: id, name: firstName + ' ' + lastName });
        setShowDeleteModal(true);
    }

    const handleDeleteEmployee = (item) => {
        dispatch(deleteEmployee(item.id));
    }

    const downloadFile = async () => {
        setIsLoading(true);
        try {
            const response = await excelExport({ options: { data: filter } }); // Await the API call
            const blob = new Blob([response.data], { type: 'text/csv' }); // Change MIME type to 'text/csv'
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exportusersList.csv'); // Change file extension to '.csv'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <EmployeeConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteEmployee={handleDeleteEmployee}
                modelEmployee={employeeModel}
            />

            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">                    
                        <Link
                            className="admin-top-bar-back"
                            to={'/siteadmin'}
                        >
                            Back
                        </Link>
                    </div>
                </div>


                <div className="pageContainer">
                    <div className="admin-page-content">
                        <div className="employee-container">
                            <div className="admin-page-title-wrap employee-header-wrap">
                                <div className="employee-header-left">
                                    <h1 className="admin-page-heading">Employees</h1>
                                    <button className="admin-button"
                                        onClick={() =>
                                            navigate('/siteadmin/employee/create')
                                        }
                                    >
                                        <span>Add Employee</span>
                                        <span className="material-symbols-rounded">add</span>
                                     </button>
                                </div>
                                <div className="employee-header-right">
                                    <button className="admin-button" onClick={downloadFile} disabled={isLoading}>
                                        <span>{isLoading ? 'Exporting...' : 'Export Employee Data'}</span>
                                        <span className="material-symbols-rounded">{isLoading ? 'hourglass_empty' : 'download'}</span>
                                    </button>
                                </div>
                            </div>

                            <div className="admin-bottom-bar filter-bar">
                                <div className="admin-bottom-bar-left">
                                    <input className="admin-default-search quoteradius"
                                        type="text"
                                        placeholder="Search name or phone"
                                        value={filter.query}
                                        onChange={(e) => setFilter({ ...filter, query: e.target.value })} />
                                    <select className="admin-default-select quoteradius" onChange={handleFilterCompany} value={filter.companyId}>
                                        <option value="0">All</option>
                                        {companiesList && companiesList.length > 0 &&
                                            companiesList.map(item => (
                                                <option key={item.id} value={item.id}>{item.abbreviatedName}</option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                       

                            <div className="pt">
                                <table className="admin-table">
                                    <tbody>
                                        <tr>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 1 ? 2 : 1 })} className={filter.sort == 1 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 2 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>First Name</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 3 ? 4 : 3 })} className={filter.sort == 3 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 4 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Last Name</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 5 ? 6 : 5 })} className={filter.sort == 5 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 6 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Primary Company</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 7 ? 8 : 7 })} className={filter.sort == 7 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 8 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Date Added</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 9 ? 10 : 9 })} className={filter.sort == 9 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 10 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Phone Number</th>
                                            <th className="admin-table-action-cell">&nbsp;</th>
                                            <th className="admin-table-action-cell">&nbsp;</th>
                                        </tr>
                                        {employeeList && employeeList.length > 0 ? (
                                            employeeList.map(item =>
                                                <tr key={item.id}>
                                                    <td className="admin-table-name-cell">{item.firstName}</td>
                                                    <td className="admin-table-name-cell">{item.lastName}</td>
                                                    <td className="admin-table-name-cell">{item.primaryCompanyName}</td>
                                                    <td className="admin-table-name-cell">{formatDate(item.createdDate)}</td>
                                                    <td className="admin-table-name-cell">{item.mobile}</td>
                                                    <td className="admin-table-name-cell">
                                                        <button className="admin-table-action" onClick={() => navigate('/siteadmin/employee/edit/' + item.id)} >
                                                            <span className="material-symbols-rounded">edit</span>
                                                        </button>
                                                    </td>
                                                    <td className="admin-table-name-cell">
                                                        <button className="admin-table-action" onClick={() => handleDeleteTrigger(item.id, item.firstName, item.lastName)}>
                                                            <span className="material-symbols-rounded">delete</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : (
                                            <tr>
                                                <td className="admin-table-name-cell" colSpan="6">No active Employees</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Employee;