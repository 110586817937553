import api from '../../utils/api';

export const saveOrUpdateFinancials = (config = {}) => {
    const { data } = config;
    return api({
        url: `api/financials/upload-files`,
        method: "POST",
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: data
    });
}

export const getAllFinancials = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/financials',
        method: 'GET',
        ...options,
    });
};

export const deleteUpdateFinancials = (config = {}) => {
    const { data } = config;
    return api({
        url: `api/financials/delete-file`,
        method: "POST",
        data: data
    });
}
export const GetFinancialHistory = (type, companyId) => {
    return api({
        url: '/api/financials/getFinancialHistory/' + type + '/' + companyId,
        method: 'GET'
    })
}

export const ChangeFinancialsVersion = (config = {}) => {
    const { data } = config;
    return api({
        url: `api/financials/changeversion`,
        method: "POST",
        data: data
    });
}