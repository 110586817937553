import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
export function SkillAddEditModal({ show, onClose, handleAddEditSkill, modelSkill}) {
    const [formDataSkill, setFormDataSkill] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [orgErrorMessage, setOrgErrorMessage] = useState(null);
    const { companiesList } = useSelector(state => state.employee);
    const [allOrganization, setAllOrganization] = useState(false);
    const [inEditMode, setInEditMode] = useState(modelSkill.id > 0);
    const [popupText, setPopupText] = useState('Add Position & Qualification');
    const [selectedOrganization, setSelectedOrganization] = useState([]);


    useEffect(() => {
        setAllOrganization(false);
        if (modelSkill?.skillCompanies.length > 0) {
            const allItemsSelected = companiesList.every(company => {
                return modelSkill.skillCompanies.some(item => item.companyId === company.id)
            });
            setAllOrganization(allItemsSelected);
        }
        setInEditMode(modelSkill.id > 0);

        setFormDataSkill(modelSkill);
        setSelectedOrganization(modelSkill?.skillCompanies);
    }, [modelSkill, show]);

    useEffect(() => {
        if (inEditMode) {
            setPopupText('Edit Position & Qualification');
        } else {
            setPopupText('Add Position & Qualification');
        }
        
    }, [inEditMode]);

    useEffect(() => {
        if (formDataSkill !== null)
            setFormDataSkill({ ...formDataSkill, skillCompanies: selectedOrganization });
    }, [selectedOrganization]);

    const handleSave = () => {
        if (!formDataSkill.name) {
            setErrorMessage('Qualifications filed is required.');
            return;
        } else {
            setErrorMessage(null);
        }

        if (formDataSkill.skillCompanies.length === 0) {
            setOrgErrorMessage('At least select one organization.');
            return;
        }

        if (formDataSkill.name) {
            handleAddEditSkill(formDataSkill);
            setFormDataSkill({});
            setAllOrganization(false);
            onClose(); // Close the modal  
        }
    };

    const handleOnClose = () => {
        onClose(); // Close the modal    
        setErrorMessage(null);
        setOrgErrorMessage(null);
        setAllOrganization(false);

    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleOnClose();
        }
    };

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">{popupText}</h2>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            {!inEditMode && (
                                <>
                                    <label className="admin-default-label" htmlFor="email-domain">Position & Qualification*</label>
                                    <input
                                        type="text"
                                        id="email-domain"
                                        label="Email Url"
                                        name="domain"
                                        value={formDataSkill.name || ''}
                                        onChange={(event) => {
                                            setFormDataSkill({ ...formDataSkill, name: event.target.value });
                                        }}
                                    />

                                    {errorMessage && <p className="validation-error-message">{errorMessage}</p>}
                                </>)}
                            {/*This is Edit Mode*/}
                            {inEditMode && (
                                <>
                                    <label className="admin-default-label" htmlFor="email-domain">Position & Qualification*</label>
                                    <input
                                        type="text"
                                        id="email-domain"
                                        label="Email Url"
                                        name="domain"
                                        value={formDataSkill.name || ''}
                                        onChange={(event) => {
                                            setFormDataSkill({ ...formDataSkill, name: event.target.value });
                                        }}
                                    />

                                    {errorMessage && <p className="validation-error-message">{errorMessage}</p>}
                                </>)}
                            
                        </div>
                    </div>
                    {!inEditMode && (
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="organization">Organization*</label>
                            <div className="admin-column-wrap inline-tags">
                                <label className="admin-checkbox-label inline">
                                    <input
                                        className="admin-inline-checkbox"
                                        type="checkbox"
                                        checked={allOrganization === true ? true : false}
                                        onChange={(e) => {
                                            setAllOrganization(e.target.checked);
                                            if (e.target.checked) {
                                                const updatedOrganization = companiesList.map(item => ({ companyId: item.id, company: item }));
                                                setSelectedOrganization(updatedOrganization);
                                            }
                                        }}
                                    />
                                    All
                                </label>
                                {companiesList.map(item => (
                                    <label className="admin-checkbox-label inline" key={item.id}>
                                        <input className="admin-inline-checkbox"
                                            type="checkbox"
                                            checked={selectedOrganization.some(x => x.companyId === item.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                const updatedOrganization = checked
                                                    ? [...selectedOrganization, { companyId: item.id, company: item }]
                                                    : selectedOrganization.filter(selectedItem => selectedItem.companyId !== item.id);
                                                setSelectedOrganization(updatedOrganization);
                                                if (checked === false) {
                                                    setAllOrganization(false);
                                                }
                                            }}
                                        />
                                        {item.abbreviatedName}
                                    </label>
                                ))}
                            </div>
                            {orgErrorMessage && <p className="validation-error-message">{orgErrorMessage}</p>}
                        </div>
                    </div>
                     )}

                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

SkillAddEditModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddEditSkill: PropTypes.func.isRequired,
    modelSkill: PropTypes.object,
    inEditMode: PropTypes.bool
};