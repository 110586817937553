import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import { GetYTDBonus } from '../../store/bonuslevels/actions';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import Sidebar from '../../components/SideBar/Sidebar.js';
import './bonus-levels-ytd.css';
import { formatUSD } from "../../helpers/formatterHelper";
import { getCurrentMonthName } from '../../store/bonuslevels/actions';
import PropTypes from 'prop-types';
import { shopMonitorSliderTime } from '../../helpers/enums';
import YtdActualBoxes from '../../components/bonuslevels/YtdActualBoxes';
import YtdBudgetedBoxes from '../../components/bonuslevels/YtdBudgetedBoxes';

function findNearestIndex(array, number) {
    let minDiff = Infinity;
    let nearestIndex = null;

    array.forEach((element, index) => {
        const diff = Math.abs(element - number);

        if (diff < minDiff) {
            minDiff = diff;
            nearestIndex = index;
        }
    });

    return nearestIndex;
}
export default function ShopYTDBonusLevel({ handleloadinTiming }) {
    const dispatch = useDispatch();
    const [filter] = useState({ quarterlyBonusId: 0, timezone: GetTimeZone(), previousQuarterlyBonus: 0, isLoaded: false });
    const { ytdBonusList } = useSelector(state => state.bonuslevels);
    const isLoading = useSelector((state) => state.loading);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const graphLines = useRef(null);
    const [scaleAmount, setScaleAmount] = useState([]);
    const [graphLinesHeight, setGraphLinesHeight] = useState(0);
    const [quarterlyBonusMonths, setQuarterlyBonusMonths] = useState([]);   
    const [scaleAmountwithNegative, setScaleAmountwithNegative] = useState([]);
    const [negativeScaleAmount, setNegativeScaleAmount] = useState([]);
    const [getCurrentMonthNameId, setGetCurrentMonthNameId] = useState(0);
    const [quarterLastMonthsList, setQuarterLastMonthsList] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [ytdScale, setYtdScale] = useState([]);
    const [isTooHigh, setIsTooHigh] = useState(false);

 

    useEffect(() => {
        dispatch(showLoading());
        handleloadinTiming(shopMonitorSliderTime.LeadingTime);
        if (getCurrentMonthNameId === 0) {
            dispatch(getCurrentMonthName()).unwrap().then((res) => {
                if (res.success) {
                    setGetCurrentMonthNameId(res.data?.id);                                     
                }
            });
        }
    }, [getCurrentMonthNameId]);

    useEffect(() => {
        document.title = 'YTD Bonus Levels | CWP Team Connect'
    })
    useEffect(() => {
        dispatch(GetYTDBonus(filter)).unwrap().then(() => {
            dispatch(hideLoading());
            handleloadinTiming(shopMonitorSliderTime.default);
            setIsDataLoaded(true);
        });
    }, [filter]);

    useEffect(() => {
        if (isLoading && isDataLoaded) {
            dispatch(hideLoading());
            handleloadinTiming(shopMonitorSliderTime.default);
        }
    }, [isLoading, isDataLoaded]);


    useEffect(() => {
        let scaleAmountList = [];
        const nagativeScaleAmountList = [];
        const scaleAmountListWithnagative = [];
        const bonuslevelsQ1 = ytdBonusList.find(item => item.name === "Q1");
        const bonuslevelsQ4 = ytdBonusList.find(item => item.name === "Q4");
        const bonuslevelsBottomQ1 = bonuslevelsQ1 && bonuslevelsQ1.bonuslevels[0];
        const bonuslevelsBottomQ4 = bonuslevelsQ4 && bonuslevelsQ4.bonuslevels[bonuslevelsQ4.bonuslevels.length - 1];

        let totalLength = 0;
        ytdBonusList.forEach((item) => {
            if (item && Array.isArray(item.bonuslevels)) {
                totalLength += item.bonuslevels.length;
            }
        });

        const quarterlyBonusMonthslist = ytdBonusList
            .map(item => item.quarterlyBonusMonths) // Map to get arrays from each item's quarterlyBonusMonths
            .flat();

        const quarterLastMonths = ytdBonusList
            .map(item => item.quarterlyBonusMonths[0].monthName)
            .flat();

        setQuarterLastMonthsList(quarterLastMonths);


        if (bonuslevelsBottomQ1 && bonuslevelsBottomQ4) {
            const calScaleAmount = Math.floor(Math.abs(bonuslevelsBottomQ4.budgetAmount - bonuslevelsBottomQ1.budgetAmount) / (totalLength - 1));


            const topScale1 = Math.round(bonuslevelsQ4?.quarterTotal + (bonuslevelsQ4?.quarterTotal * 5 / 100));
            const bonusMonths = bonuslevelsQ4?.quarterlyBonusMonths[0];
            const topScale2 = bonusMonths && bonusMonths.consolidatedBonus?.actualProjectedSum && Math.round(bonusMonths.consolidatedBonus?.actualProjectedSum + (bonusMonths.consolidatedBonus?.actualProjectedSum * 5 / 100));
            //const topScale3 = bonuslevelsBottomQ4.budgetAmount + (calScaleAmount * 2);

            let topScaleValue;
            if (topScale1 >= topScale2) {
                topScaleValue = topScale1;
            } else {
                topScaleValue = topScale2;
            }

            const nagativeValues = quarterlyBonusMonthslist.filter(item => item.id <= getCurrentMonthNameId && (item.consolidatedBonus.actualProjectedSum < 0 || item.consolidatedBonus.actualAmount < 0));

            let i;
            for (i = calScaleAmount; i <= topScaleValue; i += calScaleAmount) {
                scaleAmountList.unshift(Math.round(i));
                scaleAmountListWithnagative.unshift(Math.round(i));
            }

            if (nagativeValues && nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < calScaleAmount)) {
                scaleAmountListWithnagative.push(0);
                let value = -calScaleAmount;
                let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < calScaleAmount);
                let endValue = nagativeValue.consolidatedBonus.actualProjectedSum - calScaleAmount;
                while (value >= endValue) {
                    nagativeScaleAmountList.push(value);
                    scaleAmountListWithnagative.push(Math.round(value));
                    value -= calScaleAmount;
                }
            }


            const nagativeBudgetedValues = quarterlyBonusMonthslist.filter(item => item.consolidatedBonus.budgetSum < 0);

            if (nagativeBudgetedValues && nagativeBudgetedValues.find(item => item.consolidatedBonus.budgetSum < calScaleAmount)) {
                scaleAmountListWithnagative.push(0);
                let value = -calScaleAmount;
                let nagativeValue = nagativeBudgetedValues.find(item => item.consolidatedBonus.budgetSum < calScaleAmount);
                let endValue = nagativeValue?.consolidatedBonus.budgetSum - calScaleAmount;
                while (value >= endValue) {
                    nagativeScaleAmountList.push(value);
                    scaleAmountListWithnagative.push(Math.round(value));
                    value -= calScaleAmount;
                }
            }


            if (topScale1 == topScaleValue) {
                const getIndex = findNearestIndex(scaleAmountList, bonuslevelsQ4?.quarterTotal);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));
                }
            }

            if (topScale2 == topScaleValue && bonusMonths) {
                const getIndex = findNearestIndex(scaleAmountList, bonusMonths.consolidatedBonus?.actualProjectedSum);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));
                }
            }
        }


        const calYtdScale = scaleAmountList.length > 0 && Math.floor(scaleAmountList.length / 4);
        if (scaleAmountList.length > 0) {
            const dividedScaleAmountList = [];
            for (let i = scaleAmountList.length; i >= 0; i -= calYtdScale) {
                if (scaleAmountList[i]) {
                    dividedScaleAmountList.push(scaleAmountList[i]);
                }
            }
            setYtdScale(dividedScaleAmountList);
        } 
     

        setScaleAmount(scaleAmountList);
        setQuarterlyBonusMonths(quarterlyBonusMonthslist);
        setScaleAmountwithNegative(scaleAmountListWithnagative);
        setNegativeScaleAmount(nagativeScaleAmountList);

    }, [ytdBonusList, getCurrentMonthNameId]);


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const rect = graphLines.current?.getBoundingClientRect();
        let height = rect.height;       
        if (scaleAmountwithNegative.length > 30) {
            height = 679 / scaleAmountwithNegative.length;
            setIsTooHigh(true);
        }
        setGraphLinesHeight(height);
    }, [graphLines, scaleAmount, scaleAmountwithNegative, windowWidth]);

    const GetCustomYTDList = (quraterName) => {
        let filterYTDList;
        if (quraterName === "Q1") {
            filterYTDList = ytdBonusList?.filter(item => item.name === quraterName);
        }
        else if (quraterName === "Q2") {
            filterYTDList = ytdBonusList?.filter(item => item.name === "Q1" || item.name === "Q2");
        }
        else if (quraterName === "Q3") {
            filterYTDList = ytdBonusList?.filter(item => item.name !== "Q4");
        }
        else if (quraterName === "Q4") {
            filterYTDList = ytdBonusList;
        }
        return filterYTDList;
    }

    const GetQuarterGoal = (quraterName) => {
        const filterYTDList = GetCustomYTDList(quraterName);
        let total = 0;
        filterYTDList.forEach(bonusmonth => {
            bonusmonth.quarterlyBonusMonths.forEach(month => {
                total += month.consolidatedBonus.budgetAmount;
            });
        });

        return total;
    }

    const GetActualProjected = (quraterName) => {
        const filterYTDList = GetCustomYTDList(quraterName);
        let total = 0;

        filterYTDList.forEach(bonusmonth => {
            bonusmonth.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId).forEach(month => {
                const actualAmount = month.consolidatedBonus.actualAmount;
                const projectedAmount = month.consolidatedBonus.projectedAmount;

                if (actualAmount !== 0 && projectedAmount !== 0) {
                    // Both actual and projected have values
                    total += actualAmount;
                } else {
                    // At least one of actual or projected is zero
                    total += actualAmount + projectedAmount;
                }
            });
        });

        return total;
    }

    const GetBonusPercent = (quraterName) => {
        const filterYTDList = GetCustomYTDList(quraterName);

        let total = GetActualProjected(quraterName);

        let currentQuarter = filterYTDList.find(item => item.name === quraterName);

        if (currentQuarter && currentQuarter.bonuslevels) {
            let matchBudgetIndex = currentQuarter.bonuslevels.findIndex(x => x.budgetAmount >= total);
            return matchBudgetIndex > 0 ? matchBudgetIndex : 0;
        } else {
            return 0;
        }

    }


    const calQGoalHeight = (quarterTotal) => {
        const index = findNearestIndex(scaleAmountwithNegative, quarterTotal);
        return index * graphLinesHeight;
    }

    const getTop = () => {
        const isTopValue = ytdScale.includes(scaleAmount[0]) ? true : false;

        return (
            <div className="bonus-levels-graph-lines" ref={graphLines} style={graphLinesHeight > 0 ? { height: `${graphLinesHeight}px` } : {}}> 
                <p className="bonus-levels-numbers">{isTopValue && scaleAmount.length > 0 && formatUSD(scaleAmount[0])}</p>
            </div>
        );
    }    


    const graphInnerWrapRef = useRef(null);

    //// Function to scroll to the bottom of the element
    const scrollToBottom = () => {
        if (graphInnerWrapRef.current) {
            graphInnerWrapRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    };

    //// Use useEffect to scroll to the bottom when the component is loaded
    useEffect(() => {
        scrollToBottom();
    }, [graphInnerWrapRef, scaleAmount]);    


    const getQuarterNameById = (quarterlyBonusId) => {
        const quarter = ytdBonusList.find(x => x.id === quarterlyBonusId);
        return quarter && quarter.name;
    } 

    const reverseArray = () => {
        return quarterlyBonusMonths && [...quarterlyBonusMonths].reverse();
    };

    return (
        <div className="monitors-inner">
            <div className="monitors-item bonus-levels-left bonus-levels-ytd-left">
            <div className="monitors-header-wrap ytd-header-wrap">
                <p className="monitors-header-title"> YTD Bonus Levels</p>
                <span className="monitors-header-icon"><svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.25 17.75H14.75V16.25H16.5962C16.925 16.25 17.1995 16.1399 17.4197 15.9197C17.6399 15.6995 17.75 15.425 17.75 15.0962V11.4038C17.75 11.075 17.6399 10.8005 17.4197 10.5803C17.1995 10.3601 16.925 10.25 16.5962 10.25H11.75V7.25H17.75V5.75H14.75V4.25H13.25V5.75H11.4038C11.075 5.75 10.8005 5.8601 10.5803 6.0803C10.3601 6.30047 10.25 6.57499 10.25 6.90384V10.5962C10.25 10.925 10.3601 11.1995 10.5803 11.4197C10.8005 11.6399 11.075 11.75 11.4038 11.75H16.25V14.75H10.25V16.25H13.25V17.75ZM2.92306 21.5C2.23269 21.5 1.65625 21.2688 1.19375 20.8063C0.73125 20.3438 0.5 19.7673 0.5 19.0769V2.92306C0.5 2.23269 0.73125 1.65625 1.19375 1.19375C1.65625 0.73125 2.23269 0.5 2.92306 0.5H25.0769C25.7673 0.5 26.3438 0.73125 26.8063 1.19375C27.2688 1.65625 27.5 2.23269 27.5 2.92306V19.0769C27.5 19.7673 27.2688 20.3438 26.8063 20.8063C26.3438 21.2688 25.7673 21.5 25.0769 21.5H2.92306ZM2.92306 20H25.0769C25.3077 20 25.5192 19.9039 25.7115 19.7115C25.9038 19.5192 26 19.3077 26 19.0769V2.92306C26 2.69231 25.9038 2.48078 25.7115 2.28845C25.5192 2.09615 25.3077 2 25.0769 2H2.92306C2.69231 2 2.48078 2.09615 2.28845 2.28845C2.09615 2.48078 2 2.69231 2 2.92306V19.0769C2 19.3077 2.09615 19.5192 2.28845 19.7115C2.48078 19.9039 2.69231 20 2.92306 20Z" fill="#00FF38" />
                </svg></span>
                </div>
                <div className={`bonus-levels-graph-main-wrap shopmonitors-view ${isTooHigh ? ' shopmonitors-isTooHigh': ''}`}>
                <div className="bonus-levels-graph-outer-wrap shopmoniter-ytd bonus-levels-desktop-wrap YTD-wrapper">
                    <div className="bonus-levels-graph-inner-wrap" ref={graphInnerWrapRef}>
                    

                    {getTop()}
                    {scaleAmount && scaleAmount.length > 0 && scaleAmount.slice(1).map((item, index) => {
                        const isInterval = ytdScale.includes(item) ? true : false;
                        return (
                            <div className="bonus-levels-graph-lines" key={index} style={{ height: `${graphLinesHeight}px` } }>
                                <p className="bonus-levels-numbers ytd-line-number">{isInterval && formatUSD(item)}</p>
                            </div>
                        )
                    })}
                            <div className="bonus-levels-graph-baseline" style={negativeScaleAmount.length > 0 ? { height: `${graphLinesHeight}px` } : {}}></div>

                        {negativeScaleAmount && negativeScaleAmount.map((item, index) => {
                            const isInterval = ytdScale.includes(Math.abs(item)) ? true : false;
                            return (
                                <div className="bonus-levels-graph-lines" key={index} style={{ height: `${graphLinesHeight}px` }}>
                                    <p className="bonus-levels-numbers ytd-line-number">{isInterval && formatUSD(item)}</p>
                                </div>
                            )}
                        )}
                    </div>
                    {ytdBonusList && ytdBonusList.length > 0 &&
                        ytdBonusList.map((item, index) => (
                            <div className={`q1-goal-wrap ytd-goal-${index + 1}`} key={item.id} style={{ top: `${calQGoalHeight(GetQuarterGoal(item.name))}px` }} >
                                <p className="q1-goal-text">
                                    <span className="d-sm-none">{item.name === "Q4" ? 'Annual' : item.name}
                                        <span className="ytd-goal-value"> Budgeted {formatUSD(GetQuarterGoal(item.name))}</span>
                                    </span>
                                </p>
                            </div>
                        ))}
                    

                        <YtdBudgetedBoxes
                            quarterlyBonusMonths={reverseArray()}
                            getCurrentMonthNameId={getCurrentMonthNameId}
                            scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
                            graphLinesHeight={graphLinesHeight}
                            isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
                        />

                        <YtdActualBoxes
                            quarterlyBonusMonths={reverseArray()}
                            getCurrentMonthNameId={getCurrentMonthNameId}
                            scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
                            graphLinesHeight={graphLinesHeight}
                            getBonusPercent={GetBonusPercent}
                            getQuarterNameById={getQuarterNameById}
                            getActualProjected={GetActualProjected}
                            quarterLastMonthsList={quarterLastMonthsList}
                            isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
                            windowWidth={windowWidth}
                        /> 

                    

                    

                    </div>
                </div>
                </div>
           
            <Sidebar pageType={"bonus-level-ytd"} />
        </div>
    );
}

ShopYTDBonusLevel.propTypes = {
    handleloadinTiming: PropTypes.func
};