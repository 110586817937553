import React, { useRef, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { formatUSD, getMonthshortName, findNearestIndex } from "../../helpers/formatterHelper";


export default function ActualBoxes({ quarterlyBonusMonths, getCurrentMonthNameId, scaleAmount, graphLinesHeight, quarterName, scaleValue, lastQuarterActualAmount, isNegativeScale, windowWidth }) {
	console.log("scaleValue", scaleValue);

	const projectedBox = useRef(null);
	const [projectedBoxHeight, setProjectedBoxHeight] = useState(0);


	useEffect(() => {
		const rect = projectedBox.current?.getBoundingClientRect();
		setProjectedBoxHeight(rect?.width);
	}, [projectedBox, scaleAmount, windowWidth]);

	const BoxPositivePosition = (amount, i, isProjected) => {

		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.actualProjectedSum !== 0 && item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0));
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.actualProjectedSum));
		const top = itemsIndex[i];

		let bottom;
		if (itemsIndex[i - 1] != undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (lastQuarterActualAmount != 0 && isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}

		let indexCount;
		let css = {};
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: (scaleAmount.length - bottom) * graphLinesHeight, position: 'absolute' }
		}

		if (graphLinesHeight)
			css = { ...css, height: indexCount * graphLinesHeight }
		else
			css = { ...css, height: 0 }

		if (items.length - 1 === i) {
			css = { ...css, zIndex: isProjected ? 3 : 1 }
		}

		console.log('isPeojected', isProjected, 'projectedBoxHeight', projectedBoxHeight);


		return css;
	}

	const BoxNegativePosition = (amount, i) => {
		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.actualProjectedSum !== 0 && item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0));
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.actualProjectedSum));

		let top = itemsIndex[i];

		let bottom;
		if (itemsIndex[i - 1] !== undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (lastQuarterActualAmount !== 0) {
				bottom = findNearestIndex(scaleAmount, lastQuarterActualAmount);
			} else if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}

		let css = {};
		let indexCount;
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}

		css = { ...css, height: indexCount * graphLinesHeight, top: 'auto' }

		css = { ...css, right: 0 }

		return css;
	}

	const getActualDivHeight = () => {
		const index = isNegativeScale ? 0 : null;

		if (index !== null) {
			return { bottom: 0 };
		}
		return null;
	}

	const checkDifference = (arr) => {
		const result = [];
		for (let i = 0; i < arr.length; i++) {
			for (let j = i + 1; j < arr.length; j++) {
				if (Math.abs(arr[i] - arr[j]) < 3) {
					result.push({ num1: arr[i], num2: arr[j] });
				}
			}
		}
		return result;
	};

	const hideText = (itemAmount, i) => {
		let positiveItems = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.actualProjectedSum !== 0 && item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount > 0 || item.consolidatedBonus?.projectedAmount > 0));
		let allItems = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.actualProjectedSum !== 0 && item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0));
		if (positiveItems.length != allItems.length) {
			const itemsIndex = positiveItems.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.actualProjectedSum));
			const result = checkDifference(itemsIndex);
			const top = itemAmount >= 0 ? itemsIndex[i] : itemsIndex[i + 1];
			const ischeck = result.find(number => number.num1 === top || number.num2 === top);

			if ((allItems.length - 1) != i && ischeck && (ischeck.num1 === top || ischeck.num2 === top)) {
				const deff = ischeck.num1 - ischeck.num2;
				if ((deff * graphLinesHeight) <= 50) {
					return false;
				}
			}
		}

		return true;
	}

	return (
		<div className="actual-box-outer-wrap" style={getActualDivHeight()}>
			{quarterlyBonusMonths && quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0)).map((month, index) => {

				const filteredMonths = quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0));
				const isLastItem = index === filteredMonths.length - 1;

				if (month?.consolidatedBonus?.actualAmount < 0 || month?.consolidatedBonus?.projectedAmount < 0) {

					const monthAmount = month.consolidatedBonus.projectedAmount !== 0 && month.consolidatedBonus.actualAmount === 0 ? month.consolidatedBonus?.projectedAmount : month.consolidatedBonus?.actualAmount;

					const negativeBoxCss = BoxNegativePosition(month?.consolidatedBonus?.actualProjectedSum, index);

					return (
						<div className="loss-section-wrapper" key={month.id} style={negativeBoxCss}>
							{isLastItem && (
								<p className="box-highlight-number box-highlight-main-number active">{quarterName} {month.consolidatedBonus?.actualProjectedSum && formatUSD(month.consolidatedBonus?.actualProjectedSum)}</p>
							)}
							{isLastItem === false && (
								<p className="box-highlight-number box-highlight-main-number loss-text">{month.consolidatedBonus?.actualProjectedSum && formatUSD(month.consolidatedBonus?.actualProjectedSum)}</p>
							)}
							{negativeBoxCss != undefined && negativeBoxCss.height > 20 && (
								<div>
									<p className="budgeted-text actual-month">{getMonthshortName(month.monthName)} {month.consolidatedBonus.projectedAmount !== 0 && month.consolidatedBonus.actualAmount === 0 ? 'Projected' : 'Actual'}</p>
									{negativeBoxCss.height > 40 && (
										<p className="budgeted-text"><b>{monthAmount && formatUSD(monthAmount)}</b></p>
									)}
								</div>
							)}
						</div>
					);
				} else if (month?.consolidatedBonus?.actualAmount > 0 || month?.consolidatedBonus?.projectedAmount > 0) {

					const monthAmount = month.consolidatedBonus.projectedAmount !== 0 && month.consolidatedBonus.actualAmount === 0 ? month.consolidatedBonus?.projectedAmount : month.consolidatedBonus?.actualAmount;
					const isCurrentMonth = getCurrentMonthNameId === month.id ? true : false;


					const boxCss = BoxPositivePosition(month.consolidatedBonus?.actualProjectedSum, index, isCurrentMonth);
					const isHide = hideText(monthAmount, index);
					return (
						<div className={`${(getCurrentMonthNameId === month.id && month.consolidatedBonus.projectedAmount > 0 && month.consolidatedBonus.actualAmount === 0) ? 'projected-boxes mar-projected' : 'actual-boxes jan-boxes'}`} key={month.id} style={boxCss} ref={isCurrentMonth ? projectedBox : null}>
							{boxCss !== undefined && boxCss.height > 20 && isHide && (
								<>
									<p className="budgeted-text">{getMonthshortName(month.monthName)} {month.consolidatedBonus.projectedAmount > 0 && month.consolidatedBonus.actualAmount === 0 ? 'Projected' : 'Actual'}</p>
									{boxCss.height > 40 &&
										<>
									{month.consolidatedBonus.projectedAmount !== 0 && month.consolidatedBonus.actualAmount === 0 ?
										<p className="budgeted-text"><b>{month.consolidatedBonus?.projectedAmount && formatUSD(month.consolidatedBonus?.projectedAmount)}</b></p> :
										<p className="budgeted-text"><b>{month.consolidatedBonus?.actualAmount && formatUSD(month.consolidatedBonus?.actualAmount)}</b></p>
										}
									</>}
								</>
							)}

							{isLastItem == false && (
								<p className="box-highlight-number nowrap">{month.consolidatedBonus?.actualProjectedSum && formatUSD(month.consolidatedBonus?.actualProjectedSum)}</p>
							)}
							{isLastItem && (
								<p className="box-highlight-number box-highlight-main-number nowrap">{quarterName} {month.consolidatedBonus?.actualProjectedSum && formatUSD(month.consolidatedBonus?.actualProjectedSum)}</p>
							)}
						</div>
					);
				}
			})}

		</div>
	);
}

ActualBoxes.propTypes = {
	quarterlyBonusMonths: PropTypes.array,
	month: PropTypes.object,
	itemindex: PropTypes.number,
	getCurrentMonthNameId: PropTypes.number,
	scaleAmount: PropTypes.array,
	graphLinesHeight: PropTypes.number,
	quarterName: PropTypes.string,
	scaleValue: PropTypes.number,
	lastQuarterActualAmount: PropTypes.number,
	isNegativeScale: PropTypes.bool,
	windowWidth: PropTypes.number
};