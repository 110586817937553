import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './detail.css';
import './bonus-levels.css';
import Sidebar from '../../components/SideBar/Sidebar.js';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { GetQuarterlyBonus, getBonusLevels, getCurrentMonthName } from '../../store/bonuslevels/actions';
import { formatUSD } from '../../helpers/formatterHelper';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import PropTypes from 'prop-types';
import { shopMonitorSliderTime } from '../../helpers/enums';
import ActualBoxes from '../../components/bonuslevels/ActualBoxes';
import BudgetedBoxes from '../../components/bonuslevels/BudgetedBoxes';

function findNearestIndex(array, number) {
    let minDiff = Infinity;
    let nearestIndex = null;

    array.forEach((element, index) => {
        const diff = Math.abs(element - number);

        if (diff < minDiff) {
            minDiff = diff;
            nearestIndex = index;
        }
    });

    return nearestIndex;
}

const quarterName = {
    q1: "Q1",
    q2: "Q2",
    q3: "Q3",
    q4: "Q4"
}

export default function ShopBonusLevels({ handleloadinTiming }) {
    const dispatch = useDispatch();
    const graphLines = useRef(null);
    const { quarterlyBonus, bonusList } = useSelector(state => state.bonuslevels);
    const [filter, setFilter] = useState({ quarterlyBonusId: null, timezone: GetTimeZone(), previousQuarterlyBonus: 0 });
    const [quarterlyBonusData, setQuarterlyBonusData] = useState(quarterlyBonus.find(item => item.id === filter.quarterlyBonusId));
    const [graphLinesHeight, setGraphLinesHeight] = useState(0);
    const [scaleAmount, setScaleAmount] = useState([]);
    const [getCurrentMonthNameId, setGetCurrentMonthNameId] = useState(0);
    const [bonuslevelAmounts, setBonuslevelAmounts] = useState([]);
    const [scaleValue, setScaleValue] = useState(0);
    const [negativeScaleAmount, setNegativeScaleAmount] = useState([]);
    const [scaleAmountwithNegative, setScaleAmountwithNegative] = useState([]);
    const [isLevelsLoaing, setIsLevelsLoaing] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isTooHigh, setIsTooHigh] = useState(false);
    const isLoading = useSelector((state) => state.loading);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        document.title = 'Bonus Levels | CWP Team Connect';
        dispatch(showLoading());
        handleloadinTiming(shopMonitorSliderTime.LeadingTime);
        dispatch(getBonusLevels()).unwrap().then((res) => {       
            if (res.success) {
                const getCurrecntQuarterlyName = GetCurrecntQuarterlyBonus();                
                const getQuarterlyBonusId = res.data.find(item => item.name === getCurrecntQuarterlyName)?.id;            
                const getPreviousQuarterlyBonus = getQuarterlyBonusId > 0 && res.data.filter(item => item.id <= getQuarterlyBonusId);                
                const previousQuarterly = getPreviousQuarterlyBonus != undefined && getPreviousQuarterlyBonus.length > 0 && getPreviousQuarterlyBonus[getPreviousQuarterlyBonus.length - 2];
                getQuarterlyBonusId > 0 && setFilter({ ...filter, quarterlyBonusId: getQuarterlyBonusId, previousQuarterlyBonus: previousQuarterly !== undefined && previousQuarterly !== null ? previousQuarterly.id : 0 })
            }
            setIsLevelsLoaing(true);
        });
    }, []); 


    useEffect(() => {
        if (getCurrentMonthNameId === 0) {
            dispatch(getCurrentMonthName()).unwrap().then((res) => {
                if (res.success) {
                    setGetCurrentMonthNameId(res.data?.id);
                }
            });
        }
    }, [getCurrentMonthNameId]);


    useEffect(() => {
        if (filter.quarterlyBonusId !== null) {
            dispatch(GetQuarterlyBonus(filter)).unwrap().then((res) => {
                if (res.success) {

                    let item = res.data.filter(x =>
                        x.quarterlyBonusMonths.some(month =>
                            month.consolidatedBonus.actualAmount !== 0 || month.consolidatedBonus.projectedAmount !== 0
                        )
                    );

                    if (item.length === 0) {
                        const getPreviousQuarterlyBonus = bonusList.filter(item => item.id <= filter.quarterlyBonusId);
                        const cQuarterly = getPreviousQuarterlyBonus != undefined && getPreviousQuarterlyBonus.length > 0 && getPreviousQuarterlyBonus[getPreviousQuarterlyBonus.length - 2];
                        const previousQuarterly = getPreviousQuarterlyBonus != undefined && getPreviousQuarterlyBonus.length > 0 && getPreviousQuarterlyBonus[getPreviousQuarterlyBonus.length - 3];
                        cQuarterly && cQuarterly != undefined && setFilter({ ...filter, quarterlyBonusId: cQuarterly.id, previousQuarterlyBonus: previousQuarterly !== undefined && previousQuarterly !== null ? previousQuarterly.id : 0 })

                    } else {
                        setQuarterlyBonusData(res.data.find(item => item.id === filter.quarterlyBonusId));
                    }

                    setQuarterlyBonusData(res.data.find(item => item.id === filter.quarterlyBonusId));
                }
                setIsDataLoading(true);
            });
        }        
    }, [filter]);


    useEffect(() => {
        if (isLevelsLoaing && isDataLoading && isLoading) {
            dispatch(hideLoading());
            handleloadinTiming(shopMonitorSliderTime.default);
        }
    }, [isLevelsLoaing, isDataLoading, isLoading]);

    useEffect(() => {       
        const rect = graphLines.current.getBoundingClientRect();
        let height = rect.height;
        if (scaleAmountwithNegative.length > 30) {
            height = 679 / scaleAmountwithNegative.length;
            setIsTooHigh(true);
        }

        setGraphLinesHeight(height);
    }, [graphLines, scaleAmount, scaleAmountwithNegative, windowWidth]);


    useEffect(() => {
        let scaleAmountList = [];
        const nagativeScaleAmountList = [];
        const scaleAmountListWithnagative = [];
        const topScale = quarterlyBonusData?.bonuslevels[quarterlyBonusData?.bonuslevels.length - 1];
        const bottomScale = quarterlyBonusData?.bonuslevels[0];
        const lastQuarterBudgetAmount = quarterlyBonusData?.lastQuarterBudgetAmount;
        const lastQuarterActualAmount = quarterlyBonusData?.lastQuarterActualAmount;

        if (topScale && bottomScale) {
            const divVal = quarterlyBonusData?.bonuslevels.length - 1;
            const calScaleAmount = Math.floor(Math.abs((topScale.budgetAmount - bottomScale.budgetAmount) / divVal));
            const modFirstLabeledRow = (bottomScale.budgetAmount % calScaleAmount)
            setScaleValue(modFirstLabeledRow);

            const topScale1 = Math.round(quarterlyBonusData?.quarterTotal + (quarterlyBonusData?.quarterTotal * 5 / 100));
            const bonusMonths = quarterlyBonusData?.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId);
            const topScale2Months = bonusMonths.length > 0 && bonusMonths[0];
            const topScale2 = topScale2Months && topScale2Months.consolidatedBonus?.actualProjectedSum && Math.round(topScale2Months.consolidatedBonus?.actualProjectedSum + (topScale2Months.consolidatedBonus?.actualProjectedSum * 5 / 100));
            const topScale3 = topScale.budgetAmount + (calScaleAmount * 2);

            let topScaleValue;
            if (topScale1 >= topScale2 && topScale1 >= topScale3) {
                topScaleValue = topScale1;
            } else if (topScale2 >= topScale1 && topScale2 >= topScale3) {
                topScaleValue = topScale2;
            } else {
                topScaleValue = topScale3;
            }

            let staring = 0;

            const nagativeValues = quarterlyBonusData?.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId && ((item.consolidatedBonus.actualProjectedSum < 0 || item.consolidatedBonus.actualAmount < 0) || (item.consolidatedBonus.budgetSum < 0 || item.consolidatedBonus.budgetAmount < 0)));
         


            let i;
            if (lastQuarterBudgetAmount > 0 || lastQuarterActualAmount > 0) {

                if (lastQuarterBudgetAmount < lastQuarterActualAmount) {
                    staring = lastQuarterBudgetAmount;
                } else if (lastQuarterActualAmount < lastQuarterBudgetAmount) {
                    staring = lastQuarterActualAmount;
                }


                if (nagativeValues && nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < staring)) {
                    scaleAmountListWithnagative.push(0);
                    let value = -modFirstLabeledRow;
                    let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < 0) || nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < staring);
                    let endValue = nagativeValue && nagativeValue.consolidatedBonus.actualProjectedSum - modFirstLabeledRow;
               

                    while (value >= endValue) {
                        nagativeScaleAmountList.push(value);
                        scaleAmountListWithnagative.push(Math.round(value));
                        value -= calScaleAmount;
                    }

                    if (staring >= endValue && endValue > 0) {
                        staring = endValue;
                    }

                    if (staring < 0) {
                        staring = calScaleAmount;
                    }                    
                } else if (nagativeValues && nagativeValues.find(item => item.consolidatedBonus.budgetSum < staring)) {
                    scaleAmountListWithnagative.push(0);
                    let value = -modFirstLabeledRow;
                    let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.budgetSum < 0) || nagativeValues.find(item => item.consolidatedBonus.budgetSum < staring);
                    let endValue = nagativeValue && nagativeValue.consolidatedBonus.budgetSum - modFirstLabeledRow;



                    while (value >= endValue) {
                        nagativeScaleAmountList.push(value);
                        scaleAmountListWithnagative.push(Math.round(value));
                        value -= calScaleAmount;
                    }

                    if (staring >= endValue && endValue > 0) {
                        staring = endValue;
                    }

                    if (staring < 0) {
                        staring = calScaleAmount;
                    }


                } else if (staring < 0) {
                    scaleAmountListWithnagative.push(0);
                    let value = -modFirstLabeledRow;
                    let endValue = staring - modFirstLabeledRow;
                    while (value >= endValue) {
                        nagativeScaleAmountList.push(Math.round(value));
                        scaleAmountListWithnagative.push(Math.round(value));
                        value -= calScaleAmount;
                    }
                    staring = calScaleAmount;
                }


                if (nagativeScaleAmountList.length > 0) {
                    staring = calScaleAmount;
                }

                for (i = modFirstLabeledRow; i <= topScaleValue; i += calScaleAmount) {
                    if (i >= staring) {
                        scaleAmountList.unshift(Math.round(i));
                        scaleAmountListWithnagative.unshift(Math.round(i));
                    }
                }

            } else {

                for (i = modFirstLabeledRow; i <= topScaleValue; i += calScaleAmount) {
                    scaleAmountList.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i));
                }               

                const nagativeActualProjectedSum = nagativeValues && nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < modFirstLabeledRow);
                const nagativeBudgetSum = nagativeValues && nagativeValues.find(item => item.consolidatedBonus.budgetSum < modFirstLabeledRow);


                if (nagativeActualProjectedSum && nagativeBudgetSum) {
                    if (nagativeActualProjectedSum.consolidatedBonus.actualProjectedSum < modFirstLabeledRow && nagativeBudgetSum.consolidatedBonus.budgetSum < modFirstLabeledRow) {
                        const nagativeItem = nagativeActualProjectedSum.consolidatedBonus.actualProjectedSum < nagativeBudgetSum.consolidatedBonus.budgetSum ? nagativeActualProjectedSum.consolidatedBonus.actualProjectedSum : nagativeBudgetSum.consolidatedBonus.budgetSum;
                        scaleAmountListWithnagative.push(0);
                        let value = -modFirstLabeledRow;
                        let endValue = nagativeItem;
                        while (value >= endValue) {
                            nagativeScaleAmountList.push(value);
                            scaleAmountListWithnagative.push(Math.round(value));
                            value -= calScaleAmount;
                        }
                    }

                } else if (nagativeActualProjectedSum) {
                    scaleAmountListWithnagative.push(0);
                    let value = -modFirstLabeledRow;
                    let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < modFirstLabeledRow);
                    let endValue = nagativeValue.consolidatedBonus.actualProjectedSum - modFirstLabeledRow;
                    while (value >= endValue) {
                        nagativeScaleAmountList.push(value);
                        scaleAmountListWithnagative.push(Math.round(value));
                        value -= calScaleAmount;
                    }
                } else if (nagativeBudgetSum) {
                    scaleAmountListWithnagative.push(0);
                    let value = -modFirstLabeledRow;
                    let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.budgetSum < modFirstLabeledRow);
                    let endValue = nagativeValue.consolidatedBonus.budgetSum - modFirstLabeledRow;
                    while (value >= endValue) {
                        nagativeScaleAmountList.push(value);
                        scaleAmountListWithnagative.push(Math.round(value));
                        value -= calScaleAmount;
                    }
                }

            }

            if (topScale1 == topScaleValue) {
                const getIndex = findNearestIndex(scaleAmountList, quarterlyBonusData?.quarterTotal);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));
                }
            }

            if (topScale2 == topScaleValue && topScale2Months) {
                const getIndex = findNearestIndex(scaleAmountList, topScale2Months.consolidatedBonus?.actualProjectedSum);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));
                }
            }

            if (topScale3 == topScaleValue) {
                const getIndex = findNearestIndex(scaleAmountList, topScale.budgetAmount);
                if (getIndex == 1) {
                    scaleAmountList.unshift(i);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                } else if (getIndex == 0) {
                    scaleAmountList.unshift(i);
                    scaleAmountList.unshift(i * 2);
                    scaleAmountListWithnagative.unshift(Math.round(i));
                    scaleAmountListWithnagative.unshift(Math.round(i * 2));
                }
            }

            let updateBonuslevelAmounts = quarterlyBonusData && quarterlyBonusData.bonuslevels.length > 0 && quarterlyBonusData.bonuslevels.map(item => item.budgetAmount);
            if (updateBonuslevelAmounts) {
                for (i = 1; i <= 3; i++) {
                    updateBonuslevelAmounts.push((calScaleAmount * i) + updateBonuslevelAmounts[updateBonuslevelAmounts.length - 1]);
                }

                for (i = 1; i <= 3; i++) {
                    updateBonuslevelAmounts.unshift(updateBonuslevelAmounts[0] - (calScaleAmount * i));
                }
                setBonuslevelAmounts(updateBonuslevelAmounts);
            }
        }

        setScaleAmount(scaleAmountList);
        setNegativeScaleAmount(nagativeScaleAmountList);
        setScaleAmountwithNegative(scaleAmountListWithnagative)
    }, [quarterlyBonusData, getCurrentMonthNameId]);

    const GetCurrecntQuarterlyBonus = () => {
        const month = new Date().getMonth();
        if (month >= 0 && month <= 2) {
            return quarterName.q1; 
        }
        else if (month >= 3 && month <= 5) {
            return quarterName.q2;
        }
        else if (month >= 6 && month <= 8) {
            return quarterName.q3;
        }
        else if (month >= 9 && month <= 11) {
            return quarterName.q4;
        }
    }    

    const getTop2 = () => {
        const amount = scaleAmount.length > 0 && scaleAmount[0];
        return (
            <div className="bonus-levels-graph-lines" ref={graphLines} style={graphLinesHeight > 0 ? { height: `${graphLinesHeight}px` } : {}}>
                <p className="bonus-levels-numbers" style={{ top: `-${graphLinesHeight / 2}px` }}>{formatUSD(amount)}</p>
            </div>
        );
    }

    const calQGoalHeight = () => {
        const index = findNearestIndex(scaleAmountwithNegative, quarterlyBonusData?.quarterTotal);
        return (index + 1) * graphLinesHeight;
    } 
    
    //TODO BE help needed not sure where to put this to get the .bonus-levels-graph-inner-wrap to scrollToBottom
    //// Create a ref for the element you want to scroll to
    const graphInnerWrapRef = useRef(null);

    //// Function to scroll to the bottom of the element
    const scrollToBottom = () => {
        if (graphInnerWrapRef.current) {
            graphInnerWrapRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    };

    //// Use useEffect to scroll to the bottom when the component is loaded
    useEffect(() => {
        scrollToBottom();
    }, [graphInnerWrapRef, scaleAmount]);


    const reverseArray = () => {
        var quarterlyBonusMonths = quarterlyBonusData?.quarterlyBonusMonths;
        return quarterlyBonusMonths && [...quarterlyBonusMonths].reverse();
    };

    return (
        <div className="monitors-inner">
            <div className="monitors-item bonus-levels-left">
                <div className="monitors-header-wrap">
                    <p className="monitors-header-title">{quarterlyBonusData && quarterlyBonusData.name} Bonus Levels</p>
                    <span className="monitors-header-icon"><svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.25 17.75H14.75V16.25H16.5962C16.925 16.25 17.1995 16.1399 17.4197 15.9197C17.6399 15.6995 17.75 15.425 17.75 15.0962V11.4038C17.75 11.075 17.6399 10.8005 17.4197 10.5803C17.1995 10.3601 16.925 10.25 16.5962 10.25H11.75V7.25H17.75V5.75H14.75V4.25H13.25V5.75H11.4038C11.075 5.75 10.8005 5.8601 10.5803 6.0803C10.3601 6.30047 10.25 6.57499 10.25 6.90384V10.5962C10.25 10.925 10.3601 11.1995 10.5803 11.4197C10.8005 11.6399 11.075 11.75 11.4038 11.75H16.25V14.75H10.25V16.25H13.25V17.75ZM2.92306 21.5C2.23269 21.5 1.65625 21.2688 1.19375 20.8063C0.73125 20.3438 0.5 19.7673 0.5 19.0769V2.92306C0.5 2.23269 0.73125 1.65625 1.19375 1.19375C1.65625 0.73125 2.23269 0.5 2.92306 0.5H25.0769C25.7673 0.5 26.3438 0.73125 26.8063 1.19375C27.2688 1.65625 27.5 2.23269 27.5 2.92306V19.0769C27.5 19.7673 27.2688 20.3438 26.8063 20.8063C26.3438 21.2688 25.7673 21.5 25.0769 21.5H2.92306ZM2.92306 20H25.0769C25.3077 20 25.5192 19.9039 25.7115 19.7115C25.9038 19.5192 26 19.3077 26 19.0769V2.92306C26 2.69231 25.9038 2.48078 25.7115 2.28845C25.5192 2.09615 25.3077 2 25.0769 2H2.92306C2.69231 2 2.48078 2.09615 2.28845 2.28845C2.09615 2.48078 2 2.69231 2 2.92306V19.0769C2 19.3077 2.09615 19.5192 2.28845 19.7115C2.48078 19.9039 2.69231 20 2.92306 20Z" fill="#00FF38" />
                    </svg></span>
                </div>
                <div className={`bonus-levels-graph-main-wrap shopmonitors-view bonus-levels ${isTooHigh ? ' shopmonitors-isTooHigh': ''}`}>
                    <div className="bonus-levels-graph-outer-wrap">
                        <div className="bonus-levels-graph-inner-wrap" ref={graphInnerWrapRef}>
                            {getTop2()}
                            
                            {scaleAmount && scaleAmount.length > 0 && scaleAmount.map((item, index) => {

                                const currentValue = item;                      
                                const bonuslevelIndex = findNearestIndex(bonuslevelAmounts, item);
                                const foundItem = bonuslevelIndex && quarterlyBonusData?.bonuslevels.find(bonusItem => bonusItem.budgetAmount === bonuslevelAmounts[bonuslevelIndex]);

                                const lastMonthItem = quarterlyBonusData?.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId);
                                const lastMonth = lastMonthItem && lastMonthItem.length > 0 && lastMonthItem[0];
                                const checkItem = lastMonth && lastMonth.consolidatedBonus.actualProjectedSum > currentValue ? true : false;

                                return (
                                    <div className={`bonus-levels-graph-lines ${foundItem && foundItem.number != 1 && foundItem.number != 2 ? 'selected-graph-line' : ''}`} key={index} style={{ height: `${graphLinesHeight}px`}}>
                                        <p className="bonus-levels-numbers" style={{top: `-${graphLinesHeight / 2}px` }}>{formatUSD(item)}</p>
                                        {foundItem !== undefined && foundItem !== 0 && (
                                            <p className="bonus-levels-highlighted-number">{foundItem.number}</p>
                                        )}
                                        {foundItem !== undefined && foundItem !== 0 && checkItem !== null && checkItem !== undefined && checkItem === true && (
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.01683 8.51666L4.3835 6.88333C4.2835 6.78333 4.16127 6.73333 4.01683 6.73333C3.87238 6.73333 3.74461 6.78888 3.6335 6.89999C3.5335 6.99999 3.4835 7.12222 3.4835 7.26666C3.4835 7.41111 3.5335 7.53333 3.6335 7.63333L5.66683 9.68333C5.76683 9.78333 5.8835 9.83333 6.01683 9.83333C6.15016 9.83333 6.26683 9.78333 6.36683 9.68333L10.3502 5.69999C10.4613 5.58888 10.5168 5.46111 10.5168 5.31666C10.5168 5.17222 10.4613 5.04444 10.3502 4.93333C10.2391 4.83333 10.1085 4.78611 9.9585 4.79166C9.8085 4.79722 9.6835 4.84999 9.5835 4.94999L6.01683 8.51666ZM7.00016 13.6667C6.08905 13.6667 5.22794 13.4917 4.41683 13.1417C3.60572 12.7917 2.89738 12.3139 2.29183 11.7083C1.68627 11.1028 1.2085 10.3944 0.858496 9.58333C0.508496 8.77222 0.333496 7.91111 0.333496 6.99999C0.333496 6.07777 0.508496 5.21111 0.858496 4.39999C1.2085 3.58888 1.68627 2.88333 2.29183 2.28333C2.89738 1.68333 3.60572 1.20833 4.41683 0.858328C5.22794 0.508328 6.08905 0.333328 7.00016 0.333328C7.92238 0.333328 8.78905 0.508328 9.60016 0.858328C10.4113 1.20833 11.1168 1.68333 11.7168 2.28333C12.3168 2.88333 12.7918 3.58888 13.1418 4.39999C13.4918 5.21111 13.6668 6.07777 13.6668 6.99999C13.6668 7.91111 13.4918 8.77222 13.1418 9.58333C12.7918 10.3944 12.3168 11.1028 11.7168 11.7083C11.1168 12.3139 10.4113 12.7917 9.60016 13.1417C8.78905 13.4917 7.92238 13.6667 7.00016 13.6667Z" fill="#00FF38" />
                                            </svg>
                                        )}
                                    </div>
                                );
                            })}
                            <div className="bonus-levels-graph-baseline" style={negativeScaleAmount.length > 0 ? { height: `${graphLinesHeight}px` } : {}}>
                            </div>
                            {negativeScaleAmount && negativeScaleAmount.map((item, index) => (
                                <div className="bonus-levels-graph-lines" key={index} style={{ height: `${graphLinesHeight}px` }}>
                                    <p className="bonus-levels-numbers" style={{ top: `-${graphLinesHeight / 2}px` }}>{formatUSD(item)}</p>
                                </div>
                            ))}
                        </div>
                        <div className="q1-goal-wrap" style={{ top: `${calQGoalHeight()}px` }}>
                            <p className="q1-goal-text">
                                {quarterlyBonusData && (`${quarterlyBonusData.name} Budgeted`)}<br />
                                <span className="d-sm-none">{quarterlyBonusData && formatUSD(quarterlyBonusData.quarterTotal)}</span>
                            </p>
                        </div> 
                        


                        <BudgetedBoxes
                            quarterlyBonusMonths={reverseArray()}
                            getCurrentMonthNameId={getCurrentMonthNameId}
                            scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
                            lastQuarterActualAmount={quarterlyBonusData?.lastQuarterActualAmount}
                            isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
                            graphLinesHeight={graphLinesHeight}
                        />

                        <ActualBoxes
                            quarterlyBonusMonths={reverseArray()}
                            getCurrentMonthNameId={getCurrentMonthNameId}
                            scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
                            graphLinesHeight={graphLinesHeight}
                            quarterName={quarterlyBonusData?.name}
                            scaleValue={scaleValue}
                            lastQuarterActualAmount={quarterlyBonusData?.lastQuarterActualAmount}
                            isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
                            windowWidth={windowWidth}
                        />

                        
                    </div>
                </div>

            </div>
            <Sidebar pageType={"bonus-level"} />
        </div>
    );
}


ShopBonusLevels.propTypes = {
    handleloadinTiming: PropTypes.func
};