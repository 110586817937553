import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/auth';
import { useNavigate } from 'react-router-dom';
import { hideLoading } from '../../store/loading-reducer';
export default function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logoutUser());
        navigate('/login');
        dispatch(hideLoading());
        localStorage.removeItem('monitor_mode');
    }, [])
    return (<></>)
}
