import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ComposeDropDownOption } from "../../../components/admin/compose/compose-dropdown-option";
import { getEmployeeList, getSearchEmployeeList } from '../../../store/employee/actions';
import AdminError from '../../../components/admin/admin-error';
import { getAllEmployeeDepartment } from '../../../api/group';
import DatePickerModal from './DatePickerModal';
import MessagePopup from "../../../components/admin/compose/message-popup";

import { AddNewCompose, getComposedById, GetCountPeoples } from "../../../store/compose/actions";
import { formatDateTimeForUserTimeZone } from '../../../components/common/DateTimeFormatter';
import { formatPhoneNumberAsYouType } from '../../../helpers/formatterHelper';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { getSiteUsersByDepartmentIds } from '../../../store/compose/actions';
import CustomMultiValue from './CustomMultiValue';

const ChildBox = ({ child, parentId, childId, checkBoxItems, handleChildChange }) => {
    return (
        <>
            <label className="admin-checkbox-label inline">
                <input
                    className="admin-inline-checkbox employeecount"
                    type="checkbox"
                    data-employeeCount={child?.noOfPeople}
                    checked={checkBoxItems[parentId]?.[childId] || false}
                    disabled={child?.noOfPeople === 0}
                    onChange={(event) => handleChildChange(event, parentId, childId)}
                />
                {child.name} ({child?.noOfPeople})
            </label>
            <br />
            {child.departments &&
                child.departments.map((subItem) => (
                    <ChildBox
                        key={subItem.id}
                        child={subItem}
                        parentId={childId}
                        childId={subItem.id}
                        checkBoxItems={checkBoxItems}
                        handleChildChange={handleChildChange}
                    />
                ))}
        </>
    );
};

ChildBox.propTypes = {
    child: PropTypes.object.isRequired,
    parentId: PropTypes.number.isRequired,
    childId: PropTypes.number.isRequired,
    checkBoxItems: PropTypes.object.isRequired,
    handleChildChange: PropTypes.func.isRequired,
};

const defualtComposeObj = {
    id: 0,
    content: '',
    dateTimeSend: new Date(),
    timeSent: null,
    sendType: '',
    composeemployees: [],
    groups: [],
    dateOnly: null,
    recipients: null,
    composeAlternateMobiles: [],
    localTimeZone: null

};

export function Compose({ composeid, onDeleteTrigger }) {
    const dispatch = useDispatch();
    const [companyDepartmentList, setCompanyDepartmentList] = useState([]);
    const [employeeCount, SetEmployeeCount] = useState(0);
    const { searchEmployeeList } = useSelector(state => state.employee);
    const [getAddGroupModal, setAddGroupModal] = useState(false)
    const [getDateTimeModal, setDateTimeModal] = useState(false)
    const [currentDate, setCurrentDate] = useState(new Date());
    const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const [getSentMessageModal, setSentMessageModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState([]);
    const [checkBoxItems, setCheckBoxItem] = useState({});
    const [composeModel, setComposeModel] = useState(defualtComposeObj);
    const [filter, setFilter] = useState({ query: '', companyId: 0, sort: 0, searchFirstName: '', searchLastName: '', searchMobile: '' });
    const [toErrorMessage, setToErrorMessage] = useState(null);
    const [errorFullMessage, setErrorFullMessage] = useState(null);
    const [charCountDisplay, setCharCountDisplay] = useState('29/160'); // New state for character count display
    const [charCountClass, setCharCountClass] = useState('text-green'); // New state for character count class
    const [segmentCount, setSegmentCount] = useState('');
    const [sendingNow, setSendingNow] = useState(true);
    const [showMessagePopupModal, setShowMessagePopupModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { GetCountPeoplesList } = useSelector(state => state.compose);
    const fixedConentText = "From Team Connect (no reply):";
    const [orgCount, setOrgCount] = useState([]);
    const [error, setError] = useState('');
    const [userDetails, setUserDetails] = useState([]);
    const [isShowuserInfo, setIsShowuserInfo] = useState(false);
    const selectRef = useRef(null);
    const [displyText, setDisplyText] = useState('');



    const handleOutsideClick = (event) => {
        event.stopPropagation()
    }
    useEffect(() => {       
        dispatch(getEmployeeList(filter));
    }, [filter]);

    useEffect(() => {
        setComposeModel(prevComposeModel => ({
            ...prevComposeModel,
            content: fixedConentText,
        })); 
        dispatch(getSearchEmployeeList(null));
    }, []);

    useEffect(() => {
        if (composeid) {
            setSendingNow(false);
        }
        else {
            setSendingNow(true);
        }

    }, [composeid]);

    const handleClick = (event, parentId) => {
        const isChecked = event.target.checked;
        const updatedItems = { ...checkBoxItems };

        if (isChecked) {
            updatedItems[parentId] = {
                ...updatedItems[parentId],
                isChecked,
            };
            let totolingrp = 0;
            // Check/uncheck all child checkboxes related to this parent
            const parentItem = companyDepartmentList.find((item) => item.id === parentId);

            if (parentItem.departments) {
                parentItem.departments.forEach((child) => {
                    if (child.noOfPeople > 0) {
                        totolingrp += child.noOfPeople
                        updatedItems[parentId][child.id] = isChecked;
                    }
                });


                setOrgCount(prevOrgCount => ([
                    ...prevOrgCount,
                    { [parentId]: totolingrp }
                ]));

            }
        }
        else {

            setOrgCount(prevOrgCount => (
                prevOrgCount.filter(item => !Object.prototype.hasOwnProperty.call(item, parentId))
            ));

            delete updatedItems[parentId];
        }

        setCheckBoxItem(updatedItems);

    };

    useEffect(() => {
        countEmployeeInGroup();
    }, [checkBoxItems]);



    const handleChange = (selectedItem) => {
        setSelectedOption(selectedItem);
        const composeToEmployees = selectedItem.filter(item => item.recordCount === undefined || item.recordCount === null).map((item) => item.id);
        const composeToCreateGropup = selectedItem.filter(item => item.isNewCreateGroup).map((item) => item);
        const composeToCreateMobileList = selectedItem.filter(item => item.isMobile).map((item) => item);
        setComposeModel({
            ...composeModel,
            composeemployees: composeToEmployees,
            groups: composeToCreateGropup,
            composeAlternateMobiles: composeToCreateMobileList

        });
        setToErrorMessage(null);
        setFilter({ ...filter, query: '' });
        setDisplyText('');
    };
    const getOptionValue = option => option.name !== undefined && option.name !== null ? option.name : option.mobile.toString();

    const getOptionLabel = option => {
        if (option.isNewCreateGroup) {
            return `${option.name}`;
        }
        else if (option.isMobile) {
            return `${option.name}`;
        }
        else if (option.name !== undefined && option.name !== null && option.mobile !== undefined && option.mobile !== null) {
            return `${option.mobile}`;
        }
        else {
            return `${option.firstName} ${option.lastName}`;
        }
    }

   
    const noOptionsMessage = () => null;

    const handleChildChange = (event, parentId, childId) => {
        const isChecked = event.target.checked;
        let peopleCount = event.target.getAttribute('data-employeeCount');

        setCheckBoxItem(prevState => {
            // Update the state for the specific child checkbox
            const updatedItems = {
                ...prevState,
                [parentId]: {
                    ...prevState[parentId],
                    [childId]: isChecked,
                },
            };

            const updatedDepartmentItems = updatedItems[parentId];

            if (updatedDepartmentItems) {
                // Filter out the 'isChecked' key
                const filteredItems = Object.keys(updatedDepartmentItems)
                    .filter(key => key !== 'isChecked')
                    .reduce((obj, key) => {
                        obj[key] = updatedDepartmentItems[key];
                        return obj;
                    }, {});

                // Determine if all child checkboxes are checked
                const allChildrenChecked = Object.values(filteredItems).some(value => value === true);

                // Update the parent checkbox state
                updatedItems[parentId] = {
                    ...updatedItems[parentId],
                    isChecked: allChildrenChecked, // Update parent checkbox state based on child checkboxes    
                };

                if (!isChecked && !allChildrenChecked) {
                    updatedItems[parentId].isChecked = false;
                    delete updatedItems[parentId];
                    setOrgCount(prevOrgCount => (
                        prevOrgCount.filter(item => !Object.prototype.hasOwnProperty.call(item, parentId))
                    ));
                }
                else {
                    if (!isChecked) {

                        let data = orgCount.some(obj => Object.prototype.hasOwnProperty.call(obj, parentId));

                        if (data) {
                            // If parentId exists, update the peopleCount for that entry
                            setOrgCount(prevOrgCount => {
                                return prevOrgCount.map(entry => {
                                    if (Object.prototype.hasOwnProperty.call(entry, parentId)) {
                                        return {
                                            [parentId]: entry[parentId] - parseInt(peopleCount)
                                        };
                                    }
                                    return entry;
                                });
                            });
                        }
                    }
                    else {
                        let data = orgCount.some(obj => Object.prototype.hasOwnProperty.call(obj, parentId));

                        if (data) {
                            // If parentId exists, update the peopleCount for that entry
                            setOrgCount(prevOrgCount => {
                                return prevOrgCount.map(entry => {
                                    if (Object.prototype.hasOwnProperty.call(entry, parentId)) {
                                        return {
                                            [parentId]: entry[parentId] + parseInt(peopleCount)
                                        };
                                    }
                                    return entry;
                                });
                            });
                        }
                        else {
                            // If parentId does not exist, add a new entry
                            setOrgCount(prevOrgCount => ([
                                ...prevOrgCount,
                                { [parentId]: parseInt(peopleCount) }
                            ]));
                        }


                    }
                }
            }

            return updatedItems;
        });
    };

    const countEmployeeInGroup = () => {
        let total = 0;
        const checkboxes = document.querySelectorAll('.employeecount');
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked && !checkbox.disabled) {
                const noOfEmployee = parseInt(checkbox.getAttribute('data-employeeCount'));
                total += noOfEmployee;
            }
        });

        SetEmployeeCount(total);
    };



    useEffect(() => {
        if (getAddGroupModal) {
            getAllEmployeeDepartment().then((response) => {
                let responseData = response.data.data;
                setCompanyDepartmentList(responseData);
            });
        }
        SetEmployeeCount(0);
        setCheckBoxItem({});
        setErrorMessage(null);
    }, [getAddGroupModal]);

    const sentMessageModal = () => {
        setSentMessageModal(!getSentMessageModal)
    }

    const addGroupModal = () => {
        setAddGroupModal(!getAddGroupModal)
        setOrgCount([]);
    }
    const dateTimeModal = () => {
        setDateTimeModal(!getDateTimeModal)
    }

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleUpdateTimeModel = (date, time) => {
        const selectedDateTime = new Date(date);
        setComposeModel({
            ...composeModel,
            dateTimeSend: selectedDateTime,
            dateOnly: formatDate(date),
            timeSent: time
        });

        handleSendPopup();
    };

    const handlePrevMonth = () => {
        setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
    };


    const getDaysInMonth = (month, year) => {
        return new Array(31).fill(null).map((_, index) => {
            const day = index + 1;
            return new Date(year, month, day).getMonth() === month ? day : null;
        });
    };

    const days = getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());

    const updateCharCount = (e) => {
        const text = e.target.value;
        const length = text.length;

        if (!text.startsWith(fixedConentText)) {
            setComposeModel(prevComposeModel => ({
                ...prevComposeModel,
            }));
        } else {
            setComposeModel(prevComposeModel => ({
                ...prevComposeModel,
                content: text
            }));
        }
        // Check for special characters
        let hasSpecialChars = false;
        for (let i = 0; i < length; i++) {
            const charCode = text.charCodeAt(i);
            if (charCode < 0x20 || charCode > 0x7E) {
                hasSpecialChars = true;
                break;
            }
        }

        let smsLimit = 160; // Default segment limit

        if (hasSpecialChars) {
            smsLimit = 70; // Adjust segment limit if special characters are present
        }

        const smsParts = Math.ceil(length / smsLimit);

        const displayText = `${length}/${smsParts * smsLimit}`;
        const segmentDisplayText = smsParts > 1 ? `X${smsParts}` : '';
        setSegmentCount(segmentDisplayText);
        setCharCountDisplay(displayText);

        if (length <= smsLimit) {
            setCharCountClass('text-green');
        } else if (length <= smsLimit * 2) {
            setCharCountClass('badge-warning');
        } else {
            setCharCountClass('badge-danger');
        }
    };

    const handleContentChange = (e) => {
        const { value } = e.target;
        setComposeModel({
            ...composeModel,
            content: value,
        });
        updateCharCount(e);
        setErrorFullMessage(null);
    };


    const handleChangeSendType = (type) => {
        setComposeModel({
            ...composeModel,
            sendType: type,
        });
        if (type === 'Now') {
            setSendingNow(true);
        } else {
            setSendingNow(false);
            setDateTimeModal(!getDateTimeModal)
        }
    };


    const handleSendPopup = () => {
        let errors = false;
        if (selectedOption.length === 0) {
            setToErrorMessage('To field is required.');
            errors = true;
        }
        if (composeModel.content === null || composeModel.content === '') {
            setErrorFullMessage('Full message field is required.');
            errors = true;
        }

        if (errors) {
            return;
        }

        if (composeModel.composeemployees.length > 0 || composeModel.groups.length > 0 || composeModel.composeAlternateMobiles.length > 0) {
            if (selectedOption && selectedOption.length > 0) {
                let recipients = [];

                selectedOption.forEach(obj => {
                    if ('fullName' in obj) {
                        recipients.push({ type: 'indivisible', data: obj });
                    } else if ('isNewCreateGroup' in obj) {
                        recipients.push({ type: 'group', data: obj });
                    } else if ('isMobile' in obj) {
                        recipients.push({ type: 'alternatemobile', data: obj });
                    }
                });

                // Wrap the combined string in curly braces
                let jsonRecipients = JSON.stringify(recipients);
                setComposeModel(prevState => ({
                    ...prevState,
                    recipients: jsonRecipients
                }));

                setShowMessagePopupModal(true);
                handlePeopleCount();
            }
        }
    };

    const handleSendCompose = async () => {
        setShowMessagePopupModal(false);
        try {
            dispatch(showLoading());
            const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
            composeModel.localTimeZone = timeZoneId;            
            const response = await dispatch(AddNewCompose(composeModel)).unwrap();
            localStorage.setItem('timezone', JSON.stringify(response));
            if (!response.data.success) {
                if (!sendingNow) {
                    window.location.href = '/siteadmin/textalerts/scheduled';

                }
                else {

                    setTimeout(() => {
                        window.location.href = '/siteadmin/textalerts';
                    }, 5000);
                }
            } else {
                dispatch(hideLoading());
                setError(response.data.message);
            }

        } catch (error) {
            dispatch(hideLoading());
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError(error.response ? error.response.responseText : error.message);
            }
        }
    }
    const handlePeopleCount = () => {
        dispatch(GetCountPeoples(composeModel))
    }
    const handleCreateGroup = () => {
        setIsShowuserInfo(false);
        if (employeeCount > 0) {
            const groupData = [];


            for (const [companyId, companyData] of Object.entries(checkBoxItems)) {

                const departmentIds = Object.keys(companyData)
                    .filter(key => key !== 'isChecked')
                    .map(key => parseInt(key));

                groupData.push({
                    companyId: parseInt(companyId),
                    departmentIds: departmentIds
                });
            }

            // Create the final data object
            const selectedGroupData = {
                composedGroupDatas: groupData,
                name: employeeCount === 1 ? '1 Person ' : `${employeeCount} People`,
                isNewCreateGroup: true,
                recordCount: employeeCount
            };


            setSelectedOption(prevState => [...prevState, selectedGroupData]);


            setComposeModel({
                ...composeModel,
                groups: [...composeModel.groups, selectedGroupData]
            });


            addGroupModal();

        } else {
            setErrorMessage('No People in the group.');
        }
    }

    const showFormatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (composeid != undefined && composeid !== null) {
                try {
                    const result = await dispatch(getComposedById(composeid)).unwrap();
                    if (result) {
                        setSelectedOption(result?.data && result?.data?.composeUsers);
                        const composeToEmployees = result?.data?.composeUsers && result?.data?.composeUsers.map((item) => item.id);

                        const allGroupData = [];

                        (result?.data?.groups || []).forEach(group => {
                            const groupData = [];
                            (group.composedGroupDatas || []).forEach(item => {
                                groupData.push({
                                    companyId: parseInt(item?.companyId),
                                    departmentIds: item?.departmentIds
                                });
                            });

                            const selectedGroupData = {
                                composedGroupDatas: groupData,
                                name: group?.recordCount && group?.recordCount === 1 ? '1 Person' : group?.recordCount && `${group?.recordCount} People`,
                                isNewCreateGroup: true,
                                recordCount: group?.recordCount
                            };

                            allGroupData.push(selectedGroupData);

                            setSelectedOption(prevState => [...prevState, selectedGroupData]);

                        });


                        const composeAlternateMobiles = [];

                        (result?.data?.composeAlternateMobiles || []).forEach(alternateMobile => {

                            // for edit in to text box
                            const selectedAlterNateMobileData = {
                                isMobile: true,
                                name: formatPhoneNumberAsYouType(alternateMobile?.name).toString(),
                                mobile: formatPhoneNumberAsYouType(alternateMobile?.name).toString(),
                                recordCount: 0
                            };
                            // for data save
                            let obj = {
                                name: formatPhoneNumberAsYouType(alternateMobile?.name).toString(),
                                mobile: formatPhoneNumberAsYouType(alternateMobile?.name).toString(),
                            };
                            composeAlternateMobiles.push(obj);
                            setSelectedOption(prevState => [...prevState, selectedAlterNateMobileData]);
                        });



                        let sendDateTime = formatDateTimeForUserTimeZone(result?.data?.dateTimeSend);
                        const [date, time] = sendDateTime ? sendDateTime.split('@').map(part => part.trim()) : [null, null];

                        setComposeModel(prevState => ({
                            ...prevState,
                            composeemployees: composeToEmployees,
                            content: result?.data?.content,
                            dateTimeSend: new Date(date),
                            timeSent: time,
                            id: result?.data?.id,
                            sendType: "Schedule Send Time",
                            dateOnly: formatDate(date),
                            groups: [...prevState.groups, ...allGroupData],
                            composeAlternateMobiles: composeAlternateMobiles
                        }));
                        BodyCharCount(result?.data?.content);
                        setToErrorMessage(null);
                    }
                } catch (error) {
                    console.error("Failed to fetch composed by ID:", error);
                }
            }
        };

        fetchData();
    }, [dispatch, composeid]);

    const BodyCharCount = (content) => {
        const text = content;
        const length = text.length;

        if (!text.startsWith(fixedConentText)) {
            setComposeModel(prevComposeModel => ({
                ...prevComposeModel,
                content: fixedConentText
            }));
        } else {
            setComposeModel(prevComposeModel => ({
                ...prevComposeModel,
                content: text
            }));
        }
        // Check for special characters
        let hasSpecialChars = false;
        for (let i = 0; i < length; i++) {
            const charCode = text.charCodeAt(i);
            if (charCode < 0x20 || charCode > 0x7E) {
                hasSpecialChars = true;
                break;
            }
        }

        let smsLimit = 160; // Default segment limit

        if (hasSpecialChars) {
            smsLimit = 70; // Adjust segment limit if special characters are present
        }

        const smsParts = Math.ceil(length / smsLimit);

        const displayText = `${length}/${smsParts * smsLimit}`;
        const segmentDisplayText = smsParts > 1 ? `X${smsParts}` : '';
        setSegmentCount(segmentDisplayText);
        setCharCountDisplay(displayText);

        if (length <= smsLimit) {
            setCharCountClass('text-green');
        } else if (length <= smsLimit * 2) {
            setCharCountClass('badge-warning');
        } else {
            setCharCountClass('badge-danger');
        }
    };


    const handleInputChange = (newValue, { action }) => {

        if (action === 'input-change') {
            setIsShowuserInfo(false);          
            let cleanedValue = newValue && newValue.replace(/\D/g, '');
            let mobileLength10 = /^\d{10}$/.test(cleanedValue);
            let mobileLength11 = /^\d{11}$/.test(cleanedValue);

            if ((mobileLength10 && !cleanedValue.startsWith("1")) || (mobileLength11 && cleanedValue.startsWith("1"))) {
                let mobile = cleanedValue;

                // Prepend "1" if it's a 10-digit number
                if (mobileLength10 && !cleanedValue.startsWith("1")) {
                    mobile = "1" + mobile;
                }
             
                // Check if the mobile number already exists in the employee list
                if (searchEmployeeList.find(x => x.mobile === mobile)) {
                    return false; // Stop if the number already exists
                }

                // Format the number for display
               
                let formattedNumber = formatPhoneNumberAsYouType(cleanedValue);

                // Prepare the data object
                let obj = {
                    name: formattedNumber,
                    mobile: formattedNumber
                };

                if (obj && obj.mobile) {
                    // Create the final data object to update the state
                    const selectedMobileData = {
                        isMobile: true,
                        name: obj.mobile,
                        recordCount: 0
                    };

                    // Update the selected option and compose model
                    setSelectedOption(prevState => [...prevState, selectedMobileData]);
                    setComposeModel(prevState => ({
                        ...prevState,
                        composeAlternateMobiles: [...prevState.composeAlternateMobiles, obj]
                    }));

                    // Clear the filter query
                    setFilter(prevState => ({ ...prevState, query: '' }));
                    setDisplyText('');
                }
            }
            else {    
                setFilter({ ...filter, query: cleanedValue != '' ? cleanedValue : newValue });
                if (isNumeric(cleanedValue)) {
                    setDisplyText(formatPhoneNumberAsYouType(cleanedValue));
                }
                else {
                    setDisplyText(newValue);
                }
                
            }
        }
    };
    const isNumeric = (value) => {
        return /^\d+$/.test(value);
    };
    const handleMouseEnterParent = async (data) => {
        // Perform actions you want when mouse enters parent component
        setUserDetails([]);

        setIsShowuserInfo(true);
        if ('fullName' in data) {
            setUserDetails([{ "mobile": data.mobile, "fullName": data.fullName }]);
        }
        else if ('isNewCreateGroup' in data) {
            try {

                const result = await dispatch(getSiteUsersByDepartmentIds(data)).unwrap();
                if (result?.data) {
                    const newDetails = result.data.map(user => ({
                        mobile: user.mobile,
                        fullName: user.fullName
                    }));

                    // Then append new data while preserving the previous state
                    setUserDetails([...newDetails]);
                }

                // Handle result if needed
            } catch (error) {
                console.error('Error fetching site users:', error);
            }
        }



    };
    const MultiValueComponent = useCallback((props) => (
        <CustomMultiValue {...props} onMouseEnterParent={handleMouseEnterParent} isShow={isShowuserInfo} onLeaveEnterParent={(item) => setIsShowuserInfo(item)} userDetails={userDetails} />
    ), [handleMouseEnterParent, isShowuserInfo, setIsShowuserInfo, userDetails]);

    const formattedEmployeeList = searchEmployeeList?.map(employee => ({
        ...employee, // Spread operator to copy the employee object
        mobile: employee.mobile ? formatPhoneNumberAsYouType(employee.mobile) : employee.mobile // Format mobile if it exists
    }));

    return (
        <div>
            <section className="compose-tabs">
                <div className="admin-tab-content admin-page-wrap compose-page-wrapper">
                    <h2 className="admin-section-headline">Compose</h2>
                    <AdminError
                        message={error}
                        onClose={() => {
                            setError('');
                        }}
                    ></AdminError>
                    {sendingNow
                        ?
                        <MessagePopup
                            show={showMessagePopupModal}
                            onClose={() => setShowMessagePopupModal(false)}
                            handleConfirmation={handleSendCompose}
                            message={`Are you sure you want to send to ${(GetCountPeoplesList?.peopleCount || '') === 1 ? '1 Person' : `${GetCountPeoplesList?.peopleCount || ''} People`}?`}
                            sendButtonText={'Send'}

                        />
                        :
                        <MessagePopup
                            show={showMessagePopupModal}
                            onClose={() => setShowMessagePopupModal(false)}
                            handleConfirmation={handleSendCompose}
                            message={`Are you sure you want to schedule this message for ${(GetCountPeoplesList?.peopleCount || '') === 1 ? '1 Person' : `${GetCountPeoplesList?.peopleCount || ''} People`}?`}

                            sendButtonText={'Schedule'}
                        />}

                    <div className="compose-tab-form">
                        <div className="compose-tab-row">
                            <div className="form-group">
                                <label>To</label>
                                <div className="admin-section-row">
                                    <div className="admin-section-cell wide" >
                                        <div className="multi-compose-select-wrapper">
                                            <Select
                                                ref={selectRef}
                                                className="multi-compose-select"
                                                value={selectedOption}
                                                onChange={handleChange}
                                                options={formattedEmployeeList}
                                                getOptionValue={getOptionValue}
                                                getOptionLabel={getOptionLabel}
                                                isMulti
                                                components={{
                                                    MultiValue: MultiValueComponent,
                                                    Option: ComposeDropDownOption, DropdownIndicator: null
                                                }}
                                                onInputChange={handleInputChange}
                                                placeholder=""
                                                noOptionsMessage={noOptionsMessage}
                                                inputValue={displyText}
                                                id="compose-to"
                                            />
                                        </div>
                                    </div>


                                </div>



                                {toErrorMessage && <p className="error-messagess">{toErrorMessage}</p>}

                            </div>
                            <button type="button" onClick={addGroupModal} className="admin-button">
                                <span>Group</span>
                                <span className="material-symbols-rounded">add</span>
                            </button>
                        </div>
                        <div className="compose-tab-row">
                            <div className="form-group">
                                <label>
                                    Message
                                    <span>
                                        {segmentCount && <span className={'segment-count ' + charCountClass + ' '}>{segmentCount}</span>}
                                        <span className={charCountClass}>{charCountDisplay}</span>
                                        <a className="copy-link">
                                            <span className="material-symbols-rounded">info</span>
                                            <span className="tooltip">
                                                Basic text messages are limited to 160 characters.
                                                You can send more, and the cost per message will increase.
                                                If you send emojis or other complex characters, the limit is 70 characters per message.
                                            </span>
                                        </a>
                                    </span>
                                </label>
                                <textarea
                                    className="admin-default-textarea compose-message-textarea"
                                    id="full-message"
                                    placeholder="Message"
                                    value={composeModel.content}
                                    onChange={handleContentChange}
                                />
                                {errorFullMessage && <p className="error-messagess">{errorFullMessage}</p>}

                                <div className="compose-buttons">
                                    {composeid &&
                                        <button type="button" className="admin-button" onClick={() => onDeleteTrigger(composeid)}>
                                            <span>Discard Message</span>
                                        </button>
                                    }
                                    <div className="button-group">
                                        <button className="btn-dark" onClick={handleSendPopup}>
                                            {sendingNow ? 'Send' : 'Update & Send'}
                                        </button>
                                        <button className="btn-dark">
                                            {sendingNow ? 'Now' : ''}
                                            {sendingNow ? '' : <span>{showFormatDate(composeModel.dateTimeSend)} @ {composeModel.timeSent} CT </span>}

                                            <span className="material-symbols-rounded">stat_minus_1</span>
                                            <div className="time-dropdowan">
                                                <ul className="dropdown">
                                                    <li>
                                                        <a onClick={() => handleChangeSendType('Now')}>Now</a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => handleChangeSendType('Schedule Send Time')}>
                                                            Schedule Send Time
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Sent Message Modal*/}
            {getSentMessageModal &&
                <div className="admin-modal-overlay text-alert-modal" onClick={sentMessageModal}>
                    <div className="admin-modal admin-add-role" onClick={handleOutsideClick}>
                        <div className="modal-header">
                            <h2 className="admin-modal-heading">Sent Message</h2>
                            <button className="admin-modal-close" onClick={sentMessageModal}><span className="material-symbols-rounded">close</span></button>
                        </div>
                        <div className="admin-modal-scroll">
                            <div className="admin-modal-row">
                                <label className="admin-default-label" htmlFor="organization">To</label>
                                <p>
                                    Benjamin Franklin, George Washington, 222 People, Thomas Jefferson, Accoutning (CWP)
                                </p>
                            </div>
                            <div className="admin-modal-row">
                                <label className="admin-default-label" htmlFor="organization">Date/Time Sent</label>
                                <p>
                                    12/22/2099 @ 12:22pm
                                </p>
                            </div>
                            <div className="admin-modal-row">
                                <label className="admin-default-label" htmlFor="organization">Message</label>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis, diam at rutrum posuere, nulla metus aliquam lectus, sit amet tincidunt massa erat nec magna. Aenean congue a sapien eget maximus. Sed ullamcorper luctus dictum. Morbi in mauris mi. Donec id ipsum ex. Etiam non finibus nisl.
                                </p>
                            </div>
                        </div>
                        <div className="admin-modal-button-wrap">
                            <button className="admin-modal-button power" onClick={sentMessageModal}>Close</button>
                        </div>
                    </div>
                </div>
            }

            {/*Add Group Modal*/}
            {getAddGroupModal &&
                <div className="admin-modal-overlay text-alert-modal add-group-modal" onClick={addGroupModal}>
                    <div className="admin-modal admin-add-role" onClick={handleOutsideClick}>
                        <div className="modal-header">
                            <h2 className="admin-modal-heading">Add Group</h2>
                            <button className="admin-modal-close" onClick={addGroupModal}><span className="material-symbols-rounded">close</span></button>
                        </div>
                        <div className="admin-modal-scroll">
                            <div className="group-list-row">
                                {companyDepartmentList.map(company => (
                                    <div className="group-list-col" key={company.id}>
                                        <label className="admin-checkbox-label inline">
                                            <input
                                                className="admin-inline-checkbox"
                                                type="checkbox"
                                                data-id={company.id}
                                                checked={checkBoxItems[company.id]?.isChecked || false}
                                                onChange={(event) => handleClick(event, company.id)}
                                            />
                                            {company.abbreviatedName}
                                            {orgCount.some(obj => Object.prototype.hasOwnProperty.call(obj, company.id)) ? ` (${orgCount.find(obj => Object.prototype.hasOwnProperty.call(obj, company.id))[company.id]})` : ''}


                                        </label>
                                        <ul>
                                            {company.departments && company.departments.map(dpt => (
                                                <li key={dpt.id}>
                                                    <ChildBox
                                                        child={dpt}
                                                        parentId={company.id}
                                                        childId={dpt.id}
                                                        checkBoxItems={checkBoxItems}
                                                        handleChildChange={handleChildChange}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}


                            </div>
                        </div>
                        <div className="admin-modal-button-wrap">
                            {errorMessage && <p className="error-messagess">{errorMessage}</p>}
                            {/*     {employeeCount > 0 ? <p className="admin-legend-label">{employeeCount === 1 ? '1 Person  in Group' : `${employeeCount} People in Group`} </p> : null}*/}
                            <button className="admin-modal-button" onClick={addGroupModal}>Cancel</button>
                            <button className="admin-modal-button power" onClick={handleCreateGroup}>Add Group</button>
                        </div>
                    </div>
                </div>
            }

            {/*Schedule Date & Time*/}
            {getDateTimeModal &&

                <DatePickerModal
                    getDateTimeModal={getDateTimeModal}
                    dateTimeModal={dateTimeModal}
                    currentDate={currentDate}
                    handlePrevMonth={handlePrevMonth}
                    handleNextMonth={handleNextMonth}
                    daysOfWeek={daysOfWeek}
                    days={days}
                    updateTimeModel={handleUpdateTimeModel}
                />
            }


        </div>

    );
}
Compose.propTypes = {
    composeid: PropTypes.number.isRequired,
    onDeleteTrigger: PropTypes.func.isRequired,

};
