import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, addEditProjects, deleteJobProjects } from '../../../store/projects/actions';
import { ProjectContainer } from '../../../components/admin/projects/projectContainer';
import { ProjectsAddEditModal } from '../../../components/admin/projects/projectsAddEditModal';
import { ProjectDeleteConfrimationModal } from '../../../components/admin/projects/projectDeleteConfrimationModal';
import { showLoading, hideLoading } from '../../../store/loading-reducer';

export default function Projects() {
    const dispatch = useDispatch();

    const { projectsList, isProjectSaving } = useSelector(state => state.projects);
    const projectItem = { id: 0, title: '', active: true, media: {}, mediaId: null, projectMedias: [] }
    const [projectModel, setProjectModel] = useState(projectItem);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        document.title = 'Installed Projects | CWP Employee Portal';
        dispatch(getProjects());
    }, []);




    const handleEdit = (project) => {   
        setProjectModel(project);
        setShowAddModal(true);
    };

    const handleDelete = (project) => {
        setProjectModel(project);
        setShowDeleteModal(true);
    };

    const handleAddProject = () => {
        setProjectModel(projectItem);
        setShowAddModal(true);
    };

    const handleAddEditProject = (project) => {
        console.log("project", project);
        dispatch(addEditProjects(project));
    }

    const handleDeleteProject = (project) => {     
        if (project.id > 0) {
            dispatch(deleteJobProjects(project.id));
        }
    }

    useEffect(() => {
        if (isProjectSaving) {
            dispatch(showLoading());
        } else {
            dispatch(hideLoading());
        }
    }, [isProjectSaving]);

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Installed Projects</h1>
                    <button className="admin-button" onClick={() => handleAddProject() }>
                        <span className="admin-button-text">Add Project</span>
                        <span className="material-symbols-rounded" aria-hidden="true">
                            add
                        </span>
                    </button>
                </div>
                <div className="admin-project-wrap">
                    <ProjectContainer
                        projects={projectsList}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onAddProject={handleAddProject}
                    />                    
                </div>
                {showAddModal && 
                    <ProjectsAddEditModal
                        show={showAddModal}
                        onClose={() => setShowAddModal(false)}
                        handleAddEditProject={handleAddEditProject}
                        modelProject={projectModel}
                    /> 
                }
                      
                <ProjectDeleteConfrimationModal
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    handleDeleteProject={handleDeleteProject}
                    modelProject={projectModel}
                />
            </div>
        </div>
    );
}