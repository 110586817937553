import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const DatePickerModal = ({
    getDateTimeModal,
    dateTimeModal,
    currentDate,
    handlePrevMonth,
    handleNextMonth,
    daysOfWeek,
    days,
    updateTimeModel,
}) => {
    const formattedCurrentDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

    const getCurrentTime = () => {
        const now = new Date();
        let hours = now.getHours();
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Handle midnight (0 hours)
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return {
            hours: String(hours).padStart(2, '0'),
            minutes,
            period,
        };
    };

    const [selectedDate, setSelectedDate] = useState(formattedCurrentDate);
    const [selectedTime, setSelectedTime] = useState(getCurrentTime());
    const [selectedDay, setSelectedDay] = useState(currentDate.getDate());

    useEffect(() => {
        // Update selectedTime whenever currentDate changes
        setSelectedTime(getCurrentTime());
    }, [currentDate]);

    const handleDateClick = (day) => {
        if (day) {
            const formattedDate = `${currentDate.getMonth() + 1}/${day}/${currentDate.getFullYear()}`;
            setSelectedDate(formattedDate);
            setSelectedDay(day);
        }
    };

    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setSelectedTime(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePeriodChange = (e) => {
        const { value } = e.target;
        setSelectedTime(prevState => ({
            ...prevState,
            period: value,
        }));
    };

    const handleSchedule = () => {
        const { hours, minutes, period } = selectedTime;
        updateTimeModel(selectedDate, `${hours}:${minutes} ${period}`);
        dateTimeModal();
    };

    const handleOutsideClick = (event) => {
        event.stopPropagation()
    }

    return (
        getDateTimeModal && (
            <div className="admin-modal-overlay text-alert-modal date-time-modal" onClick={dateTimeModal}>
                <div className="admin-modal admin-add-role" onClick={handleOutsideClick}>
                    <div className="modal-header">
                        <h2 className="admin-modal-heading">Schedule Date & Time Message</h2>
                        <button className="admin-modal-close" onClick={dateTimeModal}>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                    </div>
                    <div className="admin-modal-scroll">
                        <div className="group-list-row">
                            <div className="group-list-col">
                                <div id="calendar">
                                    <div className="calendar-header">
                                        <button onClick={handlePrevMonth}>
                                            <span className="material-symbols-rounded">arrow_back_ios</span>
                                        </button>
                                        <div id="currentMonth">
                                            {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
                                        </div>
                                        <button onClick={handleNextMonth}>
                                            <span className="material-symbols-rounded">arrow_forward_ios</span>
                                        </button>
                                    </div>
                                    <div className="calendar-body">
                                        <div className="calendar-days">
                                            {daysOfWeek.map((day) => (
                                                <div key={day}>{day}</div>
                                            ))}
                                        </div>
                                        <div className="calendar-dates">
                                            {days.map((day, index) => (
                                                <div
                                                    key={index}
                                                    className={selectedDay === day ? 'current-date' : ''}
                                                    onClick={() => handleDateClick(day)}
                                                >
                                                    {day}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-modal-col">
                                <div className="admin-modal-cell">
                                    <label className="admin-default-label" htmlFor="date">Date</label>
                                    <input
                                        type="text"
                                        id="date"
                                        label="Date"
                                        name="date"
                                        value={selectedDate}
                                        readOnly
                                    />
                                </div>
                                <div className="admin-modal-cell">
                                    <label className="admin-default-label" htmlFor="time">Time</label>
                                    <div className="time-picker">
                                        <select
                                            name="hours"
                                            value={selectedTime.hours}
                                            onChange={handleTimeChange}
                                        >
                                            {[...Array(12)].map((_, i) => (
                                                <option key={i + 1} value={String(i + 1).padStart(2, '0')}>{String(i + 1).padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                        :
                                        <select
                                            name="minutes"
                                            value={selectedTime.minutes}
                                            onChange={handleTimeChange}
                                        >
                                            {[...Array(60)].map((_, i) => (
                                                <option key={i} value={String(i).padStart(2, '0')}>{String(i).padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                        &nbsp;
                                        <select
                                            name="period"
                                            value={selectedTime.period}
                                            onChange={handlePeriodChange}
                                        >
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={dateTimeModal}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSchedule}>Schedule</button>
                    </div>
                </div>
            </div>
        )
    );
};

DatePickerModal.propTypes = {
    getDateTimeModal: PropTypes.bool.isRequired,
    dateTimeModal: PropTypes.func.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    handlePrevMonth: PropTypes.func.isRequired,
    handleNextMonth: PropTypes.func.isRequired,
    daysOfWeek: PropTypes.arrayOf(PropTypes.string).isRequired,
    days: PropTypes.arrayOf(PropTypes.number).isRequired,
    updateTimeModel: PropTypes.func.isRequired
};

export default DatePickerModal;
