import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export function RollBackModal({
    modalTitle,
    show,
    onClose,
    onSave,
    children,
    adminModalClass,
    modalClass,
}) {
    const inputRef = useRef(null);

    const handleModalClose = (e) => {
        if (onClose) onClose(e);
    };
    const handleSave = () => {
        if (onSave) onSave();
    };

    const handleOutsideClick = (event) => {
        event.stopPropagation()
    }

    useEffect(() => {
        if (show) {
            const handleEnterKey = (e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                    setTimeout(() => {
                        handleSave();
                    }, 50);
                }
            };
            if (inputRef.current) {
                inputRef.current.focus();
            }
            window.addEventListener('keydown', handleEnterKey);
            return () => {
                window.removeEventListener('keydown', handleEnterKey);
            };
        }
    }, [show, onSave]);


    if (!show) return null;
    return (
        <div className={`admin-modal-overlay ${modalClass}`} onClick={handleModalClose}>
            <div className={"admin-modal admin-add-role admin-user-width " + adminModalClass} onClick={handleOutsideClick}>
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleModalClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">{modalTitle}</h2>
                    {children}
                </div>
            </div>
        </div>
    );
}

RollBackModal.propTypes = {
    children: PropTypes.node,
    modalTitle: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    showDelete: PropTypes.bool,
    adminModalClass: PropTypes.string,
    displayError: PropTypes.string,
    onHideError: PropTypes.func,
    saveButtonText: PropTypes.string,
    modalClass: PropTypes.string,
};
