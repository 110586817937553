import api from '../utils/api';

export const getProjects = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/projects/getallprojects`,
        method: "GET",
        data: data,
        ...options,
    });
}

export const addEditProjects = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/projects/addeditprojects`,
        method: "POST",
        data: data,
        ...options,
    });
}



export const deleteJobProjects = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/projects/deleteproject/${id}`,
        method: 'DELETE',
        ...options,
    });
}


export const updateProjectOrder = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/projects/order',
        method: 'POST',
        ...options,
    });
}