import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { getEventImage } from "../../store/events/actions";
import { useDispatch } from 'react-redux';

export default function EventImages({ mediaItem }) {
    const dispatch = useDispatch();
    const [previewSrc, setpreviewSrc] = useState('');
    useEffect(() => {
        if (mediaItem) {
            dispatch(getEventImage(mediaItem)).unwrap().then((res) => {
                if (res && res.success) {
                    setpreviewSrc(res.data);
                }
            });
        }
    }, [mediaItem]);

    return (
        <>
            {previewSrc && previewSrc != '' && (          
                <span className="events-img-wrap">
                    <div className="video-event-wrap">
                        {!previewSrc || !previewSrc.includes("/static/media/") && (
                            <img src={previewSrc} alt='News Item' width='' height='' loading="lazy" />
                        )}
                    </div>
                </span>
            )}
        </>
    );
}

EventImages.propTypes = {
    mediaItem: PropTypes.number
};