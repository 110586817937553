import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isInterestLoaded: false,
    interestsList: [],
    isSaving: false
}

const interestSlice = createSlice({
  name: 'interest',
  initialState,
  extraReducers: (builder) => {
    // Get Interests
    builder.addCase(actions.getInterestsList.pending, (state) => {
      state.isInterestLoaded = false;
    })
    builder.addCase(actions.getInterestsList.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.interestsList = action.payload.data;
      } else {
        state.interestsList = [];
      }
      state.isInterestLoaded = true;
    })
    builder.addCase(actions.getInterestsList.rejected, (state) => {
      state.isInterestLoaded = false;
    });

    // Save Interests
    builder.addCase(actions.saveInterests.pending, (state) => {
      state.isSaving = true;
    })
    builder.addCase(actions.saveInterests.fulfilled, (state, action) => {
      if (action.payload.success) {
        const returnObj = action.payload.data;
        state.interestsList.unshift(returnObj);
      }
      state.isSaving = false;
    })

    builder.addCase(actions.saveInterests.rejected, (state) => {
      state.isSaving = false;
    });

    // Edit Interests
    builder.addCase(actions.editInterests.pending, (state) => {
      state.isSaving = true;
    })
    builder.addCase(actions.editInterests.fulfilled, (state, action) => {
      if (action.payload.success) {
        const returnObj = action.payload.data;
        const interestsObj = state.interestsList.find(x => x.id === returnObj.id);
        if (interestsObj !== undefined && interestsObj !== null) {
          interestsObj.name = returnObj.name;
        }
      }
      state.isSaving = false;
    })

    builder.addCase(actions.editInterests.rejected, (state) => {
      state.isSaving = false;
    });

    // Delete Interests
    builder.addCase(actions.deleteInterests.pending, (state) => {
      state.saving = true;
    })
    builder.addCase(actions.deleteInterests.fulfilled, (state, action) => {
      if (action.payload.success) {
        const id = action.payload.data;
        const enityIndex = state.interestsList.findIndex(x => x.id === id);
        if (enityIndex > -1) {
          state.interestsList.splice(enityIndex, 1);
        }
      }
      state.isSaving = false;
    })
    builder.addCase(actions.deleteInterests.rejected, (state) => {
      state.isSaving = false;
    });
  }
});

export default interestSlice.reducer;
