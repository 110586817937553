import React, { createContext, useState, useCallback, useEffect } from "react";
import { ID_MODULE_MAP } from "../EditorModules/ModuleEnum";
import { v4 as uuidv4 } from "uuid";
import PropTypes from 'prop-types';

export const PageEditorContext = createContext();

export const PageEditorProvider = ({
  children,
  setValue,
  onChange,
  isEdit,
}) => {
  const moduleModel = {
    modules: [],
  };
  const [pageDetails, setPageDetails] = useState(
    setValue && setValue.modules && setValue.modules.length > 0
      ? setValue
      : moduleModel
  );
  const [hasInitialized, setHasInitialized] = useState(false);
  const [showAddModule, setShowAddModule] = useState(false);

  useEffect(() => {
    if (isEdit && setValue) {
      setPageDetails(setValue);
      setHasInitialized(true);
    }
  }, [setValue]);

  useEffect(() => {
    if (hasInitialized || !isEdit) {
      onChange(pageDetails);
    }
  }, [pageDetails, hasInitialized]);

  const getModuleTypes = () => {
    const moduleTypes = Object.keys(ID_MODULE_MAP);
    return moduleTypes.map((type) => ({
      id: type,
      label: ID_MODULE_MAP[type],
    }));
  };
  const addModule = (moduleType) => {
    setPageDetails((prevDetails) => {
      const existingModules = Array.isArray(prevDetails.modules) ? prevDetails.modules : [];
  
      return {
        ...prevDetails,
        modules: [
          ...existingModules,
          {
            type: parseInt(moduleType.id, 10),
            title: moduleType.label,
            order: existingModules.length,
            content: "",
            id: uuidv4(),
          },
        ],
      };
    });
  };
  
  const moveModule = (array, from, to) => {
    const item = array[from];
    array.splice(from, 1);
    array.splice(to, 0, item);
  };

  const updateOrderProperty = (modules) => {
    return modules.map((module, index) => ({
      ...module,
      order: index,
    }));
  };

  const moveModuleUp = (index) => {
    setPageDetails((prevDetails) => {
      if (index === 0) return prevDetails;

      const newModules = [...prevDetails.modules];
      moveModule(newModules, index, index - 1);
      const orderedModules = updateOrderProperty(newModules);

      return { ...prevDetails, modules: orderedModules };
    });
  };

  const moveModuleDown = (index) => {
    setPageDetails((prevDetails) => {
      if (index === prevDetails.modules.length - 1) return prevDetails;

      const newModules = [...prevDetails.modules];
      moveModule(newModules, index, index + 1);
      const orderedModules = updateOrderProperty(newModules);

      return { ...prevDetails, modules: orderedModules };
    });
  };

  const deleteModule = (moduleId) => {
    setPageDetails((prevDetails) => {
      const newModules = prevDetails.modules.filter(
        (module) => module.id !== moduleId
      );
      const reOrderedModules = newModules.map((module, index) => ({
        ...module,
        order: index,
      }));
      return { ...prevDetails, modules: reOrderedModules };
    });
  };

  const updateModuleContent = useCallback((moduleId, newContent) => {
  setPageDetails((prevDetails) => {
      return {
        ...prevDetails,
        modules: prevDetails.modules.map((module) =>
          module.id === moduleId ? { ...module, content: newContent } : module
        ),
      };
    });
  }, []);

  const value = {
    pageDetails,
    setPageDetails,
    showAddModule,
    setShowAddModule,
    addModule,
    moveModuleUp,
    moveModuleDown,
    deleteModule,
    getModuleTypes,
    updateModuleContent,
  };
    PageEditorProvider.propTypes = {
        children: PropTypes.node.isRequired,
        setValue: PropTypes.shape({
            modules: PropTypes.arrayOf(PropTypes.object),
        }),
        onChange: PropTypes.func.isRequired,
        isEdit: PropTypes.bool.isRequired,
    };
  return (
    <PageEditorContext.Provider value={value}>
      {children}
    </PageEditorContext.Provider>
  );
};
