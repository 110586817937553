import React from 'react';
import parse from 'html-react-parser';
export default function parseHtml(content) {
    return parse(content, {
        replace: domNode => {
            if (domNode.attribs && domNode.attribs.href && domNode.attribs.href.indexOf('kochagronomicservices.com') < 0) {
                return <a href={domNode.attribs.href} target="_blank" rel="noreferrer">{domNode.children && domNode.children.length > 0 ? domNode.children[0].data : ''}</a>
            }
        }
    }
    )
}