import api from '../utils/api';

export const GetAll = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/skill/get-all',
        method: 'GET',
        ...options,
    });
};

export const getById = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/skill/get/${id}`,
        method: 'GET',
        ...options,
    });
};

export const saveSkill = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/skill/add',
        method: 'POST',
        ...options,
    });
};

export const editSkill = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/skill/edit',
        method: 'POST',
        ...options,
    });
};

export const deleteSkill = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/skill/delete/${id}`,
        method: 'DELETE',
        ...options,
    });
};

export const restoreSkill = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/skill/restore/${id}`,
        method: 'GET',
        ...options,
    });
};

export const getSkillsList = (searchByName = null, companyId = 0, config = {}) => {
    const { data, options } = config;
    return api({
        url: searchByName ? `api/skill/get-all/${searchByName}/${companyId}` : `api/skill/get-all/ /${companyId}`,
        method: "GET",
        params: data,
        ...options,
    });
};
export const getSkillsListV2 = (searchByName = null, companyId = 0, config = {}) => {
    const { data, options } = config;
    return api({
        url: searchByName ? `api/skill/get-all-V2/${searchByName}/${companyId}` : `api/skill/get-all-V2/ /${companyId}`,
        method: "GET",
        params: data,
        ...options,
    });
};

export const getAllEmployeeEdit = (companyId = 0, config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/skill/get-all-employee-edit/${companyId}`,
        method: "GET",
        params: data,
        ...options,
    });
};


export const getSkillAndCompanyData = (config = {}) => {
    const { options } = config;
    return api({
        url: `api/skill/getCompanySkills`,
        method: "GET",
        ...options,
    });
}

export const getCompanyBySkillId = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/skill/getCompanyBySkillId/${id}`,
        method: 'GET',
        ...options,
    });
};

export const getEmployeesBySkillId = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/skill/getUserBySkillId/${id}`,
        method: 'GET',
        ...options,
    });
};

export const updateSkillOrder = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/skill/orderV2',
        method: 'POST',
        ...options,
    });
}

export const getSkillAndCompanyDataV2 = (config = {}) => {
    const { options } = config;
    return api({
        url: `api/skill/getCompanySkillsV2`,
        method: "GET",
        ...options,
    });
}