import { createAsyncThunk } from '@reduxjs/toolkit';
import * as adminFinancialsApi from '../../../api/admin/financials';

export const saveOrUpdateFinancials = createAsyncThunk('admin/saveOrUpdateFinancials',
    async (data) => {
        const res = await adminFinancialsApi.saveOrUpdateFinancials({ data: data });
        return res.data;
    }
);

export const getAllFinancials = createAsyncThunk('admin/getAllFinancials', async (data) => {
    const res = await adminFinancialsApi.getAllFinancials({ data: data });
    return res.data;
});

export const deleteUpdateFinancials = createAsyncThunk('admin/deleteUpdateFinancials',
    async (data) => {
        const res = await adminFinancialsApi.deleteUpdateFinancials({ data: data });
        return res.data;
    }
);