import React, { useState, useEffect } from "react";
import './ggobResources.css';
import Navigation from '../../components/navigation/Navigation';
import Accordion from '../../components/accordion/accordion';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanies } from '../../store/employee/actions';
import { getGGOBResourcesByFilter, getggobbyid } from '../../store/ggobResources/actions';
import HeaderProfile from "../../components/HeaderProfile";
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PortalAlert from '../../components/portalAlert/portalAlert';
import Modules from '../../components/Module/Modules';

export default function GGOBResources() {
    const dispatch = useDispatch();
    const [activePanel, setActivePanel] = useState(null);
    const { primaryCompanyId } = useSelector(state => state.auth);
    const { companiesList } = useSelector(state => state.employee); 
    const [filter, setFilter] = useState({ query: '', companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? primaryCompanyId : 0), sort: 6, IsShowPastEvent: false });
    const initialTodayFilter = primaryCompanyId !== undefined && primaryCompanyId !== 0 ? primaryCompanyId : 0;
    const { ggobResourcesListByfilter } = useSelector(state => state.ggobResources);
    const [newsFilter, setNewsFilter] = useState(initialTodayFilter);
    const [ggobRDescription, SetggobRDescription] = useState(null);



    const panelToggle = async (panel, isOpen) => {
        setActivePanel(prevActivePanel => {
            return prevActivePanel === panel.title ? null : panel.title;
        });
        if (isOpen) {
            const result = await dispatch(getggobbyid(panel.id)).unwrap();
            if (result?.data) {
                SetggobRDescription(result?.data?.description)
            }
        }
        else {
            SetggobRDescription(null);
        }
    };
     
    useEffect(() => {
        document.title = 'GGOB Resources | CWP Team Connect';
        dispatch(showLoading());
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        dispatch(getGGOBResourcesByFilter(filter)).unwrap().then(() => {
            dispatch(hideLoading());
        });
    }, [filter]);


    const isValidJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    return (
        <div className="directory-wrap events-wrap hrevents-wrap">
            <div className="drawer-menu-collapse-wrap"></div>
            <Navigation />
            <div className="events-page-wrap">
                <div className="page-inner events-inner">
                    <div className="profile-wrap-outer mobile-show">
                        <HeaderProfile />
                    </div>
                    <div className="events-content-scrollable-wrap">
                        <div className="inner-pages-alert">
                            <PortalAlert />
                        </div>
                        <div className="profile-wrap-outer">
                            <HeaderProfile />
                        </div>
                        <div className="events-header-wrap hrbenefits-header-wrap">
                            <div className="events-header-wrap-inner">
                                <h1 className="page-title-ggob page-title-wrap">{ggobResourcesListByfilter[0]?.title || 'GGOB Resources'}</h1>
                                <div className="filter-bars">
                                    <label>
                                        <select className="menu-wrap org-dropdown" name="slct" id="slct"  value={newsFilter} onChange={(event) => {
                                            setNewsFilter(event.target.value);
                                            const selectedCompanyId = parseInt(event.target.value);
                                            setFilter({ ...filter, companyId: selectedCompanyId });
                                        }}>
                                            <option value="0">All</option>
                                            {companiesList.map(company => (
                                                <option key={company.id} value={company.id}>{company.abbreviatedName}</option>
                                        ))}
                                        </select>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="events-content-wrap hrbenefits-content-wrap">
                            <div className="events-content-wrap-inner">                                
                                <div className="accordion-outer-wrap hrbenefits-listing-wrap">
                                    {ggobResourcesListByfilter[0]?.ggobResourcesDetails.map(panel => (
                                        <Accordion
                                            key={panel.title}
                                            title={panel.title}
                                            isActive={activePanel === panel.title}
                                            onToggle={() => panelToggle(panel, activePanel === panel.title ? false : true)}
                                            hideToggle={panel.description == '' ? true : false}
                                        >                        
                                            <Modules
                                                moduleData={
                                                    ggobRDescription != null && isValidJSON(ggobRDescription)
                                                        ? JSON.parse(ggobRDescription)
                                                        : []
                                                }
                                            />
                                        </Accordion>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}