import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import './ShopMonitorTab.css';
import tab1 from '../../assets/tab1.png';
import tab2 from '../../assets/tab2.png';
import tab3 from '../../assets/tab3.png';
import tab4 from '../../assets/tab4.png';
import tab5 from '../../assets/tab5.png';
import tab7 from '../../assets/installed-projects.png';
import tabYtd from '../../assets/YTD_bonus_slider_graphic.png';
import { getEmployeeWorkAnniversaries } from '../../store/employee/actions';
import { getTodayEvents } from '../../store/events/actions';
import { getProjects } from '../../store/projects/actions';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isVimeoVideoExists } from '../../helpers/formatterHelper';
import { getVimeoToken } from '../../api/docCenterApi';


export const ShopMonitorTabs = ({ timing }) => {
    const dispatch = useDispatch();
    const sliderRef = useRef(null);
    const navigate = useNavigate();
    const [filter, setFilter] = useState({ companyId: 0, userId: 0, sort: 6, timezone: GetTimeZone() });
    const { employeeAnniversaries } = useSelector(state => state.employee);
    const { todayEventNotifications } = useSelector(state => state.event);
    const { projectsList } = useSelector(state => state.projects);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [processedProjects, setProcessedProjects] = useState([]);
    const [allSlideUrls, setAllSlideUrls] = useState([]);
    const [vimeoToken, setVimeoToken] = useState("");

    useEffect(() => {
        let sharpCompanyId = localStorage.getItem('monitor_mode_company');
        if (sharpCompanyId !== undefined && sharpCompanyId !== null) {
            setFilter({ ...filter, companyId: parseInt(sharpCompanyId) });
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            await dispatch(getEmployeeWorkAnniversaries(filter));
            await dispatch(getTodayEvents(filter));
            await dispatch(getProjects());
            getVimeoToken().then((response) => {
                setVimeoToken(response.data);
            });
        }

        if (filter.companyId > 0) {
            fetchData();
        }
    }, [filter, dispatch]);
    let settings = {
        dots: true,
        infinite: true,
        speed: 400,
        arrows: false,
        autoplay: true, /*change to true when completed*/
        autoplaySpeed: timing,
        variableWidth: true,
        slidesToShow: 1, // Set the number of slides to show at a time
        slidesToScroll: 1, // Set the number of slides to scroll at a time
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }

        ]
    };

    useEffect(() => {
        const processProjects = async () => {
            const projectsWithVideoCheck = await Promise.all(
                projectsList.map(async (project) => {
                    if (project.active) {
                        const mediaChecks = await Promise.all(
                            project.projectMedias.map(async (media) => {
                                if (media.mediaItem.videoThumbnailUrl?.includes("/static/media/")) {
                                    return false;
                                }
                                if (media.mediaItem.videoType === 1) {
                                    return await isVimeoVideoExists(media.mediaItem.videoId, vimeoToken);
                                }
                                return true; // Assume images are valid
                            })
                        );
                        const allMediaValid = mediaChecks.every(check => check === true);
                        return { ...project, allMediaValid };
                    }
                    return { ...project, allMediaValid: false };
                })
            );
            setProcessedProjects(projectsWithVideoCheck.filter(project => project.allMediaValid));
        };

        if (projectsList && projectsList.length > 0) {
            processProjects();
        }
    }, [projectsList]); 


    useEffect(() => {
        const slides = [
            { url: "/shopmonitors/bonuslevels", type: "bonuslevel" },
            { url: "/shopmonitors/bonus-ytd", type: "bonusYTD" },
            { url: "/shopmonitors/financial-scoreboard", type: "financial" },
        ];
     

        if (todayEventNotifications && todayEventNotifications.length > 0) {
            const numberOfRows = Math.ceil(todayEventNotifications.length / 5);
            for (let i = 0; i < numberOfRows; i++) {
                slides.push({ url: `/shopmonitors/today/${i}`, type: "today" });
            }
        }

        if (employeeAnniversaries && employeeAnniversaries.length > 0) {
            slides.push({ url: "/shopmonitors/anniversaries", type: "anniversaries" });
        }
        slides.push({ url: "/shopmonitors/events", type: "events" });

      
        if (processedProjects && processedProjects.length > 0) {
            processedProjects
                .sort((a, b) => a.id - b.id)
                .forEach(project => {
                    slides.push({
                        url: `/shopmonitors/installed-projects/${project.id}`,
                        type: "installed-projects"
                    });
                });
        }

        setAllSlideUrls(slides);

    }, [todayEventNotifications, employeeAnniversaries, processedProjects]);

    const handleSlideChange = (index) => { 
        if (index >= 0 && index < allSlideUrls.length) {
            const clickedSlide = allSlideUrls[index];
            const slides = [...allSlideUrls];
            slides.splice(index, 1);
            slides.unshift(clickedSlide);
            setAllSlideUrls(slides);
            setActiveSlideIndex(index);
            navigate(clickedSlide.url);
        }
    };

    const handleSlideClick = (index) => {
        sliderRef.current.slickGoTo(index); // Programmatically move to the clicked slide
    };

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(activeSlideIndex); // Ensure the Slider is on the first slide initially
        }
    }, [activeSlideIndex]);
    function getImageForSlide(type) {
        //bonusYTD
        switch (type) {
            case "bonuslevel":
                return tab1;
            case "financial":
                return tab2;
            case "today":
                return tab3;
            case "anniversaries":
                return tab4;
            case "events":
                return tab5;
            case "installed-projects":
                return tab7;
            case "bonusYTD":
                return tabYtd;
            default:
                return null;
        }
    }

    function getSlideName(type) {
        //bonusYTD
        switch (type) {
            case "bonuslevel":
                return "Bonus Levels";
            case "bonusYTD":
                return "Bonus Levels YTD";
            case "financial":
                return "Financial Scoreboard";
            case "today":
                return "Today";
            case "anniversaries":
                return "Anniversary";
            case "events":
                return "Events";
            case "installed-projects":
                return "Installed Projects";
            default:
                return null;
        }
    }


    return (
        <nav className="monitors-nav">
            <div className="shop-monitors-tabs">
                <div className="shop-monitors-tabs-inner">
                    <Slider ref={sliderRef} {...settings} afterChange={handleSlideChange}>
                        {allSlideUrls && allSlideUrls.map((slide, index) => (
                            <div key={`slide_${index}`}>
                                <div onClick={() => handleSlideClick(index)} className={`shop-monitors-item ${activeSlideIndex === index ? 'active' : ''}`}>
                                    <img src={getImageForSlide(slide.type)} alt={`Tab ${index + 1}`} loading="lazy" />
                                    <span className="sr-only">{getSlideName(slide.type)}</span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </nav>
    );
};

ShopMonitorTabs.propTypes = {
    timing: PropTypes.number
};