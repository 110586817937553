import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//Module Types
import FreeformModule from './FreeformModule';
import GalleryModule from './GalleryModule';

export default function Modules({ moduleData }) {  
    const [modules, setModules] = useState(moduleData?.modules);

    useEffect(() => {
        setModules(moduleData?.modules);
    }, [moduleData]);

    return (<>
        {modules && modules.map((module, idx) =>
            <RenderModule key={idx} module={module} />
        )}
    </>)
}

Modules.propTypes = {
    moduleData: PropTypes.array,
}

const RenderModule = ({ module }) => {
    switch (module.type) {
        case 1:
            return <FreeformModule module={module} />;
        case 2:
            return <GalleryModule module={module} />;
        
        default:
            return null;
    }
}


RenderModule.propTypes = {
    module: PropTypes.obj,
}