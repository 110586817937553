import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { MediaItem } from './MediaItem';
import './mediacomponent.css';
import './react-image-crop.css';

const defaultCropSettings = {
    unit: '%',
    x: 0,
    y: 0,
    width: 1024,
    height: 576,
    aspect: 16/9
}

function EditImageArea({ ShowCrop, imageObj, setCropImage, handleCancelClick, cropRatioWidth, cropRatioheight, cancelClicked=false }) {
  
    if (imageObj && imageObj.photo) {

        const defaultCropSettingsWithAspect = cropRatioWidth && cropRatioheight ? {...defaultCropSettings, aspect: cropRatioWidth / cropRatioheight, } : defaultCropSettings;

        const [crop, setCrop] = useState('cropValues' in imageObj ? JSON.parse(imageObj.cropValues) : defaultCropSettingsWithAspect); 
        const handleCropChange = (crop, percentage) => {
            setCrop(percentage);
            setCropImage(percentage);
        }
    
       // const cropValues = ('cropValues' in imageObj ? JSON.parse(imageObj.cropValues) : defaultCropSettings); // use if we dont want recrop
        // Use split to get the parts of the URL after the last slash  
        const filenameParts = imageObj.photo.originalFileName.split('.');

        // Get the last part of the array (file extension)
        const fileExtension = filenameParts[filenameParts.length - 1];

        const parts = imageObj.previewsrc.split('/');

        // Remove the last part of the array (file name) and join the remaining parts
        const urlBeforeLastSlash = parts.slice(0, -2).join('/');
        const displayImageUrl = urlBeforeLastSlash + '/og/' + imageObj.photo.fileGuid + '.' + fileExtension;
        if (!cancelClicked && (ShowCrop || imageObj.isCropped)) {           
            return (
                <ReactCrop
                    //crop={cropValues} //use if we dont want recrop
                    crop={crop}
                    onChange={(c, p) => handleCropChange(c, p)} 
                    aspect={cropRatioWidth / cropRatioheight}
                >
                    <img className="uploadImage"
                        src={displayImageUrl} />
                </ReactCrop>
            )
        }
        else {

            return (<div className="uploadImageWrapper">
                <img className="uploadImage"
                    src={displayImageUrl} />
            </div>);
        }
    }
    else {
        handleCancelClick();
    }
}

EditImageArea.propTypes = {
    ShowCrop: PropTypes.bool,
    imageObj: PropTypes.object,
    setCropImage: PropTypes.func,    
    handleCancelClick: PropTypes.func,
    cropRatioWidth: PropTypes.number,
    cropRatioheight: PropTypes.number,
    cancelClicked: PropTypes.bool
}

EditImageArea.defaultProps = {
    cropRatioWidth: 16,
    cropRatioheight: 9
}
function ShowImageArea({ ShowCrop, BaseImage, CropImage, setCropImage, cropRatioWidth, cropRatioheight  }) {

    const baseImageUrl = URL.createObjectURL(BaseImage);
    useEffect(() => {
        if (BaseImage != null) {
            const img = new Image();
            img.src = URL.createObjectURL(BaseImage);
            img.onload = () => {
                const aspectRatio = img.width / img.height;

                // Set fixed aspect ratio for cropping
                const cropAspectRatio = cropRatioWidth / cropRatioheight;

                // Calculate the crop dimensions to maintain the fixed aspect ratio
                let cropWidth = img.width;
                let cropHeight = img.height;

                if (aspectRatio > cropAspectRatio) {
                    cropWidth = img.height * cropAspectRatio;
                } else {
                    cropHeight = img.width / cropAspectRatio;
                }

                // Calculate the initial crop position to center the crop
                const x = (img.width - cropWidth) / 2;
                const y = (img.height - cropHeight) / 2;

                // Update crop state
                setCropImage({
                    unit: '%',
                    x: (x / img.width) * 100,
                    y: (y / img.height) * 100,
                    width: (cropWidth / img.width) * 100,
                    height: (cropHeight / img.height) * 100,
                    aspect: cropRatioWidth / cropRatioheight,
                });
            };
        }
    }, []);
    const handleCropChange = (crop, percentage) => {
        setCropImage(percentage);
    }

    if (ShowCrop) {
        return (
            <ReactCrop
                crop={CropImage}
                onChange={(c, p) => handleCropChange(c, p)} 
                aspect={cropRatioWidth/cropRatioheight}
            >
                <img className="" src={baseImageUrl} />
            </ReactCrop>
        );
    }
    else {
        return (<div className="uploadImageWrapper">
            <img className="uploadImage"
                src={baseImageUrl} />
        </div>);
    }
}
ShowImageArea.propTypes = {
    ShowCrop: PropTypes.bool,
    BaseImage: PropTypes.object,
    CropImage: PropTypes.object,
    setCropImage: PropTypes.func,
    cropRatioWidth: PropTypes.number,
    cropRatioheight: PropTypes.number
}

ShowImageArea.defaultProps = {
    cropRatioWidth: 16,
    cropRatioheight: 9
}

export default function MediaImageModal({
    requireAltText, showModal, forceCrop, uploadImageObj, editImageObj,
    onCloseModal, onSaveModal, onCustomSaveModal, cropWidth, cropHeight }) {
    const [showCrop, setShowCrop] = useState(forceCrop);
    const [cropImage, setCropImage] = useState(defaultCropSettings);
    const [imageObj, setImageObj] = useState(editImageObj ?? MediaItem);
    const modalRef = useRef(null);
    const [cancelClicked, setCancelClicked] = useState(false);

    useEffect(() => {
        if (editImageObj != null && 'isCropped' in editImageObj) { 
            setShowCrop(editImageObj.isCropped);
        }
    }, [editImageObj, showModal]);
    useEffect(() => {
        // Update imageObj when editImageObj changes
        if (editImageObj && editImageObj.cropValues) {
            setCropImage(JSON.parse(editImageObj.cropValues));
        } else {
            // Reset to default MediaItem if editImageObj is not available
            setCropImage(defaultCropSettings);
        }
    }, [editImageObj]);

    useEffect(() => {
        // Update imageObj when editImageObj changes
        if (editImageObj) {
            setImageObj(editImageObj);
        } else {
            // Reset to default MediaItem if editImageObj is not available
            setImageObj(MediaItem);
        }
    }, [editImageObj]);



    const handleSaveModal = async () => {

        //setImageObj(editImageObj ?? MediaItem);
        if (requireAltText && imageObj.altText === '') {
            document.getElementById('alt-text').classList.add('input-validation-error');
            return;
        }
        const mediaData = JSON.parse(JSON.stringify(imageObj));
        mediaData.cropValues = JSON.stringify(cropImage);
        mediaData.imageFile = uploadImageObj;
        mediaData.isCropped = showCrop;
        onSaveModal(mediaData);
        setShowCrop(false);
        setCropImage(defaultCropSettings);
        setImageObj(MediaItem);
    }

    const handleCancelClick = () => {
        setImageObj(imageObj);
        if (showCrop) {
            //to do : you want to original image without crop use below code
            //setShowCrop(false);
            //setCancelClicked(true);
            //onCloseModal();
            //to do : you want to original image without crop comment below code
            handleCloseClick();
        }
        else {     
            //setShowCrop(false);
            setCancelClicked(false);
            onCloseModal();
        }
       
    }
    const handleCloseClick = () => {
        setImageObj(MediaItem);       
        setShowCrop(false);
        setCancelClicked(false);
        onCloseModal();
    }

    const handleShowCropModal = () => {
        setShowCrop(true);
        setCancelClicked(false);
    }

    const handleOverlayClick = (e) => {
        if (modalRef.current.contains(e.target)) {
            return;
        }
        handleCloseClick();
    };




    if (showModal) {
        return (
            <div data-section="CropImageOverlayPreview" className="admin-modal-overlay installed-thumbnail-modal" onClick={handleOverlayClick}>
                <div className="admin-modal admin-add-role" ref={modalRef}>
                    <div className="admin-modal-scroll">
                        <button className="admin-modal-close" onClick={handleCloseClick}>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        {!showCrop ?
                            <p className="admin-modal-heading">Thumbnail Preview </p>
                        : <></>}
                        {showCrop ?
                            <p className="admin-modal-heading">Crop Image</p>
                        : <></>}
                        {/*  { to do you want to custom alt test}*/}
                        {/*<div className="admin-modal-row">*/}
                        {/*    <div className="admin-modal-cell">*/}
                        {/*        {requireAltText ? (*/}
                        {/*            <label className="admin-default-label" htmlFor="alt-text">Alt Text (Required)</label>*/}
                        {/*        ) : (*/}
                        {/*            <label className="admin-default-label" htmlFor="alt-text">Alt Text</label>*/}
                        {/*        )}*/}
                        {/*        <input*/}
                        {/*            id="alt-text"*/}
                        {/*            type="text"*/}
                        {/*            className="admin-default-input-text"*/}
                        {/*            value={imageObj.altText}*/}
                        {/*            onChange={(e) => setImageObj({*/}
                        {/*                ...imageObj,*/}
                        {/*                altText: e.target.value*/}
                        {/*            })} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*{!showCrop ?*/}
                        {/*    <div className="admin-modal-row full">*/}
                        {/*        <div className="admin-modal-cell full">*/}
                        {/*            <label className="admin-default-label" placeholder="Typed in input" htmlFor="email-domain">Alt Text (required)</label>*/}
                        {/*            <input type="text" id="email-domain" label="Email Url" name="domain" value="" />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*: <></>}*/}
                        <div className="admin-modal-row full">
                            <div className="admin-modal-cell full">
                                {uploadImageObj !== null ?
                                    <ShowImageArea
                                        ShowCrop={showCrop}
                                        BaseImage={uploadImageObj}
                                        CropImage={cropImage}
                                        setCropImage={setCropImage}
                                        cropRatioWidth={cropWidth}
                                        cropRatioheight={cropHeight}
                                    /> : <EditImageArea
                                        imageObj={editImageObj}
                                        ShowCrop={showCrop}                                      
                                        setCropImage={setCropImage}
                                        handleCancelClick={handleCancelClick}
                                        cancelClicked={cancelClicked}
                                        cropRatioWidth={cropWidth}
                                        cropRatioheight={cropHeight}
                                    />}
                            </div>
                        </div>
                        <div className="admin-modal-button-wrap">
                            {showCrop ?
                            <button className="admin-modal-button"
                                onClick={handleCancelClick}
                            >Cancel</button>
                        : <></>}
                            {!showCrop ?
                                <button className="admin-modal-button"
                                    onClick={handleShowCropModal}
                                >Crop Image</button> : <></>}
                            <button className="admin-modal-button power"
                                onClick={onCustomSaveModal ?? handleSaveModal}
                            >
                                Save 
                                {showCrop ? ' Crop' : <></>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else return (<></>);
}

MediaImageModal.propTypes = {
    requireAltText: PropTypes.bool,
    mediaObj: PropTypes.object,
    showModal: PropTypes.bool,
    forceCrop: PropTypes.bool,
    uploadImageObj: PropTypes.object,
    editImageObj: PropTypes.object,
    onCloseModal: PropTypes.func,
    onSaveModal: PropTypes.func,
    onCustomSaveModal: PropTypes.func,
    cropWidth: PropTypes.number,
    cropHeight: PropTypes.number,
}

MediaImageModal.defaultProps = {
    requireAltText: false,
    forceCrop: false,
    uploadImageObj: null,
    editImageObj: null,
    onCustomSaveModal: null
}
