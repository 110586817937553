export const MediaItem = {
        id: 0,
        type: 'Image',
        altText: '',
        caption: '',
        photoId: null,
        isCropped: false,
        cropPhotoId: null,
        cropValues: null,
        videoId: null,
        videoSrc: null,
        videoThumbnailUrl: null,
        videoType: 'Custom',
        imageFile: null,
        videoFile: null,
        SelectedVideoSrc: null,
        vimeoVideoFileName:null
}