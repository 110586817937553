import React, { useState, useEffect } from "react";

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { RollBackModal } from './rollBackModal';
import { GetFinancialHistory, ChangeFinancialsVersion } from '../../../api/admin/financials';
import { formatDateTimeForUserTimeZone } from '../../../components/common/DateTimeFormatter';
import { getAllFinancials } from '../../../store/admin/financials/actions';

export default function RollBack({ companyId, fileType, setShowFinancialHistory }) {
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [filter, setFilter] = useState({ sort: 4 });
    const dispatch = useDispatch();

    useEffect(() => {
        GetFinancialHistory(fileType, companyId).then((response) => {
            let responseData = response.data.data;
            if (response.data.data) {
                const sortedList = [...responseData].sort((a, b) => {
                    if (filter.sort === 1) {
                        return a.orgFileName.localeCompare(b.orgFileName);
                    } else if (filter.sort === 2) {
                        return b.orgFileName.localeCompare(a.orgFileName);
                    } else if (filter.sort === 3) {
                        return new Date(a.createdDate) - new Date(b.createdDate);
                    } else if (filter.sort === 4) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return 0;
                });
                setDocumentList(sortedList);
            }
            setShowTypeModal(true);
        });
    }, [companyId, fileType]);

    const handleClose = () => {
        setShowTypeModal(false);
        setShowFinancialHistory(false);
    }

    const handlFinancialVersion = (id, baseId, financialFileId) => {
        let data = { id: id, baseId: baseId, financialFileId: financialFileId, companyId: companyId, fileType: fileType }
        ChangeFinancialsVersion({ data }).then(() => {
            setShowTypeModal(false);
            setShowFinancialHistory(false);
            dispatch(getAllFinancials());
        });
    }
    useEffect(() => {

        if (documentList && filter.sort !== 0) {
            const sortedList = [...documentList].sort((a, b) => {
                if (filter.sort === 1) {
                    return a.orgFileName.localeCompare(b.orgFileName);
                } else if (filter.sort === 2) {
                    return b.orgFileName.localeCompare(a.orgFileName);
                } else if (filter.sort === 3) {
                    return new Date(a.createdDate) - new Date(b.createdDate);
                } else if (filter.sort === 4) {
                    return new Date(b.createdDate) - new Date(a.createdDate);
                }
                return 0;
            });
            setDocumentList(sortedList);
        }
    }, [filter]);

    const toggleSort = (sortField) => {
        if (filter.sort === sortField) {
            setFilter({ ...filter, sort: sortField + 1 });
        } else {
            setFilter({ ...filter, sort: sortField });
        }
    };
    return (
        <>
            {showTypeModal && (
                <RollBackModal
                    modalTitle="Replace Document"
                    show={showTypeModal}
                    onClose={() => { handleClose() }}
                    modalClass="replace-doc-modal"
                >
                    <div className="admin-modal-row full rsvp-table-wrapper customeScrollbar">

                        <table className="admin-table rsvp-table">
                            <thead className="rsvp-table-header">
                                <tr className="header-row">
                                    <th onClick={() => toggleSort(1)} className={filter.sort === 1 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 2 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>File Name</th>
                                    <th onClick={() => toggleSort(3)} className={filter.sort === 3 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort === 4 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Last Published</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                </tr>
                            </thead>

                            <tbody>
                                {documentList && documentList.length > 0 ? (
                                    documentList.map(item => (
                                        <tr key={item.id}>
                                            <td className="admin-table-name-cell">{item?.orgFileName}</td>
                                            <td className="admin-table-name-cell">{formatDateTimeForUserTimeZone(item?.createdDate)}</td>
                                            <td className="admin-table-name-cell" onClick={() =>
                                                handlFinancialVersion(item.id, item.baseId, item.financialFileId)
                                            }>
                                                <span className="replace-btn">Replace</span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="admin-table-name-cell" colSpan="6">Not found</td>
                                    </tr>
                                )}
                            </tbody>

                        </table>

                    </div>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={() => handleClose()}>Cancel</button>
                    </div>
                </RollBackModal>)}
        </>
    );
}

RollBack.propTypes = {
    companyId: PropTypes.number,
    fileType: PropTypes.string,
    setShowFinancialHistory: PropTypes.func.isRequired
};