import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PositionsSkills from './PositionsSkills';


export default function Skills() {
    useEffect(() => {
        document.title = 'Positions & Qualifications | CWP Employee Portal'; 
    }, []);

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <PositionsSkills />   
        </div>
    );
}