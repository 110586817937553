import React from 'react';
import PropTypes from 'prop-types';
export function ScheduleDeleteConfirmationModal({ show, onClose, handleDeleteEvent, modelEvent, deletemessage }) {
    const handleConfirmDelete = () => {
        if (modelEvent.id) {
            handleDeleteEvent(modelEvent);
            onClose();
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            onClose();
        }
    };

    if (!show) {
        return null;

    }

    const handleCancel = () => {
        onClose(); // Close the modal
    };

    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal">
                <h2 className="admin-modal-heading">
                    {deletemessage}                   
                </h2>
                <div className="admin-modal-row full">
                    <div className="admin-modal-cell">{modelEvent.title}</div>
                </div>
                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button
                        className="admin-form-button power red"
                        onClick={handleConfirmDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
}

ScheduleDeleteConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func.isRequired,
    modelEvent: PropTypes.object,
    deletemessage: PropTypes.string
};