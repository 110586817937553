import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { getNewsImage } from "../../api/news";

export default function NewsImages({ mediaItem }) {
    const [previewSrc, setpreviewSrc] = useState('');

    useEffect(() => {
        if (mediaItem) {
            getNewsImage(mediaItem).then((response) => {
                if (response.data && response.data?.data) {
                    setpreviewSrc(response.data?.data);
                }
            });
        }
    }, [mediaItem]);

    return (
        <>
            
            <img src={previewSrc} alt='Happenings Item' width='' height='' loading="lazy" />
           
        </>
    );
}

NewsImages.propTypes = {
    mediaItem: PropTypes.object
};