import api from '../utils/api';

export const getDepartments = (searchByName = null, config = {}) => {
    const { data, options } = config;
    return api({
        url: searchByName ? `api/employee/getDepartments/${searchByName}` : 'api/employee/getDepartments',
        method: "GET",
        params: data,
        ...options,
    });
}
export const getDepartmentsV2 = (searchByName = null, companyId = 0, config = {}) => {
    const { data, options } = config;
    return api({
        url: searchByName ? `api/employee/getDepartmentsV2/${searchByName}/${companyId}` : `api/employee/getDepartmentsV2/ /${companyId}`,
        method: "GET",
        params: data,
        ...options,
    });
}

export const getDepartmentAndCompanyData = (config = {}) => {
    const { options } = config;
    return api({
        url: `api/employee/getDepartmentAndCompanyData`,
        method: "GET",
        ...options,
    });
}

export const updateDepartmentsOrder = (config = {}) => {
    console.log("api")
    const { options } = config;
    return api({
        url: 'api/employee/order',
        method: 'POST',
        ...options,
    });
}

export const addEditCompanyDepartment = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/companydepartments/addCompanyDepartment`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const addEditCopmanyDepartmentData = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/companydepartments/addCompanyDepartment',
        method: 'POST',
        ...options,
    });
};

export const deleteDepartment = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/companydepartments/deleteDepartment/${id}`,
        method: 'DELETE',
        ...options,
    });
};
export const deleteCompanyDepartment = (companyId, depamentId, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/companydepartments/deleteCompanyDepartment/${companyId}/${depamentId}`,
        method: 'DELETE',
        ...options,
    });
};


export const getEmployeeDetailByDepartmentId = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/companydepartments/userbydepartemtnId/${id}`,
        method: 'GET',
        ...options,
    });
};

//getCompanyDepartmentById
export const getCompanyDepartmentById = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/companydepartments/getCompanyDepartmentById/${id}`,
        method: 'GET',
        ...options,
    });
};
export const updateDepartmentOrder = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/companydepartments/order',
        method: 'POST',
        ...options,
    });
}