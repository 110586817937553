import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/account-layout.css';
//import { useNavigate } from 'react-router-dom';

import CWPlogo from '../assets/cwp-logo.png';
import CWPCommerciallogo from '../assets/cwp-commercial-logo.png';
import KBHlogo from '../assets/kbh-logo.png';
import Profilelogo from '../assets/profile-logo.png';
import Pulitologo from '../assets/pulito-logo.png';
import Unitslogo from '../assets/units-logo.png';
export default function DefaultLayout({ children }) {

  //const navigate = useNavigate();
  useEffect(() => { }, []);

  return (
    <div className="page-content">
      <div className="login-page-wrap">
        {children}
        <div className="login-page-logo-wrap">
          <div className="login-page-logo">
            <img src={CWPlogo} alt="Custom Wood Products" loading="lazy" />
          </div>
          <div className="login-page-logo">
            <img src={CWPCommerciallogo} alt="Custom Wood Commercial + Frameless" loading="lazy" />
          </div>
          <div className="login-page-logo">
            <img src={KBHlogo} alt="Kitchen Bath & Home" loading="lazy" />
          </div>
          <div className="login-page-logo">
            <img src={Profilelogo} alt="Profile by Custom Wood Products" loading="lazy" />
          </div>
          <div className="login-page-logo">
            <img src={Pulitologo} alt="Pulito" loading="lazy" />
          </div>
          <div className="login-page-logo">
            <img src={Unitslogo} alt="Units Moving and Portable Storage" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.any,
};
