import React, { useEffect } from 'react';
import './home.css';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '../../components/navigation/Navigation.jsx';
import { getEmployeeById } from '../../store/employee/actions';
/*import IntroVideo from '../../assets/team-connect-cwp_2.mp4';*/
import { useNavigate } from 'react-router-dom';
import PortalAlert from '../../components/portalAlert/portalAlert';
import HomeDashboard from '../../components/home/homePage';
export default function GetHome() {
    const { siteUserId } = useSelector(state => state.auth);
    const { isAuthenticated, isAdmin, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
    const navigate = useNavigate();
  useEffect(() => {
      document.title = 'CWP Team Connect'
      if (isAuthenticated && siteUserId != undefined && siteUserId != null && token != null) {     
          if (isAdmin || siteUserId !=0) {
              dispatch(getEmployeeById({ id: siteUserId }));
          }

      }
      else {
          navigate("/login");
      }
  }, [isAuthenticated]);

    if (!isAuthenticated || !siteUserId || !token) {
        if (!isAdmin && siteUserId == 0) {
            
                return null;
            }

    }

  return (
    <div className="home-page-wrap">
          <Navigation />
          <div className="home-page-video-wrap">
            <div className="home-page-video-alert">
                <PortalAlert />
            </div>
        
              <HomeDashboard />
      </div>
    </div>
  );
}
