import AccountLayout from '../layouts/AccountLayout.jsx';
import Login from '../pages/siteadmin/login.js';
import Logout from '../pages/siteadmin/logout.js';
import ForgotPassword from '../pages/siteadmin/forgotpassword.js';
import CreateAccount from '../pages/siteadmin/createaccount.js';
import TestToken from '../pages/siteadmin/testtoken.js';
import ResetPassword from '../pages/siteadmin/resetpassword.js';
import OneTimeLogin from '../pages/siteadmin/onetimelogin.js';
import EmailAuth from '../pages/siteadmin/emailAuth.js';

export const siteAdminRoutes = [
    {
        path: '/siteadmin/login',
        element: Login,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/logout',
        element: Logout,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/forgot-password',
        element: ForgotPassword,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/create',
        element: CreateAccount,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/test',
        element: TestToken,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/reset-password/:hash',
        element: ResetPassword,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/forgot-one-time-login',
        element: OneTimeLogin,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/siteadmin/email-auth/:hash',
        element: EmailAuth,
        layout: AccountLayout,
        secure: false
    }
];
