import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../src/assets/icon/spinner.svg'
const LoadingOverlay = styled.div`
  align-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, .8);
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 499;

  [data-filter="true"] & {
    left: 20em;
    width: calc(100% - 20em);
  }
`;

const LoadingSpinner = () => {
    const isLoading = useSelector((state) => state.loading);

    return (
        <LoadingOverlay $show={isLoading}>
            {/*<LoadingSpinnerStyled />*/}
            <div className="spinner-align-center">
                <p className="loding-text">Loading</p>
                <img src={Spinner} alt="spiiner" />
            </div>
        </LoadingOverlay>
    );
};


export default LoadingSpinner;
