import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import JobPostings from '../../../components/admin/jobpostings/jobPostings';
import Benefits from '../../../components/admin/Benefits/benefits';
import { getJobPostings, getBenefits } from '../../../store/hrbenefits/actions';
import { getCompanies } from '../../../store/employee/actions';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";



export default function HrBenefits() {
    const dispatch = useDispatch();
    const [editLink, setEditLink] = useAdminMenuContext('');
    const [activeTab, setActiveTab] = useState('benefits');
    const { openTab } = useParams();

    useEffect(() => {
        document.title = 'HR & Benefits | CWP Employee Portal';
        dispatch(getJobPostings());
        dispatch(getBenefits());
        dispatch(getCompanies());
        setEditLink('/hr-benefits');
        console.log(editLink)
    }, []);


    useEffect(() => {
        if (openTab !== undefined) setActiveTab(openTab);
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, '/siteadmin/hrbenefits/' + tab);
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">HR & Benefits</h1>
                <div className="admin-tab-wrap">
                    <button className={`admin-tab ${activeTab === 'benefits' ? 'active' : ''}`} id="tab2" onClick={() => handleTabChange('benefits')} role="tab" aria-controls="benefits-tab" aria-selected="true">Benefits & Policies</button>
                    <button className={`admin-tab ${activeTab === 'jobpostings' ? 'active' : ''}`} onClick={() => handleTabChange('jobpostings')} id="tab1" role="tab" aria-controls="jobpostings-tab" aria-selected="true">Job Postings</button>                    
                </div>
                {activeTab === 'jobpostings' && <JobPostings />}
                {activeTab === 'benefits' && <Benefits />}
            </div>
        </div>
    );
}