import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isAdminFinancialsLoaded: false,
    adminFinancialsList: [],
    saving: false
}

const adminFinancialsSlice = createSlice({
    name: 'adminFinancials',
    initialState,
    extraReducers: (builder) => {

        // Get Financial List
        builder.addCase(actions.getAllFinancials.pending, (state) => {
            state.isAdminFinancialsLoaded = false;
        })
        builder.addCase(actions.getAllFinancials.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.adminFinancialsList = action.payload.data;
            } else {
                state.adminFinancialsList = [];
            }
            state.isAdminFinancialsLoaded = true;
        })
        builder.addCase(actions.getAllFinancials.rejected, (state) => {
            state.isAdminFinancialsLoaded = false;
        }); 

        // Save Or Update Finanical Files
        builder.addCase(actions.saveOrUpdateFinancials.pending, (state) => {
            state.saving = true;
        }),
            builder.addCase(actions.saveOrUpdateFinancials.fulfilled, (state, action) => {
                if (action.payload.success) {
                    const returnObj = action.payload.data.financial;
                    let adminFinancial = state.adminFinancialsList.find(x => x?.id === returnObj.companyId);
                    if (adminFinancial == undefined || adminFinancial == null) {
                        if (returnObj != null) {
                            adminFinancial = state.adminFinancialsList[0];
                        }
                    }
                    if (adminFinancial !== undefined && adminFinancial !== null) {
                        if (adminFinancial.financial.id > 0) {
                            if (action.payload.data.fileType === "BonusLevel") {
                                adminFinancial.financial.bonusLevelId = returnObj.bonusLevelId;
                                adminFinancial.financial.bonusLevelFile = returnObj.bonusLevelFile;
                            } else {
                                adminFinancial.financial.scoreBoardId = returnObj.scoreBoardId;
                                adminFinancial.financial.scoreBoardFile = returnObj.scoreBoardFile;
                            }
                        } else {
                            adminFinancial.financial = returnObj;
                        }
                    }
                }
                else {
                    console.log(action.payload.errorMessage);
                }
                state.saving = false;
            }),
            builder.addCase(actions.saveOrUpdateFinancials.rejected, (state) => {
                state.saving = false;
            });


        // deleteAndUpdate Finanical Files
        builder.addCase(actions.deleteUpdateFinancials.pending, (state) => {
            state.saving = true;
        })
        builder.addCase(actions.deleteUpdateFinancials.fulfilled, (state, action) => {
            if (action.payload.success) {
                console.log();
                const returnObj = action.payload.data.financial;
                const adminFinancial = state.adminFinancialsList.find(x => x?.financial?.id === returnObj.id);
                if (adminFinancial !== undefined && adminFinancial !== null) {
                    if (action.payload.data.fileType === "BonusLevel") {
                        adminFinancial.financial.bonusLevelId = undefined;
                        adminFinancial.financial.bonusLevelFile = undefined;
                    } else {
                        adminFinancial.financial.scoreBoardId = undefined;
                        adminFinancial.financial.scoreBoardFile = undefined;
                    }
                    
                }
                console.log(action.payload.errorMessage);
            }
            else {
                console.log(action.payload.errorMessage);
            }
            state.saving = false;
        })
        builder.addCase(actions.deleteUpdateFinancials.rejected, (state) => {
            state.saving = false;
        });
    }
});

export default adminFinancialsSlice.reducer;
