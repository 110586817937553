import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import { getResetPassword } from '../../store/auth/actions';
import { setResetPassword } from '../../store/auth/actions';

export default function ResetPassword() {
    const { hash } = useParams();  
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isshow, setIsShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const forgotpassword = bindActionCreators(getResetPassword, dispatch);
    const updatepassword = bindActionCreators(setResetPassword, dispatch);
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        let data = {
            PasswordHash: password,
            PasswordResetHash: hash
        }
        const result = await updatepassword({ data: data }).unwrap();

        if (result.success) {
            if (result.success) {
                navigate('/account/login');
            } else {
                alert("Something is wrong");
            }
        }
        setPassword('');
        setConfirmPassword('');
    }

    useEffect(() => {
        const fetchData = async () => {
            let data = {
                PasswordResetHash: hash
            };
            try {
                const result = await forgotpassword({ data: data }).unwrap();

                if (result.success && result.hash !== undefined) {
                    setIsShow(true);
                } else {
                    navigate('/account/login');
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        document.querySelectorAll('.fancy-field').forEach((element) => {
            if (element.value != '') {
                element.parentNode.classList.add('input-focused');
            }
            else if (element.parentNode.classList.contains('input-focused')) {
                element.parentNode.classList.remove('input-focused');
            }
        });

    }, [password, confirmPassword]);


    return (
        <>{isshow ? (
            <form onSubmit={handleSubmit}>
                <div className="login-form-row">
                    <div className="login-form-cell">
                        <div className="validation-summary-valid" data-valmsg-summary="true">
                            <ul>
                                {/*{error && <li>{error}</li>}*/}
                            </ul>
                        </div>
                    </div>
                </div>
           
                <div className="login-form-row">
                    <div className="login-form-cell">
                        <div className="login-form-field fancy-parent">
                            <label className="login-label" htmlFor="login-password">New Password</label>
                            <input className="login-input fancy-field"
                                id="login-password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type="password" />
                        </div>
                    </div>
                </div>
                <div className="login-form-row">
                    <div className="login-form-cell">
                        <div className="login-form-field fancy-parent">
                            <label className="login-label" htmlFor="confirm-password">Confirm Password</label>
                            <input className="login-input fancy-field"
                                id="confirm-password"
                                name="confirmPassword"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                type="password" />
                        </div>
                    </div>
                </div>
                <div className="login-form-row">
                    <div className="login-form-cell">
                        <button
                            className="login-submit"
                            disabled={!password || !confirmPassword}
                        >Submit</button>
                    </div>
                </div>
            </form>
        ) : (
            <p>Please log in to continue.</p>
        )}
        </>
    )
}
