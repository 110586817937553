import React from 'react';
import PropTypes from 'prop-types';

export default function MessagePopup({ show, onClose, handleConfirmation, message, sendButtonText }) {

    if (!show) {
        return null;
    }

    const handleClick = (event) => {
        event.stopPropagation()
    }

    return (
        <div className="admin-modal-overlay" onClick={onClose}>
            <div className="admin-modal" onClick={handleClick}>
                <h2 className="admin-modal-heading">
                    {message}
                </h2>              
                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button
                        className="admin-form-button power"
                        onClick={handleConfirmation}
                    >
                        {sendButtonText}
                    </button>
                </div>
            </div>
        </div>
    );
}

MessagePopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleConfirmation: PropTypes.func.isRequired,
    message: PropTypes.string,
    sendButtonText: PropTypes.string
};

MessagePopup.defaultProps = {
    message: 'Are you sure you want to send to X amount of people?'
}