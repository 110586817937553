import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isDepartmentsLoaded: false,
    departmentsList: [],
    isCompanyDepartmentLoaded: false,
    companyDepartmentList: [],
    isDepartmentSaving: false,
    departmentsListV2: []
}

const interestSlice = createSlice({
    name: 'deparments',
    initialState,
    extraReducers: (builder) => {
        // Get Interests
        builder.addCase(actions.getDepartments.pending, (state) => {
            state.isDepartmentsLoaded = false;
        })
        builder.addCase(actions.getDepartments.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.departmentsList = action.payload.data;
            } else {
                state.departmentsList = [];
            }
            state.isDepartmentsLoaded = true;
        })
        builder.addCase(actions.getDepartments.rejected, (state) => {
            state.isDepartmentsLoaded = false;
        });

        //Get Compnay and Department data
        builder.addCase(actions.getDepartmentAndCompanyData.pending, (state) => {
            state.isCompanyDepartmentLoaded = false;
        })
        builder.addCase(actions.getDepartmentAndCompanyData.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.companyDepartmentList = action.payload.data;
            } else {
                state.companyDepartmentList = [];
            }
            state.isCompanyDepartmentLoaded = true;
        })
        builder.addCase(actions.getDepartmentAndCompanyData.rejected, (state) => {
            state.isCompanyDepartmentLoaded = false;
        });
        //Get Compnay and Department order data
        builder.addCase(actions.updateDepartmentsOrder.pending, (state) => {
            state.isCompanyDepartmentLoaded = false;
        })
        builder.addCase(actions.updateDepartmentsOrder.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.companyDepartmentList = action.payload.data;
            } else {
                state.companyDepartmentList = [];
            }
            state.isCompanyDepartmentLoaded = true;
        })
        builder.addCase(actions.updateDepartmentsOrder.rejected, (state) => {
            state.isCompanyDepartmentLoaded = false;
        });


        //add department
        builder.addCase(actions.addEditCompanyDepartment.pending, (state) => {
            state.isDepartmentSaving = true;
        })
        builder.addCase(actions.addEditCompanyDepartment.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                const postingsObj = state.companyDepartmentList.find(x => x.id === returnObj.id);
                if (postingsObj !== undefined && postingsObj !== null) {
                    postingsObj.name = returnObj.name;
                    postingsObj.departmentCompanies = returnObj.departmentCompanies;
                } else {
                    state.companyDepartmentList.unshift(returnObj);
                }
            }
            state.isDepartmentSaving = false;
        })
        builder.addCase(actions.addEditCompanyDepartment.rejected, (state) => {
            state.isDepartmentSaving = false;
        });

        // order company Department
        builder.addCase(actions.updateDepartmentOrder.pending, (state) => {
            state.isDepartmentSaving = true;
        })
        builder.addCase(actions.updateDepartmentOrder.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.companyDepartmentList = action.payload.data;
            } else {
                state.companyDepartmentList = [];
            }
            state.isDepartmentSaving = false;
        })
        builder.addCase(actions.updateDepartmentOrder.rejected, (state) => {
            state.isDepartmentSaving = false;
        });



        builder.addCase(actions.getDepartmentsV2.pending, (state) => {
            state.isDepartmentsLoaded = false;
        })
        builder.addCase(actions.getDepartmentsV2.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.departmentsListV2 = action.payload.data;
            } else {
                state.departmentsListV2 = [];
            }
            state.isDepartmentsLoaded = true;
        })
        builder.addCase(actions.getDepartmentsV2.rejected, (state) => {
            state.isDepartmentsLoaded = false;
        });

    }
});

export default interestSlice.reducer;
