import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isLoggedInEmployeeLoaded: false,
    loggedInEmployee: {},
    isEmployeeLoaded: false,
    employee: {},
    employeeList: [],
    isCompaniesLoaded: false,
    companiesList: [],
    isDepartmentListByCompanyIdLoaded: false,
    departmentByCompanyIdList: [],
    isSaving: false,
    employeeAnniversaries: [],
    isAnniversariesLoaded: false,
    shirtSizes: [],
    isSearchEmployeeLoaded: false,
    searchEmployeeList: [],
    secondaryDepartmentList: []
}

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    extraReducers: (builder) => {

        // Get Employee
        builder.addCase(actions.getEmployeeList.pending, (state) => {
            state.isEmployeeLoaded = false;
        })
        builder.addCase(actions.getEmployeeList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.employeeList = action.payload.data;
            } else {
                state.employeeList = [];
            }
            state.isEmployeeLoaded = true;
        })
        builder.addCase(actions.getEmployeeList.rejected, (state) => {
            state.isEmployeeLoaded = false;
        });

        // Search Employee
        builder.addCase(actions.getSearchEmployeeList.pending, (state) => {
            state.isSearchEmployeeLoaded = false;
        })
        builder.addCase(actions.getSearchEmployeeList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.searchEmployeeList = action.payload.data;
            } else {
                state.searchEmployeeList = [];
            }
            state.isSearchEmployeeLoaded = true;
        })
        builder.addCase(actions.getSearchEmployeeList.rejected, (state) => {
            state.isSearchEmployeeLoaded = false;
        });


        // Delete Employee
        builder.addCase(actions.deleteEmployee.pending, (state) => {
            state.saving = true;
        })
        builder.addCase(actions.deleteEmployee.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.employeeList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.employeeList.splice(enityIndex, 1);
                }
            }
            state.isSaving = false;
        })
        builder.addCase(actions.deleteEmployee.rejected, (state) => {
            state.isSaving = false;
        });

        // Get Companies
        builder.addCase(actions.getCompanies.pending, (state) => {
            state.isCompaniesLoaded = false;
        })
        builder.addCase(actions.getCompanies.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.companiesList = action.payload.data;
            } else {
                state.companiesList = [];
            }
            state.isCompaniesLoaded = true;
        })
        builder.addCase(actions.getCompanies.rejected, (state) => {
            state.isCompaniesLoaded = false;
        });

        //Get DepartmentByCompnayid
        builder.addCase(actions.getDepartmentListByCompanyId.pending, (state) => {
            state.isDepartmentListByCompanyIdLoaded = false;
        })
        builder.addCase(actions.getDepartmentListByCompanyId.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.departmentByCompanyIdList = action.payload.data;
            } else {
                state.departmentByCompanyIdList = [];
            }
            state.isDepartmentListByCompanyIdLoaded = true;
        })
        builder.addCase(actions.getDepartmentListByCompanyId.rejected, (state) => {
            state.isDepartmentListByCompanyIdLoaded = false;
        });

        // Get Employee
        builder.addCase(actions.getEmployeeById.pending, (state) => {
            state.isEmployeeLoaded = false;
        })
        builder.addCase(actions.getEmployeeById.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.employee = action.payload.data;
            } else {
                state.employee = {};
            }
            state.isEmployeeLoaded = true;
        })
        builder.addCase(actions.getEmployeeById.rejected, (state) => {
            state.isEmployeeLoaded = false;
        });

        // Save Employee Info
        builder.addCase(actions.saveEmployeeInfo.pending, (state) => {
            state.isSaving = true;
        })
        builder.addCase(actions.saveEmployeeInfo.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.employee = action.payload.data;
            } else {
                state.employee = {};
            }
            state.isSaving = false;
        })
        builder.addCase(actions.saveEmployeeInfo.rejected, (state) => {
            state.isSaving = false;
        });

        // Get Logged In Employee
        builder.addCase(actions.getLoggedInEmployeeById.pending, (state) => {
            state.isLoggedInEmployeeLoaded = false;
        })
        builder.addCase(actions.getLoggedInEmployeeById.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.loggedInEmployee = action.payload.data;
            } else {
                state.loggedInEmployee = {};
            }
            state.isLoggedInEmployeeLoaded = true;
        })
        builder.addCase(actions.getLoggedInEmployeeById.rejected, (state) => {
            state.isLoggedInEmployeeLoaded = false;
        });

        builder.addCase(actions.getEmployeeWorkAnniversaries.pending, (state) => {
            state.isAnniversariesLoaded = true;
        })
        builder.addCase(actions.getEmployeeWorkAnniversaries.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.employeeAnniversaries = action.payload.data;
            } else {
                state.employeeAnniversaries = [];
            }
            state.isAnniversariesLoaded = false;
        })
        builder.addCase(actions.getEmployeeWorkAnniversaries.rejected, (state) => {
            state.isAnniversariesLoaded = false;
        });

        builder.addCase(actions.getShirtSizes.pending, (state) => {
            state.isCompaniesLoaded = false;
        })
        builder.addCase(actions.getShirtSizes.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.shirtSizes = action.payload.data;
            } else {
                state.shirtSizes = [];
            }
            state.isCompaniesLoaded = true;
        })
        builder.addCase(actions.getShirtSizes.rejected, (state) => {
            state.isCompaniesLoaded = false;
        });


        //Get DepartmentByCompnayid
        builder.addCase(actions.getSecondaryDepartmentListByCompanyId.pending, (state) => {
            state.isDepartmentListByCompanyIdLoaded = false;
        })
        builder.addCase(actions.getSecondaryDepartmentListByCompanyId.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.secondaryDepartmentList = action.payload.data;
            } else {
                state.secondaryDepartmentList = [];
            }
            state.isDepartmentListByCompanyIdLoaded = true;
        })
        builder.addCase(actions.getSecondaryDepartmentListByCompanyId.rejected, (state) => {
            state.isDepartmentListByCompanyIdLoaded = false;
        });
    }
});

export default employeeSlice.reducer;