import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { localstore } from '../utils/localForageStorage';
import logger from 'redux-logger';

import authReducer from './auth';
//import siteadminauthReducer from './authsiteadmin';
import loadingReducer from './loading-reducer'
import layoutBannerReducer from './layout-banner-reducer';
import employeeReducer from './employee';
import interestReducer from './interest';
import skillReducer from './skill';
import eventReducer from './events';
import newsReducer from './news';
import hrBenefitsReducer from './hrbenefits';
import projectsReducer from './projects';
import departmentsReducer from './department';
import adminFinancialsReducer from './admin/financials';
import financialscoreboardReducer from './finanacialscoreboard';
import bonusLevelsReducer from './bonuslevels';
import composeReducer from './compose';
import ggobResourcesReducer from './ggobResources';
const authPersistConfig = {
	key: 'root',
	storage: localstore
}

const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer), //siteadminauthReducer.refreshToken == null ? persistReducer(authPersistConfig, authReducer)
		//: persistReducer(authPersistConfig, siteadminauthReducer),
	loading: loadingReducer,
	layoutBanner: layoutBannerReducer,
	employee: employeeReducer,
	interest: interestReducer,
	skill: skillReducer,
	event: eventReducer,
	news: newsReducer,
	hrBenefits: hrBenefitsReducer,
	projects: projectsReducer,
	departments : departmentsReducer,
	adminFinancials: adminFinancialsReducer,
	financialScoreboard: financialscoreboardReducer,
	bonuslevels: bonusLevelsReducer,
	compose: composeReducer,
	ggobResources: ggobResourcesReducer
})

export const store = configureStore({
    reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: true,
			serializableCheck: false,
		}).concat(logger),
	devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store)
