import api from '../utils/publicApi';


export const login = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/login',
        method: 'POST',
        data,
        ...options
    });
}

export const refreshToken = (config = {}) => {
  const { data, options } = config;
  return api({
    url: `auth/refresh-token`,
    method: "POST",
    data,
    ...options,
  });
}

export const checkAuth = (config = {}) => {
  const { data, options } = config;
  return api({
    url: 'auth/check-auth',
    method: 'GET',
    data,
    ...options
  })
}

export const forgotPassword = (config = {}) => {
    const { data, options } = config;
     return api({
        url: 'auth/forgot-password',
        method: 'POST',
        data,
        ...options
    });  
}

export const resetPassword = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/reset-password',
        method: 'POST',
        data,
        ...options
    });
}


export const updatePassword = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/update-password',
        method: 'POST',
        data,
        ...options
    });
}

export const UpdateUserData = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/update-userdata',
        method: 'POST',
        data,
        ...options
    });
}

export const oneTimeLogin = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/one-time-login',
        method: 'POST',
        data,
        ...options
    });
}

export const emailAuth = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/email-auth',
        method: 'POST',
        data,
        ...options
    });
}

export const createUserdata = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'auth/create-user',
        method: 'POST',
        data,
        ...options
    });
}