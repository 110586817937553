import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { hideBanner } from '../store/layout-banner-reducer';
import ShowLoggedWarning from '../components/common/showLoggedWarning';
import { useNavigate } from 'react-router-dom';
import AdminNavEditLink from "../context/AdminMenuContext";

export default function DefaultLayout({ children }) {
	const dispatch = useDispatch();
	const [inactive, setInactive] = useState(false);
	const navigate = useNavigate();
    let monitor_mode_company = localStorage.getItem('monitor_mode');
    let Is_my_personal_device_selected = localStorage.getItem('Is_my_personal_device_selected');
       

	useEffect(() => {
		if (!location.pathname.includes('/preview')) {
			dispatch(hideBanner());
		}

        if (monitor_mode_company ==="shop-screen" && !location.pathname.includes('/shopmonitors')) {
            navigate('/logout');
        }

        let inactivityTimer;

        const resetTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                const alertTimeout = setTimeout(() => {
                    setInactive(true);
                }, 100);

                setTimeout(() => {
                    setInactive((prevInactive) => {
                        if (prevInactive && !stringToBool(Is_my_personal_device_selected) &&  monitor_mode_company != "shop-screen") {
                            navigate('/logout');
                            localStorage.setItem('Is_my_personal_device_selected', false);
                        }
                        return true;
                    });
                    clearTimeout(alertTimeout);
                }, 15000);
            }, 45000);
        };

        const handleUserActivity = () => {
            setInactive(false);
            resetTimer();
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('mousedown', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);
        window.addEventListener('touchmove', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity);
        window.addEventListener('scroll', handleUserActivity);

        resetTimer();

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('mousedown', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            window.removeEventListener('touchmove', handleUserActivity);
            window.removeEventListener('touchstart', handleUserActivity);
            window.removeEventListener('scroll', handleUserActivity);
            clearTimeout(inactivityTimer);
        };

	}, [location.pathname, dispatch]);
	useEffect(() => {
	}, [navigate]);

    function stringToBool(str) {
        if (str != null && str != undefined)
		return str.toLowerCase() === 'true';
	}

	return (
        <div className="page-content">
            <AdminNavEditLink>
                {children}
            </AdminNavEditLink>
            {inactive && !stringToBool(Is_my_personal_device_selected) && monitor_mode_company != "shop-screen"  &&
				<ShowLoggedWarning show={true} /> 
			}
		</div>
	);
}

DefaultLayout.propTypes = {
	children: PropTypes.any,
};
