import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import DefaultLayout from '../layouts/DefaultLayout';
import AccessDenied from '../pages/account/accessdenied';
import NotFound from '../pages/pagenotfound/pagenotfound.jsx';
import Home from '../pages/home';

import { accountRoutes } from './Accountrouter';
import { appRoutes } from './Approuter';
import { adminRoutes } from './Adminrouter';

import { siteAdminRoutes } from './SiteAdminRouter';


import PropTypes from 'prop-types';

function Element({ element, layout, secure, adminPage }) {
	const { isAuthenticated, isAdmin, token } = useSelector((state) => state.auth);
	const Component = element;
	const Layout = layout;
	const navigate = useNavigate();

	const location = useLocation();

	const path = location.pathname;

	// Remove the leading slash if needed
	const pathWithoutLeadingSlash = path.substring(1);
	useEffect(() => {
		if (adminPage && !isAdmin) {
			//To do later
			const currentUrl = window.location.href;
			if (secure && !isAuthenticated && currentUrl.indexOf('/siteadmin') !== -1) {
				navigate('/siteadmin/login');
			}
			else {
				navigate('/');
			}
			console.log('');
		}
	}, []);

	useEffect(() => {
		const currentUrl = window.location.href;
		if (secure && !isAuthenticated && currentUrl.indexOf('/siteadmin') !== -1) {
			navigate('/siteadmin/login');
		}
		else if (secure && isAuthenticated && token === null) {
			navigate('/login');
		}
		else if (secure && !isAuthenticated) {
			if (pathWithoutLeadingSlash != undefined && pathWithoutLeadingSlash != null) {
				const isValidURL = appRoutes.some((route) => route.path === '/' + pathWithoutLeadingSlash);
				if (!isValidURL && isAuthenticated) {
					navigate('/error');
				}
				else
					window.location.href = '/login?returnUrl=' + pathWithoutLeadingSlash;
			}
			else {
				navigate('/login');
			}
		} else if (isAuthenticated && token === null) {
			if (currentUrl.indexOf('/siteadmin') !== -1) {
				navigate('/siteadmin/login');
			} else {
				navigate('/login');
			}
		}
	}, [isAuthenticated]);


	//To do later

	//if (adminPage && !isAdmin) return null;
	//else if (secure && !isAuthenticated) return null;
	if (secure && !isAuthenticated) {
		return null
	}
	else if (secure && isAuthenticated && token === null) {
		return null
	}
	else if (adminPage && !isAdmin) {
		return null
	} 
	else {
		return (
			<Layout>
				<Component />
			</Layout>
		);
	}
}

Element.propTypes = {
	element: PropTypes.any,
	layout: PropTypes.any,
	secure: PropTypes.bool,
	adminPage: PropTypes.bool
};

Element.defaultProps = {
	secure: true,
	adminPage: false
}


export const createRoutes = (routesData) =>
	routesData.map((route) => {
		if (route.routes?.length) {
			const indexRoute = route.routes.find((r) => r.index);
			return (
				<Route
					key={route.path}
					path={route.path}
					element={<Element {...route} />}
					index={route.index}
				>
					{!!indexRoute && (
						<Route index element={<Element {...route} />} />
					)}
					{createRoutes(route.routes)}
				</Route>
			);
		}
		return (
			<Route
				key={route.path}
				path={route.path}
				element={<Element {...route} />}
				index={route.index}
			/>
		);
	});

export default function AppRouter() {	
	return (
		<Routes>
			{createRoutes(accountRoutes)}
			{createRoutes(appRoutes)}
			{createRoutes(adminRoutes)}
			{createRoutes(siteAdminRoutes)}
			<Route
				path='/'
				element={<DefaultLayout><Home /></DefaultLayout> }
			/>
			<Route
				path='access-denied'
				element={<AccessDenied />}
			/>
			<Route
				path='error'
				element={<NotFound />}
			/>
			<Route path='*' element={<NotFound />} />
    </Routes>);
}