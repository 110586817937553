let debounceTimer;

const debounce = (callback) => {
  clearTimeout(debounceTimer);

  debounceTimer = setTimeout(() => {
    callback();
  }, 250);
};

export default debounce;