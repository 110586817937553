import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../helpers/formatterHelper'
import PropTypes from 'prop-types';
import NewsImages from './NewsImages';

const News = ({ newsItem }) => {
    const navigate = useNavigate();

    return (newsItem && (
        <a onClick={() => navigate('/news-details/' + newsItem.baseId)} className={`news-listing-item yellow`} key={newsItem}>
            <span className="news-title-wrap">
                <span className="news-title-inner">                   

                    <div className="flex-wrap">
                    {newsItem.newsCompanies && newsItem.newsCompanies.length > 0 && (
                        <>
                            {newsItem.newsCompanies.map(item =>
                                <span key={item.companyId} className="news-day-title">
                                    {item.company.abbreviatedName}
                                </span>
                            )}
                        </>
                        )}
                    </div>

                    <span className="news-day-date">{formatDate(newsItem.publishDate, "MMM dd, yyyy")}</span>
                    

                    <span className="news-description">{newsItem.title}</span>
                </span>
                <span className="news-img-wrap">
                    <span className="news-image-wrap">
                       
                        {newsItem && newsItem.media && newsItem.media.length > 0 && (() => {
                            const media = newsItem.media.filter(x => !x.mediaItem?.videoThumbnailUrl?.includes("/static/media/"));
                            if (media.length > 0) {
                                return (
                                    <div className="video-event-wrap" key={media[0]?.id}>
                                        <NewsImages mediaItem={media[0]?.mediaItem} />
                                    </div>
                                );
                            }
                        })()}

                    </span>
                </span>
            </span>
        </a>
    )
    );
};
News.propTypes = {
    newsItem: PropTypes.object,
    isHrBenefits: PropTypes.bool
};

export default News;

