import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllDocuments, deleteDocument } from "../../../api/documentLibraryApi";
import { formatDateTime, GetTimeZone } from "../../../components/common/DateTimeFormatter";
import { DocumentConfrimationModal } from "./documentConfrimationModal";

export default function DocumentLibrary() {
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const [filter] = useState({ timezone: GetTimeZone() });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [documentModel, setDocumentModel] = useState({ title: "", id: 0 }); 
    const [sort, setSort] = useState({
        field: "created",
        direction: "desc",
    });

    const fetchDocuments = () => {
        getAllDocuments(filter).then((results) => {  
            if (results.data.success) { 
                setDocuments(results.data.data);
            }
        });
    };

    useEffect(() => {
        document.title = 'Document Library | CWP Employee Portal';
        fetchDocuments();
    }, []);


    const copyToClipboard = (documentPath) => {
        const fullUrl = `${window.location.origin}/doccenter/${documentPath}`;
        navigator.clipboard.writeText(fullUrl).then(
            () => {
                alert("Link copied to clipboard");
            },
            (err) => {
                console.error("Could not copy text: ", err);
            }
        );
    };

    const handleDeleteEvent = (item) => {
        deleteDocument(item.id).then(() => {
            fetchDocuments();
        });
    }

    const handleSort = (fieldName) => {
        const isAsc = sort.field === fieldName && sort.direction === "asc";
        setSort({ field: fieldName, direction: isAsc ? "desc" : "asc" });
    };


    const sortDocuments = (docs) => {
        return [...docs].sort((a, b) => {
            if (sort.field === "created") {
                const dateA = new Date(a[sort.field]);
                const dateB = new Date(b[sort.field]);
                return sort.direction === "asc" ? dateA - dateB : dateB - dateA;
            } else if (sort.field === "name") {
                const nameA = a[sort.field].toLowerCase();
                const nameB = b[sort.field].toLowerCase();
                if (nameA < nameB) {
                    return sort.direction === "asc" ? -1 : 1;
                }
                if (nameA > nameB) {
                    return sort.direction === "asc" ? 1 : -1;
                }
                return 0;
            }
        });
    };

    useEffect(() => {
        setDocuments((currentDocuments) => sortDocuments(currentDocuments));
    }, [sort]);

    return (
        <>
            <DocumentConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                deletemessage="Are you sure you want to delete this document?"
                modelEvent={documentModel}
                handleDeleteEvent={handleDeleteEvent}
                resetEventModel={() => setDocumentModel({ title: "", id: 0 })}
            />
            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link
                            className="admin-top-bar-back"
                            to={'/siteadmin'}
                        >
                            Back
                        </Link>
                    </div>
                </div>
                <div className="pageContainer">
                    <div className="admin-page-content">
                        <div className="admin-page-title-wrap">
                            <h1 className="admin-page-heading">Document Library</h1>
                            <button className="admin-button"
                                onClick={() =>
                                    navigate('/siteadmin/document-library/create')
                                }
                            >
                                <span>Add Document</span>
                                <span className="material-symbols-rounded">add</span>
                            </button>
                        </div>
                        <div className="pt">
                            <table className="admin-table">
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort("created")}>
                                            Last Modified
                                            <span className="sort-btn">
                                                {sort.field === "created" ? (
                                                    sort.direction === "asc" ? (
                                                        <span className="material-symbols-rounded">
                                                            keyboard_arrow_up
                                                        </span>
                                                    ) : (
                                                            <span className="material-symbols-rounded">
                                                            keyboard_arrow_down
                                                        </span>
                                                    )
                                                ) : (
                                                        <span className="material-symbols-rounded">
                                                        unfold_more
                                                    </span>
                                                )}
                                            </span>
                                        </th>
                                        <th onClick={() => handleSort("name")}>
                                            Document Name
                                            <span className="sort-btn">
                                                {sort.field === "name" ? (
                                                    sort.direction === "asc" ? (
                                                        <span className="material-symbols-rounded">
                                                            keyboard_arrow_up
                                                        </span>
                                                    ) : (
                                                            <span className="material-symbols-rounded">
                                                            keyboard_arrow_down
                                                        </span>
                                                    )
                                                ) : (
                                                        <span className="material-symbols-rounded">
                                                        unfold_more
                                                    </span>
                                                )}
                                            </span>
                                        </th>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.map((document, idx) => (
                                        <tr key={idx}>
                                            <td>{formatDateTime(document.created)}</td>
                                            <td>{document.name}</td>
                                            <td className="admin-table-name-cell copy-link">
                                                <span
                                                    onClick={() => copyToClipboard(document.uid)}
                                                    className="material-symbols-rounded"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    content_copy
                                                    
                                                </span>
                                                <span className="tooltip">Copy Link</span>
                                            </td>
                                            <td className="admin-table-name-cell">
                                                <Link
                                                    to={"/siteadmin/document-library/edit/" + document.baseId}
                                                    className="material-symbols-rounded"
                                                >
                                                    edit
                                                </Link>
                                                </td>
                                            <td className="admin-table-name-cell">
                                                <div
                                                    onClick={() => {                                         
                                                        setShowDeleteModal(true);
                                                        setDocumentModel({
                                                            id: document.baseId,
                                                            title: document.name                                         
                                                        });                                                 
                                                    }}
                                                    className="material-symbols-rounded"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    delete
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

