import { createAsyncThunk } from '@reduxjs/toolkit';
import * as adminApi from '../../api/ggobResources';


export const getGGOBResources = createAsyncThunk('getGGOBResources', async (data) => {
    const res = await adminApi.getAll(data);
    return res.data;
});

export const updateGGOBResourcesOrder = createAsyncThunk('updateGGOBResourcesOrder', async (ids) => {
    const res = await adminApi.updateOrder({
        options: {
            params: { Ids: ids },
        },
    });
    return res.data;
});

export const addEditGGOBResources = createAsyncThunk('addEditGGOBResources', async (data) => {
    const res = await adminApi.addEdit({ data: data });
    return res.data;
});

export const deleteGGOBResources = createAsyncThunk('deleteGGOBResources', async (data) => {
    const res = await adminApi.Delete({ id: data });
    return res.data;
});

export const getGGOBResourcesByFilter = createAsyncThunk('getGGOBResourcesByFilter', async (data) => {
    const res = await adminApi.getByFilter({ data: data });
    return res.data;
});
export const getggobbyid = createAsyncThunk('getggobbyid', async (data) => {
    const res = await adminApi.getggobbyid({ id: data });
    return res.data;
});
