import React from 'react';
import Navigation from '../../components/navigation/Navigation';
import PortalAlert from '../../components/portalAlert/portalAlert';
import HomeDashboard from '../../components/home/homePage';

export default function Dashboard() {
    return (
        <div>
            <Navigation />
            <div className="home-page-video-alert">
                <PortalAlert />
            </div>
            <HomeDashboard />
        </div>
    );
}