import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

export const Button = ({ primary, backgroundColor, size, label, external, url, ...props }) => {
  const mode = primary ? 'default-button--primary' : 'default-button--secondary';
  
  const handleClick = () => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <button
      type="button"
      className={['default-button','drop' ,`default-button--${size}`, `${external}`, mode].join(' ')}
      style={backgroundColor && { backgroundColor }}
      onClick={handleClick}
      {...props}>
      {label}
    </button>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
    external: PropTypes.bool,
    url: PropTypes.string,
};

Button.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'large',
    external: false,
    url: ""
   
};
