import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './bonus-levels.css';
import '../shopmonitors/bonus-levels.css';
import Navigation from '../../components/navigation/Navigation.jsx';
import { GetQuarterlyBonus, getBonusLevels, getCurrentMonthName, GetYTDBonus } from '../../store/bonuslevels/actions';
import { formatUSD } from '../../helpers/formatterHelper'; //getMonthshortName
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { GetTimeZone } from '../../components/common/DateTimeFormatter';
import YTDBonusPage from '../../components/bonuslevels/YtdBonus';
import ActualBoxes from '../../components/bonuslevels/ActualBoxes';
import PortalAlert from '../../components/portalAlert/portalAlert';
import BudgetedBoxes from '../../components/bonuslevels/BudgetedBoxes';

function findNearestIndex(array, number) {
	let minDiff = Infinity;
	let nearestIndex = null;

	array.forEach((element, index) => {
		const diff = Math.abs(element - number);

		if (diff < minDiff) {
			minDiff = diff;
			nearestIndex = index;
		}
	});

	return nearestIndex;
}


const quarterName = {
	q1: "Q1",
	q2: "Q2",
	q3: "Q3",
	q4: "Q4"
}
export default function BonusLevels() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const graphLines = useRef(null);
	const { loggedInEmployee } = useSelector(state => state.employee);
	const { quarterlyBonus, bonusList, ytdBonusList } = useSelector(state => state.bonuslevels);
	const [filter, setFilter] = useState({ quarterlyBonusId: null, timezone: GetTimeZone(), previousQuarterlyBonus: 0 });
	const [isChecked, setIsChecked] = useState(true);
	const [quarterlyBonusData, setQuarterlyBonusData] = useState(quarterlyBonus.find(item => item.id === filter.quarterlyBonusId));
	const [scaleAmount, setScaleAmount] = useState([]);
	const { siteUserId } = useSelector(state => state.auth);
	const [graphLinesHeight, setGraphLinesHeight] = useState(0);
	const [bonuslevelAmounts, setBonuslevelAmounts] = useState([]);
	const [getCurrentMonthNameId, setGetCurrentMonthNameId] = useState(0);
	const [negativeScaleAmount, setNegativeScaleAmount] = useState([]);
	const [scaleAmountwithNegative, setScaleAmountwithNegative] = useState([]);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const currentMonth = new Date().getMonth();
	const { qid } = useParams();
	const [scaleValue, setScaleValue] = useState(0);
	
	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		document.title = 'Bonus | CWP Team Connect';
		dispatch(showLoading());
		dispatch(getBonusLevels()).unwrap().then((res) => {
			if (res.success) {
				const getCurrecntQuarterlyName = GetCurrecntQuarterlyBonus(res.data);
				const getQuarterlyBonusId = res.data.find(item => item.name === getCurrecntQuarterlyName)?.id;
				const getPreviousQuarterlyBonus = getQuarterlyBonusId > 0 && res.data.filter(item => item.id <= getQuarterlyBonusId);
				const previousQuarterly = getPreviousQuarterlyBonus != undefined && getPreviousQuarterlyBonus.length > 0 && getPreviousQuarterlyBonus[getPreviousQuarterlyBonus.length - 2];
				getQuarterlyBonusId > 0 && setFilter({ ...filter, quarterlyBonusId: getQuarterlyBonusId, previousQuarterlyBonus: previousQuarterly !== undefined && previousQuarterly !== null ? previousQuarterly.id : 0 })
			}
		});
	}, []);



	useEffect(() => {
		if (getCurrentMonthNameId === 0) {
			dispatch(getCurrentMonthName()).unwrap().then((res) => {
				if (res.success) {
					setGetCurrentMonthNameId(res.data?.id);
				}
			});
		}
	}, [getCurrentMonthNameId]);

	useEffect(() => {
		if (filter.quarterlyBonusId !== null) {
			dispatch(GetQuarterlyBonus(filter)).unwrap().then((res) => {
				if (res.success) {

					let item = res.data.filter(x =>
						x.quarterlyBonusMonths.some(month =>
							month.consolidatedBonus.actualAmount !== 0 || month.consolidatedBonus.projectedAmount !== 0
						)
					);

					if (item.length === 0) {
						const getPreviousQuarterlyBonus = bonusList.filter(item => item.id <= filter.quarterlyBonusId);
						const cQuarterly = getPreviousQuarterlyBonus != undefined && getPreviousQuarterlyBonus.length > 0 && getPreviousQuarterlyBonus[getPreviousQuarterlyBonus.length - 2];
						const previousQuarterly = getPreviousQuarterlyBonus != undefined && getPreviousQuarterlyBonus.length > 0 && getPreviousQuarterlyBonus[getPreviousQuarterlyBonus.length - 3];
						cQuarterly && cQuarterly != undefined && setFilter({ ...filter, quarterlyBonusId: cQuarterly.id, previousQuarterlyBonus: previousQuarterly !== undefined && previousQuarterly !== null ? previousQuarterly.id : 0 })

					} else {
						setQuarterlyBonusData(res.data.find(item => item.id === filter.quarterlyBonusId));
					}
				}
				if (filter.quarterlyBonusId > 0) {
					dispatch(hideLoading());
				}
			});
		}

		if (filter.quarterlyBonusId === 0) {
			dispatch(GetYTDBonus(filter)).unwrap().then(() => {
				dispatch(hideLoading());
			});
		}
	}, [filter, qid]);



	useEffect(() => {
		let scaleAmountList = [];
		const nagativeScaleAmountList = [];
		const scaleAmountListWithnagative = [];
		const topScale = quarterlyBonusData?.bonuslevels[quarterlyBonusData?.bonuslevels.length - 1];
		const bottomScale = quarterlyBonusData?.bonuslevels[0];
		const lastQuarterBudgetAmount = quarterlyBonusData?.lastQuarterBudgetAmount;
		const lastQuarterActualAmount = quarterlyBonusData?.lastQuarterActualAmount;

		if (topScale && bottomScale) {
			const divVal = quarterlyBonusData?.bonuslevels.length - 1;
			const calScaleAmount = Math.floor(Math.abs((topScale.budgetAmount - bottomScale.budgetAmount) / divVal));
			setScaleValue(calScaleAmount);

			const topScale1 = Math.round(quarterlyBonusData?.quarterTotal + (quarterlyBonusData?.quarterTotal * 5 / 100));
			const bonusMonths = quarterlyBonusData?.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId);
			const topScale2Months = bonusMonths.length > 0 && bonusMonths[0];
			const topScale2 = topScale2Months && topScale2Months.consolidatedBonus?.actualProjectedSum && Math.round(topScale2Months.consolidatedBonus?.actualProjectedSum + (topScale2Months.consolidatedBonus?.actualProjectedSum * 5 / 100));
			const topScale3 = topScale.budgetAmount + (calScaleAmount * 2);

			console.log("calScaleAmount", calScaleAmount);
			const modFirstLabeledRow = (bottomScale.budgetAmount % calScaleAmount);
			console.log("modFirstLabeledRow", modFirstLabeledRow);

			let topScaleValue;
			if (topScale1 >= topScale2 && topScale1 >= topScale3) {
				topScaleValue = topScale1;
			} else if (topScale2 >= topScale1 && topScale2 >= topScale3) {
				topScaleValue = topScale2;
			} else {
				topScaleValue = topScale3;
			}

			let starting = 0;

			const nagativeValues = quarterlyBonusData?.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId && ((item.consolidatedBonus.actualProjectedSum < 0 || item.consolidatedBonus.actualAmount < 0) || (item.consolidatedBonus.budgetSum < 0 || item.consolidatedBonus.budgetAmount < 0)));


			let i;
			if (lastQuarterBudgetAmount > 0 || lastQuarterActualAmount > 0) {

				if (lastQuarterBudgetAmount < lastQuarterActualAmount) {
					starting = lastQuarterBudgetAmount;
				} else if (lastQuarterActualAmount < lastQuarterBudgetAmount) {
					starting = lastQuarterActualAmount;
				}


				if (nagativeValues && nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < starting)) {
					scaleAmountListWithnagative.push(0);
					let value = -modFirstLabeledRow;
					let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < 0) || nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < starting);
					let endValue = nagativeValue && nagativeValue.consolidatedBonus.actualProjectedSum - modFirstLabeledRow;



					while (value >= endValue) {
						nagativeScaleAmountList.push(value);
						scaleAmountListWithnagative.push(Math.round(value));
						value -= calScaleAmount;
					}

					if (starting >= endValue && endValue > 0) {
						starting = endValue;
					}

					if (starting < 0) {
						starting = calScaleAmount;
					}

				} else if (nagativeValues && nagativeValues.find(item => item.consolidatedBonus.budgetSum < starting)) {
					scaleAmountListWithnagative.push(0);
					let value = -modFirstLabeledRow;
					let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.budgetSum < 0) || nagativeValues.find(item => item.consolidatedBonus.budgetSum < starting);
					let endValue = nagativeValue && nagativeValue.consolidatedBonus.budgetSum - modFirstLabeledRow;



			while (value >= endValue) {
						nagativeScaleAmountList.push(value);
						scaleAmountListWithnagative.push(Math.round(value));
						value -= calScaleAmount;
					}

					if (starting >= endValue && endValue > 0) {
						starting = endValue;
					}

					if (starting < 0) {
						starting = calScaleAmount;
					}


				} else if (starting < 0) {
					scaleAmountListWithnagative.push(0);
					let value = -modFirstLabeledRow;
					let endValue = starting - modFirstLabeledRow;
					while (value >= endValue) {
						nagativeScaleAmountList.push(Math.round(value));
						scaleAmountListWithnagative.push(Math.round(value));
						value -= calScaleAmount;
					}
					starting = calScaleAmount;
				}

				if (nagativeScaleAmountList.length > 0) {
					starting = calScaleAmount;
				}

				for (i = modFirstLabeledRow; i <= topScaleValue; i += calScaleAmount) {					
					if (i >= starting) {
						scaleAmountList.unshift(Math.round(i));
						scaleAmountListWithnagative.unshift(Math.round(i));
					}
				}
				
			} else {

				for (i = modFirstLabeledRow; i <= topScaleValue; i += calScaleAmount) {
					scaleAmountList.unshift(Math.round(i));
					scaleAmountListWithnagative.unshift(Math.round(i));
				}


				const nagativeActualProjectedSum = nagativeValues && nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < modFirstLabeledRow);
				const nagativeBudgetSum = nagativeValues && nagativeValues.find(item => item.consolidatedBonus.budgetSum < modFirstLabeledRow);


				if (nagativeActualProjectedSum && nagativeBudgetSum) {
					if (nagativeActualProjectedSum.consolidatedBonus.actualProjectedSum < modFirstLabeledRow && nagativeBudgetSum.consolidatedBonus.budgetSum < modFirstLabeledRow) {
						const nagativeItem = nagativeActualProjectedSum.consolidatedBonus.actualProjectedSum < nagativeBudgetSum.consolidatedBonus.budgetSum ? nagativeActualProjectedSum.consolidatedBonus.actualProjectedSum : nagativeBudgetSum.consolidatedBonus.budgetSum;
						scaleAmountListWithnagative.push(0);
						let value = -modFirstLabeledRow;
						let endValue = nagativeItem;
						while (value >= endValue) {
							nagativeScaleAmountList.push(value);
							scaleAmountListWithnagative.push(Math.round(value));
							value -= calScaleAmount;
						}
					}

				} else if (nagativeActualProjectedSum) {
					scaleAmountListWithnagative.push(0);
					let value = -modFirstLabeledRow;
					let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.actualProjectedSum < modFirstLabeledRow);
					let endValue = nagativeValue.consolidatedBonus.actualProjectedSum - modFirstLabeledRow;
					while (value >= endValue) {
						nagativeScaleAmountList.push(value);
						scaleAmountListWithnagative.push(Math.round(value));
						value -= calScaleAmount;
					}
				} else if (nagativeBudgetSum) {
					scaleAmountListWithnagative.push(0);
					let value = -modFirstLabeledRow;
					let nagativeValue = nagativeValues.find(item => item.consolidatedBonus.budgetSum < modFirstLabeledRow);
					let endValue = nagativeValue.consolidatedBonus.budgetSum - modFirstLabeledRow;
					while (value >= endValue) {
						nagativeScaleAmountList.push(value);
						scaleAmountListWithnagative.push(Math.round(value));
						value -= calScaleAmount;
					}
				}
			}

			if (topScale1 == topScaleValue) {
				const getIndex = findNearestIndex(scaleAmountList, quarterlyBonusData?.quarterTotal);
				if (getIndex == 1) {
					scaleAmountList.unshift(i);
					scaleAmountListWithnagative.unshift(Math.round(i));
				} else if (getIndex == 0) {
					scaleAmountList.unshift(i);
					scaleAmountList.unshift(i * 2);
					scaleAmountListWithnagative.unshift(Math.round(i));
					scaleAmountListWithnagative.unshift(Math.round(i * 2));
				}
			}

			if (topScale2 == topScaleValue && topScale2Months) {
				const getIndex = findNearestIndex(scaleAmountList, topScale2Months.consolidatedBonus?.actualProjectedSum);
				if (getIndex == 1) {
					scaleAmountList.unshift(i);
					scaleAmountListWithnagative.unshift(Math.round(i));
				} else if (getIndex == 0) {
					scaleAmountList.unshift(i);
					scaleAmountList.unshift(i * 2);
					scaleAmountListWithnagative.unshift(Math.round(i));
					scaleAmountListWithnagative.unshift(Math.round(i * 2));
				}
			}

			if (topScale3 == topScaleValue) {
				const getIndex = findNearestIndex(scaleAmountList, topScale.budgetAmount);
				if (getIndex == 1) {
					scaleAmountList.unshift(i);
					scaleAmountListWithnagative.unshift(Math.round(i));
				} else if (getIndex == 0) {
					scaleAmountList.unshift(i);
					scaleAmountList.unshift(i * 2);
					scaleAmountListWithnagative.unshift(Math.round(i));
					scaleAmountListWithnagative.unshift(Math.round(i * 2));
				}
			}

			let updateBonuslevelAmounts = quarterlyBonusData && quarterlyBonusData.bonuslevels.length > 0 && quarterlyBonusData.bonuslevels.map(item => item.budgetAmount);
			if (updateBonuslevelAmounts) {
				for (i = 1; i <= 3; i++) {
					updateBonuslevelAmounts.push((calScaleAmount * i) + updateBonuslevelAmounts[updateBonuslevelAmounts.length - 1]);
				}

				for (i = 1; i <= 3; i++) {
					updateBonuslevelAmounts.unshift(updateBonuslevelAmounts[0] - (calScaleAmount * i));
				}
				setBonuslevelAmounts(updateBonuslevelAmounts);
			}
		}

		setScaleAmount(scaleAmountList);
		setNegativeScaleAmount(nagativeScaleAmountList);
		setScaleAmountwithNegative(scaleAmountListWithnagative)
	}, [quarterlyBonusData]);


	useEffect(() => {
		const rect = graphLines.current?.getBoundingClientRect();

		setGraphLinesHeight(rect?.height);
	}, [graphLines, scaleAmount, windowWidth]);

	const handleToggle = () => {
		setIsChecked(!isChecked);
		if (isChecked === false)
			navigate('/bonuslevels');
		else
			navigate('/financial-scoreboard');
	};


	const getTop2 = () => {
		const amount = scaleAmount.length > 0 && scaleAmount[0];
		return (
			<div className="bonus-levels-graph-lines" ref={graphLines}>
				<p className="bonus-levels-numbers">{formatUSD(amount)}</p>
			</div>
		);
	}

	const calQGoalHeight = () => {
		const index = findNearestIndex(scaleAmountwithNegative, quarterlyBonusData?.quarterTotal);
		return index * graphLinesHeight;
	}

	const GetCurrecntQuarterlyBonusList = () => {
		if (currentMonth >= 0 && currentMonth <= 2) {
			return bonusList.filter(item => item.name === quarterName.q1 && item.isActualProjectedAmount === true);
		}
		else if (currentMonth >= 3 && currentMonth <= 5) {
			return bonusList.filter(item => (item.name === quarterName.q1 || item.name === quarterName.q2) && item.isActualProjectedAmount === true);
		}
		else if (currentMonth >= 6 && currentMonth <= 8) {
			return bonusList.filter(item => item.name !== quarterName.q4 && item.isActualProjectedAmount === true);
		}
		else if (currentMonth >= 9 && currentMonth <= 11) {
			return bonusList.filter(item => item.isActualProjectedAmount === true);
		}
	}

	const updateHistoryAndReturnQuarter = (path, quarter) => {
		window.history.replaceState(null, '', `bonuslevels/${path}`);
		return quarter;
	}

	const GetCurrecntQuarterlyBonus = (qBonusList) => {
		if (currentMonth >= 0 && currentMonth <= 2) {
			qBonusList = qBonusList.filter(item => item.name === quarterName.q1 && item.isActualProjectedAmount === true);
		}
		else if (currentMonth >= 3 && currentMonth <= 5) {
			qBonusList = qBonusList.filter(item => (item.name === quarterName.q1 || item.name === quarterName.q2) && item.isActualProjectedAmount === true);
		}
		else if (currentMonth >= 6 && currentMonth <= 8) {
			qBonusList = qBonusList.filter(item => item.name !== quarterName.q4 && item.isActualProjectedAmount === true);
		}
		else if (currentMonth >= 9 && currentMonth <= 11) {
			qBonusList.filter(item => item.isActualProjectedAmount === true);
		}

		let isQuarterName = '';
		if (qid !== undefined) {
			switch (qid.toLowerCase()) {
				case 'q1':
					isQuarterName = quarterName.q1;
					break;
				case 'q2':
					isQuarterName = quarterName.q2;
					break;
				case 'q3':
					isQuarterName = quarterName.q3;
					break;
				case 'q4':
					isQuarterName = quarterName.q4;
					break;
				case 'ytd':
					isQuarterName = 'ytd';
					break;
				default:
					break;
			}
		}

		if (isQuarterName === 'ytd') {
			setFilter({ ...filter, quarterlyBonusId: 0 });
			return isQuarterName;
		} else if (isQuarterName !== '') {
			const quarterItem = qBonusList.find(item => item.name === isQuarterName);
			if (quarterItem !== undefined) {
				return quarterItem.name;
			}
		}

		if (currentMonth >= 0 && currentMonth <= 2) {
			const isExits = qBonusList.find(item => item.name === quarterName.q1);
			if ((isExits === null || isExits === undefined) && qBonusList.length > 0) {
				const isLast = qBonusList[qBonusList.length - 1];
				if (isLast !== null && isLast !== undefined) {
					return updateHistoryAndReturnQuarter(isLast.name.toLowerCase(), isLast.name);
				}
			}
			return updateHistoryAndReturnQuarter('q1', quarterName.q1);
		}
		else if (currentMonth >= 3 && currentMonth <= 5) {
			const isExits = qBonusList.find(item => item.name === quarterName.q2);
			if ((isExits === null || isExits === undefined) && qBonusList.length > 0) {
				const isLast = qBonusList[qBonusList.length - 1];
				if (isLast !== null && isLast !== undefined) {
					return updateHistoryAndReturnQuarter(isLast.name.toLowerCase(), isLast.name);
				}
			}
			return updateHistoryAndReturnQuarter('q2', quarterName.q2);
		}
		else if (currentMonth >= 6 && currentMonth <= 8) {
			const isExits = qBonusList.find(item => item.name === quarterName.q3);
			if ((isExits === null || isExits === undefined) && qBonusList.length > 0) {
				const isLast = qBonusList[qBonusList.length - 1];
				if (isLast !== null && isLast !== undefined) {
					return updateHistoryAndReturnQuarter(isLast.name.toLowerCase(), isLast.name);
				}
			}
			return updateHistoryAndReturnQuarter('q3', quarterName.q3);
		}
		else if (currentMonth >= 9 && currentMonth <= 11) {
			const isExits = qBonusList.find(item => item.name === quarterName.q4);
			if ((isExits === null || isExits === undefined) && qBonusList.length > 0) {
				const isLast = qBonusList[qBonusList.length - 1];
				if (isLast !== null && isLast !== undefined) {
					return updateHistoryAndReturnQuarter(isLast.name.toLowerCase(), isLast.name);
				}
			}
			return updateHistoryAndReturnQuarter('q4', quarterName.q4);
		}
	}


	const reverseArray = () => {
		var quarterlyBonusMonths = quarterlyBonusData?.quarterlyBonusMonths;
		return quarterlyBonusMonths && [...quarterlyBonusMonths].reverse();

	};

	return (
		<div className="directory-wrap events-wrap financial-scoreboard-desktop bonus-level-page">
			<div className="drawer-menu-collapse-wrap"></div>
			<Navigation />
			<div className="events-page-wrap bonus-levels-header">
				<div className="page-inner events-inner">
					<div className="profile-wrap-outer mobile-show">
						<a className="profile-wrapper" href={`/profile/${siteUserId}`}>
							<span className="profile-image">
								{(loggedInEmployee && loggedInEmployee.profileImage && loggedInEmployee.profileImage.previewsrc) ?
									<img src={loggedInEmployee.profileImage.previewsrc} alt={loggedInEmployee.profileImage.altText} loading="lazy" />
									:
									<span className="navigation-menu-sticky-profile-image">
										<img src="../../profile-lg.png" alt="Placeholder Profile Image" loading="lazy" />
									</span>
								}
							</span>
							<span className="profile-content">
								<span className="profile-name">Benjamin Franklin</span>
								<span className="profile-title">
									<span>Solid Wood Processing, Office</span>
								</span>
							</span>
						</a>
					</div>
					<div className="events-content-scrollable-wrap">
						<div className="inner-pages-alert">
							<PortalAlert />
						</div>
						<div className="event-content-page">
							<div className="profile-wrap-outer">
								<a className="profile-wrapper" href={`/profile/${siteUserId}`}>
									<span className="profile-picture">
										<span className="profile-image">
											{(loggedInEmployee && loggedInEmployee.profileImage && loggedInEmployee.profileImage.previewsrc) ?
												<span className="profile-image">
													<img src={loggedInEmployee.profileImage.previewsrc} alt={loggedInEmployee.profileImage.altText} loading="lazy" />
												</span>
												:
												<span className="navigation-menu-sticky-profile-image">
													<img src="../../profile-lg.png" alt="Placeholder Profile Image" loading="lazy" />
												</span>
											}
										</span>
									</span>
									<span className="profile-content">
										{loggedInEmployee && loggedInEmployee.fullName && (
											<>
												<span className="profile-name">{loggedInEmployee.fullName}</span>
												<span className="profile-title">
													<span>{loggedInEmployee.departmentDisplay}</span>
												</span>
											</>
										)}
									</span>
								</a>
							</div>
							<div className="monitors-header-wrap">
								<div className="scoreboard-header-left bonus-levels">
									<h1 className="page-title bonus-header-title">{quarterlyBonusData && quarterlyBonusData !== undefined ? quarterlyBonusData.name : "YTD"} Bonus Levels</h1>
								</div>

								<div className="toggle-wrapper">
									<div className={`toogle-button ${isChecked ? 'togglechecked' : ''}`}>
										<label className="toggle-label scorelabel" htmlFor="switch" onClick={() => navigate('/financial-scoreboard')}>Scoreboard</label>
										<label className="toggle-label bonuslabel" htmlFor="switch">Bonuses</label>
										<input className="toggle-input"
											type="checkbox"
											id="switch"
											name="switch"
											checked={isChecked}
											onChange={handleToggle}
										/>
									</div>
									<div className="filter-bars">
										<label>
											<select className="menu-wrap" value={filter.quarterlyBonusId} onChange={(e) => {
												const url = "bonuslevels/" + e.target.selectedOptions[0].innerHTML?.toLowerCase();
												window.history.replaceState(null, '', url);

												const selectedIndex = e.target.options[e.target.selectedIndex].index;
												const getPreviousQuarterlyBonus = bonusList[selectedIndex - 1];
												setFilter({ ...filter, quarterlyBonusId: parseInt(e.target.value), previousQuarterlyBonus: getPreviousQuarterlyBonus != null && getPreviousQuarterlyBonus != undefined ? getPreviousQuarterlyBonus?.id : 0 });
											}}>
												{bonusList && bonusList.length > 0 && GetCurrecntQuarterlyBonusList()?.map(item => (
													<option key={item.id} value={item.id}>{item.name}</option>
												))}
												<option value="0">YTD</option>
											</select>
										</label>
									</div>
								</div>


							</div>

							<div className="bonus-tab-wrapper">
								{bonusList && bonusList.length > 0 && GetCurrecntQuarterlyBonusList()?.map((item, index )=> (
									<button onClick={() => {
										const url = "bonuslevels/" + item.name.toLowerCase();
										window.history.replaceState(null, '', url);

										const getPreviousQuarterlyBonus = bonusList[index - 1];
										setFilter({ ...filter, quarterlyBonusId: item.id, previousQuarterlyBonus: getPreviousQuarterlyBonus != null && getPreviousQuarterlyBonus != undefined ? getPreviousQuarterlyBonus?.id : 0 });
									}} className={`bonus-tab-btn ${item.id === filter.quarterlyBonusId ? 'active' : ''}`} key={item.id}>{item.name}</button>
								))}
								<button className={`bonus-tab-btn ${0 === filter.quarterlyBonusId ? 'active' : ''}`} onClick={() => {
									window.history.replaceState(null, '', 'bonuslevels/ytd');
									setFilter({ ...filter, quarterlyBonusId: 0 })
								}}>YTD</button>
							</div>
							<div className="bonus-level-graph-wrapper">
								{filter.quarterlyBonusId > 0 ?
									<div className="bonus-levels-graph-outer-wrap bonus-levels-desktop-wrap q-wrap">
										<div className="bonus-levels-graph-inner-wrap">
											{getTop2()}

											{scaleAmount && scaleAmount.length > 0 && scaleAmount.slice(1).map((item, index) => {

												const currentValue = item;

												const bonuslevelIndex = findNearestIndex(bonuslevelAmounts, item);
												const foundItem = bonuslevelIndex && quarterlyBonusData?.bonuslevels.find(bonusItem => bonusItem.budgetAmount === bonuslevelAmounts[bonuslevelIndex]);

												const lastMonthItem = quarterlyBonusData?.quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId);
												const lastMonth = lastMonthItem && lastMonthItem.length > 0 && lastMonthItem[0];
												const checkItem = lastMonth && lastMonth.consolidatedBonus.actualProjectedSum > currentValue ? true : false;


												return (
													<div className={`bonus-levels-graph-lines ${foundItem && foundItem.number != 1 ? 'selected-graph-line' : ''}`} key={index}>
														<p className="bonus-levels-numbers">{formatUSD(item)}</p>
														{foundItem !== undefined && foundItem !== 0 && (
															<p className="bonus-levels-highlighted-number">{foundItem.number}</p>
														)}
														{foundItem !== undefined && foundItem !== 0 && checkItem !== null && checkItem !== undefined && checkItem === true && (
															<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M6.01683 8.51666L4.3835 6.88333C4.2835 6.78333 4.16127 6.73333 4.01683 6.73333C3.87238 6.73333 3.74461 6.78888 3.6335 6.89999C3.5335 6.99999 3.4835 7.12222 3.4835 7.26666C3.4835 7.41111 3.5335 7.53333 3.6335 7.63333L5.66683 9.68333C5.76683 9.78333 5.8835 9.83333 6.01683 9.83333C6.15016 9.83333 6.26683 9.78333 6.36683 9.68333L10.3502 5.69999C10.4613 5.58888 10.5168 5.46111 10.5168 5.31666C10.5168 5.17222 10.4613 5.04444 10.3502 4.93333C10.2391 4.83333 10.1085 4.78611 9.9585 4.79166C9.8085 4.79722 9.6835 4.84999 9.5835 4.94999L6.01683 8.51666ZM7.00016 13.6667C6.08905 13.6667 5.22794 13.4917 4.41683 13.1417C3.60572 12.7917 2.89738 12.3139 2.29183 11.7083C1.68627 11.1028 1.2085 10.3944 0.858496 9.58333C0.508496 8.77222 0.333496 7.91111 0.333496 6.99999C0.333496 6.07777 0.508496 5.21111 0.858496 4.39999C1.2085 3.58888 1.68627 2.88333 2.29183 2.28333C2.89738 1.68333 3.60572 1.20833 4.41683 0.858328C5.22794 0.508328 6.08905 0.333328 7.00016 0.333328C7.92238 0.333328 8.78905 0.508328 9.60016 0.858328C10.4113 1.20833 11.1168 1.68333 11.7168 2.28333C12.3168 2.88333 12.7918 3.58888 13.1418 4.39999C13.4918 5.21111 13.6668 6.07777 13.6668 6.99999C13.6668 7.91111 13.4918 8.77222 13.1418 9.58333C12.7918 10.3944 12.3168 11.1028 11.7168 11.7083C11.1168 12.3139 10.4113 12.7917 9.60016 13.1417C8.78905 13.4917 7.92238 13.6667 7.00016 13.6667Z" fill="#00FF38" />
															</svg>
														)}
													</div>
												);
											})}
											<div className="bonus-levels-graph-baseline"></div>
											{negativeScaleAmount && negativeScaleAmount.map((item, index) => (
												<div className="bonus-levels-graph-lines" key={index}>
													<p className="bonus-levels-numbers">{formatUSD(item)}</p>
												</div>
											))}
										</div>


										<div className="q1-goal-wrap" style={{ top: `${calQGoalHeight()}px` }} >
											<p className="q1-goal-text">
												{quarterlyBonusData && (`${quarterlyBonusData.name} Budgeted`)}<br />
												<span className="d-sm-none">{quarterlyBonusData && formatUSD(quarterlyBonusData.quarterTotal)}</span>
											</p>
										</div>

										<BudgetedBoxes
											quarterlyBonusMonths={reverseArray()}
											getCurrentMonthNameId={getCurrentMonthNameId}
											scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
											lastQuarterActualAmount={quarterlyBonusData?.lastQuarterActualAmount}
											isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
											graphLinesHeight={graphLinesHeight}
										/>


										<ActualBoxes
											quarterlyBonusMonths={reverseArray()}
											getCurrentMonthNameId={getCurrentMonthNameId}
											scaleAmount={negativeScaleAmount.length > 0 ? scaleAmountwithNegative : scaleAmount}
											graphLinesHeight={graphLinesHeight}
											quarterName={quarterlyBonusData?.name}
											scaleValue={scaleValue}
											lastQuarterActualAmount={quarterlyBonusData?.lastQuarterActualAmount}
											isNegativeScale={negativeScaleAmount.length > 0 ? true : false}
											windowWidth={windowWidth}
										/>

									</div>
									:
									<YTDBonusPage ytdList={ytdBonusList} getCurrentMonthNameId={getCurrentMonthNameId} />
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}



