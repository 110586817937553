import api from '../utils/api';

export const getAll = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/ggobresource/getallggobresource',
        method: 'GET',
        ...options,
    });
};


export const updateOrder = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/ggobresource/order',
        method: 'POST',
        ...options,
    });
}

export const addEdit = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/ggobresource/addeditggobresourcedetail`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const Delete = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/ggobresource/deleteggobresourcedetail/${id}`,
        method: 'DELETE',
        ...options,
    });
}

export const updateResourceTitle = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/ggobresource/editggobresource`,
        method: "POST",
        data: data,
        ...options,
    });
}
export const getByFilter = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/ggobresource/getallbyfilter`,
        method: "POST",
        data: data,
        ...options,
    });
}

//getGGOBResource

export const getGGOBResourceHeader = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/ggobresource/getGGOBResource',
        method: 'GET',
        ...options,
    });
}

export const getggobbyid = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/ggobresource/getggobbyid/${id}`,
        method: 'GET',
        ...options,
    });
}