import React, { useEffect, useState } from 'react';
import './events.css'; 
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../../components/navigation/Navigation';
import { getLoggedInEmployeeById, getCompanies } from '../../store/employee/actions';
import { getCalendarEvents } from '../../store/events/actions';
import { getEmployeesCompanyList } from '../../api/events';
import { useNavigate } from 'react-router-dom';
import { GetTimeZone, formatDate } from '../../components/common/DateTimeFormatter';
import { v4 as uuidv4 } from 'uuid';
import HeaderProfile from '../../components/HeaderProfile';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { getWeekLine } from '../../helpers/formatterHelper';
import EventImages from '../../components/events/EventImages';
import { setScrollId } from '../../store/events/index';
import PortalAlert from '../../components/portalAlert/portalAlert';

const RsvpTypes = {
    NO: 'no',
    YES: 'yes',
    ONE_PLUS: 'oneplus',
};

export default function Events() { 
    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    const { companiesList } = useSelector(state => state.employee);
    const { siteUserId, primaryCompanyId } = useSelector(state => state.auth);
    const { calendarEvents, scrollId } = useSelector(state => state.event); 
    const [filter, setFilter] = useState({ query: '', companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? primaryCompanyId : 0), sort: 5, IsShowPastEvent: false, start: 1, length: 5, userId: siteUserId, timezone: GetTimeZone() });
    
    const [employeeCompany, setEmployeeCompany] = useState([]); 

    const handleFilterCompany = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        filter.start = 1;
        setFilter({ ...filter, companyId: selectedCompanyId });
    };

    useEffect(() => {
        document.title = 'Events | CWP Team Connect';
        dispatch(showLoading());
        dispatch(getLoggedInEmployeeById({ id: siteUserId }));
        dispatch(getCompanies());

        getEmployeesCompanyList(siteUserId).then((response) => {
            let employeeCompanyResponse = response.data.data;
            setEmployeeCompany(employeeCompanyResponse);
        });

    }, []);

    useEffect(() => {     
        setFilter({ ...filter, userId: siteUserId });
    }, [siteUserId]);

    useEffect(() => {     
        dispatch(getCalendarEvents(filter)).unwrap().then(() => {
            dispatch(hideLoading());
        });        
    }, [filter]);
 
    let checkDate = '';
    const hideEventDate = (date) => {
        if (checkDate != date) {
            checkDate = date;
            return true;
        }
        return false;
    }

    const handlePastEventsFilter = () => {
        dispatch(showLoading());
        setFilter({ ...filter, IsShowPastEvent: true });
    }

    const handleEventsFilter = () => {
        dispatch(showLoading());
        setFilter({ ...filter, IsShowPastEvent: false });
    }

    useEffect(() => {
        if (calendarEvents.length > 0) {
            const element = document.getElementById(scrollId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }
    }, [calendarEvents]);

    return (
        <div className="directory-wrap events-wrap events-screen-wrapper">
            <Navigation />
            <div className="events-page-wrap">
                <div className="page-inner events-inner">
                    <div className="profile-wrap-outer mobile-show">
                        <HeaderProfile />
                    </div>

                    
                   
                    <div className="events-content-scrollable-wrap">
                        <div className="inner-pages-alert todays-alert">
                            <PortalAlert />
                        </div>
                        <div className="profile-wrap-outer">
                            <HeaderProfile />
                        </div>
                        <div className="events-header-wrap">
                            <div className="events-header-wrap-inner">
                                <h1 className="page-title">Events</h1>
                                <div className="events-select-text-wrap">
                                    <div className="filter-bars events-filter">
                                        <label>
                                            <select className="menu-wrap org-dropdown" name="slct" onChange={handleFilterCompany} value={filter.companyId}>
                                                <option value="0">All</option>
                                                {companiesList && companiesList.length > 0 &&
                                                    companiesList.map(item => (
                                                        <option key={item.id} value={item.id}>{item.abbreviatedName}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div className="events-span-date d-flex past-event">
                                        {filter.IsShowPastEvent === false && (
                                            <p onClick={handlePastEventsFilter} className="view-events-label">View Past Events</p>
                                        )}
                                        {filter.IsShowPastEvent === true && (
                                            <p onClick={handleEventsFilter} className="view-events-label">View Upcoming Events</p>
                                        )}

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="events-content-wrap">

                            {/*January*/} 
                            {calendarEvents && calendarEvents.length > 0 && calendarEvents.map(calendar => (
                                <div className="events-content-wrap-inner" key={uuidv4()}>
                                    <div className="sticky-content">
                                        <p className="sticky-content-title">{calendar.month}</p>
                                    </div>
                                    {calendar.weeklyEvents.length > 0 && calendar.weeklyEvents.map(week => (
                                        <>
                                            <div className="events-listing-wrap" key={uuidv4()}>
                                                <div className="events-span-date">
                                                    <p>{getWeekLine(week.weekStart)} - {getWeekLine(week.weekEnd)}</p>                                                    
                                                </div>
                                            </div>
                                            <div className="events-listing-wrap" key={uuidv4()}>
                                                {week?.eventLogs && week.eventLogs?.length > 0 && week?.eventLogs?.map(log => (
                                                    <a onClick={() => {
                                                        dispatch(setScrollId({ scrollId: `event-${log.event.baseId}-${log.id}` }));
                                                        navigate(`/event-detail/${log.event.baseId}/${log.id}`);
                                                    }} id={`event-${log.event.baseId}-${log.id}`} className="events-listing-item" key={uuidv4()}> 
                                                        <div className="events-date-wrap">
                                                            {hideEventDate(formatDate(log?.eventDate, "MMM d")) && (
                                                                <div className="events-date-wrap-inner">
                                                                    <span className="events-month-title">{formatDate(log?.eventDate, "EEE")}</span>
                                                                    <span className="events-day-title">{formatDate(log?.eventDate, "d")}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="events-title-wrap">
                                                            <div className="events-title-inner">
                                                                <div className="events-image-title-wrap">                                              
                                                                    {log.event && log.event.media && log.event.media.length > 0 && (
                                                                        <EventImages mediaItem={log.event.baseId} />
                                                                    )}                                                                    
                                                                <p>                                                                    

                                                                    <span className="flex-wrap">
                                                                    {log.event.eventCompanies && log.event.eventCompanies.length > 0 && (
                                                                        <>
                                                                            {log.event.eventCompanies.map(item => (
                                                                                <span key={item.companyId} className="news-day-title">
                                                                                    {companiesList.find(company => company.id === item.companyId)?.abbreviatedName}
                                                                                </span>
                                                                            ))}
                                                                        </>
                                                                        )}
                                                                    </span>

                                                                    <span>{log.event.title}</span>                                                                    
                                                                    </p>
                                                                </div>
                                                                <div className="events-rsvp-wrap">
                                                                    <p>{log.event.eventStartTime}</p>


                                                                    {log.event.eventCompanies && log.event.eventCompanies.length > 0 && log.event.eventCompanies.some(company => employeeCompany.find(empCompany => empCompany.id === company.companyId)) && (
                                                                        (log.event.rsvpType === RsvpTypes.YES || log.event.rsvpType === RsvpTypes.ONE_PLUS) && (
                                                                            <>
                                                                                {(log.event?.eventRSVPs?.siteUserId !== siteUserId || (log.event?.eventRSVPs?.siteUserId === siteUserId && log.event?.eventRSVPs?.numPlus1s === 0)) && (
                                                                                    <button className="events-rsvp-cta">RSVP Here</button>
                                                                                )}
                                                                                {log.event?.eventRSVPs?.siteUserId === siteUserId && log.event?.eventRSVPs?.numPlus1s > 0 && (
                                                                                    <button className="events-rsvp-cta selected">RSVP&rsquo;d</button>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ))} 
                                            </div>
                                        </>
                                    ))}
                                    
                                </div>
                            ))}

                            {calendarEvents && calendarEvents.length === 0 && (
                                <div className="events-content-wrap-inner">
                                    <div className="sticky-content">
                                        <p className="sticky-content-title">No Events</p>
                                    </div>
                                </div>
                            )}
 
                        </div>
                    </div>
             
                </div>
            </div>
        </div>
    );
}
