
import AccountLayout from '../layouts/AccountLayout.jsx';
import CreateAccount from '../pages/account/createaccount.js';
import Login from '../pages/account/login.js';
import Logout from '../pages/account/logout.js';

export const accountRoutes = [
  {
    path: '/login',
    element: Login,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/logout',
    element: Logout,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/create',
    element: CreateAccount,
    layout: AccountLayout,
    secure: false
  },
];
