import { format } from 'date-fns';
export const formatDateTimeForUserTimeZone = (utcDateTimeString) => {
    try {
        const formatOfDate = "MM/dd/yyyy @ hh:mm a";
        const dateParsed = getLocalDateTime(utcDateTimeString);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;
    } catch (error) {
        console.error(error);
        return "";
    }
};

export const formatDate = (utcDateTimeString, formatOfDate) => {
    try {
        if (!formatOfDate) formatOfDate = "MM/dd/yyyy";
        const dateParsed = getLocalDateTime(utcDateTimeString);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;
    } catch (error) {
        console.error(error);
        return "";
    }
};

export const DateTimeForUserTimeZone = (utcDateTimeString) => {
    try {
        const formatOfDate = "MM/dd/yyyy, hh:mm:ss a";
        const dateParsed = getLocalDateTime(utcDateTimeString);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;
    } catch (error) {
        console.error(error);
        return "";
    }
};


export const GetTimeZone = () => {
  try {
    const offsetInMinutes = new Date().getTimezoneOffset();
    // Convert offset from minutes to hours and minutes
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const minutes = Math.abs(offsetInMinutes) % 60;

    // Adjust the sign based on the offset direction
    const sign = offsetInMinutes > 0 ? '-' : '+';

    // Set the timezone offset state
    let offset = `${sign}${String(hours).padStart(2, '0') }:${String(minutes).padStart(2, '0')}`;
    return offset;        
    }
    catch (error) {
        console.error(error);
        return "UTC"
    }
};

function getLocalDateTime(utcDateTimeString) {
    const utcDateTime = new Date(utcDateTimeString);

    // Get the local date and time
    const localDateTime = new Date(utcDateTime);
    const offset = localDateTime.getTimezoneOffset();
    localDateTime.setMinutes(localDateTime.getMinutes() - offset);

    return localDateTime;
}


export function formatDateTime(dateInput) {
    const date = new Date(dateInput);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
}
