import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SentMessages } from '../../../components/admin/textalert/sentMessageContainer';
import { ScheduledMessage } from '../../../components/admin/textalert/scheduledMessageContainer'
import { Compose } from '../../../components/admin/textalert/composeContainer'
import { getScheduledData } from '../../../store/compose/actions';
import { ScheduleDeleteConfirmationModal } from './ScheduleDeleteConfirmation';
import { cancelScheduleDate } from '../../../api/compose';

export default function textAlert() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('sent');
    const { openTab, getcomposeid } = useParams();
    const [scheduledCount, setScheduledCount] = useState(0);
    const [filter, setFilter] = useState({ sort: 1 });
    const { scheduledList} = useSelector(state => state.compose);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [cancelSchdleModel, setCancelSchdleModel] = useState({});
    const [deleteMessage, setdeleteMessage] = useState('');
    const [composeid, setcomposeid] = useState(getcomposeid);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setcomposeid(null);       
        window.history.replaceState(null, null, '/siteadmin/textalerts/' + tab);
    }
    useEffect(() => {
        document.title = 'Text Alerts | CWP Employee Portal';
    }, []);

    useEffect(() => {
        dispatch(getScheduledData(filter));
    }, [dispatch, filter]);

    useEffect(() => {
        if (scheduledList) {
            setScheduledCount(scheduledList.length);
        }
    }, [scheduledList]);

    const handleSortChange = (newSort) => {
        setFilter({ ...filter, sort: newSort });
    };

    useEffect(() => {
        if (openTab !== undefined) setActiveTab(openTab);
    }, [openTab]);


    const handleDeleteTrigger = (composeId) => {
        const deleteMessage = 'Are you sure you want to cancel this scheduled Text Alert?'
        setdeleteMessage(deleteMessage);
        setCancelSchdleModel({ id: composeId });
        setShowDeleteModal(true);
    }


    const handleDeleteEvent = (item) => {
        cancelScheduleDate(item.id)
            .then((response) => {
                if (response.data.success) {
                    dispatch(getScheduledData(filter));
                    setActiveTab('scheduled');
                    window.history.replaceState(null, null, '/siteadmin/textalerts/scheduled');
                }
            });
    }
    const onEditComposeById = (composeid) => {      
        setcomposeid(composeid);
        setActiveTab('compose');   
        window.history.replaceState(null, null, '/siteadmin/textalerts/compose/' + composeid);
    }
    

    return (
        <div className="admin-page-wrap text-alert-page-wrap">
            <ScheduleDeleteConfirmationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteEvent={handleDeleteEvent}
                modelEvent={cancelSchdleModel}
                deletemessage={deleteMessage}
            />

            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">Text Alerts</h1>
                <div className="admin-tab-wrap">
                    <button className={`admin-tab ${activeTab === 'sent' ? 'active' : ''}`} id="tab2" onClick={() => handleTabChange('sent')} role="tab" aria-controls="sent-tab" aria-selected="true">Sent</button>
                    <button className={`admin-tab ${activeTab === 'scheduled' ? 'active' : ''}`} onClick={() => handleTabChange('scheduled')} id="tab1" role="tab" aria-controls="scheduled-tab" aria-selected="true">Scheduled ({scheduledCount})</button>
                    <button className={`admin-tab ${activeTab === 'compose' ? 'active' : ''}`} onClick={() => handleTabChange('compose')} id="tab1" role="tab" aria-controls="compose-tab" aria-selected="true">Compose</button>
                </div>
                {activeTab === 'sent' &&
                    <SentMessages />
                }
                {activeTab === 'scheduled' &&
                    <ScheduledMessage
                        scheduledList={scheduledList}
                        onSortChange={handleSortChange}
                        filter={filter}
                    onEditComposeTrigger={onEditComposeById}
                    />
                }

                {activeTab === 'compose' &&
                    <Compose
                    composeid={composeid}   
                    onDeleteTrigger={handleDeleteTrigger}
                    />
                }

            </div>
        </div>
    );
}