import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { saveOrUpdateFinancials, deleteUpdateFinancials } from '../../../store/admin/financials/actions';
import { hideLoading, showLoading } from '../../../store/loading-reducer';
import RollBack from './rollBack';
import { /*formatDate,*/ formatDateTimeForUserTimeZone } from '../../../components/common/DateTimeFormatter';
import { getAllFinancials } from '../../../store/admin/financials/actions';

function FileItem({ file, companyId, financialId, type, bonusLevelId, scoreBoardId, baseId, totalFinancialRecords }) {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useSelector(state => state.auth);
    const [showFinancialHistory, setShowFinancialHistory] = useState(false);

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            dispatch(showLoading());
            setIsLoading(true);
            const formData = new FormData();
            formData.append("File", uploadedFile);
            formData.append("FileType", type);
            formData.append("Financial.CompanyId", companyId);
            formData.append("Financial.Id", financialId);
            formData.append("Financial.BonusLevelId", bonusLevelId);
            formData.append("Financial.ScoreBoardId", scoreBoardId);
            formData.append("Financial.BaseId", baseId)
            dispatch(saveOrUpdateFinancials(formData)).unwrap().then(() => {
                dispatch(hideLoading());
                dispatch(getAllFinancials());
                fileInputRef.current.value = '';
            });
        }
    };

    const handleDeleteUpdate = () => {
        const formData = new FormData();
        formData.append("FileType", type);
        formData.append("Financial.Id", financialId);
        dispatch(deleteUpdateFinancials(formData)).unwrap().then(() => {
            dispatch(getAllFinancials());
        })
    };

    const handleDownload = async () => {
        try {

            const response = await fetch(`/api/financials/downloadfile/${file.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const contentDisposition = response.headers.get("Content-Disposition");
            let fileNameFromHeader = null;
            if (contentDisposition) {
                const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (match) {
                    fileNameFromHeader = match[1];
                }
            }
            const fileNameToUse = fileNameFromHeader ? fileNameFromHeader.replace(/['"]/g, "") : file.OrgFileName;

            const blob = await response.blob();

            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileNameToUse;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (err) {
            console.log(err);
        }
    };

    const handleAddCorti = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        setIsLoading(false);
    }, [file]);


    const handleHistory = () => {
        setShowFinancialHistory(true);
    };

    return (
        <>
            {file === undefined && (
                <div className="file-item-upload-section">
                    <button className="admin-button upload-with-spinner" onClick={handleAddCorti}>Upload Document {isLoading && (<span className="loader"></span>)}</button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                    {showFinancialHistory && <RollBack companyId={companyId} fileType={type} setShowFinancialHistory={setShowFinancialHistory} />}
                    {totalFinancialRecords > 0 ?
                        <button className="admin-button history-btn">
                            <span className="material-symbols-rounded" onClick={handleHistory}>history</span>
                        </button> : ''
                    }

                </div>
            )}
            {file !== undefined && (
                <div className="admin-skill-tile financial-skill-tile">
                    <span className="admin-skill-tile-name file-date-name"><span>{file?.orgFileName && file?.createdDate && (formatDateTimeForUserTimeZone(file?.orgFileName && file?.createdDate))}</span> <span>{file?.orgFileName}</span></span>
                    <span className="admin-skill-tile-action-wrap">
                        <button className="admin-skill-tile-action upload-csv upload-with-spinner" onClick={handleAddCorti}>Upload New Document{isLoading && (<span className="loader"></span>)}</button>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept=".csv"
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                        />

                        {showFinancialHistory && <RollBack companyId={companyId} fileType={type} setShowFinancialHistory={setShowFinancialHistory} />}

                        <button className="admin-skill-tile-action">
                            <span className="material-symbols-rounded" onClick={handleDownload}>download</span>
                        </button>
                        {totalFinancialRecords > 0 ?
                            <button className="admin-skill-tile-action">
                                <span className="material-symbols-rounded" onClick={handleHistory}>history</span>
                            </button> : ''
                        }
                        <button className="admin-skill-tile-action">
                            <span className="material-symbols-rounded" onClick={handleDeleteUpdate}>delete</span>
                        </button>
                    </span>
                </div>
            )}
        </>
    );
}

FileItem.defaultProps = {
    file: undefined,
    companyId: undefined,
    type: undefined,
    financialId: undefined,
    bonusLevelId: undefined,
    scoreBoardId: undefined,
    baseId: null,
    totalFinancialRecords: 0
}

FileItem.propTypes = {
    file: PropTypes.object,
    companyId: PropTypes.number,
    type: PropTypes.string,
    financialId: PropTypes.number,
    bonusLevelId: PropTypes.number,
    scoreBoardId: PropTypes.number,
    baseId: PropTypes.number,
    totalFinancialRecords: PropTypes.number
};

export default FileItem;