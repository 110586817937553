import api from '../utils/api';


export const getJobPostings = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/jobpostings/getalljobpostings',
        method: 'GET',
        ...options,
    });
};


export const addEditJobPostings = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/jobpostings/addeditjobposting`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const deleteJobPosting = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/jobpostings/deletejobposting/${id}`,
        method: 'DELETE',
        ...options,
    });
}

export const updateJobPostingOrder = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/jobpostings/order',
        method: 'POST',
        ...options,
    });
}


export const getAllBenefits = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/benefits/getallbenefits',
        method: 'GET',
        ...options,
    });
};


export const updateBenefitsOrder = (config = {}) => {
    const { options } = config;
    return api({
        url: 'api/benefits/order',
        method: 'POST',
        ...options,
    });
}

export const addEditBenefit = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/benefits/addeditbenefit`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const deleteBenefit = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/benefits/deletebenefit/${id}`,
        method: 'DELETE',
        ...options,
    });
}


export const getJobPostingsByFilter = (config = {}) => {
    const { data, options } = config;
    return api({
        url: 'api/jobpostings/jobpostingsbyfilter',
        method: "POST",
        data: data,
        ...options,
    });
};


export const getBenefitsByFilter = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/benefits/getallbenefitsbyfilter`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const getDocumentList = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `/api/documents/get-all`,
        method: "POST",
        data: data,
        ...options,
    });
}
export const getbenefitbyid = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/benefits/getbenefitbyid/${id}`,
        method: 'GET',
        ...options,
    });
}
export const getjobpostingbyid = (config = {}) => {
    const { id, options } = config;
    return api({
        url: `api/jobpostings/getjobpostingbyid/${id}`,
        method: 'GET',
        ...options,
    });
}