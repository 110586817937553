import React, { useState, useEffect } from "react";
import './hrbenefits.css';
import Navigation from '../../components/navigation/Navigation';
import Accordion from '../../components/accordion/accordion';
import { useSelector, useDispatch } from 'react-redux';
import { getJobPostingsByFilter } from '../../store/hrbenefits/actions';
import HeaderProfile from "../../components/HeaderProfile";
import { getCompanies } from '../../store/employee/actions';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import PortalAlert from '../../components/portalAlert/portalAlert';
import Modules from '../../components/Module/Modules';

export default function JobPostings() {
    const dispatch = useDispatch();
    const [activePanel, setActivePanel] = useState(null);
    const { jobPostingsListByfilter } = useSelector(state => state.hrBenefits); 
    const { primaryCompanyId } = useSelector(state => state.auth);
    const [filter, setFilter] = useState({ query: '', companyId: (primaryCompanyId !== undefined && primaryCompanyId !== null ? primaryCompanyId : 0), sort: 6, IsShowPastEvent: false });
    const initialTodayFilter = primaryCompanyId !== undefined && primaryCompanyId !== 0 ? primaryCompanyId : 0;
    const [newsFilter, setNewsFilter] = useState(initialTodayFilter);
    const { companiesList } = useSelector(state => state.employee);

    const handlePanelToggle = panelTitle => {
        setActivePanel(prevActivePanel => {
            return prevActivePanel === panelTitle ? null : panelTitle;
        });
    };

    useEffect(() => {
        document.title = 'Job Postings | HR & Benefits | CWP Team Connect';
        dispatch(showLoading());
        dispatch(getCompanies());
    }, []);


    useEffect(() => {
        dispatch(getJobPostingsByFilter(filter)).unwrap().then(() => {
            dispatch(hideLoading());
        });
    }, [filter]);

    const isValidJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    return (
        <div className="directory-wrap events-wrap hrevents-wrap">
            <div className="drawer-menu-collapse-wrap"></div>
            <Navigation />
            <div className="events-page-wrap">
                <div className="page-inner events-inner">
                    <div className="profile-wrap-outer mobile-show">
                        <HeaderProfile />
                    </div>
                    <div className="events-content-scrollable-wrap">
                        <div className="inner-pages-alert todays-alert">
                            <PortalAlert />
                        </div>
                        <div className="profile-wrap-outer">
                            <HeaderProfile />
                        </div>
                        <div className="events-header-wrap hrbenefits-header-wrap">
                            <div className="events-header-wrap-inner">
                                <h1 className="page-title">Job Postings {jobPostingsListByfilter && jobPostingsListByfilter.length > 0 ? `(${jobPostingsListByfilter.length})` : ''}</h1>
                                <div className="filter-bars">
                                    <label>
                                        <select className="menu-wrap org-dropdown" name="slct" id="slct" value={newsFilter} onChange={(event) => {
                                            setNewsFilter(event.target.value);
                                            const selectedCompanyId = parseInt(event.target.value);
                                            setFilter({ ...filter, companyId: selectedCompanyId });
                                        }}>
                                            <option value="0">All</option>
                                            {companiesList.map(company => (
                                                <option key={company.id} value={company.id}>{company.abbreviatedName}</option>
                                        ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="events-content-wrap hrbenefits-content-wrap">
                            <div className="events-content-wrap-inner">                          
                                <div className="accordion-outer-wrap hrbenefits-listing-wrap">
                                    {jobPostingsListByfilter.map(panel => (
                                        <Accordion
                                            key={panel.title}
                                            title={panel.title}
                                            isActive={activePanel === panel.title}
                                            onToggle={() => handlePanelToggle(panel.title)}
                                            hideToggle={panel.description == '' ? true : false}
                                        >                        
                                            <Modules
                                                moduleData={
                                                    panel?.description != null && isValidJSON(panel?.description)
                                                        ? JSON.parse(panel.description)
                                                        : []
                                                }
                                            />
                                        </Accordion>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}