import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModuleWrapper from "../../../components/admin/Editor/ModuleWrapper/ModuleWrapper";


export function AddEditModal({ show, onClose, handleAddEditResource, modelResource }) {
    const { companiesList } = useSelector(state => state.employee);
    const [errorMessage, setErrorMessage] = useState(null);
    const [orgErrorMessage, setOrgErrorMessage] = useState(null);
    const [formDataJobPosting, setFormDataJobPosting] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [allOrganization, setAllOrganization] = useState(false);
    const [moduleData, setModuleData] = useState({ modules: [] });
    //gGOBResourcesCompanies
    useEffect(() => {
        if (modelResource?.ggobResourcesCompanies.length > 0) {
            const allItemsSelected = companiesList.every(company => {
                return modelResource.ggobResourcesCompanies.some(item => item.companyId === company.id)
            });
            setAllOrganization(allItemsSelected);
        }       

        setFormDataJobPosting(modelResource);
        setSelectedOrganization(modelResource?.ggobResourcesCompanies || []);       
        
    }, [modelResource, companiesList]);  
    

    useEffect(() => {
        if (formDataJobPosting !== null)
            setFormDataJobPosting({ ...formDataJobPosting, ggobResourcesCompanies: selectedOrganization });
    }, [selectedOrganization]);

    useEffect(() => {
        if (modelResource && modelResource.description && moduleData.modules.length === 0) {
            try {
                const parsedData = JSON.parse(modelResource.description);            
                setModuleData(parsedData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [show]);

    const handleSave = () => {
        if (!formDataJobPosting.title) {
            setErrorMessage('Accordion Headline is required.');
        }

        if (!formDataJobPosting.ggobResourcesCompanies.length >0) {
            setOrgErrorMessage('At least one organization is required.');
        }

        if (errorMessage !== null || orgErrorMessage != null || formDataJobPosting.ggobResourcesCompanies.length==0) {
            return;
        }
    
        const obj = { ...formDataJobPosting, description: moduleData === null ? null : JSON.stringify(moduleData) };
        
        if (obj.title) {
            handleAddEditResource(obj);
            setModuleData({ ...moduleData, modules: [] });
            setErrorMessage(null);
            setOrgErrorMessage(null);
            setAllOrganization(false);           
            onClose();             
        }
    };

    const handleOnClose = () => {        
        setModuleData({ ...moduleData, modules: [] });
        setErrorMessage(null);
        setOrgErrorMessage(null);
        setAllOrganization(false);       
        onClose();
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            handleOnClose();
        }
    };
    

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Add/Edit Accordion</h2>                  
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="headline">Accordion Headline*</label>
                            <input
                                type="text"
                                id="headline"
                                label="Accordion Headline"
                                name="headline"
                                value={formDataJobPosting?.title || ''}
                                onChange={(event) => {
                                    setFormDataJobPosting({ ...formDataJobPosting, title: event.target.value });
                                    setErrorMessage(null);
                                }}
                            />

                            {errorMessage && <p className="error-messagess">{errorMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="organization">Organization*</label>
                            <div className="admin-column-wrap inline-tags">
                                <label className="admin-checkbox-label inline">
                                    <input
                                        className="admin-inline-checkbox"
                                        type="checkbox"
                                        checked={allOrganization === true ? true : false}
                                        onChange={(e) => {                 
                                            setAllOrganization(e.target.checked);
                                            if (e.target.checked) {
                                                const updatedOrganization = companiesList.map(item => ({ companyId: item.id, company: item }));                                          
                                                setSelectedOrganization(updatedOrganization);                                                
                                            }    
                                            setOrgErrorMessage(null);
                                        }}
                                    />
                                    All
                                </label>                                
                                {companiesList.map(item => (
                                    <label className="admin-checkbox-label inline" key={item.id}>
                                        <input className="admin-inline-checkbox"
                                            type="checkbox"
                                            checked={selectedOrganization.some(x => x.companyId === item.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;                                   
                                                const updatedOrganization = checked
                                                    ? [...selectedOrganization, { companyId: item.id, company: item }]
                                                    : selectedOrganization.filter(selectedItem => selectedItem.companyId !== item.id);
                                                setSelectedOrganization(updatedOrganization);
                                                if (checked === false) {
                                                    setAllOrganization(false);
                                                }
                                                setOrgErrorMessage(null);
                                            }}
                                        />
                                        {item.abbreviatedName}
                                    </label>
                                ))}                               
                            </div>
                            {orgErrorMessage && <p className="error-messagess">{orgErrorMessage}</p>}
                        </div>
                    </div>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell admin-video-wrap">                            
                            <ModuleWrapper
                                setValue={moduleData}
                                onChange={(getValue) => setModuleData(getValue)}
                                isEdit={formDataJobPosting?.baseId != null && formDataJobPosting.baseId > 1}
                            />
                        </div>
                        
                    </div>

                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

AddEditModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddEditResource: PropTypes.func.isRequired,
    modelResource: PropTypes.object
};