import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const delayedHideLoading = createAsyncThunk(
    'loading/delayedHideLoading',
    async (_, { dispatch }) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                dispatch(hideLoading());
                resolve();
            }, 250);
        });
    }
);

const loadingSlice = createSlice({
    name: 'loading',
    initialState: false, // Not loading initially
    reducers: {
        showLoading: () => true,
        hideLoading: () => false,
    },
});

export const { showLoading, hideLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
