import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './detail.css';
import './installed-projects.css';
import Sidebar from '../../components/SideBar/Sidebar.js';
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { getProjects } from '../../store/projects/actions';
import { hideLoading, showLoading } from '../../store/loading-reducer';
import YouTube from 'react-youtube';
import VimeoPlayer from '@vimeo/player';
import { shopMonitorSliderTime } from '../../helpers/enums';
import Slider from "react-slick";
import { processUrl } from '../../helpers/formatterHelper';

export default function ShopInstalledProjects({ handleVideoTiming }) {
    const dispatch = useDispatch();
    const { projectsList } = useSelector(state => state.projects);
    const { projectId } = useParams();
    const iframeRef = useRef(null);
    const isLoading = useSelector((state) => state.loading);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const innerSliderRef = useRef(null);
    const [mediaDurations, setMediaDurations] = useState({});
    const [totalDuration, setTotalDuration] = useState(0);
    const [installedProject, setInstalledProject] = useState(null);
    const [sliderKey, setSliderKey] = useState(0);
    const [childTime, setChildTime] = useState(5000);



    useEffect(() => {
        document.title = 'Installed Projects | CWP Team Connect'
    });

    useEffect(() => {
        dispatch(showLoading());
        handleVideoTiming(shopMonitorSliderTime.LeadingTime);
        if (projectId > 0) {
            dispatch(getProjects()).unwrap().then(() => {
                dispatch(hideLoading());
                setIsDataLoaded(true);
            });
        }
    }, [projectId]);


    useEffect(() => {
        if (projectId > 0) {
            const project = projectsList.find(item => item.id === parseInt(projectId));
            setInstalledProject(project);
            setSliderKey(prevKey => prevKey + 1);
        }
    }, [projectsList, projectId]);

    useEffect(() => {
        if (installedProject?.projectMedias?.length > 0) {
            if (installedProject?.projectMedias[0]) {
                if (installedProject?.projectMedias[0]?.mediaItem?.type === 0) {
                    setChildTime(5000);
                } else if (installedProject?.projectMedias[0]?.mediaItem?.videoType === 0) {
                    setChildTime(shopMonitorSliderTime.LeadingTime);
                }
                else if (installedProject?.projectMedias[0]?.mediaItem?.videoType === 1) {
                    setChildTime(shopMonitorSliderTime.LeadingTime);
                }
            }
        }
    }, [installedProject?.projectMedias]);

    useEffect(() => {
        if (installedProject && installedProject?.projectMedias !== undefined && installedProject?.projectMedias !== null && installedProject?.projectMedias.length > 0) {

            const durations = {};
            installedProject.projectMedias.forEach((media, index) => {
                if (media?.mediaItem?.type === 0) {
                    durations[index] = 5;
                }
            });
            setMediaDurations(durations);

        } else {
            handleVideoTiming(shopMonitorSliderTime.default);
        }
    }, [installedProject]);


    const handleYouTubeReady = (event, videoId) => {
        const duration = event.target.getDuration();
        setMediaDurations(prevDurations => ({
            ...prevDurations,
            [videoId]: duration
        }));

        if (videoId === 0) {
            const timing = Math.ceil(duration) * 1000;
            setChildTime(timing);
        }
    };

    useEffect(() => {
        if (isLoading && isDataLoaded) {
            dispatch(hideLoading());
        }
    }, [isLoading, isDataLoaded]);

    const handleIframeLoad = useCallback((iframe, mediaId) => {
        const vimeoPlayer = new VimeoPlayer(iframe);
        vimeoPlayer.getDuration().then(duration => {
            setMediaDurations(prevDurations => ({
                ...prevDurations,
                [mediaId]: duration
            }));

            if (mediaId === 0) {
                const timing = Math.ceil(duration) * 1000;
                setChildTime(timing);
            }
        });
    }, []);



    var settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplaySpeed: childTime,
        autoplay: true
    };

    useEffect(() => {
        const total = Object.values(mediaDurations).reduce((sum, duration) => sum + duration, 0);
        setTotalDuration(total);

    }, [mediaDurations]);

    useEffect(() => {
        const timing = Math.ceil(totalDuration) * 1000;
        if (timing < 10000) {
            handleVideoTiming(shopMonitorSliderTime.default);
        } else {
            handleVideoTiming(timing);
        }

    }, [totalDuration]);

    const setNextSlideTime = (current, next) => {
        if (next > 0 && next < installedProject?.projectMedias?.length) {
            if (installedProject?.projectMedias[next]) {
                if (installedProject?.projectMedias[next]?.mediaItem?.type === 0) {
                    setChildTime(5000);
                } else if (installedProject?.projectMedias[next]?.mediaItem?.videoType === 0) {
                    const timing = mediaDurations[next] * 1000;
                    setChildTime(timing);
                }
                else if (installedProject?.projectMedias[next]?.mediaItem?.videoType === 1) {
                    const timing = mediaDurations[next] * 1000;
                    setChildTime(timing);
                }
            }
        }
    };

    return (
        <div className="monitors-inner">
            <div className="monitors-item">
                <div className="monitors-header-wrap">
                    <p className="monitors-header-title">Installed Projects</p>
                </div>
                <div className="monitors-item-event-wrap">
                    <div className="monitors-item-event-item" >
                        <div className="monitors-item-event-full">
                            <div className="installed-projects-box-wrap">
                                <Slider key={sliderKey} beforeChange={setNextSlideTime} {...settings} className="installed-projects-slider" ref={innerSliderRef}>
                                    {installedProject && installedProject?.projectMedias !== undefined && installedProject?.projectMedias !== null && installedProject?.projectMedias.length > 0 && installedProject?.projectMedias.map((media, index) => (
                                        <React.Fragment key={index}>
                                            {media === null ? (
                                                <div className="installed-projects-box-text-inner">
                                                    <p className="installed-projects-title">{installedProject.title}</p>
                                                </div>
                                            ) : media?.mediaItem?.type === 0 ? (
                                                <div className="installed-projects-box-img-inner">
                                                    <img src={media?.mediaItem?.previewsrc} alt="project Img" />
                                                </div>
                                            ) : (
                                                <div className="installed-projects-box-img-inner">
                                                

                                                    {media?.mediaItem?.videoType === 1 && (
                                                        (() => {
                                                                    const mediaData = processUrl(media?.mediaItem?.videoSrc);
                                                                 
                                                            let videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true&muted=1&loop=1`;
                                                       
                                                            return (
                                                                <iframe
                                                                    ref={iframeRef}
                                                                    onLoad={(e) => handleIframeLoad(e.target, index)}
                                                                    width="560"
                                                                    height="315"
                                                                    src={videoUrl}
                                                                    title=""
                                                                    allow="autoplay;fullscreen"
                                                                    frameBorder="0"
                                                                    allowFullScreen
                                                                />
                                                            );
                                                        })()
                                                    )}


                                                    {media?.mediaItem?.videoType === 0 && (
                                                        <YouTube
                                                            videoId={media?.mediaItem?.videoId}
                                                            opts={{
                                                                height: '315',
                                                                width: '560',
                                                                playerVars: {
                                                                    autoplay: 1,
                                                                    mute: 1
                                                                },
                                                            }}
                                                            onReady={(event) => handleYouTubeReady(event, index)}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Slider>
                            </div>
                            <div className="installed-projects-title-wrap">
                                <p className="installed-projects-small-title">{installedProject?.title || "No title available"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Sidebar pageType={"install-project"} />
        </div>
    );
}

ShopInstalledProjects.propTypes = {
    handleVideoTiming: PropTypes.func
};
