import { createAsyncThunk } from '@reduxjs/toolkit';
import * as bonuslevelsApi from '../../api/bonuslevels';

export const GetQuarterlyBonus = createAsyncThunk('getQuarterlyBonus', async (data) => {
    const res = await bonuslevelsApi.GetQuarterlyBonus({ data: data });
    return res.data;
});

export const getBonusLevels = createAsyncThunk('getBonusLevels', async (data) => {
    const res = await bonuslevelsApi.getBonusLevels(data);
    return res.data;
});


export const getCurrentMonthName = createAsyncThunk('getCurrentMonthName', async (data) => {
    const res = await bonuslevelsApi.getCurrentMonthName(data);
    return res.data;
});


export const GetYTDBonus = createAsyncThunk('getYTDBonus', async (data) => {
    const res = await bonuslevelsApi.GetYTDBonus({ data: data });
    return res.data;
});