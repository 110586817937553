import React, { useRef } from 'react';
import PropTypes from 'prop-types';
export function DepartmentDeleteConfrimationModal({ show, onClose, handleDeleteDepartment, modelDepartment }) {
    const modalRef = useRef(null);
    const handleConfirmDelete = () => {
        if (modelDepartment.name) {
            handleDeleteDepartment(modelDepartment);
            onClose(); // Close the modal  //departmentEmployees
        }
    };
    const handleOverlayClick = (e) => {
        if (modalRef.current.contains(e.target)) {
            return;
        }
        onClose();
    };

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal" ref={modalRef}>
                {modelDepartment && modelDepartment.departmentEmployees > 0 ?
                    <>
                        <h2 className="admin-modal-heading">
                            There are employees within this department. Are you sure you want to delete it? Doing so will require reassigning departments for these employees.
                        </h2></> :
                    <>
                        <h2 className="admin-modal-heading">
                            Are you sure you want to delete this department?
                        </h2>
                        <div className="admin-modal-row full">
                            <div className="admin-modal-cell">{modelDepartment && modelDepartment.name}</div>
                        </div>
                    </>
                }

                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button
                        className="admin-form-button power red"
                        onClick={handleConfirmDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
}

DepartmentDeleteConfrimationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleDeleteDepartment: PropTypes.func.isRequired,
    modelDepartment: PropTypes.object
};