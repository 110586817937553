import React from 'react';

import './bonus-levels-new.css';
export default function BonusLevelsNew() {
    return (
        <div className="bonus-level-outer-wrap">
            <div className="bonus-level-inner-wrap">
                <div className="">
                      
                </div>
            </div>
        </div>
    );
}
