import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllFinancials } from '../../../store/admin/financials/actions';
import FinancialItem from '../../../components/admin/Financials/financialItem';
import FileItem from '../../../components/admin/Financials/fileItem';
import { templateDocuments } from '../../../helpers/enums';
import { useAdminMenuContext } from "../../../context/AdminMenuContext";

function Financials() {
    const dispatch = useDispatch();
    const financialList = useSelector(state => state.adminFinancials.adminFinancialsList);
    const [editLink, setEditLink] = useAdminMenuContext('');

    useEffect(() => {
        document.title = 'Financials | CWP Employee Portal';
        dispatch(getAllFinancials());
        setEditLink('/financial-scoreboard');
        console.log(editLink);
    }, []);

    const firstFinancialItem = financialList.length > 0 ? financialList[0] : null;


    const downloadFile = (path, name) => {
        const link = document.createElement('a');
        link.href = path; 
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="admin-page-wrap financial-wrapper financial-edit-wrapper">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link className="admin-top-bar-back" to={'/siteadmin'}>Back</Link>
                    </div>
                </div>

                <div className="pageContainer">
                    <div className="admin-page-content">
                        <div className="admin-page-inner">
                            <div className="admin-page-title-wrap">
                                <h1 className="admin-page-heading">Financials</h1>
                                {/*TODO we need the download button to be wired up*/}
                                <button className="admin-button" onClick={() => downloadFile(templateDocuments.bonusLevels, templateDocuments.bonusLevelsFileName)}>
                                    <span>Bonus Level Template</span>
                                    <span className="material-symbols-rounded">download</span>
                                </button>
                                <button className="admin-button" onClick={() => downloadFile(templateDocuments.scoreboard, templateDocuments.scoreboardFileName)}>
                                    <span>Scoreboard Template</span>
                                    <span className="material-symbols-rounded">download</span>
                                </button>
                            </div>
                            <div className="admin-page-item">
                            <h5 className="admin-page-heading">All Organizations</h5>
                                <label className="small-label">Bonus Levels</label>
                                {/*TODO The upload document button would appear if there is nothing uploaded*/}
                                {/*TODO the loader will show during the file upload process. otherwise it can be set to opacity: 0; or display: none; */}
                                {/*<span className="admin-button upload-with-spinner">Upload Document<span className="loader"></span></span>*/}
                                {firstFinancialItem && (
                                <FileItem
                                        type="BonusLevel"
                                        file={(firstFinancialItem?.financial?.status == 0 && firstFinancialItem?.totalFinancialRecords >= 0 && firstFinancialItem?.financial?.baseId != 0)  ? firstFinancialItem?.financial?.bonusLevelFile : undefined}
                                        companyId={firstFinancialItem?.id}
                                        bonusLevelId={firstFinancialItem?.financial?.bonusLevelId}
                                        financialId={firstFinancialItem?.financial?.id}
                                        baseId={firstFinancialItem?.financial?.baseId}
                                        totalFinancialRecords={firstFinancialItem?.totalFinancialRecords}
                                />
                                )}
                            </div>

                            {financialList?.filter(item => item.abbreviatedName != null && item.abbreviatedName != undefined).map((item) =>

                                <FinancialItem key={item.id} companyData={item} />

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Financials;