import React from 'react';
import PropTypes from 'prop-types';
export function EmployeeConfrimationModal({ show, onClose, handleDeleteEmployee, modelEmployee }) {
    const handleConfirmDelete = () => {
        if (modelEmployee.name) {
            handleDeleteEmployee(modelEmployee);
            onClose(); // Close the modal  
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'admin-modal-overlay') {
            onClose();
        }
    };

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay" onClick={handleOverlayClick}>
            <div className="admin-modal">
                <h2 className="admin-modal-heading">
                    Are you sure you want to delete this employee?
                </h2>
                <div className="admin-modal-row full">
                    <div className="admin-modal-cell">{modelEmployee.name}</div>
                </div>
                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button
                        className="admin-form-button power red"
                        onClick={handleConfirmDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
}

EmployeeConfrimationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleDeleteEmployee: PropTypes.func.isRequired,
    modelEmployee: PropTypes.object
};