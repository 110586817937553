import { createAsyncThunk } from '@reduxjs/toolkit';
import * as adminInterestApi from '../../api/interest';

export const getInterestsList = createAsyncThunk('getInterestsList', async (data) => {
    const res = await adminInterestApi.getInterestsList(data);
    return res.data;
});

export const saveInterests = createAsyncThunk('saveInterests', async (data) => {
    const res = await adminInterestApi.saveInterests({ data: data });
    return res.data;
});
export const editInterests = createAsyncThunk('editInterests', async (data) => {
    const res = await adminInterestApi.editInterests({ data: data });
    return res.data;
});

export const deleteInterests = createAsyncThunk('deleteInterests', async (data) => {
    const res = await adminInterestApi.deleteInterests({ data: data });
    return res.data;
});