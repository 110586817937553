import api from '../utils/publicApi';


export const login = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/login',
        method: 'POST',
        data,
        ...options
    });
}

export const refreshToken = (config = {}) => {
  const { data, options } = config;
  return api({
      url: `/api/siteadmin/refresh-token`,
    method: "POST",
    data,
    ...options,
  });
}

export const checkAuth = (config = {}) => {
  const { data, options } = config;
  return api({
      url: '/api/siteadmin/check-auth',
    method: 'GET',
    data,
    ...options
  })
}

export const forgotPassword = (config = {}) => {
    const { data, options } = config;
     return api({
         url: '/api/siteadmin/forgot-password',
        method: 'POST',
        data,
        ...options
    });  
}

export const resetPassword = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/reset-password',
        method: 'POST',
        data,
        ...options
    });
}


export const updatePassword = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/update-password',
        method: 'POST',
        data,
        ...options
    });
}

export const UpdateUserData = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/update-userdata',
        method: 'POST',
        data,
        ...options
    });
}

export const oneTimeLogin = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/one-time-login',
        method: 'POST',
        data,
        ...options
    });
}

export const emailAuth = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/email-auth',
        method: 'POST',
        data,
        ...options
    });
}

export const createUserdata = (config = {}) => {
    const { data, options } = config;
    return api({
        url: '/api/siteadmin/create-user',
        method: 'POST',
        data,
        ...options
    });
}