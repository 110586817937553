import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../../store/employee/actions';
import { DepartmentsAddEdit } from '../../../components/admin/departments/departmentsAddEdit';
import { getDepartmentAndCompanyData } from '../../../store/department/actions';
import { addEditCopmanyDepartmentData, /*deleteDepartment,*/ getEmployeeDetailByDepartmentId, getCompanyDepartmentById, deleteCompanyDepartment } from '../../../api/department';
import { DepartmentDeleteConfrimationModal } from './DepartmentDeleteConfirmation'
//import { DeparmentContainer } from '../../../components/admin/departments/deparmentContainer';

const Departments = () => {
    const dispatch = useDispatch();
    const { companyDepartmentList } = useSelector(state => state.departments);
    
    const defaultDepartmentObj = { id: 0, name: '', departmentCompanies: [], departmentEmployees: 0, companyId: 0 }

    const [departmentModel, setDepartmentModel] = useState(defaultDepartmentObj);
    const [showAddModal, setShowAddModal] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false);

    const LoadCompanyData = () => {
        dispatch(getDepartmentAndCompanyData());
    }

    useEffect(() => {
        document.title = 'Departments | CWP Employee Portal';
        dispatch(getCompanies());
        dispatch(getDepartmentAndCompanyData());
    }, []);

    const handleAddDepartment = () => {
        setDepartmentModel(defaultDepartmentObj);
        setShowAddModal(true);
    }

    const handleEditDepartment = (model) => {
        getCompanyDepartmentById(model.id)
            .then((response) => {
                if (response.data.success) {

                    const departmentCompanies = response.data.data.map(company => ({
                        companyId: company.id,
                        name: company.name,
                        abbreviatedName: company.abbreviatedName,
                        ...company
                    }));


                    setDepartmentModel({ ...departmentModel, id: model.id, name: model.name, departmentCompanies: departmentCompanies });
                    setShowAddModal(true);
                }
            });
    }

    const handleAddEditDepartment = (postingData) => {
        setDepartmentModel(postingData);
        addEditCopmanyDepartmentData({ options: { data: postingData } })
            .then((response) => {
                if (response.data.success) LoadCompanyData();
                else alert(response.data.message);
            });
    }

    const handleDeleteCompanyDepartment = (skillData) => {
        //deleteDepartment(skillData.id)
        //    .then((response) => {
        //        if (response.data.success) LoadCompanyData();
        //    });
        deleteCompanyDepartment(skillData.companyId,skillData.id)
            .then((response) => {
                if (response.data.success) LoadCompanyData();
            });
    }

    const handleDeleteConfirmation = (id, name, companyId) => {
        if (id > 0) {
            getEmployeeDetailByDepartmentId(id)
                .then((response) => {
                    if (response.data.success) {
                        setDepartmentModel({ ...departmentModel, id: id, name: name, departmentEmployees: response.data.data.length, companyId: companyId });
                        setShowDeleteModal(true);
                    }
                });
        }
    }
    console.log("companyDepartmentList:", companyDepartmentList);
    return (

        <div className="admin-page-wrap">

            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>

            <DepartmentDeleteConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteDepartment={handleDeleteCompanyDepartment}
                modelDepartment={departmentModel}
            />

            <div className="admin-page-content">
                <div className="admin-section">
                    <DepartmentsAddEdit
                        show={showAddModal}
                        onClose={() => setShowAddModal(false)}
                        handleAddEditPosting={handleAddEditDepartment}
                        modelPosting={departmentModel}
                    />
                    <div className="admin-section-title-bar job-postings-heading">
                        <div className="admin-section-title-action-wrap">
                            <h2 className="admin-section-headline">Departments</h2>
                            <button className="admin-button" onClick={() => handleAddDepartment()}>
                                <span className="admin-button-text">Add Department</span>
                                <span className="material-symbols-rounded" aria-hidden="true">add</span>
                            </button>
                        </div>
                    </div>       
                    <div className="admin-tab-content">
                        {companyDepartmentList.map(company => (
                            <div key={company.id}>
                                <h3>{company.abbreviatedName}</h3>
                                {company.departments.map(dpt => (
                                    <div className={`admin-drag-box is-drag-disable`} key={dpt.id}>
                                        <span className="admin-drag-box-name">{dpt.name}</span>

                                        <span className="admin-drag-option-wrap">
                                            <button
                                                className="admin-drag-option edit"
                                                onClick={() => handleEditDepartment(dpt)}
                                            >
                                                <span className="material-symbols-rounded">edit</span>
                                            </button>
                                            <button
                                                className="admin-drag-option delete"
                                                onClick={() => handleDeleteConfirmation(dpt.id, dpt.name, company.id)}
                                            >
                                                <span className="material-symbols-rounded">delete</span>
                                            </button>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    {/*<div className="admin-tab-content">*/}
                    {/*    {companyDepartmentList.map(company => (*/}
                    {/*        <div key={company.id}>*/}
                    {/*            <h3>{company.abbreviatedName}</h3>*/}

                    {/*            <DeparmentContainer*/}
                    {/*                deparment={company.departments}*/}
                    {/*                companyId={parseInt(company.id)}*/}
                    {/*                onEdit={handleEditDepartment}*/}
                    {/*                onDelete={handleDeleteConfirmation}*/}
                    {/*            />                               */}
                    {/*        </div>*/}
                    {/*    ))}*/}
                      
                    {/*</div>            */}
                </div>
            </div>
        </div>
    );
};
Departments.propTypes = {
};

export default Departments;