import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DragDropContainer from '../../DragAndDropContainer';
import { updateJobPostingOrder } from '../../../store/hrbenefits/actions';

export const JobPostingContainer = ({ jobs, onEdit, onDelete }) => {
    const dispatch = useDispatch();

    if (!jobs) {
        return <h2>No job postings exist</h2>;
    }

    const [localCategories, setLocalCategories] = useState(jobs);
    const [trackOrderCategories, setTrackOrderCategories] = useState(jobs);

    useEffect(() => {
        if (jobs) {
            setLocalCategories(jobs);
        }
    }, [jobs]);

    const moveCategory = (fromIndex, toIndex, makeApiCall) => {
        const updatedCategories = [...localCategories];
        const [movedItem] = updatedCategories.splice(fromIndex, 1);
        updatedCategories.splice(toIndex, 0, movedItem);

        const newUpdatedCategories = updatedCategories.map((category, index) => ({
            ...category,
            order: index,
        }));

        setLocalCategories(newUpdatedCategories);
        setTrackOrderCategories(newUpdatedCategories);
        if (makeApiCall) {
            setLocalCategories(trackOrderCategories);

            const ids = trackOrderCategories.map((category) => category.id).join(',');
            dispatch(updateJobPostingOrder(ids));
        }
    };

    const renderCategoryItem = (job) => (
        <div className={`admin-drag-box ${job.active == false ? 'inactiveItem' : ''}`} key={job.id}>
            <span className="admin-drag-box-name">{job.title}</span>
            <span className="admin-drag-option-wrap">
                {job.active == false ? <span className="inactiveStatus">Inactive</span> : null}
                <button
                    className="admin-drag-option edit"
                    onClick={() => onEdit(job)}
                >
                    <span className="material-symbols-rounded">edit</span>
                </button>
                <button
                    className="admin-drag-option delete"
                    onClick={() => onDelete(job)}
                >
                    <span className="material-symbols-rounded">delete</span>
                </button>
            </span>
        </div>
    );

    return (
        <DragDropContainer
            items={localCategories}
            moveItem={moveCategory}
            renderItem={renderCategoryItem}
            isHorizontal={false}
        />
    );
};
JobPostingContainer.propTypes = {
    jobs: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
