import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  isSkillLoaded: false,
  skillsList: [],
    isSaving: false,
    companySkillList: [],
    skillListData: [],
    skillsListV2: [],
    companySkillListV2: []
}

const skillSlice = createSlice({
  name: 'skill',
  initialState,
  extraReducers: (builder) => {
    // Get Skills
    builder.addCase(actions.getSkillsList.pending, (state) => {
      state.isSkillLoaded = false;
    })

    builder.addCase(actions.getSkillsList.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.skillsList = action.payload.data;
      } else {
        state.skillsList = [];
      }
      state.isSkillLoaded = true;
    })
    builder.addCase(actions.getSkillsList.rejected, (state) => {
      state.isSkillLoaded = false;
    });

    // Save Skill
    builder.addCase(actions.saveSkill.pending, (state) => {
      state.isSaving = true;
    })
    builder.addCase(actions.saveSkill.fulfilled, (state, action) => {
      if (action.payload.success) {
        const returnObj = action.payload.data;
        state.skillsList.unshift(returnObj);
      }
      state.isSaving = false;
    })

    builder.addCase(actions.saveSkill.rejected, (state) => {
      state.isSaving = false;
    });

    // Edit Skill
    builder.addCase(actions.editSkill.pending, (state) => {
      state.isSaving = true;
    })
    builder.addCase(actions.editSkill.fulfilled, (state, action) => {
      if (action.payload.success) {
        const returnObj = action.payload.data;
        const skillsObj = state.skillsList.find(x => x.id === returnObj.id);
        if (skillsObj !== undefined && skillsObj !== null) {
          skillsObj.name = returnObj.name;
        }
      }
      state.isSaving = false;
    })

    builder.addCase(actions.editSkill.rejected, (state) => {
      state.isSaving = false;
    });

    // Delete Skill
    builder.addCase(actions.deleteSkill.pending, (state) => {
      state.saving = true;
    })
    builder.addCase(actions.deleteSkill.fulfilled, (state, action) => {
      if (action.payload.success) {
        const id = action.payload.data;
        const enityIndex = state.skillsList.findIndex(x => x.id === id);
        if (enityIndex > -1) {
          state.skillsList.splice(enityIndex, 1);
        }
      }
      state.isSaving = false;
    })
    builder.addCase(actions.deleteSkill.rejected, (state) => {
      state.isSaving = false;
    });

      // Get Skills
      builder.addCase(actions.getSkillAndCompanyData.pending, (state) => {
          state.isSkillLoaded = false;
      })
      builder.addCase(actions.getSkillAndCompanyData.fulfilled, (state, action) => {
          if (action.payload.success) {
              state.companySkillList = action.payload.data;
          } else {
              state.companySkillList = [];
          }
          state.isSkillLoaded = true;
      })
      builder.addCase(actions.getSkillAndCompanyData.rejected, (state) => {
          state.isSkillLoaded = false;
      });

      // Get Skills V2
      builder.addCase(actions.getSkillAndCompanyDataV2.pending, (state) => {
          state.isSkillLoaded = false;
      })
      builder.addCase(actions.getSkillAndCompanyDataV2.fulfilled, (state, action) => {
          if (action.payload.success) {
              state.companySkillListV2 = action.payload.data;
          } else {
              state.companySkillListV2 = [];
          }
          state.isSkillLoaded = true;
      })
      builder.addCase(actions.getSkillAndCompanyDataV2.rejected, (state) => {
          state.isSkillLoaded = false;
      });


      //Get updated skill department
      builder.addCase(actions.updateSkillOrder.pending, (state) => {
          state.isSkillLoaded = false;
      })
      builder.addCase(actions.updateSkillOrder.fulfilled, (state, action) => {
          if (action.payload.success) {
              state.companySkillListV2 = action.payload.data;
          } else {
              state.companySkillListV2 = [];
          }
          state.isSkillLoaded = true;
      })
      builder.addCase(actions.updateSkillOrder.rejected, (state) => {
          state.isSkillLoaded = false;
      });




      // Get Skills
      builder.addCase(actions.getSkillsOnLoad.pending, (state) => {
          state.isSkillLoaded = false;
      })
      builder.addCase(actions.getSkillsOnLoad.fulfilled, (state, action) => {
          if (action.payload.success) {
              state.skillListData = action.payload.data;
          } else {
              state.skillListData = [];
          }
          state.isSkillLoaded = true;
      })
      builder.addCase(actions.getSkillsOnLoad.rejected, (state) => {
          state.isSkillLoaded = false;
      });

      // Get SkillsV2

      builder.addCase(actions.getSkillsListV2.pending, (state) => {
          state.isSkillLoaded = false;
      })

      builder.addCase(actions.getSkillsListV2.fulfilled, (state, action) => {
          if (action.payload.success) {
              state.skillsListV2 = action.payload.data;
          } else {
              state.skillsListV2 = [];
          }
          state.isSkillLoaded = true;
      });
      builder.addCase(actions.getSkillsListV2.rejected, (state) => {
          state.isSkillLoaded = false;
      });




 

  }
});

export default skillSlice.reducer;
