import React from 'react';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';

const DragLayer = () => {
    const { item, itemType, isDragging, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        isDragging: monitor.isDragging(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
    }));

    const renderItem = () => {
        if (itemType === 'DRAGGABLE_ITEM' && item.children) {
            return (
                <div style={{ opacity: 1 }}>
                    {/* Render ghost content */}
                    {item.children}
                </div>
            );
        }
        return null;
    };

    if (!isDragging || !currentOffset) {
        return null;
    }

    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
    return (
        <div className="draggable" style={{ position: 'fixed', pointerEvents: 'none', zIndex: 100, left: 0, top: 0, transform, width:'auto' }}>
            {renderItem()}
        </div>
    );
};

const DraggableItem = ({ index, moveItem, children, isHorizontal, canDropItem, colorGroupId}) => {
    const [, ref, preview] = useDrag({
        type: 'DRAGGABLE_ITEM',
        item: { originalIndex: index, currentIndex: index, children, colorGroupId},
    });
    //disabling default browser ghost
    preview(new Image());

    const [, drop] = useDrop({
        accept: 'DRAGGABLE_ITEM',
        canDrop: (draggedItem, monitor) => {
            // Use the provided canDropItem function
            if (typeof canDropItem === 'function') {
                return canDropItem(draggedItem, monitor);
            }
            // Default to true if no function is provided
            return true;
        },
        hover: (draggedItem) => {
            if (draggedItem.currentIndex !== index) {
                moveItem(draggedItem.currentIndex, index, false);
                draggedItem.currentIndex = index;
            }
        },
        drop: (draggedItem) => {
            if (draggedItem.originalIndex !== index) {
                moveItem(draggedItem.originalIndex, index, true);
            }
        },
    });


    const style = isHorizontal  ? { display: 'inline-block' } : {};

    return <div style={style} ref={(node) => ref(drop(node))}>{children}</div>;
};

const DragDropList = ({ items, moveItem, renderItem, isHorizontal, canDropItem, colorGroupId, nonDropItem }) => {
    return (
        <div>
            {items.map((item, index) => (
                <DraggableItem key={index} index={index} moveItem={moveItem} item={item} isHorizontal={isHorizontal } canDropItem={canDropItem} colorGroupId={colorGroupId} >
                    {renderItem(item, index)}
                </DraggableItem>
            ))}
            {nonDropItem && nonDropItem()}  
        </div>
    );
};

const DragDropContainer = ({ items, moveItem, renderItem, isHorizontal, canDropItem, colorGroupId = 0, nonDropItem }) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <DragLayer />
            <DragDropList items={items} moveItem={moveItem} renderItem={renderItem} isHorizontal={isHorizontal } canDropItem ={canDropItem} colorGroupId={colorGroupId} nonDropItem={nonDropItem} />
        </DndProvider>
    );
};

export default DragDropContainer;

DraggableItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    moveItem: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isHorizontal: PropTypes.bool.isRequired,
    canDropItem: PropTypes.func,
    colorGroupId:PropTypes.number



};

DragDropList.propTypes = {
    items: PropTypes.array.isRequired,
    moveItem: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    isHorizontal: PropTypes.bool.isRequired,
    canDropItem: PropTypes.func,
    colorGroupId: PropTypes.number,
    nonDropItem: PropTypes.func
};

DragDropContainer.propTypes = {
    items: PropTypes.array.isRequired,
    moveItem: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    isHorizontal: PropTypes.bool.isRequired,
    canDropItem: PropTypes.func,
    colorGroupId: PropTypes.number,
    nonDropItem: PropTypes.func
};
