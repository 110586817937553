import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import LoadingSpinner from './components/LoadingSpinner';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/site.css';

import AppRouter from './router/BaseRouter';

export const UserContext = React.createContext();

function App() {
	return (
		<div className="full-wrap">
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<LoadingSpinner />
					<Router>
						<AppRouter />
					</Router>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
