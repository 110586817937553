import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'banner',
    initialState: {
        isVisible: false, 
        message: '',
        isButtonShown: false,
    },
    reducers: {
        showBanner: (state) => {
            state.isVisible = true;
        },
        hideBanner: (state) => {
            state.isVisible = false;
        },
        showBannerWithButton:(state) => {
            state.isVisible=true;
            state.isButtonShown = true;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        }
    }
    
});

export const { showBanner, hideBanner, setMessage, showBannerWithButton } = slice.actions;
export default slice.reducer;

export const selectBannerVisible = (state) => state.banner.isVisible;
export const selectBannerMessage = (state) => state.banner.message;
export const selectIsBtnShown = (state) => state.banner.isButtonShown;

