import api from '../utils/api';

export const getUsers = () => {
  return api({
    url: '/api/adminusers/get-all',
    method: 'GET',
  })
}

export const getUserDetails = (userId) => {
  return api({
    url: '/api/adminusers/get/' + userId,
    method: 'GET'
  })
}

export const saveUserDetails = (user) => {
  let url = '/api/adminusers/';
  if (user.id === null) url += 'add';
  else url += 'edit';
  return api.post(url, user);
}

export const getLoginUserDetails = () => {
    return api({
        url: '/api/adminusers/get-login-user',
        method: 'GET'
    })
}

//export const excelExport = (config = {}) => {
//    const { options } = config;
//    return api({
//        url: `api/adminusers/export-all-users-list`,
//        method: "GET",
//        ...options,
//    });
//}

export const excelExport = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/adminusers/export-all-users-list`,
        method: "POST",
        data: data,
        ...options,
    });
}