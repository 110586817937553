import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import AdminInterestsPage from '../../../components/admin/Interests/interestsList';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import PositionsSkills from './PositionsSkills';

function SkillInterestDetail() {
    const [activeTab, setActiveTab] = useState('skills');
    const { openTab } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Skills & Interests | CWP Employee Portal';
        dispatch(showLoading());
        if (openTab !== undefined) setActiveTab(openTab);
        dispatch(hideLoading());
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, '/siteadmin/skillinterest/' + tab);
        dispatch(hideLoading());
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={'/siteadmin'}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">Positions & Qualifications & Interests</h1>
                <div className="admin-tab-wrap">
                    <button className={`admin-tab ${activeTab === 'skills' ? 'active' : ''}`} onClick={() => handleTabChange('skills')} id="tab1" role="tab" aria-controls="skills-tab" aria-selected="true">Positions & Qualifications</button>
                    <button className={`admin-tab ${activeTab === 'interests' ? 'active' : ''}`} id="tab2" onClick={() => handleTabChange('interests')} role="tab" aria-controls="interests-tab" aria-selected="true">Interests</button>
                </div>
                {activeTab === 'skills' && <PositionsSkills />}
                {activeTab === 'interests' && <AdminInterestsPage />}
            </div>
        </div>
    );
}

export default SkillInterestDetail;