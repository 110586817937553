import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { processUrl, isVimeoVideoExists } from '../../helpers/formatterHelper';
import nextArrow from '../../assets/arrow_next.svg';
import prevArrow from '../../assets/arrow_prev.svg';
import { getVimeoToken } from '../../api/docCenterApi';
import PlayIcon from '../../assets/play_arrow.png';
export default function GalleryModule({ module }) {
    const [moduleData, setModuleData] = useState(module);
    const [openModal, setOpenModal] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [type, setType] = useState(0);
    const [videoUrl, setVideoUrl] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slide, setSlide] = useState(0);
    const [processedItems, setProcessedItems] = useState([]);
    const [vimeoToken, setVimeoToken] = useState("");

    useEffect(() => {
        const processItems = async () => {
            const itemsWithVideoCheck = await Promise.all(
                moduleData.content.filter(item => !item.videoThumbnailUrl?.includes("/static/media/")).map(async (item) => {
                    if (item.type === 1 && item.videoSrc) {
                        const videoExists = await isVimeoVideoExists(item?.videoId, vimeoToken);
                        return { ...item, videoExists };
                    }
                    return item;
                })
            );
            setProcessedItems(itemsWithVideoCheck.filter(x => (x.videoExists === true && x.videoType === 1) || x.videoType === 0 || x.type === 0));
        };

        processItems();
    }, [moduleData]);

    const openVideo = (e, item, index) => {
        setOpenModal(!openModal)
        setPreviewImage(item?.previewsrc);
        setType(item?.type);
        if (item?.type == 1) {


            const mediaData = processUrl(item?.videoSrc);

            let videoUrl = '';
            if (item.videoType === 0) {
                videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
            }
            else {
                if (mediaData.type === "vimeo") {
                    videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
                }
                if (mediaData.type === "htmlVideo") {
                    videoUrl = item?.videoSrc;
                }
            }

            setVideoUrl(videoUrl);
        }
        setCurrentIndex(index);
    }

    const nextSlide = () => {
        const newIndex = currentIndex === moduleData.content.length - 1 ? 0 : currentIndex + 1;
        const media = moduleData.content[newIndex];

        if (media) {
            setPreviewImage(media.previewsrc);
            setType(media.type);
            if (media.type == 1) {

                const mediaData = processUrl(media.videoSrc);


                let videoUrl = '';
                if (media.videoType === 0) {
                    videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
                }
                else {
                    if (mediaData.type === "vimeo") {
                        videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
                    }
                    if (mediaData.type === "htmlVideo") {
                        videoUrl = media.item?.videoSrc;
                    }
                }

                setVideoUrl(videoUrl);
            }
        }
        setCurrentIndex(newIndex);
    };

    const prevSlide = () => {
        const newIndex = currentIndex === 0 ? moduleData.content.length - 1 : currentIndex - 1;
        const media = moduleData.content[newIndex];
        if (media) {
            setPreviewImage(media.previewsrc);
            setType(media.type);
            if (media.type == 1) {
                const item = processUrl(media.videoSrc);


                let videoUrl = '';
                if (media.videoType === 0) {
                    videoUrl = `https://www.youtube.com/embed/${item.id}?rel=0&autoplay=1`
                }
                else {
                    if (item.type === "vimeo") {
                        videoUrl = `https://player.vimeo.com/video/${item.id}?title=0&byline=0&portrait=0&${item.urlval ? `${item.urlval}&` : ''}autoplay=true`;
                    }
                    if (item.type === "htmlVideo") {
                        videoUrl = media.item?.videoSrc;
                    }
                }
 
                setVideoUrl(videoUrl);
            }
        }
        setCurrentIndex(newIndex);
    }

    useEffect(() => {
        getVimeoToken().then((response) => {
            setVimeoToken(response.data);
        });

        setModuleData(module);
        setSlide(moduleData.content?.filter(item => !item.videoThumbnailUrl?.includes("/static/media/")).length);
    }, [module]);

   

    

    

    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            <div className="benefits-video-img">
                <div>
                    <Slider {...settings}>
                        {processedItems.map((item, index) => {
                            if (item.mediaItem?.videoType === 1) {
                                if (item.videoExists) {
                                    return (
                                        <div className="video-box" onClick={(e) => openVideo(e, item, index)} key={item.id}>
                                            <img key={item.id} src={item.previewsrc} />
                                            <button className="media-thumbnail-action">                                              
                                                <div className="play-icon" >
                                                    <img src={PlayIcon} alt="play" loading="lazy" />
                                                </div>
                                            </button>
                                        </div>
                                    );
                                }                                
                            } else {
                                return (
                                    <div className="video-box" onClick={(e) => openVideo(e, item, index)} key={item.id}>
                                        <img key={item.id} src={item.previewsrc} />
                                        <button className="media-thumbnail-action">
                                            <div className="play-icon" >
                                                <img src={PlayIcon} alt="play" loading="lazy" />
                                            </div>
                                        </button>
                                    </div>
                                );

                            }
                            
                        })}
                    </Slider>
                </div>
            </div>
            {openModal && (
                <div className="video-modal">
                    <div className="close-modal" onClick={openVideo}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                    </div>
                    <div className="video-event-wrap news-video gallery-module-video">
                        {type == 0 && (<img src={previewImage} alt="video" className="video-box" />)}
                        {type == 1 && (
                            <iframe
                                width="560"
                                height="315"
                                src={videoUrl}
                                title=""
                                allow="autoplay;"
                                frameBorder="0"
                                allowFullScreen
                            >
                            </iframe>
                        )}

                    </div>
                    {slide > 1 && (
                        <div className="video-arrows-wrap">
                            <div className="arrow-modal prev" onClick={prevSlide}>
                                <span className="arrow-wrap">
                                    <img src={prevArrow} alt="prev item" />
                                </span>
                            </div>
                            <div className="arrow-modal next" onClick={nextSlide}>
                                <span className="arrow-wrap">
                                    <img src={nextArrow} alt="next item" />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>

    );
}


GalleryModule.propTypes = {
    module: PropTypes.object
}