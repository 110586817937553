import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function ShowLoggedWarning({ show }) {
    const [count, setCount] = useState(15);
    if (!show) {
        return null;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => prevCount - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);


    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal">
                <h2 className="admin-modal-heading">
                    Your session is about to expire due to inactivity. To stay logged in, simply interact with the page.
                </h2>
                <p className="counter">{count}</p>
            </div>
        </div>
    );
}


ShowLoggedWarning.propTypes = {
    show: PropTypes.bool.isRequired
};