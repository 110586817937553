import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JobPostingContainer } from './jobPostingContainer';
import { PostingAddEditModal } from './postingAddEditModal';
import { addEditJobPosting, deleteJobPosting, getjobpostingbyid } from '../../../store/hrbenefits/actions';
import { JobPostingDeleteConfrimationModal } from './jobPostingDeleteConfrimationModal';
import { showLoading, hideLoading } from '../../../store/loading-reducer';

export default function JobPostings() {
    const dispatch = useDispatch();

    const defaultPostingObj = { id: 0, title: '', description: null, active: true, jobPostingCompanies: [] }
    const { jobPostingsList, isJobPostingSaving } = useSelector(state => state.hrBenefits);
    const [showJobPostingAddModal, setShowJobPostingAddModal] = useState(false);
    const [postingModel, setPostingModel] = useState(defaultPostingObj);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (jobPosting) => {
        setPostingModel(jobPosting);
        setShowDeleteModal(true)
    };
    useEffect(() => {
        document.title = 'Job Posting | HR & Benefits | CWP Employee Portal'
    }, []);
    const handleEdit = async (jobPosting) => {
        const result = await dispatch(getjobpostingbyid(jobPosting.id)).unwrap();
        if (result?.data) {
            setPostingModel(result?.data)
        }
        //setPostingModel(jobPosting);
        setShowJobPostingAddModal(true);
    };

    const handleAddPosting = () => {
        setPostingModel(defaultPostingObj);
        setShowJobPostingAddModal(true);
    }

    const handleAddEditPosting = (postingData) => {
        dispatch(addEditJobPosting(postingData));
    }

    const handleDeletePosting = (postingData) => {
        if (postingData.id > 0) {
            dispatch(deleteJobPosting(postingData.id));
        }
    }

    useEffect(() => {
        if (isJobPostingSaving) {
            dispatch(showLoading());
        } else {
            dispatch(hideLoading());
        }
    }, [isJobPostingSaving]);

    return (
        <div className="admin-tab-content" id="jobpostings-tab" role="tabpanel" aria-labelledby="tab1">
            <div className="admin-section">
                {showJobPostingAddModal &&
                    <PostingAddEditModal
                        showJobPost={showJobPostingAddModal}
                        onClose={() => {
                            setPostingModel(defaultPostingObj);
                            setShowJobPostingAddModal(false);
                        }}
                        handleAddEditPosting={handleAddEditPosting}
                        modelPosting={postingModel}
                    />
                }

                <JobPostingDeleteConfrimationModal
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    handleDeleteJobPosting={handleDeletePosting}
                    modelJobPosting={postingModel}
                />
                <div className="admin-section-title-bar job-postings-heading">
                    <div className="admin-section-title-action-wrap">
                        <h2 className="admin-section-headline">Job Postings</h2>
                        <button className="admin-button" onClick={() => handleAddPosting()}>
                            <span className="admin-button-text">Add Posting</span>
                            <span className="material-symbols-rounded" aria-hidden="true">add</span>
                        </button>
                    </div>
                </div>
                <JobPostingContainer
                    jobs={jobPostingsList}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </div>
        </div>
    );
}