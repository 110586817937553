import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DragDropContainer from '../../DragAndDropContainer';
import { updateProjectOrder } from '../../../store/projects/actions';

export const ProjectContainer = ({ projects, onEdit, onDelete, onAddProject }) => {
    const dispatch = useDispatch();

    if (!projects) {
        return <h2>No project exists.</h2>;
    }

    const [localCategories, setLocalCategories] = useState(projects);
    const [trackOrderCategories, setTrackOrderCategories] = useState(projects);

    useEffect(() => {
        if (projects) {
            setLocalCategories(projects);
        }
    }, [projects]);

    const moveCategory = (fromIndex, toIndex, makeApiCall) => {
        const updatedCategories = [...localCategories];
        const [movedItem] = updatedCategories.splice(fromIndex, 1);
        updatedCategories.splice(toIndex, 0, movedItem);

        const newUpdatedCategories = updatedCategories.map((category, index) => ({
            ...category,
            order: index,
        }));

        setLocalCategories(newUpdatedCategories);
        setTrackOrderCategories(newUpdatedCategories);
        if (makeApiCall) {
            setLocalCategories(trackOrderCategories);

            const ids = trackOrderCategories.map((category) => category.id).join(',');
            dispatch(updateProjectOrder(ids));
        }
    };

    const renderCategoryItem = (project) => (
        <div className="admin-draggable-editable-option-cell">
            <div className="galleryItem" data-element="galleryItem">
                <img className="galleryImage" src={project?.projectMedias.length > 0 && project?.projectMedias[0].mediaItem?.previewsrc ? project?.projectMedias[0].mediaItem?.previewsrc : 'https://img.youtube.com/vi/wjQq0nSGS28/maxresdefault.jpg'} alt="asdf" />
                <div className="media-thumbnail-action-wrap">
                    <span className={`media-thumbnail-status ${!project.active ? 'inactive' : ''}`}>{project.active ? 'Active' : 'Inactive'}</span>
                    <button className="media-thumbnail-action red edit" onClick={() => onEdit(project)}>
                        <span className="material-symbols-rounded">edit</span>
                    </button>
                    <button className="media-thumbnail-action red delete" onClick={() => onDelete(project)}>
                        <span className="material-symbols-rounded">delete</span>
                    </button>
                </div>
            </div>
            <label className="admin-default-label">{project.title}</label>
        </div>
    );

    const renderNonDropItem = () => {
        return (
            <div draggable="false">
                <button className="mediaAddVideo add-project-button" onClick={() => onAddProject()}>
                    <span className="mediaAdd-button">Add Project</span>
                </button>
            </div>
        );
    }

    return (
        <DragDropContainer
            items={localCategories}
            moveItem={moveCategory}
            renderItem={renderCategoryItem}
            isHorizontal={false}
            nonDropItem={renderNonDropItem}
        />
    );
};
ProjectContainer.propTypes = {
    projects: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onAddProject: PropTypes.func.isRequired
};
