import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaImageModal from './MediaImageModal';
import MediaVideoModal from './MediaVideoModal';
import axios from 'axios';
import PageLoadingSpinner from '../FullPageLoader';
import profile from '../../assets/profile.jpg';
import ReactDragListView from 'react-drag-listview';
import VideoProcessing from "../../assets/video-processing.jpg";

/*import './mediacomponent.css';*/


function MediaDisplay({ imageId, imageSrc, imageAlt, children }) {
    return (
        <div className="galleryItem node-selector select-handler videoThumbWrap" data-element="galleryItem" data-id={imageId}>
            <img className="galleryImage" src={imageSrc} alt={imageAlt} />
            {children}
        </div>
    )
}
MediaDisplay.propTypes = {
    imageId: PropTypes.number,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    children: PropTypes.node
}

function MediaGallery({ mediaItems, onMediaDelete, onMediaEdit, isPlaceHolder, allowSorting, sortInTable, saveMediaItems }) {

    const handleEditItem = (e) => {
        let itemId = e.target.closest('[data-element="galleryItem"]').dataset.id;
        onMediaEdit(itemId);
    }

    const handleDeleteMedia = (e) => {
        let itemId = e.target.closest('[data-element="galleryItem"]').dataset.id;
        if (itemId !== undefined && itemId != null)
            onMediaDelete(itemId);
    }

    const [sortedMediaItems, setSortedMediaItems] = useState({});

    useEffect(() => {
        setSortedMediaItems(mediaItems);
    }, [mediaItems]);

    if (mediaItems.length > 0) {
        return (
            <>
                {(allowSorting === true) ?
                    <ReactDragListView
                        nodeSelector='.node-selector'
                        handleSelector='.select-handler'
                        allowDragAndDrop={true}
                        onDragEnd={(fromIndex, toIndex) => {
                            const updatedItems = [...sortedMediaItems];
                            const [draggedItem] = updatedItems.splice(fromIndex, 1);
                            updatedItems.splice(toIndex, 0, draggedItem);
                            setSortedMediaItems(updatedItems);
                            console.log(sortInTable);
                            saveMediaItems(updatedItems);
                        }}>
                        <div className="mediaGallery">
                            {sortedMediaItems.map((item, idx) =>
                                <MediaDisplay
                                    key={'mediaItem_' + idx}
                                    imageId={item.id}
                                    imageSrc={item?.videoCustomThumbnailUrl ? item.videoCustomThumbnailUrl : item?.previewsrc && item?.previewsrc == '/images/video-processing.jpg' ? VideoProcessing : item?.previewsrc}
                                    imageAlt={item.altText}
                                >
                                    <div className="media-thumbnail-action-wrap">
                                        {/* Hiding edit for now until we get logic for it in place. User can always delete and add item */}

                                        <button className="media-thumbnail-action red margin-bt-edit-image"
                                            onClick={handleEditItem}
                                        >
                                            <span className="material-symbols-rounded">edit</span>
                                        </button>
                                        <button className="media-thumbnail-action red"
                                            onClick={handleDeleteMedia}
                                        >
                                            <span className="material-symbols-rounded">delete</span>
                                        </button>
                                    </div>
                                </MediaDisplay>
                            )}
                        </div>
                    </ReactDragListView>
                    :
                    <div className="mediaGallery">
                        {mediaItems.map((item, idx) =>
                            <MediaDisplay
                                key={'mediaItem_' + idx}
                                imageId={item.id}
                                imageSrc={item?.videoCustomThumbnailUrl ? item.videoCustomThumbnailUrl : item?.previewsrc && item?.previewsrc == '/images/video-processing.jpg' ? VideoProcessing : item?.previewsrc}
                                imageAlt={item.altText}
                            >
                                <div className="media-thumbnail-action-wrap">
                                    {/* Hiding edit for now until we get logic for it in place. User can always delete and add item */}

                                    <button className="media-thumbnail-action red margin-bt-edit-image"
                                        onClick={handleEditItem}
                                    >
                                        <span className="material-symbols-rounded">edit</span>
                                    </button>
                                    <button className="media-thumbnail-action red"
                                        onClick={handleDeleteMedia}
                                    >
                                        <span className="material-symbols-rounded">delete</span>
                                    </button>
                                </div>
                            </MediaDisplay>
                        )}
                    </div>
                }
            </>
        )
    }
    else {
        return (
            isPlaceHolder ? <img className="galleryImage" src={profile} alt='profileImage' /> : null
        )
    }
}
MediaGallery.propTypes = {
    mediaItems: PropTypes.array,
    onMediaDelete: PropTypes.func,
    onMediaEdit: PropTypes.func,
    isPlaceHolder: PropTypes.bool,
    allowSorting: PropTypes.bool,
    sortInTable: PropTypes.string,
    saveMediaItems: PropTypes.func
}

export default function MediaUploader({
    forceCrop, allowGallery, imageOnly, videoOnly, requireAltText,
    mediaItems, saveMediaItems, customImageSave, isPlaceHolder, allowSorting, sortInTable, idealSizeText, width, higth, validationOnVideoUpload }) {

    const [showImageModal, setShowImageModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [ogImageSrc, setOgImageSrc] = useState(null);
    const [editImageObj, setEditImageObj] = useState(null);
    const [mediaLibrary, setLibrary] = useState(mediaItems ?? []);
    const [showSaving, setShowSaving] = useState(false);

    const handleSaveMedia = async (mediaObj) => {
        setShowSaving(true);
        axios({
            url: '/api/media/add',
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: mediaObj
        })
            .then((result) => {               
                let newMediaItem = {
                    id: result?.data?.data?.id,
                    altText: result?.data?.data?.altText && result?.data?.data?.altText.trim() !== "" ? result?.data?.data?.altText : result?.data?.data?.photo?.originalFileName,
                    previewsrc: result?.data?.previewsrc,
                    mediaId: result?.data?.data.id,
                    photo: result?.data?.data?.photo,
                    cropValues: result?.data?.data?.cropValues,
                    videoThumbnailUrl: result?.data?.data?.videoThumbnailUrl,
                    videoSrc: result?.data?.data?.videoSrc,
                    type: result?.data?.data?.type,
                    isCropped: result?.data?.data?.isCropped,
                    photoId: result?.data?.data?.photoId,
                    videoId: result?.data?.data?.videoId,
                    videoType: result?.data?.data?.videoType,
                    isVideoThumbnail: false,
                    cropPhoto: result?.data?.data?.cropPhoto,
                    cropPhotoId: result?.data?.data?.cropPhotoId,
                    videoCustomThumbnailUrl: (result?.data?.data?.photoId && result?.data?.data?.videoSrc ? result?.data?.previewsrc : ''),
                    selectedVideoSrc: result?.data?.data?.selectedVideoSrc,
                    vimeoVideoFileName: result?.data?.data?.vimeoVideoFileName
                    
                }
                setOgImageSrc(null);
                setShowImageModal(false);
                setShowVideoModal(false);
                // if you want to remove image and add new use this code
                //if (mediaObj != null && mediaObj?.id > 0) {
                //    const enityIndex = mediaLibrary.findIndex(x => x.id === mediaObj.id);
                //    if (enityIndex > -1) {
                //        mediaLibrary.splice(enityIndex, 1);
                //    }
                //}
                //setLibrary([...mediaLibrary, newMediaItem]);

                // if you want to replace if find use this code
                if (mediaObj != null && mediaObj.id > 0) {
                    // Find the index of the existing media item with the same id
                    const entityIndex = mediaLibrary.findIndex(x => x.id === mediaObj.id);

                    if (entityIndex > -1) {
                        // If the existing media item is found, remove it using splice

                        mediaLibrary.splice(entityIndex, 1);


                        // Add the new media item at the same index
                        mediaLibrary.splice(entityIndex, 0, newMediaItem);

                        saveMediaItems([...mediaLibrary]);

                    } else {
                        // If the existing media item is not found, simply add the new media item
                        setLibrary([...mediaLibrary, newMediaItem]);
                        saveMediaItems([...mediaLibrary, newMediaItem]);
                    }
                } else {
                    // Handle the case where mediaObj is null or id is not greater than 0
                    // Add the new media item to the library without removing any existing item
                    setLibrary([...mediaLibrary, newMediaItem]);
                    saveMediaItems([...mediaLibrary, newMediaItem]);
                }



                setShowSaving(false);
            })
            .catch((error) => {
                setShowSaving(false);
                alert(error);
            });
    }


    const handleMediaDelete = (mediaId) => {
        setLibrary(mediaLibrary.filter(x => x.id != mediaId));
        saveMediaItems(mediaLibrary.filter(x => x.id != mediaId));
    }
    const handleMediaEdit = (mediaId) => {
        let mediaObj = mediaLibrary.filter(x => x.id == mediaId)[0];
        setEditImageObj(mediaObj);
        if (mediaObj && mediaObj.type === 0) {
            setShowImageModal(true);
        }
        if (mediaObj && mediaObj.type === 1) {
            setShowVideoModal(true);
        }
    }
    useEffect(() => {
        setLibrary(mediaItems);
    }, [mediaItems]);

    const handleVideo = () => {
        if (showImageModal) setShowImageModal(false);
        if (typeof validationOnVideoUpload !== 'undefined') {
            if (validationOnVideoUpload()) {
                setShowVideoModal(true);
            }
        } else {           
            setShowVideoModal(true);
        }
        setEditImageObj(null)
    }

    return (
        <>
            <MediaGallery
                mediaItems={mediaLibrary}
                onMediaDelete={handleMediaDelete}
                onMediaEdit={handleMediaEdit}
                isPlaceHolder={isPlaceHolder}
                allowSorting={allowSorting}
                sortInTable={sortInTable}
                saveMediaItems={saveMediaItems}
            />
            {(!allowGallery && mediaLibrary.length > 0) ? null : (
                <div className="mediaOptions">
                    {(imageOnly || !videoOnly) ? (
                        <>
                            <label className="mediaAddImage">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setShowImageModal(true);
                                        setOgImageSrc(e.target.files[0])
                                    }} />
                                <span className="mediaAdd-button">Upload Image </span>
                                <div className="image-info">
                                    ideal image size :<br />
                                    {idealSizeText}
                                </div>

                            </label>

                        </>
                    ) : (<></>)}
                    {(videoOnly || !imageOnly) ? (
                        <button
                            className="mediaAddVideo"
                            onClick={() => handleVideo()}
                        >
                            <span className="mediaAdd-button">Add Video</span>
                        </button>
                    ) : (<></>)}
                </div>
            )}
            <MediaVideoModal
                requireAltText={requireAltText}
                showModal={showVideoModal}
                editVideoObj={editImageObj}
                onCloseModal={() => {
                    setEditImageObj(null);
                    setShowVideoModal(false);
                }}
                onSaveModal={handleSaveMedia}
                onMediaDelete={handleMediaDelete}
            />
            <MediaImageModal
                requireAltText={requireAltText}
                showModal={showImageModal}
                forceCrop={forceCrop}
                uploadImageObj={ogImageSrc}
                editImageObj={editImageObj}
                onCloseModal={() => {
                    setShowImageModal(false);
                    setEditImageObj(null);
                }}
                onSaveModal={handleSaveMedia}
                onCustomImageSave={customImageSave}
                cropWidth={width}
                cropHeight={higth}
            />
            <PageLoadingSpinner show={showSaving} />
        </>
    );
}

MediaUploader.propTypes = {
    forceCrop: PropTypes.bool,
    imageOnly: PropTypes.bool,
    videoOnly: PropTypes.bool,
    requireAltText: PropTypes.bool,
    allowGallery: PropTypes.bool,
    mediaItems: PropTypes.array,
    saveMediaItems: PropTypes.func,
    customImageSave: PropTypes.func,
    isPlaceHolder: PropTypes.bool,
    allowSorting: PropTypes.bool,
    sortInTable: PropTypes.string,
    idealSizeText: PropTypes.string,
    width: PropTypes.number,
    higth: PropTypes.number,
    validationOnVideoUpload: PropTypes.func
}

MediaUploader.defaultProps = {
    forceCrop: true,
    imageOnly: false,
    videoOnly: false,
    requireAltText: false,
    allowGallery: true,
    mediaItems: [],
    saveMediaItems: null,
    customImageSave: null,
    allowSorting: false,
    sortInTable: null,
    idealSizeText: '750px x 630px', 

}
