import React from "react";
import { formatPhoneNumberAsYouType } from "../../../helpers/formatterHelper";
import PropTypes from "prop-types";

export const ComposeDropDownOption = ({ innerProps, label, value }) => {
    return (
        <div {...innerProps} className="compose-option-wrapper cursor-pointer">
            <span className="color-black mobile-number">{formatPhoneNumberAsYouType(value)}</span>
            <span className="color-black">{label}</span>
        </div>
    )
}

ComposeDropDownOption.propTypes = {
    innerProps: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.string
};