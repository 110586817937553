import React, { useEffect } from 'react'
import api from '../../utils/api';

export default function TestToken() {

  useEffect(() => {
    api({ url: '/api/test/test-auth', method: 'GET' })
      .then((result) => {
        console.log(result);
      })
      .catch(() => {
      });
  }, []);

  return(<div>Testing Token</div>)
}