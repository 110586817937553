import React, { useRef, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { formatUSD, findNearestIndex } from "../../helpers/formatterHelper";


export default function YtdActualBoxes({ quarterlyBonusMonths, getCurrentMonthNameId, scaleAmount, graphLinesHeight, scaleValue, lastQuarterActualAmount, isNegativeScale, getBonusPercent, getQuarterNameById, getActualProjected, quarterLastMonthsList, windowWidth }) {


	console.log("scaleValue", scaleValue);


	const projectedBox = useRef(null);
	const [projectedBoxHeight, setProjectedBoxHeight] = useState(0);


	useEffect(() => {
		const rect = projectedBox.current?.getBoundingClientRect();
		setProjectedBoxHeight(rect?.width);
	}, [projectedBox, scaleAmount, windowWidth]);

	const BoxPositivePosition = (amount, i, isPeojected) => {

		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.actualProjectedSum !== 0 && item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0));
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.actualProjectedSum));
		const top = itemsIndex[i];

		let bottom;
		if (itemsIndex[i - 1] != undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (lastQuarterActualAmount != 0 && isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;
			}
		}

		let indexCount;
		let css = {};
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: (scaleAmount.length - bottom) * graphLinesHeight, position: 'absolute' }
		}

		
		css = { ...css, height: indexCount * graphLinesHeight }

		

		console.log(isPeojected, projectedBoxHeight);

		return css;
	}

	const BoxNegativePosition = (amount, i) => {
		let items = quarterlyBonusMonths.length > 0 && quarterlyBonusMonths.filter(item => item.consolidatedBonus?.actualProjectedSum !== 0 && item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0));
		const itemsIndex = items.map(item => findNearestIndex(scaleAmount, item.consolidatedBonus?.actualProjectedSum));

		let top = itemsIndex[i];

		let bottom;
		if (itemsIndex[i - 1] !== undefined) {
			bottom = itemsIndex[i - 1];
		} else {
			if (isNegativeScale) {
				bottom = findNearestIndex(scaleAmount, 0);
			} else {
				bottom = 0;		
			}			
		}

		let css = {};
		let indexCount;
		if (bottom === 0) {
			indexCount = scaleAmount.length - top;

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}
		else {
			if (bottom > top) {
				indexCount = bottom - top;
			} else {
				indexCount = top - bottom;
			}

			css = { ...css, bottom: Math.round((scaleAmount.length - top) * graphLinesHeight) }
		}

		css = { ...css, height: indexCount * graphLinesHeight, top: 'auto' }

		css = { ...css, right: 0 }

		return css;
	}

	const getActualDivHeight = () => {
		const index = isNegativeScale ? 0 : null;

		if (index !== null) {
			return { bottom: 0 };
		}
		return null;
	}

	

	

	return (
		<div className="actual-box-outer-wrap" style={getActualDivHeight()}>
			{quarterlyBonusMonths && quarterlyBonusMonths.filter(item => item.id <= getCurrentMonthNameId && (item.consolidatedBonus?.actualAmount != 0 || item.consolidatedBonus?.projectedAmount != 0)).map((month, index) => {
				if (month?.consolidatedBonus?.actualAmount < 0 || month?.consolidatedBonus?.projectedAmount < 0) {					

					const negativeBoxCss = BoxNegativePosition(month?.consolidatedBonus?.actualProjectedSum, index);
					const quarterName = getQuarterNameById(month.quarterlyBonusId);
					const bonusPercent = getBonusPercent(quarterName);
					return (
						<div className="loss-section-wrapper" key={month.id} style={negativeBoxCss}>
							{quarterLastMonthsList.includes(month.monthName) && (
								<p className="box-highlight-number box-highlight-main-number">
									<span className={`bonus-point-box ${bonusPercent === 0 ? "disable-bonus-point" : ""}`}>
										{(
											`${bonusPercent}% Bonus`
										)}
										{bonusPercent === 0 &&
											<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
												<path d="M7.00016 13.6666C6.07794 13.6666 5.21127 13.4916 4.40016 13.1416C3.58905 12.7916 2.8835 12.3166 2.2835 11.7166C1.6835 11.1166 1.2085 10.411 0.858496 9.59992C0.508496 8.78881 0.333496 7.92214 0.333496 6.99992C0.333496 6.0777 0.508496 5.21103 0.858496 4.39992C1.2085 3.58881 1.6835 2.88325 2.2835 2.28325C2.8835 1.68325 3.58905 1.20825 4.40016 0.858252C5.21127 0.508252 6.07794 0.333252 7.00016 0.333252C7.92238 0.333252 8.78905 0.508252 9.60016 0.858252C10.4113 1.20825 11.1168 1.68325 11.7168 2.28325C12.3168 2.88325 12.7918 3.58881 13.1418 4.39992C13.4918 5.21103 13.6668 6.0777 13.6668 6.99992C13.6668 7.92214 13.4918 8.78881 13.1418 9.59992C12.7918 10.411 12.3168 11.1166 11.7168 11.7166C11.1168 12.3166 10.4113 12.7916 9.60016 13.1416C8.78905 13.4916 7.92238 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.48905 12.3333 9.75016 11.8166 10.7835 10.7833C11.8168 9.74992 12.3335 8.48881 12.3335 6.99992C12.3335 6.39992 12.2363 5.82214 12.0418 5.26658C11.8474 4.71103 11.5668 4.19992 11.2002 3.73325L3.7335 11.1999C4.20016 11.5666 4.71127 11.8471 5.26683 12.0416C5.82238 12.236 6.40016 12.3333 7.00016 12.3333ZM2.80016 10.2666L10.2668 2.79992C9.80016 2.43325 9.28905 2.1527 8.7335 1.95825C8.17794 1.76381 7.60016 1.66659 7.00016 1.66659C5.51127 1.66659 4.25016 2.18325 3.21683 3.21659C2.1835 4.24992 1.66683 5.51103 1.66683 6.99992C1.66683 7.59992 1.76405 8.1777 1.9585 8.73325C2.15294 9.28881 2.4335 9.79992 2.80016 10.2666Z" fill="#9E9E9E" />
											</svg>
										}

										{bonusPercent > 0 &&
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.01683 8.51666L4.3835 6.88333C4.2835 6.78333 4.16127 6.73333 4.01683 6.73333C3.87238 6.73333 3.74461 6.78888 3.6335 6.89999C3.5335 6.99999 3.4835 7.12222 3.4835 7.26666C3.4835 7.41111 3.5335 7.53333 3.6335 7.63333L5.66683 9.68333C5.76683 9.78333 5.8835 9.83333 6.01683 9.83333C6.15016 9.83333 6.26683 9.78333 6.36683 9.68333L10.3502 5.69999C10.4613 5.58888 10.5168 5.46111 10.5168 5.31666C10.5168 5.17222 10.4613 5.04444 10.3502 4.93333C10.2391 4.83333 10.1085 4.78611 9.9585 4.79166C9.8085 4.79722 9.6835 4.84999 9.5835 4.94999L6.01683 8.51666ZM7.00016 13.6667C6.08905 13.6667 5.22794 13.4917 4.41683 13.1417C3.60572 12.7917 2.89738 12.3139 2.29183 11.7083C1.68627 11.1028 1.2085 10.3944 0.858496 9.58333C0.508496 8.77222 0.333496 7.91111 0.333496 6.99999C0.333496 6.07777 0.508496 5.21111 0.858496 4.39999C1.2085 3.58888 1.68627 2.88333 2.29183 2.28333C2.89738 1.68333 3.60572 1.20833 4.41683 0.858328C5.22794 0.508328 6.08905 0.333328 7.00016 0.333328C7.92238 0.333328 8.78905 0.508328 9.60016 0.858328C10.4113 1.20833 11.1168 1.68333 11.7168 2.28333C12.3168 2.88333 12.7918 3.58888 13.1418 4.39999C13.4918 5.21111 13.6668 6.07777 13.6668 6.99999C13.6668 7.91111 13.4918 8.77222 13.1418 9.58333C12.7918 10.3944 12.3168 11.1028 11.7168 11.7083C11.1168 12.3139 10.4113 12.7917 9.60016 13.1417C8.78905 13.4917 7.92238 13.6667 7.00016 13.6667Z" fill="#00FF38" />
											</svg>
										}
									</span>
									<p className="nowrap bonus-max-wid">{quarterName} {month.consolidatedBonus?.actualProjectedSum && formatUSD(month.consolidatedBonus?.actualProjectedSum)}</p>
								</p>
							)}							
						</div>
					);
				} else if (month?.consolidatedBonus?.actualAmount > 0 || month?.consolidatedBonus?.projectedAmount > 0) {	

					const isCurrentMonth = getCurrentMonthNameId === month.id ? true : false;
					const boxCss = BoxPositivePosition(month.consolidatedBonus?.actualProjectedSum, index, isCurrentMonth);
					const quarterName = getQuarterNameById(month.quarterlyBonusId);
					const bonusPercent = getBonusPercent(quarterName);
					
	
					return (
						<div className={`${getCurrentMonthNameId === month.id ? 'projected-boxes' : 'actual-boxes'}`} key={month.id} style={boxCss} ref={isCurrentMonth ? projectedBox : null}>
							{quarterLastMonthsList.includes(month.monthName) && (
								<p className="box-highlight-number">
									<span className={`bonus-point-box bonus-line-height ${bonusPercent === 0 ? "disable-bonus-point" : ""}`}>
										{`${bonusPercent}% Bonus`}

										{bonusPercent === 0 && (
											<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
												<path d="M7.00016 13.6666C6.07794 13.6666 5.21127 13.4916 4.40016 13.1416C3.58905 12.7916 2.8835 12.3166 2.2835 11.7166C1.6835 11.1166 1.2085 10.411 0.858496 9.59992C0.508496 8.78881 0.333496 7.92214 0.333496 6.99992C0.333496 6.0777 0.508496 5.21103 0.858496 4.39992C1.2085 3.58881 1.6835 2.88325 2.2835 2.28325C2.8835 1.68325 3.58905 1.20825 4.40016 0.858252C5.21127 0.508252 6.07794 0.333252 7.00016 0.333252C7.92238 0.333252 8.78905 0.508252 9.60016 0.858252C10.4113 1.20825 11.1168 1.68325 11.7168 2.28325C12.3168 2.88325 12.7918 3.58881 13.1418 4.39992C13.4918 5.21103 13.6668 6.0777 13.6668 6.99992C13.6668 7.92214 13.4918 8.78881 13.1418 9.59992C12.7918 10.411 12.3168 11.1166 11.7168 11.7166C11.1168 12.3166 10.4113 12.7916 9.60016 13.1416C8.78905 13.4916 7.92238 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.48905 12.3333 9.75016 11.8166 10.7835 10.7833C11.8168 9.74992 12.3335 8.48881 12.3335 6.99992C12.3335 6.39992 12.2363 5.82214 12.0418 5.26658C11.8474 4.71103 11.5668 4.19992 11.2002 3.73325L3.7335 11.1999C4.20016 11.5666 4.71127 11.8471 5.26683 12.0416C5.82238 12.236 6.40016 12.3333 7.00016 12.3333ZM2.80016 10.2666L10.2668 2.79992C9.80016 2.43325 9.28905 2.1527 8.7335 1.95825C8.17794 1.76381 7.60016 1.66659 7.00016 1.66659C5.51127 1.66659 4.25016 2.18325 3.21683 3.21659C2.1835 4.24992 1.66683 5.51103 1.66683 6.99992C1.66683 7.59992 1.76405 8.1777 1.9585 8.73325C2.15294 9.28881 2.4335 9.79992 2.80016 10.2666Z" fill="#9E9E9E" />
											</svg>
										)}

										{bonusPercent > 0 && (
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.01683 8.51666L4.3835 6.88333C4.2835 6.78333 4.16127 6.73333 4.01683 6.73333C3.87238 6.73333 3.74461 6.78888 3.6335 6.89999C3.5335 6.99999 3.4835 7.12222 3.4835 7.26666C3.4835 7.41111 3.5335 7.53333 3.6335 7.63333L5.66683 9.68333C5.76683 9.78333 5.8835 9.83333 6.01683 9.83333C6.15016 9.83333 6.26683 9.78333 6.36683 9.68333L10.3502 5.69999C10.4613 5.58888 10.5168 5.46111 10.5168 5.31666C10.5168 5.17222 10.4613 5.04444 10.3502 4.93333C10.2391 4.83333 10.1085 4.78611 9.9585 4.79166C9.8085 4.79722 9.6835 4.84999 9.5835 4.94999L6.01683 8.51666ZM7.00016 13.6667C6.08905 13.6667 5.22794 13.4917 4.41683 13.1417C3.60572 12.7917 2.89738 12.3139 2.29183 11.7083C1.68627 11.1028 1.2085 10.3944 0.858496 9.58333C0.508496 8.77222 0.333496 7.91111 0.333496 6.99999C0.333496 6.07777 0.508496 5.21111 0.858496 4.39999C1.2085 3.58888 1.68627 2.88333 2.29183 2.28333C2.89738 1.68333 3.60572 1.20833 4.41683 0.858328C5.22794 0.508328 6.08905 0.333328 7.00016 0.333328C7.92238 0.333328 8.78905 0.508328 9.60016 0.858328C10.4113 1.20833 11.1168 1.68333 11.7168 2.28333C12.3168 2.88333 12.7918 3.58888 13.1418 4.39999C13.4918 5.21111 13.6668 6.07777 13.6668 6.99999C13.6668 7.91111 13.4918 8.77222 13.1418 9.58333C12.7918 10.3944 12.3168 11.1028 11.7168 11.7083C11.1168 12.3139 10.4113 12.7917 9.60016 13.1417C8.78905 13.4917 7.92238 13.6667 7.00016 13.6667Z" fill="#00FF38" />
											</svg>
										)}
									</span>
									<p className="nowrap bonus-max-wid">{quarterName} {formatUSD(getActualProjected(quarterName))}</p>
								</p>
							)}
						</div>
					);
				}
			})}

		</div>
	);
}

YtdActualBoxes.propTypes = {
	quarterlyBonusMonths: PropTypes.array,
	month: PropTypes.object,
	itemindex: PropTypes.number,
	getCurrentMonthNameId: PropTypes.number,
	scaleAmount: PropTypes.array,
	graphLinesHeight: PropTypes.number,
	scaleValue: PropTypes.number,
	lastQuarterActualAmount: PropTypes.number,
	isNegativeScale: PropTypes.bool,
	quarterLastMonthsList: PropTypes.array,
	getBonusPercent: PropTypes.func,
	getQuarterNameById: PropTypes.func,
	getActualProjected: PropTypes.func,
	windowWidth: PropTypes.number
};