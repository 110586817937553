import { createAsyncThunk } from '@reduxjs/toolkit';
import * as composeApi from '../../api/compose';

export const AddNewCompose = createAsyncThunk('AddNewCompose', async (data) => {
    const res = await composeApi.AddNewCompose({ data: data });
    return res.data;
});

export const getScheduledData = createAsyncThunk('getScheduledData', async (data) => {
    const res = await composeApi.getScheduledData({ data: data });
    return res.data;
});

export const cancelScheduleData = createAsyncThunk('cancelScheduledData', async (data) => {
    const res = await composeApi.cancelScheduleDate(data);
    return res.data;
});

export const getComposedById = createAsyncThunk('getComposedById', async (data) => {
    const res = await composeApi.getComposeById({ data: data });
    return res.data;
});
export const GetCountPeoples = createAsyncThunk('GetCountPeoples', async (data) => {
    const res = await composeApi.GetCountPeoples({ data: data });
    return res.data;
});

export const getAllMessage = createAsyncThunk('getAllMessage', async (data) => {
    const res = await composeApi.getAllMessage({ data: data });
    return res.data;
});

export const getSiteUsersByDepartmentIds = createAsyncThunk('getSiteUsersByDepartmentIds', async (data) => {
    const res = await composeApi.getSiteUsersByDepartmentIds({ data: data });
    return res.data;
});
export const getFailedReason = createAsyncThunk('getFailedReason', async (data) => {
    const res = await composeApi.getFailedReason({ data: data });
    return res.data;
});


