import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isNewsLoaded: false,
    newsList: [],
    newsData: []
}

const newsSlice = createSlice({
    name: 'news', 
    initialState,
    extraReducers: (builder) => {

        // Get Events
        builder.addCase(actions.getNews.pending, (state) => {
            state.isNewsLoaded = false;
        })
        builder.addCase(actions.getNews.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.newsList = action.payload.data;
            } else {
                state.newsList = [];
            }
            state.isNewsLoaded = true;
        })
        builder.addCase(actions.getNews.rejected, (state) => {
            state.isNewsLoaded = false;
        }); 

        // delete

        builder.addCase(actions.deleteNews.pending, (state) => {
            state.saving = true;
        })
        builder.addCase(actions.deleteNews.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.newsList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.newsList.splice(enityIndex, 1);
                }
            }
            state.isSaving = false;
        })
        builder.addCase(actions.deleteNews.rejected, (state) => {
            state.isSaving = false;
        });


        builder.addCase(actions.getNewsList.pending, (state) => {
            state.isNewsLoaded = false;
        })
        builder.addCase(actions.getNewsList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.newsData = action.payload.data;
            } else {
                state.newsData = [];
            }
            state.isNewsLoaded = true;
        })
        builder.addCase(actions.getNewsList.rejected, (state) => {
            state.isNewsLoaded = false;
        }); 
    }
});

export default newsSlice.reducer;