import "../EditorModules/module.css";
import React, { useState, useContext } from "react";
import { PageEditorContext } from "./ModuleEditorContext";
import { Freeform } from "../EditorModules/Freeform";
import { MultiMediaSlider } from "../EditorModules/MultiMediaSlider";


import { Button } from "../../../Button/Button";
import { SelectModuleModal } from "./SelectModuleModal";
import { ReactComponent as FreeformIcon } from "../EditorModules/icons/freeform.svg";
import { ReactComponent as VideoIcon } from "../EditorModules/icons/multiMediaSlider.svg";
import PropTypes from 'prop-types';

const iconComponents = {
    1: FreeformIcon,
    2: VideoIcon

};

export const ModuleEditor = () => {
  const {
    pageDetails,
    addModule,
    deleteModule,
    getModuleTypes,
    moveModuleDown,
    moveModuleUp,
  } = useContext(PageEditorContext);
  const [showAddModule, setShowAddModule] = useState(false);

  const AddModuleModal = () => {
    const moduleTypes = getModuleTypes();
    return (
      <div>
        <SelectModuleModal
          modalTitle={"Select Module Type"}
          show={true}
          onClose={() => setShowAddModule(false)}
        >
          <div className="module-add-container">
            {moduleTypes.map((moduleType) => {
              const IconComponent = iconComponents[moduleType.id];
              return (
                <div
                  key={moduleType.id}
                  onClick={() => {
                    addModule(moduleType);
                    setShowAddModule(false);
                  }}
                  className="module-add-modal"
                >
                  {IconComponent && <IconComponent />}
                  <p className="module-add-name">{moduleType.label}</p>
                </div>
              );
            })}
          </div>
        </SelectModuleModal>
      </div>
    );
  };

  return (
    <div className="page-editor"> 
      {showAddModule && AddModuleModal()}

      <div>
        {pageDetails && pageDetails.modules?.length === 0 ? (
          ""
        ) : (
          <div>
            {pageDetails &&
              pageDetails.modules?.map((module, index) => (
                <Module
                  key={module.id}
                  module={module}
                  id={module.id}
                  index={index}
                  moveModuleUp={moveModuleUp}
                  moveModuleDown={moveModuleDown}
                  deleteModule={deleteModule}
                  isFirst={index === 0}
                  isLast={index === pageDetails.modules.length - 1}
                  showAddBtn={module.type == 3 || module.type == 4}
                />
              ))}
          </div>
        )}
      </div>
      <div>
        {!showAddModule && (
          <Button
            className="admin-modal-button"
            size="large"
            label="Add Content  +"
            external={false}
            onClick={() => {
              setShowAddModule(true);
            }}
            style={{ borderRadius: 100, border: "1px #D9D9D9 solid" }}
          />

        )}
      </div>
    </div>
  );
};

const Module = ({
  module,
  id,
  index,
  moveModuleUp,
  moveModuleDown,
  deleteModule,
  isLast,
  isFirst

}) => {


    Module.propTypes = {
        module: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        moveModuleUp: PropTypes.func.isRequired,
        moveModuleDown: PropTypes.func.isRequired,
        deleteModule: PropTypes.func.isRequired,
        isLast: PropTypes.bool.isRequired,
        isFirst: PropTypes.bool.isRequired
    };

  return (
    <div className="module-container">
      <div className="module-container-controls">
        <p className="module-title">
          {module.title}         
        </p>
        <div className="module-controls">
          {!isFirst && (
            <>
              <p className="module-controls-inline-label">move</p>
              <button
                className="module-controls-button"
                onClick={() => moveModuleUp(index)}
              >
                              <span className="material-symbols-rounded">
                  keyboard_arrow_up
                </span>
              </button>
            </>
          )}
          {!isLast && (
            <button
              className="module-controls-button"
              onClick={() => moveModuleDown(index)}
            >
                          <span className="material-symbols-rounded">
                keyboard_arrow_down
              </span>
            </button>
          )}

          <button
            className="module-controls-button"
            onClick={() => deleteModule(id)}
          >
                      <span className="material-symbols-rounded">delete</span>
          </button>
        </div>
      </div>
      {(() => {
        switch (module.type) {
          case 1:
            return (
              <div className="module-content">
                    <Freeform id={module.id} content={module.content} />
                </div>
            );
          case 2:
            return (
              <div className="module-content">
                    <MultiMediaSlider id={module.id} content={module.content} />
              </div>
            );
         
          default:
            return null;
        }
      })()}
    </div>
  );
};
