import MediaUploader from '../../../../components/multipleMedia/MediaUploader.js';
import React, { useState, useContext} from "react";
import { PageEditorContext } from "../ModuleWrapper/ModuleEditorContext";
import PropTypes from 'prop-types';
export const  MultiMediaSlider = ({ id, content }) => {
  const [mediaData, setMediaData] = useState(content || []);
  const { updateModuleContent } = useContext(PageEditorContext);

  const saveMediaItems = (newItems) => {
    setMediaData(newItems);
    updateModuleContent(id, newItems);

  };
  const handleDeleteMedia = (mediaId) => {

    updateModuleContent(id, mediaData.filter((x) => x.id != mediaId));
    setMediaData(mediaData.filter((x) => x.id != mediaId));
  };
  const moveColumn = (fromIndex, toIndex, canDrop) => {
    const updatedMediaData = [...mediaData];
    const [movedItem] = updatedMediaData.splice(fromIndex, 1);
    updatedMediaData.splice(toIndex, 0, movedItem);
    if (canDrop) {
      updateModuleContent(id, updatedMediaData);
      setMediaData(updatedMediaData);
     
    }
  };
    MultiMediaSlider.propTypes = {
        id: PropTypes.string.isRequired,
        content: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
            })
        ).isRequired,
    }; 
  return (
    <div>
          <MediaUploader
           forceCrop={false}
              imageOnly={false}
              allowGallery={true}
              videoOnly={true}
        requireAltText={false}
        mediaItems={mediaData}
        saveMediaItems={saveMediaItems}
        isDnd={true}
        moveColumn={moveColumn}
        delteMediaFromContent={handleDeleteMedia}

      />
    </div>
  );
}
