import { createAsyncThunk } from '@reduxjs/toolkit';
import * as newsApi from '../../api/news';

export const getNews = createAsyncThunk('getNews', async (data) => {
    const res = await newsApi.getNews({ data: data });
    return res.data;
}); 
 
export const deleteNews = createAsyncThunk('deleteNews', async (data) => {
    const res = await newsApi.deleteNews({ data: data });
    return res.data;
});


export const getNewsList = createAsyncThunk('getNewsList', async (data) => {
    const res = await newsApi.getNewsList({ data: data });
    return res.data;
}); 