import api from '../utils/api';

export const getNews = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/news/getAllNews`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const deleteNews = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/news/deleteNews/${data}`,
        method: "DELETE",
        data: data,
        ...options,
    });
}

export const submitNews = (news) => {
    let url = '/api/news/addUpdateNews';
    return api({
        url: url,
        method: 'POST',
        data: news
    })
}
export const getNewsByIdEdit = (newsBaseId) => {
    return api({
        url: '/api/news/getNewsByBaseId/' + newsBaseId,
        method: 'GET'
    })
}


export const getNewsList = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/news/getNews`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const getNewsImage = (media) => {
    let url = '/api/news/getNewsImage';
    return api({
        url: url,
        method: 'POST',
        data: media
    })
}
export const AddUpdatePin = (news) => {
    let url = '/api/news/AddUpdatePin';
    return api({
        url: url,
        method: 'POST',
        data: news
    })
}