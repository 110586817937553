import React, { useEffect, useState, useRef } from "react";
import { GetTimeZone } from "../../components/common/DateTimeFormatter";
import { getAllDocuments } from "../../api/documentLibraryApi";
import { hideLoading, showLoading } from '../../store/loading-reducer';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const getCurrentDateTime = () => {
    const now = new Date();
    const formattedDateTime =
        now.getFullYear().toString() +
        (now.getMonth() + 1).toString().padStart(2, '0') +
        now.getDate().toString().padStart(2, '0') +
        now.getHours().toString().padStart(2, '0') +
        now.getMinutes().toString().padStart(2, '0') +
        now.getSeconds().toString().padStart(2, '0');

    return formattedDateTime
};

export default function DocumentUploader() {
    const dispatch = useDispatch();
    const { token } = useSelector(state => state.auth);
    const [documents, setDocuments] = useState([]);
    const [filter] = useState({ timezone: GetTimeZone() });
    const [document, setDocument] = useState({ name: '', formFile: null, uid: uuidv4(), dateTime: getCurrentDateTime() });
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [linkName, setLinkName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const docuploadersection = useRef(null);
    const [isSetUrl, setIsSetUrl] = useState(false);
    const [isSetLinkText, setIsSetLinkText] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        dispatch(showLoading());
        await getAllDocuments(filter).then((results) => {
            if (results.data.success) {
                setDocuments(results.data.data);
            }
            dispatch(hideLoading());
        });
    };

    useEffect(() => {
        const urlValue = docuploadersection.current?.getAttribute('data-url');
        if (!selectedDocumentUrl && urlValue) {
            setSelectedDocumentUrl(urlValue);
            setIsSetUrl(true);
        }
        const titleValue = docuploadersection.current?.getAttribute('data-title');
        if (!linkName && titleValue) {
            setLinkName(titleValue);
            setIsSetLinkText(true);
        }
    }, [documents]);

    const handleUploadDocument = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        setError('');
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            setDocument({ ...document, formFile: file });
            setIsFileSelected(true);
        }
    };

    const handleNameChange = (e) => {
        setError('');
        setDocument({ ...document, name: e.target.value });
    };

    const handleLinkTextChange = (e) => {
        if (docuploadersection.current) {
            const attributeValue = docuploadersection.current.getAttribute('data-url'); 
            if (isSetUrl !== true && selectedDocumentUrl == '' && attributeValue != undefined && attributeValue != null && attributeValue != '') {
                setSelectedDocumentUrl(attributeValue);
                setIsSetUrl(true);
            }
        }

        setLinkName(e.target.value);
        setIsSetLinkText(true);
    };

    const handleDocumentSelect = (e) => {
        if (docuploadersection.current) {
            const attributeValue = docuploadersection.current.getAttribute('data-title');
            if (isSetLinkText !== true && linkName == '' && attributeValue != undefined && attributeValue != null && attributeValue != '') {
                setLinkName(attributeValue);
                setIsSetLinkText(true);
            }
        }
        setSelectedDocumentUrl(e.target.value);
        setIsSetUrl(true);
    };

    const onSave = async () => {
        if (selectedDocumentUrl) {   
            return;
        }

        if (!document.formFile) {
            setError('Please upload a document file.');
            return;
        }

        if (document.name == '') {
            document.name = fileName;
        }
        setIsLoading(true);

        await handleChunkedUpload(document.formFile);
        
    };


    const handleChunkedUpload = async (file) => {
        const chunkSize = 5 * 1024 * 1024; // 5 MB chunk size
        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;

        const filepath = `${document.uid}/${document.dateTime}`;



        const uploadNextChunk = async () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('docChunk', chunk);
            formData.append('chunkNumber', currentChunk);
            formData.append('fileName', file.name);
            formData.append('filepath', filepath);

            const chunkDocument = {
                formFile: chunk,
                chunkNumber: currentChunk,
                fileName: file.name,
                uID: document.uid,
                dateTime: document.dateTime
            }

            try {
                const response = await axios.post('/api/documents/uploadChunk', chunkDocument, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log('Chunk upload response:', response);

                currentChunk++;

                if (currentChunk < totalChunks) {
                    await uploadNextChunk();
                } else {
                    await finalizeUpload(file.name, totalChunks);
                }
            } catch (error) {
                console.error('Error uploading chunk', error);
                // Handle error as needed
            }
        };

        uploadNextChunk();
    };


    const finalizeUpload = async (fileName, totalChunks) => {
        try {     

            const chunkDocument = {
                totalChunks: totalChunks,
                fileName: fileName,
                uID: document.uid,
                dateTime: document.dateTime,
                name: document.name,
                baseId: document.baseId
            }

            const response = await axios.post('/api/documents/finalizeUpload',
                chunkDocument,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response) {
                setIsLoading(false);
                setIsFileSelected(false);
                if (response.data.success) {
                    fetchDocuments();
                    const url = `/doccenter/` + response.data.data.uid;
                    setSelectedDocumentUrl(url);
                    setFileName('');
                    setDocument({ name: '', formFile: null, uid: uuidv4(), dateTime: getCurrentDateTime() });
                    setError('');
                } else {
                    setError(response.data.message);
                }
            }
        } catch (error) {
            console.error('Error finalizing upload', error);
        }
    };

    return (
        <div ref={docuploadersection} id="docuploadersection" className="admin-page-content documentuploader" data-url="" data-title="">
            <div id="showdocumenterror">

            </div>

            <div className="admin-modal-row">
                <div className="admin-modal-cell">
                    <label className="admin-default-label">Link Text</label>
                    <input
                        type="text"
                        className="admin-default-input"
                        value={linkName || ''}
                        onChange={handleLinkTextChange}
                        id="linktextinput"
                    />
                </div>
            </div>

            <div className="admin-modal-row">
                <div className="admin-modal-cell">
                    <label className="admin-default-label">Select from the Document Library*</label>
                    <select className="admin-default-select" id="selectDocument" data-uploading={isFileSelected ? 'fileSelected' : 'uploaded'} data-linktext={linkName} value={selectedDocumentUrl} onChange={handleDocumentSelect}>
                        <option value="">Select Document</option>
                        {documents.map(document => (
                            <option value={`/doccenter/` + document.uid} key={document.id}>{document.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <br></br>


            {error && (
                <div className="admin-modal-row">
                    <div className="admin-modal-cell">
                        <p className="admin-error" style={{ color: 'red' }}>{error}</p>
                    </div>
                </div>
            )}

            <div className="admin-modal-row">
                <div className="admin-modal-cell">
                    <label className="admin-default-label">Or upload a new document</label>
                    <button
                        className="mediaAdd-button upload-with-spinner"
                        onClick={handleUploadDocument}
                        disabled={isLoading}
                    >
                        Upload Document
                    </button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
            </div>
            {fileName && (
                <div className="admin-modal-row">
                    <div className="admin-modal-cell">
                        <p>Uploaded File: {fileName}</p>
                    </div>
                </div>
            )}

            {fileName && (
                <div className="admin-modal-row">
                    <div className="admin-modal-cell">
                        <label className="admin-default-label">Rename File (optional)</label>
                        <input
                            type="text"
                            className="admin-default-input"
                            value={document.name}
                            onChange={handleNameChange}
                            placeholder="Document Name"
                            disabled={isLoading}
                        />
                    </div>
                </div>
            )}



            <div className="admin-modal-row">
                <div className="admin-modal-cell">
                    <button
                        className="admin-modal-button power"
                        onClick={onSave}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Saving...' : 'Save Document'}
                    </button>
                </div>
            </div>

        </div>
    );
}
