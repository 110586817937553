import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../store/loading-reducer';

export default function DocCenter() {
    const dispatch = useDispatch();
    const { uid } = useParams();

    const [fileURL, setFileURL] = useState(null);
    const [error, setError] = useState(null);
    const [hasDownloaded, setHasDownloaded] = useState(false);


    useEffect(() => {
        if (uid) {
            dispatch(showLoading());
            let isMounted = true;
            const fetchFile = async () => {   
                try {
                    const response = await fetch(`/api/doccenter/${uid}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const contentDisposition = response.headers.get("Content-Disposition");
                    let fileNameFromHeader = null;
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                        if (match) {
                            fileNameFromHeader = match[1];
                        }
                    }
                    const fileNameToUse = fileNameFromHeader ? fileNameFromHeader.replace(/['"]/g, "") : uid;
                                 
                    const blob = await response.blob();
                    const fileType = response.headers.get("Content-Type");

                    const url = URL.createObjectURL(blob);
                    if (isMounted) {
                        setFileURL(url);

                        if (fileType !== "application/pdf") {
                            const link = document.createElement("a");
                            link.href = url;
                            link.download = fileNameToUse;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);                            
                        } else {
                            setHasDownloaded(true);
                        }

                        dispatch(hideLoading());
                 
                    }
                } catch (err) {
                    if (isMounted) {
                        setError(err);
                        dispatch(hideLoading());
                    }
                } finally {
                    if (isMounted) {
                        dispatch(hideLoading());
                    }
                }
            };

            fetchFile();

            return () => {
                isMounted = false;
                if (fileURL) {
                    URL.revokeObjectURL(fileURL);
                }
            };
        }
    }, [uid]);


    if (error) {
        return <p>Error: {error.message}</p>;
    }


    return (
        <div>
            {fileURL && hasDownloaded && (
                <iframe
                    src={fileURL}
                    style={{ width: "100%", height: "100vh" }}
                    title="Document Viewer"
                />
            )}
        </div>
    );
}
