﻿import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import DoclibraryEditing from './doclibraryediting';
import DoclibraryUI from './doclibraryui';


export default class Doclibrary extends Plugin {
	static get requires() {
		return [DoclibraryEditing, DoclibraryUI];
	}
}
