import React, { useEffect } from 'react';


export default function AccessDenied() {
  
  useEffect(() => {
  }, [])

  return (<>
    <p>Access Denied</p>
  </>)
}
